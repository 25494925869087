import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import PicturesViews from './PicturesViews'
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import TextHidePicture from './TextHidePicture';

const PicturesMiniRow = ({
    typeImagesSlider,
    setCurrentIdPicture,
    currentIdPicture,
    setTypeImagesSlider,
    itemsPicture,
    handleClick,
    handleClickButtonSuccess,
    type,
    visibleImg
}) => {
    const typeImages = typeImagesSlider === "updateMainImage"
    if (!itemsPicture) return null

    const handleClickPicture = (picture) => {
        let click;
        if (typeImages) {
            if (currentIdPicture === picture._id) {
                setCurrentIdPicture(null)
            } else {
                setCurrentIdPicture(picture._id)
            }
        } else {
            click = handleClick(picture._id)
        }
        return click
    }
    const handleCloseUpdateMainImage = () => {
        setTypeImagesSlider("")

    }

    return (
        <div className="pictureMiniRow">
            {typeImages
                ? <div className="pictureMiniRow__titleBlock">
                    <div className="pictureMiniRow__title">
                        Выбрать главное фото
                    </div>
                    <div className="pictureMiniRow__closeIcon"
                        onClick={handleCloseUpdateMainImage}
                    >
                        <CloseIcon />
                    </div>
                </div>
                : null
            }
            <div className="pictureMiniRow__items">
                {itemsPicture.map((picture, index) =>
                    <>
                        <div
                            // className={`pictureMiniRow__imgBox ${typeImages ? "updateMainImage" : ""} ${picture.mainImg ? "mainImg" : ""}`}
                            className={`pictureMiniRow__imgBox 
                            ${currentIdPicture === picture._id && typeImages ? "active" : ""}
                            ${picture.mainImg ? "mainImg" : ""}
                            `}
                            onClick={() => handleClickPicture(picture)}
                            key={index}
                        >
                            <img src={generatePublicUrl(picture.img)} />
                            <PicturesViews
                                type={type}
                                picture={picture}
                            />
                            <TextHidePicture
                                visibleImg
                                pictureVisible={picture.visibleImg}
                            />
                        </div>
                    </>
                )
                }
            </div>
            {currentIdPicture && typeImages
                ? <div>
                    <h5>
                        Назначить основным фото
                    </h5>
                    <Button
                        variant="contained"
                        onClick={handleClickButtonSuccess}
                    >
                        Подтвердить
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setCurrentIdPicture(null)}
                    >
                        Отменить
                    </Button>
                </div>
                : null
            }
        </div>
    )
}

export default PicturesMiniRow