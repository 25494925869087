import React, { useState } from 'react'
import ProductImgSliderModal from './../../components/ProductImgSliderModal/ProductImgSliderModal';
import CheckboxLabels from './../../components/Checkbox/CheckboxLabels';
import { updateProductMaterialById } from '../../actions/product.action';

const RenderMaterialProductModal = ({
    show,
    handleClose,
    modalTitle,
    productDetails
}) => {
    const [type, setType] = useState("")

    if (!productDetails) return null

    return (
        <ProductImgSliderModal
            show={show}
            handleClose={handleClose}
            modalTitle={modalTitle}
            size="md"
        >
            {productDetails?.material?.map((obj) => (
                <div key={obj._id}>
                    <CheckboxLabels
                        obj={obj}
                        productId={productDetails._id}
                        setType={setType}
                    // setData
                    />
                </div>


            ))}

        </ProductImgSliderModal>
    )
}

export default RenderMaterialProductModal