import React from 'react'

const EmptyMessage = ({
    message
}) => {
    return (
        <div style={{
            fontWeight: "600",
            marginTop: "10px",
            textAlign: "center"
        }}>
            {message ? message : "Пусто"}
        </div>
    )
}

export default EmptyMessage