import React, { useEffect } from 'react'

const ErrorTitle = ({
    time,
    text,
    setShow,
    show
}) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                setShow(false)
            }, time ? time : 4000);
            return () => clearTimeout(timer);
        }
    }, [show]);

    if (!show) return null
    return (
        <div className="errorTitle">
            <div className="errorTitle__text">
                {text}
            </div>
        </div>
    )
}
export default ErrorTitle