import React from 'react'
import SettingIconBlock from './../../components/Button/SettingIconBlock';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PicturesMiniRow from '../../components/Pictures/PicturesMiniRow';
import { useDispatch } from 'react-redux';
import { getGalleryById } from '../../actions';
import ContainerModalConfirmPlusIcon from '../Orders/ContainerModalConfirmPlusIcon';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
const RenderItem = ({
    item,
    setCurrentId,
    setTypeContent,
    handleDetailsShow,
    handleShowModalImg,
    setCurrentIdPicture,
    setShowDeleteConfirm,
    showDeleteConfirm,
    confirmDelete
}) => {
    const dispatch = useDispatch();
    const handleClick = async (pictureImg) => {
        handleDetailsShow(item._id, "details")
        await dispatch(getGalleryById(item._id))
        handleShowModalImg(pictureImg)
    }
    return (
        <div className="galleryPage__renderItem">
            <div className="galleryPage__headerRow">
                <div className="galleryPage__name"
                    onClick={() => handleDetailsShow(item._id, "details")}
                >
                    <h3>{item.name} </h3>
                </div>

                <ContainerModalConfirmPlusIcon
                    tooltip="Удалить эту галерею"
                    top="5px"
                    right="60px"
                    icon={<DeleteForeverTwoToneIcon />}
                    onClickShow={() => setShowDeleteConfirm(true)}
                    title={`Подтвердить удаление галереи: ${item.name}`}
                    description="Если вы продолжите то это действие будет невозможно вернуть, галерея удалится навсегда!"
                    show={showDeleteConfirm}
                    handleClose={() => setShowDeleteConfirm(false)}
                    onClick={() => confirmDelete(item._id)}
                />
                <SettingIconBlock
                    tooltip="Добавить"
                    icon={<AddCircleOutlineIcon />}
                    right="40px"
                    top="5px"
                    onClick={() => handleDetailsShow(item._id, "addPhoto")}
                />
                <SettingIconBlock
                    tooltip="Настройки"
                    onClick={() => handleDetailsShow(item._id, "setting")}
                    right="10px"
                    top="5px"
                />
            </div>
            <PicturesMiniRow
                itemsPicture={item.galleryPictures}
                handleClick={handleClick}
            />
            {/* <div className="galleryPage__imgRow" >
                <div>
                    {item.galleryPictures?.map((picture) =>
                        <img
                            key={picture._id}
                            style={{ margin: "10px", height: "70px", width: "auto" }}
                            src={generatePublicUrl(picture.img)}
                            alt={item.name}
                        />

                    )}
                </div>

            </div> */}

            <div className="galleryPage__quantityAddedPhoto">
                <div>Всего фото: {item.countPictures}</div>
            </div>
            <div className="galleryPage__quantityAddedPhoto">
                <div>Переход на страницу: {item.views.length}</div>
            </div>
        </div>
    )
}

export default RenderItem