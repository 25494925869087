
import React, { useState, useEffect } from "react";
import { deleteProductById, getProductById, getProductsBySlug, updateAllMaterialProduct } from './../../actions/product.action';
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { getMaterial, getProducts, searchProducts } from "../../actions";
import { MainContainer } from '../MainContainer';
import { DetailsProduct } from "../render/DetailsProduct";
import AlertComponent from './../../components/Alert/Alert';
import { AddProductModal } from "../create/AddProductModal";
import { setCloseAlert } from "../../reducers/product.reducer";
import CategoryContainer from "../../components/CategoryContainer";
import ModalConfirm from './../../components/BurgerMenu/ModalConfirm';
import RenderFileContainer from '../../components/RenderFileContainer';
import RenderSettingProductModal from "../render/RenderSettingProductModal";
// import ButtonSecondary from './../../components/Button/ButtonSecondary';
import ContainerButtonVisible from './../../components/ContainerButtonVisible';
import RenderMaterialProductModal from './../render/RenderMaterialProductModal';
import RenderSettingCategoryModal from './../../components/Modal/RenderSettingCategoryModal';
import EditProductCategories from './EditProductCategoryForm';
// import SortForAdmin from "./SortForAdmin";


const Products = () => {
  const product = useSelector((state) => state.product)
  const material = useSelector((state) => state.material.material)
  const loadingCategory = useSelector((state) => state.category.loading)
  const categoryDetails = useSelector((state) => state.category.categoryDetails)

  const dispatch = useDispatch();

  const {
    view,
    sort,
    loading,
    message,
    products,
    pageSize,
    showAlert,
    variantAlert,
    productDetails,
    activeCategory,
    loadingProductDetails,
    totalPages,
    totalAll,
  } = product

  const [checkedCategoryVisible, setCheckedCategoryVisible] = useState(categoryDetails?.visibleCategory);
  const [showSettingCategoryModal, setShowSettingCategoryModal] = useState(false)
  const [showActiveCategory, setShowActiveCategory] = useState(activeCategory);
  const [checked, setChecked] = useState(categoryDetails?.visibleAllProductPrice);
  const [productDetailModal, setProductDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newCategoryId, setNewCategoryId] = useState(null);
  const [showModalImg, setShowModalImg] = useState(false);
  const [showMaterialModal, setShowMaterialModal] = useState(false);
  const [showSModal, setShowSModal] = useState(false);
  const [searchMainName, setSearchMainName] = useState("")
  const [categoryItem, setCategoryItem] = useState(null);
  const [_pageSize, setPageSize] = useState(pageSize);
  const [hideMarked, setHideMarked] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [productPictures, setProductPictures] = useState([]);
  const [currentIdPicture, setCurrentIdPicture] = useState(null)
  const [typeModal, setTypeModal] = useState(null)
  const [updatedPictures, setUpdatedPictures] = useState("all")
  const [typePictureStatus, setTypePictureStatus] = useState("inProgress")
  const [valueTabsSort, setValueTabsSort] = useState("all")
  const [sortSearch, setSortSearch] = useState("categoryNamePlusMaterialText")

  const [productForm, setProductForm] = useState({
    name: "",
    quantity: "",
    price: "",
    visibilityPrice: "",
    description: "",
    descriptionNew: "",
    categoryId: "",
    size: "",
    weight: "",
    height: "",
    width: "",
    length: "",
    nameMaterial: "",
    numberMaterialName: "",
    categoryName: "",
    categorySlug: "",
    categoryOnlyLatterName: "",
    visibleProduct: "",
    visibleWatermark: "",
    addWhiteBackground: ""
  });
  const clear = () => {
    setCurrentId(null)
    setProductForm({
      name: "",
      quantity: "",
      price: "",
      visibilityPrice: "",
      description: "",
      descriptionNew: "",
      categoryId: "",
      weight: "",
      size: "",
      height: "",
      width: "",
      length: "",
      nameMaterial: "",
      numberMaterialName: "",
      categoryName: "",
      categorySlug: "",
      visibleWatermark: "",
      addWhiteBackground: ""
    })
  }
  const handleShow = () => setShow(true)

  const handleShowModalImg = (pictureId) => {
    setCurrentIdPicture(pictureId)
    setShowModalImg(true)
  }

  const handleClose = () => {
    clear()
    setShow(false)

  }
  const { slug } = useParams()

  const showAllProductsButton = () => {
    setPageNumber(0)
    dispatch(getProducts(sort, pageNumber, _pageSize))

  }
  useEffect(() => {
    dispatch(getMaterial())
  }, [])

  useEffect(() => {
    if (slug !== "all") {
      dispatch(getProductsBySlug(slug, sort, pageNumber, _pageSize, updatedPictures))
    }
  }, [slug, checked, checkedCategoryVisible])

  const showSettingModal = (item) => {
    setShowSModal(true)
    dispatch(getProductById(item._id))
  }
  const handleShowMaterialModal = (item) => {
    setShowMaterialModal(true)
    dispatch(getProductById(item._id))
  }
  const showProductDetailsModal = (product, _typeModal) => {
    setTypeModal(_typeModal)
    dispatch(getProductById(product._id))
    setProductDetailModal(true);
  };
  const confirmDelete = () => {
    dispatch(deleteProductById(deleteId, slug))
    setShowDeleteModal(false)
  }
  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  const renderDeleteModal = () => {
    return (
      <ModalConfirm
        description="Если в действительно хотите удалить этот продукт, подтвердите это"
        title={"Удалить продукт"}
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        onClick={confirmDelete}
      />
    )
  }
  return (
    <MainContainer
      sortSearchBlock
      valueTabsSort={valueTabsSort}
      setUpdatedPictures={setUpdatedPictures}
      setSortSearch={setSortSearch}
      sortSearch={sortSearch}
      setSearchMainName={setSearchMainName}
      updatedPictures={updatedPictures}
      buttonText={"Добавить продукт"}
      setPageNumber={setPageNumber}
      getSlug={getProductsBySlug}
      setPageSize={setPageSize}
      search={searchProducts}
      hideMarked={hideMarked}
      pageNumber={pageNumber}
      handleShow={handleShow}
      pageSize={_pageSize}
      type="fullFunctional"
      backButtonProduct
      title="Товары"
      slug={slug}
      view={view}
      productSort
      // tabs
      // itemsType="products"
      setValueTabsSort={setValueTabsSort}
      totalPages={totalPages}
      totalAll={totalAll}
      pagination="onlyButton"
    >
      <CategoryContainer
        showAllProductsButton={showAllProductsButton}
        setShowActiveCategory={setShowActiveCategory}
        variantRender="productCategory"
        setCategoryItem={setCategoryItem}
        setPageNumber={setPageNumber}
        categoryItem={categoryItem}
        checked={checked}
      />
      <Container>
        {/* <SortForAdmin
          setUpdatedPictures={setUpdatedPictures}
          updatedPictures={updatedPictures}
        /> */}
        <EditProductCategories
          products={products}
          slug={slug}
        />
        <RenderSettingCategoryModal
          categoryObj={categoryDetails === null ? categoryDetails : categoryItem}
          setShowSettingCategoryModal={setShowSettingCategoryModal}
          setCheckedCategoryVisible={setCheckedCategoryVisible}
          showSettingCategoryModal={showSettingCategoryModal}
          checkedCategoryVisible={checkedCategoryVisible}
          loadingCategory={loadingCategory}
          setChecked={setChecked}
          checked={checked}
          slug={slug}
        />
        <ContainerButtonVisible
          setHideMarked={setHideMarked}
          hideMarked={hideMarked}
          slug={slug}
        />
        <Row>
          <Col>
            {/* <ButtonSecondary
              disabled
              onClick={() => dispatch(updateAllMaterialProduct())}
              text="Обновить все материалы"
            /> */}
            <RenderFileContainer
              categoryObj={categoryDetails === null ? categoryDetails : categoryItem}
              setShowSettingCategoryModal={setShowSettingCategoryModal}
              handleShowMaterialModal={handleShowMaterialModal}
              showDetailsModal={showProductDetailsModal}
              setShowDeleteModal={setShowDeleteModal}
              showMaterialModal={showMaterialModal}
              showSettingModal={showSettingModal}
              newCategoryId={newCategoryId}
              setCurrentId={setCurrentId}
              setTypeModal={setTypeModal}
              setDeleteId={setDeleteId}
              hideMarked={hideMarked}
              fileView={view}
              loading={loading}
              setShow={setShow}
              checked={checked}
              items={products}
              slug={slug}
              setType={setTypePictureStatus}
              type={typePictureStatus}
            />
          </Col>
        </Row>
      </Container>
      <AddProductModal
        slug={slug}
        show={show}
        product={products}
        material={material}
        currentId={currentId}
        handleClose={handleClose}
        productForm={productForm}
        setCurrentId={setCurrentId}
        setProductForm={setProductForm}
        setProductPictures={setProductPictures}
        productPictures={productPictures}
      />
      <DetailsProduct
        detailsTypeModal={typeModal}
        currentIdPicture={currentIdPicture}
        setCurrentIdPicture={setCurrentIdPicture}
        loadingProductDetails={loadingProductDetails}
        setProductDetailModal={setProductDetailModal}
        productDetailModal={productDetailModal}
        handleShowModalImg={handleShowModalImg}
        setShowModalImg={setShowModalImg}
        productDetails={productDetails}
        showModalImg={showModalImg}
        setProductPictures={setProductPictures}
        productPictures={productPictures}

      />
      <RenderMaterialProductModal
        modalTitle={`Настройки материалов: ${productDetails?.name}`}
        handleClose={() => setShowMaterialModal(false)}
        productDetails={productDetails}
        show={showMaterialModal}
      />
      <RenderSettingProductModal
        modalTitle={`Настроить отображение товара" ${productDetails?.name} "`}
        handleClose={() => setShowSModal(false)}
        productDetails={productDetails}
        show={showSModal}
      />
      {renderDeleteModal()}
      <AlertComponent
        dispatchCloseAlert={setCloseAlert}
        color={variantAlert}
        showObj={showAlert}
        text={message}
      />
    </MainContainer>
  );
};

export default Products;
