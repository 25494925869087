import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getApplication, searchApplication, deleteApplicationById, updateApplicationStatus } from './../../actions/application.action';
import { MainContainer } from '../MainContainer';
import { RenderApplication } from './RenderApplication';
import { getItemById } from '../../actions/mainActions/mainAction';
import RenderDetailsModal from './RenderDetailsModal';
import AlertComponent from '../../components/Alert/Alert';
import { setCloseAlertApplication } from '../../reducers/application.reducer';
import RenderImgSliderModal from './../../components/ProductImgSliderModal/RenderImgSliderModal';

const Applications = () => {
    const { applications, applicationObj, showAlert, variantAlert, message, loading } = useSelector(state => state.application);
    const { user } = useSelector(state => state.auth);

    const [applicationDetailModal, setApplicationDetailModal] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [type, setType] = useState("")
    const [showModalImg, setShowModalImg] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getApplication())
    }, [])

    const showModal = (id) => {
        if (selectedItemId === id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(id);
            dispatch(getItemById("application", id));
        }

        setApplicationDetailModal(true);
    };
    const handleClose = () => {
        setApplicationDetailModal(false);
        setTypeModal("")
    };
    const handleDeleteById = () => {
        dispatch(deleteApplicationById(applicationObj._id))
        setApplicationDetailModal(false)
    }
    const handleUpdateStatus = (id) => {
        const payload = {
            applicationId: id,
            type
        }
        dispatch(updateApplicationStatus(payload))
    }

    return (
        <MainContainer
            backButtonProduct
            type
            backHref
            search={searchApplication}
            get={getApplication}
            title={"Запросы на Звонок"}
        >
            <Row style={{ marginTop: "15px" }}>
                <Col>
                    <RenderApplication
                        applications={applications}
                        setTypeModal={setTypeModal}
                        showModal={showModal}
                    />
                </Col>
            </Row>
            <RenderDetailsModal
                handleUpdateStatus={handleUpdateStatus}
                setShowModalImg={setShowModalImg}
                role={user.role}
                setType={setType}
                handleClose={handleClose}
                typeModal={typeModal}
                applicationObj={applicationObj}
                handleDeleteById={handleDeleteById}
                applicationDetailModal={applicationDetailModal}
            />
            <AlertComponent
                dispatchCloseAlert={setCloseAlertApplication}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
            <RenderImgSliderModal
                handleClose={() => setShowModalImg(false)}
                productDetails={applicationObj}
                disableRemoveFunctional
                item={applicationObj?.samplePhoto}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            // loading={loading}
            // slug={slug}
            />
        </MainContainer>
    )
}

export default Applications
