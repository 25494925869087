import { applicationChangeProfileConstants, mainActionsConstants } from "../actions/constants";
import { } from '../actions/constants';

const initialState = {
    applicationsChangeProfile: [],
    applicationChangeProfileObj: {},
    message: "",
    showAlert: false,
    variantAlert: "success",
    loading: false

};

export default (state = initialState, action) => {
    switch (action.type) {
        case applicationChangeProfileConstants.GET_ALL_APPLICATION_CHANGE_PROFILE_SUCCESS:
            state = {
                ...state,
                applicationsChangeProfile: action.payload.applicationsChangeProfile
            }
            break;
        case applicationChangeProfileConstants.DELETE_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS:
            state = {
                ...state,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;

        case applicationChangeProfileConstants.DELETE_MANY_REQUEST:
            state = {
                ...state,
                loading: true
            };
        case applicationChangeProfileConstants.DELETE_MANY_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;
        case applicationChangeProfileConstants.DELETE_MANY_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case applicationChangeProfileConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
        case applicationChangeProfileConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                applicationChangeProfileObj: action.payload?.applicationsChangeProfile,
                loading: false,
            };
            break;
        case applicationChangeProfileConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case mainActionsConstants.CANCELLATION_STATUS_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case mainActionsConstants.CANCELLATION_STATUS_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                applicationChangeProfileObj: action.payload.result,
                showAlert: true,
            };
            break;
        case mainActionsConstants.CANCELLATION_STATUS_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
            };
            break;
        case applicationChangeProfileConstants.UPDATE_STATUS_REQUEST:
            state = {
                ...state,
            }
            break;
        case applicationChangeProfileConstants.UPDATE_STATUS_SUCCESS:
            state = {
                ...state,
                applicationsChangeProfile: action.payload.applicationsChangeProfile,
                applicationChangeProfileObj: action.payload.result,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case applicationChangeProfileConstants.UPDATE_STATUS_FAILURE:
            state = {
                ...state,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            }
            break;
        case applicationChangeProfileConstants.CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false
            }
            break;
        default:
            return state
    }

    return state;
}
export const setCloseAlertApplicationChangeProfile = (payload) => ({ type: applicationChangeProfileConstants.CLOSE_ALERT, payload })

export const setApplicationChangeProfile = (applicationsChangeProfile) => ({ type: applicationChangeProfileConstants.GET_ALL_APPLICATION_CHANGE_PROFILE_SUCCESS, payload: applicationsChangeProfile })
