import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { deleteGalleryById, getItemById } from '../../actions';
import Loader from '../../components/Loader';
import RenderItem from './RenderItem';
import { Button } from 'react-bootstrap';

const RenderGallery = ({
    galleries,
    setTypeModal,
    handleShowModalImg,

    typeModal,
    setValueTabsSort,
    currentId,
    setCurrentId,
    typeContent,
    loading,
    setShow,
}) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const dispatch = useDispatch()

    const handleDetailsShow = (id, type) => {
        setCurrentId(id)
        setTypeModal(type)
        setShow(true)
    }

    const confirmDelete = (id) => {
        dispatch(deleteGalleryById(id))
    }

    const handleTypeModal = (item) => {
        let content;
        switch (typeContent) {
            case "analytics": content = <>
                <div>
                    <div>
                        Всего просмотров
                    </div>
                </div>
            </>
                break;
            default: content = <RenderItem
                setShowDeleteConfirm={setShowDeleteConfirm}
                handleShowModalImg={handleShowModalImg}
                showDeleteConfirm={showDeleteConfirm}
                handleDetailsShow={handleDetailsShow}
                setTypeContent={setValueTabsSort}
                confirmDelete={confirmDelete}
                setCurrentId={setCurrentId}
                key={item._id}
                item={item}
            />
        }
        return content
    }
    if (loading) return <Loader />
    return (
        <div className="galleryPage">
            {galleries.map((item) => handleTypeModal(item))}
        </div>
    )
}

export default RenderGallery