import axios from "../helpers/axios"
import { updatedProductListLink } from "./api/productListLink";
import { productListLinkConstants } from "./constants";


export const getProductListLink = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: productListLinkConstants.GET_PRODUCT_LIST_LINK_REQUEST })
            const res = await axios.get("/productListLink/get")
            if (res.status === 200) {
                dispatch({
                    type: productListLinkConstants.GET_PRODUCT_LIST_LINK_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: productListLinkConstants.GET_PRODUCT_LIST_LINK_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
export const getByIdProductListLink = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_REQUEST })
            const res = await axios.get(`/productListLink/getById/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addProductListLink = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productListLinkConstants.ADD_PRODUCT_LIST_LINK_REQUEST })
            const res = await axios.post("/productListLink/create", payload)
            if (res.status === 201) {
                dispatch({ type: productListLinkConstants.ADD_PRODUCT_LIST_LINK_SUCCESS })
                dispatch(getProductListLink())
            } else {
                dispatch({ type: productListLinkConstants.ADD_PRODUCT_LIST_LINK_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateProductListLink = (id, post) => {
    return async (dispatch) => {
        try {
            const data = await updatedProductListLink(id, post)
            dispatch({
                type: productListLinkConstants.UPDATE_PRODUCT_LIST_LINK_SUCCESS, payload: data
            })
            dispatch(getProductListLink())
        } catch (e) {
            console.log(e)
        }
    }
}

export const deleteProductListLink = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productListLinkConstants.DELETE_PRODUCT_LIST_LINK_REQUEST })
            const res = await axios.delete(`/productListLink/delete/${id}`)
            if (res.status === 202) {
                dispatch({ type: productListLinkConstants.DELETE_PRODUCT_LIST_LINK_SUCCESS })
                dispatch(getProductListLink())
            } else {
                const { error } = res.data
                dispatch({
                    type: productListLinkConstants.DELETE_PRODUCT_LIST_LINK_FAILURE,
                    payload: {
                        error
                    }
                })
            }
        } catch (e) {
            console.log(e.message)
        }
    }
}