import React from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';

const VisibleIconComponent = ({
    handleClick,
    itemVisible,
    itemId,
    text,
}) => {
    return (
        <div className="file__visibleIconBlock"
            onClick={() => handleClick(itemId)}
        >
            {itemVisible
                ? <Tooltip
                    title={`Скрыть ${text}`}>
                    <VisibilityIcon />
                </Tooltip>
                : <Tooltip
                    title={`Показать ${text}`}>
                    <VisibilityOffIcon style={{ color: "black" }} />
                </Tooltip>
            }
        </div>
    )
}

export default VisibleIconComponent