import React from 'react'
import { Tooltip } from '@material-ui/core'

const PicturesViews = ({
    type,
    picture,
    right,
    top,
    tooltip,
    color
}) => {
    return (
        <div className={`pictureMiniRow__views ${color ? color : ""}`}
            style={{
                right: right ? right : "",
                top: top ? top : ""
            }}
        >
            <Tooltip title={tooltip ? tooltip : type === "product" ? "Очередность" : "Просмотров"}>
                <span>
                    {picture.views?.length || type === "product" && picture.order}
                </span>
            </Tooltip>
        </div >
    )
}

export default PicturesViews