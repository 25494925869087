import { pickupPointsConstants } from "../../actions/constants"


const initialState = {
    pickupPoints: [],
    pickupPointsObj: {},
    loading: false
}


export default (state = initialState, action) => {
    switch (action.type) {
        case pickupPointsConstants.DELETE_PICKUP_POINTS_SUCCESS:
            return state.pickupPoints.filter((pickupPoints) => pickupPoints._id !== action.payload)
        case pickupPointsConstants.UPDATE_PICKUP_POINTS_SUCCESS:
            return state.pickupPoints.map((pickupPoints) => pickupPoints._id === action.payload._id ? action.payload : pickupPoints)

        case pickupPointsConstants.GET_PICKUP_POINTS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case pickupPointsConstants.GET_PICKUP_POINTS_BY_ID_SUCCESS:
            state = {
                ...state,
                pickupPointsObj: action.payload.pickupPoints,
                loading: false
            }
            break
        case pickupPointsConstants.GET_PICKUP_POINTS_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case pickupPointsConstants.ADD_PICKUP_POINTS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case pickupPointsConstants.ADD_PICKUP_POINTS_SUCCESS:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
                loading: false
            }
            break
        case pickupPointsConstants.ADD_PICKUP_POINTS_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case pickupPointsConstants.UPDATE_PICKUP_POINTS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case pickupPointsConstants.UPDATE_PICKUP_POINTS_SUCCESS:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
                loading: false
            }
            break
        case pickupPointsConstants.UPDATE_PICKUP_POINTS_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case pickupPointsConstants.GET_PICKUP_POINTS_SUCCESS:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
            }
            break
        default:
            return state

    }
    return state
}