import React, { useState } from 'react';
import { generatePublicUrl } from '../../urlConfig';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const MailingRenderProductItems = ({
    productArray,
    mainImage,
    contact
}) => {
    const [type, setType] = useState("server")

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert(`Скопировано: ${text}`);
    };

    const itemData = {
        mobileNumber: type === "server" ? contact[0].number : "+7(123) 456-78 - 90",
        email: type === "server" ? contact[0].email : "test"
    }

    return (
        <div style={{
            width: "100%",
            background: 'rgb(236, 236, 236)',
            padding: '20px',
        }}>
            <div style={{
                fontFamily: 'Arial, sans-serif',
                background: 'white',
                borderRadius: '5px',
                textAlign: 'center',
                padding: '20px',
                maxWidth: '600px',
                margin: "0 auto",
            }}>
                <div style={{
                    marginBottom: "20px"
                }}>
                    <div style={{
                        width: '50%',
                        display: 'inline-block',
                        textAlign: 'left',
                    }}>
                        <img
                            style={{
                                width: "120px",
                                height: "120px"
                            }}
                            src={generatePublicUrl(mainImage[0].image)} alt="" />
                    </div>
                    <a
                        href='http://alotb.ru'
                        style={{
                            color: '#007bff',
                            textDecoration: 'none',
                            width: '50%',
                        }}>
                        Перейти на наш сайт
                    </a>
                </div>
                <h2 style={{
                    color: '#333',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '10px'
                }}>
                    Здравствуйте, представляем вам наш каталог!
                </h2>
                <p style={{
                    color: '#666',
                    fontSize: '16px',
                    marginBottom: '20px'
                }}>
                    Мы недавно открыли интернет-магазин и хотим поделиться с вами нашими лучшими предложениями.
                </p>
                <div style={{
                    textAlign: 'center'
                }}>
                    {productArray && productArray.map((item) => (
                        <div key={item.productId._id} style={{
                            display: 'inline-block',
                            maxWidth: '300px',
                            minWidth: '250px',
                            margin: '10px'
                        }}>
                            <div style={{
                                marginBottom: '10px'
                            }}>
                                <img
                                    style={{
                                        height: '120px',
                                        width: 'auto',
                                        borderRadius: '5px'
                                    }}
                                    src={generatePublicUrl(item.reducedImage)}
                                    alt={item.productId.fullName}
                                />
                            </div>
                            <div style={{
                                color: "black",
                                fontSize: '16px',
                                textDecoration: 'none',
                                marginBottom: '10px'
                            }}>
                                {item.productId.fullName}
                            </div>
                            <a
                                style={{
                                    background: '#349a87',
                                    color: "black",
                                    padding: "9px 20px",
                                    fontSize: '16px',
                                    textDecoration: 'none',
                                    marginTop: '10px'
                                }}
                                href={`https://alotb.ru/productDetails/${item.productId._id}`}
                            >
                                Подробнее
                            </a>
                        </div>
                    ))}
                </div>
                <p style={{
                    color: '#666',
                    fontSize: '16px',
                    marginTop: '20px'
                }}>
                    Полный каталог изделий можно посмотреть на нашем сайте
                </p>
                <a
                    style={{
                        background: '#349a87',
                        color: "black",
                        padding: "9px 20px",
                        fontSize: '16px',
                        textDecoration: 'none',
                        marginTop: '10px'
                    }}
                    href="https://alotb.ru"
                >
                    Перейти на сайт
                </a>
                <div style={{
                    marginTop: '30px',
                    textAlign: 'left'
                }}>
                    <p style={{
                        color: '#666',
                        fontSize: '14px'
                    }}>
                        Связаться с нами:
                    </p>
                    <p style={{
                        color: '#333',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <ContentCopyIcon
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            onClick={() => copyToClipboard(itemData.mobileNumber)}
                        />
                        <a
                            href={`tel:${itemData.mobileNumber}`}
                            style={{ color: '#007bff', textDecoration: 'none' }}
                        >
                            {itemData.mobileNumber}
                        </a>
                    </p>
                    <p style={{
                        color: '#333',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <ContentCopyIcon
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            onClick={() => copyToClipboard(itemData.email)}
                        />
                        Почта:
                        <a
                            href={`mailto:${itemData.email}`}
                            style={{
                                color: '#007bff',
                                textDecoration: 'none',
                                marginLeft: '5px'
                            }}>
                            {itemData.email}
                        </a>
                    </p>

                    {/* <p style={{
                        color: '#666',
                        fontSize: '14px'
                    }}>
                        Или просто ответьте на это сообщение.
                    </p> */}

                </div>
            </div>
        </div>
    );
};

export default MailingRenderProductItems;
