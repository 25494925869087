import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ContainerSiteSetting from '../ContainerSiteSetting';
import RenderItemsContainer from './../components/RenderItemsContainer';
import RenderAddModalContainer from './../components/RenderAddModalContainer';
import ButtonSecondary from './../../components/Button/ButtonSecondary';
import { updatePickupPoints, deletePickupPoints, getPickupPoints, addPickupPoints } from './../../actions/components/pickupPoints';
import AddPickupPoints from './../create/AddPickupPoints';

const PickupPointsSetting = () => {
    const pickupPoints = useSelector((state) => state.pickupPoints.pickupPoints)
    const dispatch = useDispatch()
    const [currentId, setCurrentId] = useState(null)
    const [show, setShow] = useState(false)

    useEffect(() => {
        dispatch(getPickupPoints())
    }, [])

    const handleShow = () => {
        setShow(true)
    }

    const updateAllW = () => {
        dispatch(updatePickupPoints())
    }
    return (
        <ContainerSiteSetting
            item={pickupPoints}
            setShow={handleShow}
            title="Точки выдачи товаров"
            href="/siteSetting"
        >
            <ButtonSecondary
                onClick={updateAllW}
                text="Обновить все"
            />
            <RenderItemsContainer
                setShow={setShow}
                items={pickupPoints}
                setCurrentId={setCurrentId}
                deleteButton={deletePickupPoints}
            />
            <AddPickupPoints
                show={show}
                handleClose={() => setShow(false)}
                setCurrentId={setCurrentId}
                currentId={currentId}
            />
        </ContainerSiteSetting>
    )
}

export default PickupPointsSetting
