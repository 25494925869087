import React from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import ReusableTable from './ReusableTable';

export const RenderMailingItems = ({
    data,
    setTypeModal,
    showModal,
    setShowModal,
    setEmailInfo
}) => {
    const dispatch = useDispatch();

    if (!data) return null;

    if (data.length === 0) {
        return (
            <Loader />
        );
    }

    const onSettingIconClick = (e, type, item) => {
        console.log("type", type)
        e.stopPropagation();
        setTypeModal(type);
        setShowModal(item);
    };

    const handleEmailClick = (emailItem) => {
        setEmailInfo(emailItem);
        setShowModal(true);
    };
    const onRowClick = () => {

    }

    const columns = [
        { header: '#', accessor: (item, index) => index + 1 },
        { header: 'Сообщение', accessor: (item) => item.message },
        { header: 'Название', accessor: (item) => item.name },
        { header: 'Время', accessor: (item) => item.createdAt.slice(11, 19) },
    ];

    return (
        <div>
            <ReusableTable
                data={data}
                columns={columns}
                onRowClick={onRowClick}
                onSettingIconClick={onSettingIconClick}
                onEmailClick={handleEmailClick}
            />
        </div>
    );
};
