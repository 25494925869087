import React from 'react'

const Loader = ({
    typeSize
}) => {
    return (
        <div className="loader" style={{
            height: typeSize && typeSize
        }}>
            <div className="lds-dual-ring"></div>
        </div>
    )
}

export default Loader
