
import { modalContentConstants } from '../../actions/constants';

const initialState = {
    modalContent: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case modalContentConstants.DELETE_MODAL_CONTENT_SUCCESS:
            return state.modalContent.filter((modalContent) => modalContent._id !== action.payload)
        case modalContentConstants.UPDATE_MODAL_CONTENT_SUCCESS:
            return state.modalContent.map((modalContent) => modalContent._id === action.payload._id ? action.payload : modalContent)


        case modalContentConstants.GET_MODAL_CONTENT_SUCCESS:
            state = {
                ...state,
                modalContent: action.payload.modalContent,

            }
            break
        default:
            return state
    }
    return state
}

