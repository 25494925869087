import axios from "../helpers/axios";
import { roleChangeRequest } from "./constants";

export const updateRoleChangeRequestViewed = (applicationId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: roleChangeRequest.APPLICATION_VIEWED_REQUEST, applicationId });
            const res = await axios.put(`/roleChangeRequest/${applicationId}/viewed`);
            dispatch({ type: roleChangeRequest.APPLICATION_VIEWED_SUCCESS, applicationId });
        } catch (error) {
            dispatch({ type: roleChangeRequest.APPLICATION_VIEWED_FAILURE, error });
        }
    };
};

export const getRoleChangeRequest = (sortStatusOrder = "all", sort = "createdAt") => {
    return async (dispatch) => {
        try {
            dispatch({ type: roleChangeRequest.GET_ALL_REQUEST });
            let url = "/roleChangeRequest/get"
            if (sort) {
                url = `/roleChangeRequest/get?sort=${sort}`
            }
            const res = await axios.get(url, {
                params: {
                    sortStatusOrder: sortStatusOrder,
                },
            });
            if (res.status === 200) {
                dispatch({
                    type: roleChangeRequest.GET_ALL_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({ type: roleChangeRequest.GET_ALL_FAILURE });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const updateRoleChangeRequestStatus = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: roleChangeRequest.UPDATE_STATUS_REQUEST });
            const res = await axios.post("/roleChangeRequest/updateStatus", payload);
            if (res.status === 201) {
                dispatch({
                    type: roleChangeRequest.UPDATE_STATUS_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: roleChangeRequest.UPDATE_STATUS_FAILURE,
                    payload: res.status
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const deleteRoleChangeRequest = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: roleChangeRequest.DELETE_BY_ID_REQUEST });
            const res = await axios.delete(`/roleChangeRequest/delete/${id}`);
            if (res.status === 200) {
                dispatch({
                    type: roleChangeRequest.DELETE_BY_ID_SUCCESS,
                    payload: res.data
                });
                dispatch(getRoleChangeRequest())
            } else {
                dispatch({
                    type: roleChangeRequest.DELETE_BY_ID_FAILURE,
                    payload: res.status
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};