import React from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';

const TotalAmountPaymentAccount = ({
    orderObj,
    paymentAccounts
}) => {

    const paymentTotalAmount = () => {
        if (paymentAccounts.length) {
            const totalAmount = paymentAccounts.reduce(
                (totalPrice, currentPayment) => {
                    const { price } = currentPayment;
                    const numericPrice = parseFloat(price);
                    return totalPrice + numericPrice;
                },
                0
            );
            return totalAmount;
        }
        return 0;
    };

    const paymentPercent = Math.round(paymentTotalAmount() / orderObj.totalAmount * 100);

    const everythingLeft = orderObj.totalAmount - paymentTotalAmount()

    return (
        <div>
            <Row>
                <Col md="6">
                    <label className="key">Сумма заказа</label>
                    <p className="value"> {orderObj.totalAmount}</p>
                </Col>
                <Col md="6">
                    <label className="key">Уже внесли</label>
                    <p className="value">{paymentTotalAmount()}</p>

                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Всего осталось</label>
                    <p className="value"> {everythingLeft}</p>
                </Col>
                <Col md="6">
                    <ProgressBar
                        now={paymentPercent}
                        label={`${paymentPercent}%`}
                        style={{ height: '20px' }}
                    />
                </Col>


            </Row>

        </div>
    )
}

export default TotalAmountPaymentAccount
