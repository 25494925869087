import React from "react";
import { Modal, Button } from "react-bootstrap";

const NewModal = ({
  size,
  show,
  handleClose,
  modalTitle,
  children,
  buttons,
  onSubmit,
  currentId,
  closeButton,
  onOpen,
  disabledFunctional,
  disabledSubmit
}, props) => {
  const handleSubmit = () => {
    if (disabledSubmit) return alert("нуно заполнитьв все")

    onSubmit()
  }
  return (
    <Modal size={size} show={show} onHide={handleClose} onOpen={onOpen}>
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{modalTitle || null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {buttons ? (
          buttons.map((btn, index) => (
            <Button key={index} variant={btn.color} onClick={btn.onClick}>
              {btn.label}
            </Button>
          ))
        ) : (
          <>

            <Button
              variant="primary"
              style={{ backgroundColor: "#" }}
              className="btn-sm"
              onClick={handleClose}

            >
              Закрыть
            </Button>

            {

            }
            {disabledFunctional ? null : <Button
              disabled={disabledSubmit}
              variant="primary"
              {...props}
              style={{ backgroundColor: "#333" }}
              className="btn-sm"
              onClick={handleSubmit}

            >
              {!currentId ? "Подтвердить" : "Изменить"}
            </Button>
            }
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default NewModal;




