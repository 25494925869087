import React, { useState, useEffect } from 'react'
import Modal from '../../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, updateFeaturedProductItemById, updateFeaturedProductOrder } from '../../../actions';
import { createFeaturedProductItem } from '../../../actions';
import AddModal from './AddModal';
import DetailsContent from './DetailsContent';
import RenderProductList from './RenderProductList';

const ModalFeaturedProductItem = ({
    updateVisible,
    featuredProductItemObj,
    setTypeModal,
    show,
    handleClose,
    setCurrentId,
    currentId,
    typeModal,
    handleShowModalImg
}) => {
    const dispatch = useDispatch()
    const featuredProductItem = useSelector((state) => currentId ? state.featuredProductItem.featuredProductItems?.find((c) => c._id === currentId) : null)
    const { products } = useSelector((state) => state.product)

    const [productIds, setProductIds] = useState([]);
    const [filterProductId, setFilterProductId] = useState([]);
    const [updatedProducts, setUpdatedProducts] = useState([]);

    const [itemPictures, setItemPictures] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [formFeaturedProduct, setFormFeaturedProduct] = useState({
        name: "",
        description: "",
        type: "",
    })

    useEffect(() => {
        dispatch(getProducts("createdAtMinus", "0", "500"))
    }, [])

    useEffect(() => {

        if (featuredProductItem) setFormFeaturedProduct(featuredProductItem)
    }, [featuredProductItem])


    const handleCloseAndClear = () => {
        setUpdatedProducts([])
        handleClose()
        clear()
    }
    const submitContactForm = (e) => {
        if (currentId) {
            e.preventDefault()

            dispatch(updateFeaturedProductItemById(currentId, formFeaturedProduct))
            handleCloseAndClear()
        } else {
            const payload = {
                name: formFeaturedProduct.name,
                type: formFeaturedProduct.type,
                description: formFeaturedProduct.description,
                products: productIds,
            }
            dispatch(createFeaturedProductItem(payload)).then(() => handleCloseAndClear())
        }
    }
    const clear = () => {
        setCurrentId(null)
        setFormFeaturedProduct({
            name: "",
            description: "",
        })
        setItemPictures("")
        setProductIds([])
    }
    const filterProduct = () => {
        let filteredProducts;
        filteredProducts = products.filter(
            (item) => !featuredProductItemObj?.products?.find((product) => product.productId === item._id)
        );

        return filteredProducts;
    };
    const renderContentModal = () => {
        let content, title;
        switch (typeModal) {

            case "details":
                content = <DetailsContent
                    updateVisible={updateVisible}
                    handleShowModalImg={handleShowModalImg}
                    item={featuredProductItemObj}
                    setTypeModal={setTypeModal}
                />
                title = "Подробная информация"
                break;
            case "detailsProducts":
                content = <RenderProductList
                    featuredProductItemObj={featuredProductItemObj}
                    setUpdatedProducts={setUpdatedProducts}
                    setFilterProductId={setFilterProductId}
                    setSelectedProduct={setSelectedProduct}
                    filterProductId={filterProductId}
                    updatedProducts={updatedProducts}
                    selectedProduct={selectedProduct}
                    itemObj={featuredProductItemObj}
                    setProductIds={setProductIds}
                    setTypeModal={setTypeModal}
                    productIds={productIds}
                    products={products}
                    filterProduct={filterProduct}
                />
                title = "Настройка вложености продуктов"
                break;
            default:
                content = <AddModal
                    setFormFeaturedProduct={setFormFeaturedProduct}
                    featuredProductItemObj={featuredProductItemObj}
                    formFeaturedProduct={formFeaturedProduct}
                    setSelectedProduct={setSelectedProduct}
                    selectedProduct={selectedProduct}
                    setItemPictures={setItemPictures}
                    setProductIds={setProductIds}
                    productIds={productIds}
                    currentId={currentId}
                    products={products}
                />
                title = currentId ? "Редактировать список" : "Создать список"

        }
        return { content, title }
    }

    const disableVisibleButton = typeModal === "details" || typeModal === "detailsProducts" ? true : false

    return (
        <Modal
            disabledFunctional={disableVisibleButton}
            modalTitle={renderContentModal().title}
            handleClose={handleCloseAndClear}
            onSubmit={submitContactForm}
            show={show}
        >
            {renderContentModal().content}
        </Modal>
    );
};

export default ModalFeaturedProductItem;