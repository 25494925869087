import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import Modal from "../../components/UI/Modal";
import { generatePublicUrl } from '../../urlConfig';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import { cancellationStatus } from '../../actions';
import { useDispatch } from 'react-redux';
import { formatDate } from './../../components/FormDate';

const RenderDetailsModal = ({
    applicationObj,
    setShowModalImg,
    applicationDetailModal,
    handleClose,
    handleDeleteById,
    typeModal,
    handleUpdateStatus,
    setType,
    role
}) => {
    const dispatch = useDispatch()

    if (!applicationObj) {
        return null;
    }
    const handleChangeOrderStatus = (e) => {
        setType(e.target.value)
    }
    const handleCancellationStatus = (text, type) => {
        const payload = {
            text,
            type
        }
        dispatch(cancellationStatus("application", applicationObj._id, "applicationStatus", payload))
    }
    const onSubmit = (id) => {
        handleUpdateStatus(id)
        // handleClose()
    }
    return (
        <Modal
            show={applicationDetailModal}
            handleClose={handleClose}
            modalTitle={"Детали заявки на звонок"}
            size="lg"
        >
            {typeModal === "settingModal"
                ? <div className="orderModalCenter__orderStatusMainContainer">
                    <div className="orderModalCenter__orderStatusBox">
                        {applicationObj.applicationStatus?.map((status) => (
                            <>
                                <div className={`orderModalCenter__orderStatus ${status.isCompleted ? "active" : ""}`}>
                                    {role === "superAdmin" && status.isCompleted && <SettingIconBlock
                                        icon={<CloseIcon />}
                                        tooltip="Отменить"
                                        onClick={() => handleCancellationStatus(status.text, status.type)}
                                        top="10px"
                                    />}
                                    <div className={`time ${status.isCompleted ? "active" : ""
                                        }`}>{formatDate(status.date)}</div >
                                    <span>{status.text}</span>
                                    <div className={`orderModalCenter__line ${status.isCompleted ? "active" : ""}`}>
                                    </div>
                                </div>

                            </>
                        ))}

                    </div>
                    <div className="orderModalCenter__selectBlock">
                        <div>
                            <select onChange={(e) => handleChangeOrderStatus(e)}>
                                <option value={""}>Выбрать статус</option>
                                {applicationObj.applicationStatus?.map((status) => {
                                    return (
                                        <>
                                            {!status.isCompleted ? (
                                                <option key={status.type} value={status.type}>
                                                    {status.type} {status.text}
                                                </option>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <Button style={{ marginLeft: "10px" }} onClick={() => onSubmit(applicationObj._id)}>
                                подтвердить
                            </Button>

                        </div>
                    </div>
                </div>
                : <>
                    <div style={{
                        margin: "10px",
                    }}>
                        <button className="btn btn-secondary" onClick={handleDeleteById}>Удалить заявку</button>
                    </div>
                    <Row>
                        <Col md="6">
                            <label className="key">Полное имя</label>
                            <p className="value">{applicationObj.fullName}</p>
                        </Col>
                        <Col md="6">
                            <label className="key">Название фирмы</label>
                            <p className="value">{applicationObj.nameCompany}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <label className="key">Номер</label>
                            <div>
                                <a href='tel:' className="value">{applicationObj.contactNumber}</a>
                            </div>
                        </Col>
                        <Col md="6">
                            <label className="key">Email
                            </label>
                            <p className="value">{applicationObj.email}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <label className="key">Описание проблемы</label>
                            <p className="value">{applicationObj.descriptionProblem}</p>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {applicationObj.samplePhoto?.length > 0 ?
                                <>
                                    <label className="key">Фотографии</label>
                                    <div style={{ display: "flex" }}>
                                        {applicationObj.samplePhoto.map((picture) => (
                                            <div className="productImgContainer p-1"
                                                key={picture._id}
                                            >
                                                <img
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => setShowModalImg(true)}
                                                    src={generatePublicUrl(picture.img)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <label className="key">Покупатель не добавлял фото</label>
                            }

                        </Col>
                    </Row>
                </>
            }
        </Modal>
    );
};

export default RenderDetailsModal