import React from 'react'
import { useDispatch } from 'react-redux';
import { generatePublicUrl } from '../../urlConfig';
import Loader from './../../components/Loader';
import RenderTextEmptyProduct from './../../components/RenderTextEmptyProduct';

const RenderItemsContainer = ({
    deleteButton,
    setCurrentId,
    setTypeModal,
    limitItem,
    setShow,
    items,
}) => {
    const dispatch = useDispatch()

    const Edit = (item) => {
        setCurrentId(item._id)
        setShow(true)
    }
    const handleDetailsShow = (id, typeModal) => {
        setCurrentId(id)
        setTypeModal(typeModal)
        setShow(true)
    }

    return (
        <div className="row">
            {!items ? <Loader /> :
                items.length === 0 ? <RenderTextEmptyProduct /> : items.map((item) =>
                    <div
                        className={limitItem ? "col-sm-10" : "col-sm-4"}
                        key={item._id}
                        style={{ textAlign: "center", height: "300px", paddingTop: "20px", margin: "auto" }}>
                        <img
                            onClick={() => handleDetailsShow(item._id, "renderDetails")}
                            src={
                                generatePublicUrl(
                                    item.aboutUsPicture ||
                                    item.itemImg ||
                                    item.mainImg ||
                                    item.iconImg ||
                                    item.itemPictures?.[0]?.img
                                )
                            }
                            style={{ height: limitItem ? "200px" : "100px" }}
                        />
                        <div style={{ margin: "20px" }}>
                            <div>
                                {item.title
                                    || item.name
                                    || item.headerTitle
                                    || item.address
                                }
                            </div>

                            <div>
                                {
                                    `${item.description && item.description.slice(0, 40)}...`
                                    || item.textImage
                                    || item.city
                                }
                            </div>
                            {item.number && <div>
                                {item.number}
                            </div>}
                        </div>
                        <div style={{ textAlign: "center" }}>
                        </div>
                        <button
                            className="btn btn-secondary"
                            onClick={() => Edit(item)}>
                            Редактировать
                        </button>
                        <button
                            style={{ marginLeft: "5px" }}

                            className="btn btn-secondary"
                            onClick={() => dispatch(deleteButton(item._id))}>
                            Удалить
                        </button>
                    </div>

                )}
        </div >
    )
}

export default RenderItemsContainer