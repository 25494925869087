import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import { Row, Col } from 'react-bootstrap';

const PickupPointsOrder = ({
    item,
}) => {

    const { address, city, number, itemPictures } = item
    const ContentBox = ({ label, value, md }) => {
        return (
            <Col md={md ? md : "6"}>
                <div className="key">{label}</div>
                <p className="value"
                    style={{
                        color: value === "" && "red"
                    }}
                >
                    {value === "" ? "Не заполнено" : value}</p>
            </Col>
        )
    }

    if (!item) return null
    return (
        <div>
            <Row>
                <ContentBox
                    md="12"
                    label="Адрес"
                    value={address}
                />

            </Row>
            <Row>
                <ContentBox
                    label="Город"
                    value={city}
                />
                <ContentBox
                    label="Номер"
                    value={number}
                />
            </Row>
            <Row>
                {itemPictures?.length && <img
                    style={{
                        width: "100px",
                        height: "auto",
                        margin: "0 auto"
                    }}
                    src={generatePublicUrl(itemPictures[0].img)} alt="foto" />}
            </Row>
        </div>
    )
}

export default PickupPointsOrder