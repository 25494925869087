import React from 'react';

const numbersToWords = (num) => {
    const units = ["", "один", "два", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
    const teens = ["десять", "одиннадцать", "двенадцать", "тринадцать", "четырнадцать", "пятнадцать", "шестнадцать", "семнадцать", "восемнадцать", "девятнадцать"];
    const tens = ["", "", "двадцать", "тридцать", "сорок", "пятьдесят", "шестьдесят", "семьдесят", "восемьдесят", "девяносто"];
    const hundreds = ["", "сто", "двести", "триста", "четыреста", "пятьсот", "шестьсот", "семьсот", "восемьсот", "девятьсот"];

    const getWords = (n) => {
        if (n === 0) return "";
        let str = "";

        if (n >= 100) {
            str += hundreds[Math.floor(n / 100)] + " ";
            n %= 100;
        }

        if (n >= 10 && n < 20) {
            str += teens[n - 10] + " ";
        } else if (n >= 20) {
            str += tens[Math.floor(n / 10)] + " ";
            n %= 10;
        }

        if (n > 0 && n < 10) {
            str += units[n] + " ";
        }

        return str.trim();
    };

    const convertToWords = (number) => {
        if (number === 0) return "ноль долларов";

        let words = "";
        let integerPart = Math.floor(number);
        let decimalPart = Math.round((number - integerPart) * 100);

        // Разделяем на миллионы, тысячи и сотни
        const millions = Math.floor(integerPart / 1000000);
        const thousands = Math.floor((integerPart % 1000000) / 1000);
        const hundredsPart = integerPart % 1000;

        if (millions > 0) {
            words += getWords(millions) + " миллион" + (millions > 1 ? "ов " : " ");
        }

        if (thousands > 0) {
            words += getWords(thousands) + " тысяч ";
        }

        if (hundredsPart > 0) {
            words += getWords(hundredsPart) + " ";
        }

        words += "долларов";

        if (decimalPart > 0) {
            words += ` и ${getWords(decimalPart)} центов`;
        }

        return words.trim();
    };

    return convertToWords(num);
};

const CheckAmountInWords = ({ amount }) => {
    return (
        <div>
            <p>Сумма: {amount} долларов</p>
            <p>Сумма прописью: {numbersToWords(amount)}</p>
        </div>
    );
};

export default CheckAmountInWords;
