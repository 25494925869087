import { productMaterialConstants } from "../actions/constants";



const initialState = {
    productMaterials: [],
    productMaterialObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case productMaterialConstants.UPDATE_PRODUCT_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productMaterialConstants.UPDATE_PRODUCT_MATERIAL_SUCCESS:
            state = {
                productMaterials: state.productMaterials.map((item) => item._id === action.payload._id ? action.payload : item),
                message: "Успешно обновлено!",
                showAlert: true,
                variantAlert: "success"
            }
            break;
        case productMaterialConstants.UPDATE_PRODUCT_MATERIAL_FAILURE:
            state = {
                message: "Ошибка обновления!",
                showAlert: true,
                variantAlert: "warning"
            }
            break;
        case productMaterialConstants.GET_BY_ID_PRODUCT_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productMaterialConstants.GET_BY_ID_PRODUCT_MATERIAL_SUCCESS:
            state = {
                ...state,
                loading: false,
                productMaterialObj: action.payload.productMaterial
            };
            break;
        case productMaterialConstants.GET_BY_ID_PRODUCT_MATERIAL_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case productMaterialConstants.GET_PRODUCT_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productMaterialConstants.GET_PRODUCT_MATERIAL_SUCCESS:
            state = {
                ...state,
                loading: false,
                productMaterials: action.payload.productMaterials
            };
            break;
        case productMaterialConstants.GET_PRODUCT_MATERIAL_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case productMaterialConstants.DELETE_PRODUCT_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productMaterialConstants.DELETE_PRODUCT_MATERIAL_SUCCESS:
            state = {
                productMaterial: state.productMaterials.filter((item) => item._id !== action.payload),
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case productMaterialConstants.DELETE_PRODUCT_MATERIAL_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;

        case productMaterialConstants.ADD_PRODUCT_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productMaterialConstants.ADD_PRODUCT_MATERIAL_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            };
            break;
        case productMaterialConstants.ADD_PRODUCT_MATERIAL_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;
        case productMaterialConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false,
            };
            break;
        default:
            return state
    }
    return state
}

export const setCloseAlertProductMaterial = (payload) => ({ type: productMaterialConstants.SET_CLOSE_ALERT, payload })
