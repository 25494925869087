import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col } from "react-bootstrap";
import Loader from '../../components/Loader';
import Input from "../../components/UI/Input";

const AddressForm = ({
    initialData,
    setEditAddress,
    editAddress,
    loadingA
}) => {

    console.log("test", initialData)
    const [mobileNumber, setMobileNumber] = useState(
        initialData ? initialData.mobileNumber : ""
    );
    const [pinCode, setPinCode] = useState(
        initialData ? initialData.pinCode : ""
    );
    const [locality, setLocality] = useState(
        initialData ? initialData.locality : ""
    );
    const [address, setAddress] = useState(
        initialData ? initialData.address : ""
    );
    const [cityDistrictTown, setCityDistrictTown] = useState(
        initialData ? initialData.cityDistrictTown : ""
    );
    const [state, setState] = useState(initialData ? initialData.state : "");
    const [landmark, setLandmark] = useState(
        initialData ? initialData.landmark : ""
    );
    const [alternatePhone, setAlternatePhone] = useState(
        initialData ? initialData.alternatePhone : ""
    );
    const [addressType, setAddressType] = useState(
        initialData ? initialData.addressType : ""
    );

    const [formChanged, setFormChanged] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (initialData) {
            setMobileNumber(initialData.mobileNumber)
            setPinCode(initialData.pinCode)
            setLocality(initialData.locality)
            setAddress(initialData.address)
            setCityDistrictTown(initialData.cityDistrictTown)
            setState(initialData.state)
            setLandmark(initialData.landmark)
            setAlternatePhone(initialData.alternatePhone)
            setAddressType(initialData.addressType)
        }
    }, [initialData])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [])


    const handleInputChange = () => {
        setFormChanged(true);
    }

    const ContentBox = ({ label, value, md, setValue }) => {
        return (
            <Col md={md ? md : "6"}>
                <div className="key">{label}</div>
                {editAddress
                    ? <Input
                        value={value}
                        placeholder="Ввести текст"
                        onChange={(e) => {
                            setValue(e.target.value)
                            handleInputChange();
                        }}
                        ref={inputRef}
                    />
                    : <p className="value"
                        style={{
                            color: value === "" && "red"
                        }}
                    >
                        {value === "" ? "Не заполнено" : value}</p>}
            </Col>
        )
    }
    return (
        <>
            {loadingA ? <Loader typeSize="100%" /> :
                <>
                    <Row>
                        <ContentBox
                            label="Область"
                            value={state}
                            setValue={setState}
                        />
                        <ContentBox
                            label="Город"
                            value={cityDistrictTown}
                            setValue={setCityDistrictTown}
                        />

                    </Row>
                    <Row>
                        <ContentBox
                            label="Индекс"
                            value={pinCode}
                            md="4"
                            setValue={setPinCode}

                        />
                        <ContentBox
                            label="Тип адреса"
                            value={addressType}
                            md="4"
                            setValue={setAddressType}
                        />
                        <ContentBox
                            label="Адрес"
                            value={address}
                            md="4"
                            setValue={setAddress}
                        />

                    </Row>
                    <Row>
                        <ContentBox
                            label="Ориентир"
                            value={landmark}
                            setValue={setLandmark}
                        />
                    </Row>
                    <Button style={{ marginLeft: "10px" }} onClick={() => setEditAddress(!editAddress)}>
                        {editAddress ? "Назад" : "Изменить"}
                    </Button>
                    {formChanged && <Button style={{ marginLeft: "10px" }} onClick={() => setEditAddress(!editAddress)}>
                        Сохранить изменения
                    </Button>}

                </>
            }
        </>
    )
}

export default AddressForm