import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,

} from '@material-ui/core'
import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import RenderMiniImg from '../../components/Button/RenderMiniImg';

const SelectMultiple = ({
    items,
    productIds,
    selectedProduct,
    setSelectedProduct,
    setProductIds,
    rightImg,
    topImg
}) => {
    const handleProductIdsChange = (event) => {
        const selectedIds = event.target.value;

        const newProducts = selectedIds.map((productId, index) => {
            const item = items.find((prod) => prod._id === productId);
            return {
                productId,
                img: item.productPictures[0].img,
                order: index + 1
            };
        });

        setProductIds(newProducts);
    };

    const handleDeleteProduct = (productId) => {
        const updatedProductIds = productIds.filter((product) => product.productId !== productId);
        setProductIds(updatedProductIds);
    };

    return (
        <div className="selectMultiple">
            <FormControl fullWidth margin="normal">
                <InputLabel>Товары</InputLabel>
                <Select
                    multiple
                    value={productIds.map((product) => product.productId)}
                    onChange={handleProductIdsChange}
                    renderValue={() => productIds.length > 0 ? `${productIds.length} позиций выбрано` : ''
                    }
                >
                    {items.map((prod) => (
                        <MenuItem
                            className="selectMultiple__item"
                            key={prod._id}
                            value={prod._id}
                            onMouseEnter={() =>
                                setSelectedProduct(prod.reducedImage)
                            }
                            onMouseLeave={() => setSelectedProduct(null)}
                        >
                            {prod.fullName}
                            {!prod.visibleProduct
                                ? <div className="selectMultiple__visibleProductText">
                                    Скрытый товар
                                </div>
                                : null
                            }
                        </MenuItem>
                    ))}

                    {selectedProduct && (
                        <div
                            style={{
                                position: 'fixed',
                                right: rightImg ? rightImg : '37%',
                                top: topImg ? topImg : '10%',
                                width: '200px',
                                height: '150px',
                                display: 'flex',
                                justifyContent: 'right',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                style={{
                                    width: 'auto',
                                    height: '100%',
                                }}
                                src={generatePublicUrl(selectedProduct)}
                                alt={selectedProduct}
                            />
                        </div>
                    )}
                </Select>
                <RenderMiniImg
                    itemObj={productIds}
                    onClickIcon={handleDeleteProduct}
                    tooltip="Удалить"
                />
            </FormControl>
        </div>
    );
}

export default SelectMultiple