import React, { useState, useEffect } from 'react'
import { deleteColorProduct, getColorProduct } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import AddColorProduct from '../create/AddColorProduct';
import { MainContainer } from '../MainContainer';
import ObjRender from '../render/ObjRender';

const ColorProduct = () => {
    const colorProduct = useSelector((state) => state.colorProduct)
    const { colorProducts, loading } = colorProduct
    const [show, setShow] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getColorProduct())
    }, [])

    return (
        <MainContainer
            type="add"
            backButtonProduct
            title="Возможные цвета"
            get={getColorProduct}
            handleShow={handleShow}
            buttonText="Добавить цвет"
        >
            <ObjRender
                loading={loading}
                typeView="list"
                deleteFunc={deleteColorProduct}
                setCurrentId={setCurrentId}
                mainTitle="Список цветов товаров"
                obj={colorProducts}
                setShow={setShow}
            />
            <AddColorProduct
                handleClose={handleClose}
                show={show}
                currentId={currentId}
                setCurrentId={setCurrentId}
            />
        </MainContainer>
    )
}

export default ColorProduct