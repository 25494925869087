import React from 'react'
import { Button } from '@mui/material'

const VariantBlock = ({
    setTypeContent,
    typeContent,
    onClick,
    items
}) => {

    const buttonArray = [
        {
            text: "Главное меню",
            value: "main"
        },
        {
            text: "Добавить новые материалы",
            value: "newObj"
        },
        {
            text: "Посмотреть добавленные материалы",
            value: "viewAdded"
        },
        {
            text: "Чертежи",
            value: "blueprints"
        },

    ]
    return (
        <div>
            {items
                ? items.map((item, index) =>
                    <Button
                        key={index}
                        style={{
                            margin: "5px"
                        }}
                        variant='contained'
                        onClick={item.onClickButton}
                    >
                        {item.text}
                    </Button>
                )
                : buttonArray.map((item, index) =>
                    item.value !== typeContent && (
                        <Button
                            key={index}
                            style={{
                                margin: "5px"
                            }}
                            variant='contained'
                            onClick={() => setTypeContent(item.value)}
                        >
                            {item.text}
                        </Button>
                    )
                )}
        </div >
    )
}

export default VariantBlock 
