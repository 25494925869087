import React, { useEffect, useState } from 'react'
import { MainContainer, } from '../MainContainer'
import Input from '../../components/UI/Input';
import { addMailing, sendMailing } from '../../actions/mailing.action';
import HtmlMailingContent from './HtmlMailingContent';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux'
import { getFeaturedProductItemById, getFeaturedProductItems, getMainImage, getProducts, updateProductsViewsLength } from '../../actions';
import RenderAdd from '../../siteSetting/containers/ProductListLink/RenderAdd';
import RenderSelectModel from '../../components/RenderSelectModel';
import { getContacts } from './../../actions/components/contact';

const MailingPage = () => {
    const {
        featuredProductItems,
        featuredProductItemObj,
        loading
    } = useSelector((state) => state.featuredProductItem)

    const { mainImage } = useSelector((state) => state.mainImage)
    const { contact } = useSelector((state) => state.contact)

    const dispatch = useDispatch()
    console.log("contact", contact)

    useEffect(() => {
        dispatch(getMainImage())
    }, [])
    const [formProductListLink, setFormProductListLink] = useState({
        featuredProductItemId: ""
    })
    console.log("featuredProductItemObj", featuredProductItemObj)

    const [formMaterial, setFormMaterial] = useState({
        name: "",
        phone: "",
        message: "",
        html: "",
        itemsEmails: ["alotb.mail@yandex.ru", "mafkirovsk51@yandex.ru"]
        // itemsEmails: ["alotb.mail@yandex.ru", "mafkirovsk51@yandex.ru", "graneva.aleksandra@yandex.ru", "graneva.aleksandra5@gmail.com", "lexaleeeeee@gmail.com"]
    });
    // 


    const renderComponentToHTML = () => {
        const html = ReactDOMServer.renderToString(<HtmlMailingContent
            mainImage={mainImage}
            products={featuredProductItemObj.products}
            loading={loading}
            contact={contact}
        />);
        return html;
    };

    useEffect(() => {
        dispatch(getFeaturedProductItems())
        dispatch(getContacts())
    }, [])
    useEffect(() => {
        if (formProductListLink.featuredProductItemId) {
            let id = formProductListLink.featuredProductItemId
            dispatch(getFeaturedProductItemById(id))
        }
    }, [formProductListLink.featuredProductItemId])

    useEffect(() => {
        if (formMaterial.name !== "") {
            const html = renderComponentToHTML();
            setFormMaterial(prevState => ({
                ...prevState,
                html: html,
            }));
        }
    }, [formMaterial.name, formMaterial.phone, formMaterial.message]);


    const submitMaterial = () => {
        console.log("formMaterial", formMaterial)
        dispatch(sendMailing(formMaterial))
            .then(() => dispatch(addMailing(formMaterial)));
    }
    const handleChange = (key, value) => {
        setFormProductListLink({ ...formProductListLink, [key]: value })
    }
    return (
        <MainContainer
            backButtonProduct
            type
            backHref
            title={"Рассылка по почтам"}
        >
            <RenderSelectModel
                value={formProductListLink.featuredProductItemId}
                titleOption="Ничего не привязано"
                title="Выбрать какую список товаров привязать"
                itemsModel={featuredProductItems}
                valueKey="featuredProductItemId"
                handleChange={handleChange}
            />
            <Input
                label="Название"
                value={formMaterial.name}
                placeholder="Введите название"
                name="name"
                onChange={(e) => setFormMaterial({ ...formMaterial, name: e.target.value })}
            />
            <Input
                label="Телефон"
                value={formMaterial.phone}
                placeholder="Введите телефон"
                name="phone"
                onChange={(e) => setFormMaterial({ ...formMaterial, phone: e.target.value })}
            />
            <Input
                label="Сообщение"
                value={formMaterial.message}
                placeholder="Введите сообщение"
                name="message"
                onChange={(e) => setFormMaterial({ ...formMaterial, message: e.target.value })}
            />
            <button className="file__btn file__download" onClick={submitMaterial}>
                Отправить
            </button>
            <button className="file__btn file__download" onClick={() => dispatch(updateProductsViewsLength())}>
                Обновить все просмотры
            </button>
            <HtmlMailingContent
                mainImage={mainImage}
                contact={contact}
                products={featuredProductItemObj.products}
                loading={loading}
            />
        </MainContainer>
    )
}

export default MailingPage