import React from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import material from '../assets/material.jpg'

import mailingCreate from '../assets/mailingCreate.webp'

import productMain from '../assets/productMain.jpg'
import ourPartners from '../assets/ourPartners.png'
import applicationPage from '../assets/applicationPage.png'
import applicationCallMePage from '../assets/applicationCallMePage.png'
import allPartnerApplications from '../assets/allPartnerApplications.jpg'
import featuredProduct from '../assets/featuredProduct.jpg'
import applicationChangeProfile from '../assets/23-2.jpg'
import profileMetal from '../assets/ProfileMetal.webp'
import lightImg from '../assets/LightImg.jpg'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CollectionsIcon from '@mui/icons-material/Collections';
import DraftsIcon from '@mui/icons-material/Drafts';

export const mainItemsHref = [
    {
        href: "/mailing",
        text: "Рассылка",
        icon: <DraftsIcon />
    },
    {
        href: "/category",
        text: "Категории",
        icon: <CategoryIcon />
    },
    {
        href: "/blog",
        text: "Блог",
        icon: <CategoryIcon />
    },
    {
        href: "/containerProductPage",
        text: "Продукты",
        icon: <CurtainsClosedIcon />
    },
    {
        href: "/orders",
        text: "Заказы",
        icon: <FilterFramesIcon />
    },
    {
        href: "/portfolio",
        text: "Портфолио",
        icon: <ImageIcon />
    },
    // {
    //   href: "/page",
    //   text: "Страницы",
    //   icon: <DocumentScannerIcon />
    // },
    {
        href: "/applicationPage",
        text: "Заявки",
        icon: <BorderColorIcon />
    },
    {
        href: "/galleryPhotoPage",
        text: "Галлерея",
        icon: <CollectionsIcon />
    },
    {
        href: "/analytics",
        text: "Аналитика",
        icon: <AssessmentIcon />
    },
    {
        href: "/containerPartnerPage",
        text: "Партнеры",
        icon: <HandshakeIcon />
    },
    {
        href: "/users",
        text: "Пользователи",
        icon: <PersonOutlineIcon />
    },
    {
        href: "/siteSetting",
        text: "Настройки сайта",
        icon: <SettingsIcon />
    },
]


export const materialArray = [
    {
        text: "Материалы",
        href: "/materials",
        photo: ourPartners
    },
    {
        text: "Расходные материалы",
        href: "/productionMaterial",
        photo: profileMetal
    },
]

export const partnerArray = [
    {
        text: "Наши партнеры",
        href: "/partner/all",
        photo: ourPartners
    },
    {
        text: "Все заявки",
        href: "/partner/application",
        photo: allPartnerApplications
    },
]
export const mailingArray = [
    {
        text: "Создать рассылку",
        href: "/mailing/create",
        photo: mailingCreate
    },
    {
        text: "Список отправленных",
        href: "/mailing/lists",
        photo: featuredProduct
    },
]
export const prodArray = [
    {
        text: "Основная продукция",
        href: "/products/all",
        photo: productMain
    },
    {
        text: "Внешняя продукция",
        href: "/products/externalProducts",
        photo: productMain
    },
    {
        text: "Продукты по спискам",
        href: "/featuresProductItem",
        photo: featuredProduct
    },
    {
        text: "Материалы",
        href: "/containerMaterialPage",
        photo: material
    },
    {
        text: "Цвета(производство)",
        href: "/colorsProduct",
        photo: "https://www.teks.ru/files/treecontent/nodes/attaches/100/179/1_1.jpg"
    },
]
export const applicationArray = [
    {
        text: "Заявки (С временем)",
        href: "/applicationsCallMe",
        photo: applicationPage
    },
    {
        text: "Заявки (Задать Вопрос)",
        href: "/applications",
        photo: applicationCallMePage
    },
    {
        text: "Заявки (Изменить данные)",
        href: "/applicationsChangeProfile",
        photo: applicationChangeProfile
    },
    {
        text: "Заявки (Галерея)",
        href: "/applicationsGallery",
        photo: lightImg
    },
]

export const itemsLink = [
    {
        href: "/siteCategory",
        text: "Категории в сайтах"
    },
    {
        href: "/productListLink",
        text: "Списки продуктов на старницах"
    },
    {
        href: "/mainPageSetting",
        text: "Главные страницы"
    },
    {
        href: "/elementPaymentPageSetting",
        text: "Страница способов оплаты"
    },
    {
        href: "/regionSetting",
        text: "Все регионы"
    },
    {
        href: "/mainImageSetting",
        text: "Изображение главной страницы"
    },
    {
        href: "/pickupPointsSetting",
        text: "Точки самовывоза"
    },
    {
        href: "/warranty",
        text: "Гарантии"
    },
    {
        href: "/deliveryInfo",
        text: "Инфо доставка"
    },
    {
        href: "/paymentList",
        text: "Способы Оплаты"
    },
    {
        href: "/aboutUsSetting",
        text: "О нас"
    },
    {
        href: "/benefitsSetting",
        text: "Преймущества нашей компании"
    },
    {
        href: "/contactsSetting",
        text: "Контакты"
    },
    {
        href: "/contactsMSetting",
        text: "Наши соцсети"
    },
    {
        href: "/architectSetting",
        text: "Страница артитекторам"
    },
    {
        href: "/architectLiSetting",
        text: "Список на странице архитектор"
    },
    {
        href: "/architectSkillsSetting",
        text: "Список преимуществ на странице архитектор"
    },
]

export const modalLinkItem = [
    {
        icon: <DriveFileRenameOutlineIcon />,
        text: "партнерка",
    },
    {
        icon: <DriveFileRenameOutlineIcon />,
        text: "изменить номер"
    },
    {
        icon: <DriveFileRenameOutlineIcon />,
        text: "изменить email"
    },
    {
        icon: <DriveFileRenameOutlineIcon />,
        text: "Позвонить мне"
    },

]