import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Loader from '../../components/Loader';
import MoreIconButton from '../../components/MoreIconButton';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import { deleteOrderAdditionalById, updateOrderAdditionalById } from '../../actions';
import { useDispatch } from 'react-redux';
import Input from './../../components/UI/Input/index';
import { Col, Row } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import ContentItem from './ContentItem';
import ModalConfirm from './../../components/BurgerMenu/ModalConfirm';

const AdditionalField = ({
    setShowAddAdditionalField,
    productOrderDetails,
    loading,
    orderObj,
}) => {
    const [additionalObjDetails, setAdditionalObjDetails] = useState(null)

    const [showMoreMenu, setShowMoreMenu] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [afTitle, setAfTitle] = useState(additionalObjDetails === null ? "" : additionalObjDetails.adTitle);
    const [afDescription, setAfDescription] = useState(additionalObjDetails === null ? "" : additionalObjDetails.afDescription);
    const [afViewType, setAfViewType] = useState(additionalObjDetails === null ? "" : additionalObjDetails.afViewType);
    const [showDeletingAdditionalId, setShowDeletingAdditionalId] = useState(false);


    const dispatch = useDispatch()

    if (loading) {
        return <Loader />
    }
    const handleShowEditModal = (item) => {
        setAdditionalObjDetails(item)
        setShowEditModal(true)
    }

    const handleEditAdditional = (item) => {
        const payload = {
            afTitle: "Измененный загловок",
            afDescription: "ПОдроно изменил",
            afViewType: "publicly",
            productOrderDetailsId: productOrderDetails._id,
            additionalId: item._id,
            orderId: orderObj._id,
        }
        dispatch(updateOrderAdditionalById(payload))
    }



    const handleDeleteAdditional = (item) => {

        const payload = {
            orderId: orderObj._id,
            additionalId: item._id,
        }

        dispatch(deleteOrderAdditionalById(payload))
        setShowDeletingAdditionalId(false)
    }

    const itemMoreArray = [
        {
            title: "Удалить",
            type: "delete",
            icon: <DeleteForeverTwoToneIcon />,
            onClick: () => setShowDeletingAdditionalId(true)
        },
        {
            title: "Редактировать",
            type: "edit",
            icon: <DriveFileRenameOutlineIcon />,
            onClick: handleEditAdditional
        },

    ]
    const handleViewType = (afViewType) => {
        let textType;
        switch (afViewType) {
            case "admin":
                textType = "Админ"
                break;
            case "publicly":
                textType = "Публичный"
                break;
        }
        return textType
    }
    return (
        <div className="additionalField">
            <h3 style={{
                marginTop: "15px"
            }}>
                Добавить параметры
            </h3>
            <div className="additionalField__itemHeader">
                <div className="additionalField__titleHeader">
                    <span>
                        Название
                    </span>
                </div>
                <div className="additionalField__descriptionHeader">
                    <span>
                        Описание
                    </span>
                </div>
                <div className="additionalField__typeHeader">
                    <span>
                        Тип
                    </span>
                </div>
            </div>
            {productOrderDetails.additionalFieldItems.length > 0 && <div className="additionalField__items">
                {productOrderDetails.additionalFieldItems.map((item, index) => (
                    <>
                        {!showEditModal ?
                            <ContentItem
                                key={index}
                                orderObj={orderObj}
                                index={index}
                                item={item}
                                itemMoreArray={itemMoreArray}
                                handleViewType={handleViewType}
                            />
                            : <>
                                <div className="additionalField__modalAdditionalBlock">
                                    <Row style={{
                                        height: "100px"
                                    }}>
                                        <Col sm="6">
                                            <Input
                                                label="Заголовок"
                                                name={"title"}
                                                value={afTitle}
                                                placeholder={"Заголовок"}
                                                onChange={(e) => setAfTitle(e.target.value)}
                                            />

                                        </Col>
                                        <Col sm="5">
                                            <Input
                                                label="Описание"
                                                value={afDescription}
                                                placeholder="Введите описание"
                                                name={"afDescription"}
                                                onChange={(e) => setAfDescription(e.target.value)}
                                            />
                                        </Col>
                                        <SettingIconBlock
                                            icon={<CloseIcon />}
                                            tooltip="Отменить"
                                            onClick={() => setShowEditModal(false)}
                                            top="10px"
                                        />
                                    </Row>

                                </div>
                            </>

                        }

                        <ModalConfirm
                            title={`Подтвердить удаление доп-параметра с товара `}
                            descriptionDelete
                            show={showDeletingAdditionalId}
                            handleClose={() => setShowDeletingAdditionalId(false)}
                            onClick={() => handleDeleteAdditional(item._id)}
                        />
                    </>
                ))}
            </div>}

            <div className="renderSettingOrderProductModal">
                {orderObj.disableFunctionality ? null : <div className="renderSettingOrderProductModal__iconAddsBox"
                    onClick={() => setShowAddAdditionalField(true)}
                >
                    <AddCircleOutlineIcon />
                </div>
                }
            </div>

        </div >
    )
}

export default AdditionalField