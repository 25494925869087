import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Input from '../../components/UI/Input';
import { login } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import AlertComponent from '../../components/Alert/Alert';
import { setCloseAlertAuth } from '../../reducers/auth.reducers';


const Signin = (props) => {
    const {
        authenticating,
        authenticate,
        variantAlert,
        showAlert,
        message,
    } = useSelector(state => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();


    const userLogin = (e) => {

        e.preventDefault();

        const user = {
            email, password
        }
        dispatch(login(user));
    }

    if (authenticate) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Layout>
                <Container>
                    <Row style={{ marginTop: '100px' }}>
                        <Col md={{ span: 6, offset: 3 }}>
                            <Form onSubmit={userLogin}>
                                <Input
                                    label="Email"
                                    placeholder="Введите email"
                                    value={email}
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <Input
                                    label="Пароль"
                                    placeholder="Введите пароль"
                                    value={password}
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Button variant="primary" type="submit">
                                    Войти
                                </Button>
                            </Form>
                        </Col>
                    </Row>

                </Container>
            </Layout>
            <AlertComponent
                dispatchCloseAlert={() => dispatch(setCloseAlertAuth())}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </>
    )

}

export default Signin