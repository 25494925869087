import React from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContainerModalConfirmPlusIcon from '../../Orders/ContainerModalConfirmPlusIcon';
import SettingIconBlock from './../../../components/Button/SettingIconBlock';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const IconItemProductBox = ({
    setShowEditOrderInput,
    setShowDeleteConfirm,
    handleShowEditOrder,
    setUpdatedProducts,
    showEditOrderInput,
    showDeleteConfirm,
    updatedProducts,
    confirmDelete,
    setEditOrderId,
    editOrderId,
    item,
}) => {

    const undoEdit = () => {
        setShowEditOrderInput(false);
        const updatedProductIndex = updatedProducts.findIndex(obj => obj.productId === item.productId);
        if (updatedProductIndex !== -1) {
            const newUpdatedProducts = [...updatedProducts];
            newUpdatedProducts.splice(updatedProductIndex, 1);
            setUpdatedProducts(newUpdatedProducts);
        }
    };

    const handleShow = (id) => {
        setEditOrderId(id)
        setShowDeleteConfirm(true)
    }
    return (
        <>
            <ContainerModalConfirmPlusIcon
                tooltip="Удалить"
                right="0"
                top="80px"
                icon={<DeleteForeverIcon />}
                onClickShow={() => handleShow(item._id)}
                title="Подтвердить удаление продукта из этого списка"
                description="Если вы продолжите, это действие будет невозможно отменить, продукт будет удален из списка навсегда!"
                show={showDeleteConfirm}
                handleClose={() => setShowDeleteConfirm(false)}
                onClick={confirmDelete}
            />
            {showEditOrderInput && editOrderId === item._id
                ? <>
                    <SettingIconBlock
                        icon={<CloseIcon />}
                        tooltip="Отменить"
                        right="0"
                        top="55px"
                        onClick={undoEdit}
                    />
                    <SettingIconBlock
                        icon={<SaveIcon />}
                        tooltip="Сохранить"
                        right="0"
                        top="35px"
                        onClick={() => handleShowEditOrder(item)}
                    />
                </>
                : <SettingIconBlock
                    icon={<BorderColorIcon />}
                    tooltip="Изменить номер"
                    right="0"
                    top="55px"
                    onClick={() => handleShowEditOrder(item)}
                />
            }
        </>
    )
}

export default IconItemProductBox