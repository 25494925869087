import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import ContainerSiteSetting from '../../ContainerSiteSetting';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addElementPaymentPage, getElementPaymentPage, getItemById, updateElementPaymentPage } from '../../../actions';
import Loader from '../../../components/Loader';
import AddElementPaymentPage from './AddElementPaymentPage';
import ElementPaymentPageItems from './ElementPaymentPageItems';

const ElementPaymentPage = () => {
    const [currentId, setCurrentId] = useState(null);
    const [show, setShow] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const [pictures, setPictures] = useState([]);
    const [formItem, setFormItem] = useState({
        mainTitle: "",
        mainDescription: "",
        itemsInfo: [],
    });
    const [showModalImg, setShowModalImg] = useState(false);

    const dispatch = useDispatch();
    const { elementPaymentPages, loading, elementPaymentPageObj } = useSelector(
        (state) => state.elementPaymentPage
    );

    useEffect(() => {
        dispatch(getElementPaymentPage());
    }, [dispatch]);

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setTypeModal("")
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const updatedFormItem = {
            ...formItem,
            pictures: pictures
        };

        const form = new FormData();
        form.append('mainTitle', updatedFormItem.mainTitle);
        form.append('mainDescription', updatedFormItem.mainDescription);

        for (let i = 0; i < updatedFormItem.itemsInfo.length; i++) {
            form.append("itemsInfo[]", JSON.stringify(updatedFormItem.itemsInfo[i]));
        }
        for (let i = 0; i < pictures.length; i++) {
            form.append("pictures", pictures[i]);
        }

        if (currentId) {
            dispatch(updateElementPaymentPage(currentId, form));
        } else {
            dispatch(addElementPaymentPage(form));
        }

        handleClose();
    };

    const showDetailsModal = (id) => {
        setTypeModal("renderDetails")
        setCurrentId(id)
        dispatch(getItemById("elementPaymentPage", id, ''))
        handleShow()
    }

    return (
        <ContainerSiteSetting
            item={elementPaymentPages}
            itemsLength={elementPaymentPages.length}
            setShow={handleShow}
            title="Страница подробной информации об оплате"
            href="/siteSetting"
        >
            <ElementPaymentPageItems
                elementPaymentPages={elementPaymentPages}
                showDetailsModal={showDetailsModal}
            />
            <AddElementPaymentPage
                show={show}
                loading={loading}
                handleClose={handleClose}
                onSubmit={handleSubmit}
                currentId={currentId}
                itemObj={elementPaymentPageObj}
                formItem={formItem}
                setPictures={setPictures}
                setFormItem={setFormItem}
                typeModal={typeModal}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            />
        </ContainerSiteSetting>
    )
}

export default ElementPaymentPage