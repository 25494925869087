import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { updateProductMaterialById } from '../../actions/product.action';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';

const CheckboxLabels = ({
    setType,
    productId,
    obj,
}) => {
    const { slug } = useParams()
    const { isCompleted, _id, type } = obj
    const typeMaterial = type
    const [checked, setChecked] = useState(isCompleted);
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setType(typeMaterial)
        // setData(e.target.checked)
        dispatch(updateProductMaterialById(productId, typeMaterial, slug))
        setChecked(e.target.checked);
    };
    const textLabel = (typeMaterial) => {
        switch (typeMaterial) {
            case 'metal':
                return 'Меттал';
            case 'concrete':
                return 'Бетон';
            default:
                return 'Дерево';
        }
    }


    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox
                    checked={checked}
                    onChange={(e) => handleChange(e)} />}
                label={textLabel(type)} />
            {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
    );
}

export default CheckboxLabels;