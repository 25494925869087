import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { generatePublicUrl } from '../../urlConfig';
import Modal from './../../components/UI/Modal/index';
import RenderImgSliderModal from './../../components/ProductImgSliderModal/RenderImgSliderModal';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import Loader from '../../components/Loader';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { saveAs } from 'file-saver';
import PicturesMiniRow from '../../components/Pictures/PicturesMiniRow';
import { removeProductPicturesByIds, updateMainImageById } from '../../actions';
import DetailsStatusPictures from './DetailsStatusPictures';
import ProductionsMaterial from './ProductionMaterial/ProductionsMaterial';
import { useDispatch } from 'react-redux';

export const DetailsProduct = ({
    setCurrentIdPicture,
    detailsTypeModal,
    setProductDetailModal,
    loadingProductDetails,
    productDetailModal,
    setProductPictures,
    handleShowModalImg,
    currentIdPicture,
    setShowModalImg,
    productPictures,
    productDetails,
    showModalImg,
    loading,
    slug
}) => {

    const [typeModal, setTypeModal] = useState("")
    const [typeImagesSlider, setTypeImagesSlider] = useState("")

    const dispatch = useDispatch()

    const handleUpdateMainImageById = () => {
        if (productDetails._id !== null && typeImagesSlider === "updateMainImage")
            dispatch(updateMainImageById(productDetails._id, currentIdPicture))
                .then(() => setCurrentIdPicture(null))
    }
    const handleEditTypeImagesSlider = (type) => {
        setCurrentIdPicture(null)
        setTypeImagesSlider(type)

    }
    const handleDownloadAllPhotos = () => {
        const promises = productDetails?.productPictures?.map((picture, index) => {
            return fetch(generatePublicUrl(picture.img))
                .then(response => response.blob())
                .then(blob => {
                    const filename = `photo_${index}.jpg`;
                    saveAs(blob, filename);
                });
        });
        Promise.all(promises)
            .then(() => {
                console.log("All photos downloaded");
            })
            .catch((error) => {
                console.log("Error downloading photos", error);
            });
    };


    const handleClose = () => {
        setProductDetailModal(false)
    }

    if (!productDetails) {
        return null;
    }

    const handleShowTypeModal = (type) => {
        console.log("type", type)
        setTypeModal(type)
        setShowModalImg(true)
    }

    const renderContent = () => {
        let content, title;

        switch (detailsTypeModal) {
            case "updatedPictures":
                title = "Статус готовности 3д модели и фото";
                content = <DetailsStatusPictures
                    loading={loadingProductDetails}
                    productDetails={productDetails}
                />
                break;
            case "QRCode":
                title = "QRCode";
                content =
                    <>
                        {loadingProductDetails
                            ? <Loader />
                            : <div className="QRCodePicture">
                                <div className="QRCodePicture__imgContainer">
                                    <img
                                        src={generatePublicUrl(productDetails?.QRCodePicture)}
                                        alt={productDetails?._id}
                                    />
                                </div>
                            </div>
                        }
                    </>
                break;
            case "production":
                title = "Используемые материалы и чертежи";
                content =
                    <>
                        {loadingProductDetails
                            ? <Loader />
                            : <ProductionsMaterial
                                loading={loadingProductDetails}
                                productDetails={productDetails}
                            />
                        }
                    </>
                break;
            default:
                title = `Подробное описание к товару - ${productDetails.fullName}`
                content = <>
                    {loadingProductDetails
                        ? <Loader />
                        : <>
                            <div className="QRCodePicture__imgContainer">
                                <img
                                    src={generatePublicUrl(productDetails?.QRCodePicture)}
                                    alt={productDetails?._id}
                                />
                            </div>
                            <Row>
                                <Col md="6">
                                    <label className="key">Имя</label>
                                    <p className="value">{productDetails?.name}</p>
                                </Col>
                                <Col md="6">
                                    <label className="key">Цена</label>
                                    <p className="value">От {productDetails?.price} Валюта</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <label className="key">Высота</label>
                                    <p className="value">{productDetails?.height} мм.</p>
                                </Col>
                                <Col md="4">
                                    <label className="key">Ширина</label>
                                    <p className="value">{productDetails?.width} мм.</p>
                                </Col>
                                <Col md="4">
                                    <label className="key">Длина</label>
                                    <p className="value">{productDetails?.length} мм.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <label className="key">В Наличии</label>
                                    <p className="value">{productDetails?.quantity}</p>
                                </Col>
                                <Col md="6">
                                    <label className="key">Категория</label>
                                    <p className="value">{productDetails?.categoryName}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <label className="key">Вес готового изделия</label>
                                    <p className="value">{productDetails?.weight}</p>
                                </Col>
                                <Col md="6">
                                    <label className="key">Размеры заготовки</label>
                                    <p className="value">{productDetails?.size}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <label className="key">Дата и время создания</label>
                                    <p className="value">
                                        {productDetails?.createdAt?.slice(0, 10)}
                                        &nbsp;
                                        {productDetails?.createdAt?.slice(11, 19)}
                                    </p>
                                </Col>
                                <Col md="12">
                                    <label className="key">Дата и время последнего обновления</label>
                                    <p className="value">
                                        {productDetails?.updatedAt?.slice(0, 10)}
                                        &nbsp;
                                        {productDetails?.updatedAt?.slice(11, 19)}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <label className="key">Подробное Описание</label>
                                    <p className="value">{productDetails?.description}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <label className="key">Подробное Описание видимое для пользователей</label>
                                    <p className="value">{productDetails?.descriptionNew}</p>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <label
                                        style={{ position: "relative" }}
                                        className="key">
                                        Фотографии
                                        <SettingIconBlock
                                            color="black"
                                            tooltip="Добавить"
                                            icon={<AddCircleOutlineIcon />}
                                            right="-25px"
                                            top="-2px"
                                            onClick={() => handleShowTypeModal("settingPhoto")}
                                        />
                                        <SettingIconBlock
                                            color="black"
                                            tooltip="Скачать все фото сразу"
                                            icon={<DownloadForOfflineIcon />}
                                            right="-50px"
                                            top="-2px"
                                            onClick={handleDownloadAllPhotos}
                                        />
                                        <SettingIconBlock
                                            color="black"
                                            tooltip="Назначить фоновое фото"
                                            icon={<LooksOneIcon />}
                                            right="-75px"
                                            top="-2px"
                                            onClick={() => handleEditTypeImagesSlider("updateMainImage")}
                                        />
                                    </label>
                                    <PicturesMiniRow
                                        handleClickButtonSuccess={handleUpdateMainImageById}
                                        setCurrentIdPicture={setCurrentIdPicture}
                                        currentIdPicture={currentIdPicture}
                                        typeImagesSlider={typeImagesSlider}
                                        setTypeImagesSlider={setTypeImagesSlider}
                                        itemsPicture={productDetails?.productPictures}
                                        handleClick={handleShowModalImg}
                                        visibleImg
                                    />
                                </Col>
                            </Row>
                            <RenderImgSliderModal
                                deleteAction={removeProductPicturesByIds}
                                setProductPictures={setProductPictures}
                                item={productDetails.productPictures}
                                currentIdPicture={currentIdPicture}
                                setShowModalImg={setShowModalImg}
                                productPictures={productPictures}
                                productDetails={productDetails}
                                setTypeModal={setTypeModal}
                                showModalImg={showModalImg}
                                handleClose={handleClose}
                                typeModal={typeModal}
                                loading={loading}
                                slug={slug}
                            />
                        </>
                    }
                </>
        }
        return { content, title }
    }
    return (
        <Modal
            show={productDetailModal}
            handleClose={handleClose}
            modalTitle={renderContent()?.title}
            size="lg"
        >
            {renderContent()?.content}
        </Modal>
    );
}
