import React, { useState, useEffect } from 'react'
import Layout from './../components/Layout/index';
import { NavLink } from 'react-router-dom';
import { itemsLink, modalLinkItem } from './../store/localItems';
import ProductImgSliderModal from './../components/ProductImgSliderModal/ProductImgSliderModal';
import { useDispatch, useSelector } from 'react-redux';
import { getModalContent } from '../actions';
import { RenderModalContent } from './render/RenderModalContent';
import { AddModalContent } from './create/AddModalContent';
import BackButton from './components/BackButton';

const SiteSetting = () => {
    const [showModal, setShowModal] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const modalContent = useSelector((state) => state.modalContent.modalContent)

    const handleClose = () => {
        setShowModal(false)
    }
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getModalContent())
    }, [])
    if (modalContent.length === 0) return null
    return (
        <Layout
            sidebar
            backButton
        >
            <div className="siteSettingMainContainer">
                <h2>Настройка Сайта</h2>
                <div className="siteSettingMainContainer__titleModalSetting">
                    Модальные окна
                </div>
                <BackButton
                    onClickButton
                    text="Добавить"
                    onClick={() => setShowModal(true)}
                />

                <RenderModalContent
                    item={modalContent}
                    setCurrentId={setCurrentId}
                    setShowModal={setShowModal}
                />
                <AddModalContent
                    showModal={showModal}
                    handleClose={handleClose}
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    setShowModal={setShowModal}
                />
                <div className="siteSettingMainContainer__titleModalSetting">
                    Страницы и их содержимое
                </div>
                <ul className="siteSetting">
                    {itemsLink.map((obj, index) => (
                        <li key={index}>
                            <NavLink to={obj.href}>{obj.text}</NavLink></li>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default SiteSetting
