import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { findSearchQueryById } from '../../../../actions';
import Loader from './../../../../components/Loader';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingIconBlock from '../../../../components/Button/SettingIconBlock';

export const RenderSearchQuery = ({
    showDetailsModal,
    searchQuery,
    handleShowDetailsUserModal
}) => {
    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(findSearchQueryById())
    // }, [])

    if (!searchQuery) return <Loader />
    return (
        <div>
            <div className="tableContainer">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Запрос</th>
                            <th scope="col">Имя</th>
                            <th scope="col">Поиск по</th>
                            <th scope="col">Дата</th>
                            <th scope="col">Время</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchQuery.length > 0
                            ? searchQuery.map((searchQ, index) =>
                                <tr key={searchQ._id}
                                    onClick={() => showDetailsModal(searchQ._id)}

                                >
                                    <th scope="row">{index + 1}</th>
                                    <td style={{
                                        maxWidth: "200px",
                                        overflow: "hidden"
                                    }}>{searchQ.searchName}</td>
                                    <td>
                                        <span style={{
                                            fontWeight: "600",
                                            position: "relative",
                                            color: searchQ.userId && searchQ.userId.email !== ""
                                                ? "green"
                                                : "red"
                                        }}>
                                            {searchQ.userId && searchQ.userId.email !== ""
                                                ? <>
                                                    {searchQ.userId.email}
                                                    <SettingIconBlock
                                                        icon={<AccountCircleIcon />}
                                                        tooltip="Подробнее о пользователе"
                                                        onClick={() => handleShowDetailsUserModal(searchQ.userId._id)}
                                                        right="-30px"
                                                    />
                                                </>
                                                : "Не авторизованный"
                                            }
                                        </span>
                                    </td>
                                    <td>{searchQ.sort}</td>
                                    <td>{searchQ.createdAt.slice(0, 10)}</td>
                                    <td>{searchQ.createdAt.slice(11, 19)}</td>
                                </tr>
                            )
                            : null}

                    </tbody>
                </table>
            </div>

        </div>
    )
}
