import { searchQueryConstants } from "../../actions/constants"


const initialState = {
    searchQuery: [],
    analyticsObj: {},
    loading: false,
    pageSize: 100,
    sortSearchQuery: "createdAtMinus",
    pageNumber: 0,

}


export default (state = initialState, action) => {
    switch (action.type) {
        case searchQueryConstants.DELETE_SEARCH_QUERY_SUCCESS:
            return state.searchQuery.filter((searchQuery) => searchQuery._id !== action.payload)
        case searchQueryConstants.UPDATE_SEARCH_QUERY_SUCCESS:
            return state.searchQuery.map((searchQuery) => searchQuery._id === action.payload._id ? action.payload : searchQuery)

        case searchQueryConstants.GET_SEARCH_QUERY_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case searchQueryConstants.GET_SEARCH_QUERY_BY_ID_SUCCESS:
            state = {
                ...state,
                analyticsObj: action.payload.searchQuery,
                loading: false
            }
            break;
        case searchQueryConstants.GET_SEARCH_QUERY_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case searchQueryConstants.ADD_SEARCH_QUERY_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case searchQueryConstants.ADD_SEARCH_QUERY_SUCCESS:
            state = {
                ...state,
                searchQuery: action.payload.searchQuery,
                loading: false
            }
            break;
        case searchQueryConstants.ADD_SEARCH_QUERY_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case searchQueryConstants.UPDATE_SEARCH_QUERY_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case searchQueryConstants.UPDATE_SEARCH_QUERY_SUCCESS:
            state = {
                ...state,
                searchQuery: action.payload.searchQuery,
                loading: false
            }
            break;
        case searchQueryConstants.UPDATE_SEARCH_QUERY_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case searchQueryConstants.GET_SEARCH_QUERY_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case searchQueryConstants.GET_SEARCH_QUERY_SUCCESS:
            state = {
                ...state,
                loading: false,
                searchQuery: action.payload.searchQuery,
                totalAll: action.payload.totalAll,
                totalPages: action.payload.totalPages,
            }
            break;
        case searchQueryConstants.GET_SEARCH_QUERY_FAILURE:
            state = {
                ...state,
                loading: false,
            }
            break;
        case searchQueryConstants.SET_CURRENT_PAGE:
            state = {
                ...state,
                pageNumber: action.payload,
            }
            break;
        case searchQueryConstants.SET_PAGE_SIZE:
            state = {
                ...state,
                pageSize: action.payload,
            }
            break;
        case searchQueryConstants.SET_SORT:
            state = {
                ...state,
                sortSearchQuery: action.payload,
            }
            break;
        default:
            return state

    }
    return state
}

export const setPerPageSearchQuery = (payload) => ({ type: searchQueryConstants.SET_PAGE_SIZE, payload })
export const setCurrentPageSearchQuery = (payload) => ({ type: searchQueryConstants.SET_CURRENT_PAGE, payload })
export const setSortSearchQuery = (payload) => ({ type: searchQueryConstants.SET_SORT, payload })

