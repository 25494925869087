import React, { useState, useEffect } from 'react'
import { generatePublicUrl } from '../../urlConfig'
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirm from './../../components/BurgerMenu/ModalConfirm';
import { useDispatch } from 'react-redux';
import { deleteOrderProductById } from '../../actions';
import ContainerModalConfirmPlusIcon from './../Orders/ContainerModalConfirmPlusIcon';
import CheckAmountInWords from '../../components/CheckAmountInWords';

const RenderProductOrder = ({
    handleShowSettingModal,
    setClicked,
    orderItem,
    orderId,
    itemObj,
    item,
}) => {


    const [completelyFilled, setCompletelyFilled] = useState(false)
    const [showDeletingProduct, setShowDeletingProduct] = useState(false)


    const dispatch = useDispatch()

    useEffect(() => {
        if (itemObj.height !== undefined
            && itemObj.height !== undefined
            && itemObj.width !== undefined
            && itemObj.bayerPrice !== undefined
            && itemObj.typeProductColor !== undefined
        ) {
            setCompletelyFilled(true)
        } else {
            setCompletelyFilled(false)
        }
    }, [itemObj])  // добавил зависимости


    const confirmDelete = (id) => {
        const payload = {
            productOrderDetailsId: item._id,
            orderId: orderId,
            fullNameProduct: item.fullName !== "" ? item.fullName : "Не указано"
        }

        dispatch(deleteOrderProductById(payload))
        setClicked(null)
    }

    // Убрал переменную renderItemsData, так как её можно вызывать сразу в JSX
    const itemsData = [
        {
            title: "Артикул",
            obj: <div className="renderProductOrder__name">
                <a href='#'>
                    {item.fullName !== "" ? item.fullName : "Не указано"}
                </a>
            </div>
        },
        {
            title: "Цена с описания за 1шт",
            obj: <SettingIconBlock
                color="black"
                tooltip="Цена товара"
                children={<div className="renderProductOrder__visibilityPrice">
                    {item.visibilityPrice === "admin" || item.visibilityPrice === "public" ? item.price + " $" : "Нет цены"}
                </div>}
            />
        },
        {
            title: "Цена для покупателя за 1шт",
            obj: itemObj.bayerPrice > 0
                ? <div className="renderProductOrder__priceValue">
                    {itemObj.bayerPrice} $
                </div>
                : <div className="renderProductOrder__noPriceValue">
                    Не указано
                </div>
        },
        {
            title: "Кол-во",
            obj: <div className="renderProductOrder__quantityValue">{itemObj.purchasedQty} шт</div>
        },
    ]

    return (
        <>
            <div className={`renderProductOrder ${completelyFilled && "completelyFilled"}`} key={item._id}
                onClick={e => e.stopPropagation()}
            >
                <div className='renderProductOrder__imgBox'>
                    <img src={generatePublicUrl(item.productPictures[0].img)} alt={item.name} />
                </div>
                <div className="renderProductOrder__row">

                    {itemsData.map((data, index) => (
                        <div className="renderProductOrder__itemsDataRow" key={index}>
                            <div className="renderProductOrder__itemsDataRow__title">
                                {data.title}
                            </div>
                            <div className="renderProductOrder__itemsDataRow__line" />
                            <div className="renderProductOrder__itemsDataRow__obj">
                                {data.obj}
                            </div>
                        </div>
                    ))}
                </div>

                <SettingIconBlock
                    color="black"
                    tooltip="Подробная настройка"
                    right="5px"
                    onClick={() => handleShowSettingModal(itemObj)}
                />
                {orderItem.disableFunctionality ? null : <ContainerModalConfirmPlusIcon
                    color="black"
                    tooltip="Удалить товар с заказа"
                    right="25px"
                    icon={<DeleteIcon />}
                    onClickShow={() => setShowDeletingProduct(true)}
                    title={`Подтвердить удаление товара с заказа ${item.fullName !== "" ? item.fullName : "Не указано"}`}
                    description="Если вы продолжите то вернуть его будет невозможно!!!"
                    show={showDeletingProduct}
                    handleClose={() => setShowDeletingProduct(false)}
                    onClick={() => confirmDelete(item._id)}
                />}
            </div>
        </>
    )
}

export default RenderProductOrder
