import React from 'react';
import StatusOrder from '../Orders/StatusOrder';

const StatusProductPictures = ({ productPicturesStatus, type }) => {
    const filterOrderStatusColor = (text) => {
        let color;

        if (type === "product") {
            switch (text) {
                case "Оформлена":
                    color = "gray";
                    break;
                case "На рассмотрении":
                    color = "orange";
                    break;
                case "Подтвержено":
                    color = "green";
                    break;
                case "Отклонено":
                    color = "red";
                    break;
                default:
                    color = "gray";
            }
        } else if (type === "work") {
            switch (text) {
                case "В работе":
                    color = "orange";
                    break;
                case "Модель готова":
                    color = "green";
                    break;
                case "Инфо по товару":
                    color = "red";
                    break;
                case "Фото загружены":
                    color = "green";
                    break;
                default:
                    color = "gray";
            }
        } else {
            color = "gray";
        }

        return color;
    };

    return (
        <div>
            <StatusOrder
                filterOrderStatusColor={filterOrderStatusColor}
                status={productPicturesStatus}
                mini
            />
        </div>
    );
};

export default StatusProductPictures;
