import { categoryConstants, aboutUsConstants } from "./constants";
import axios from "../helpers/axios";

export const getInitialData = () => {
  return async (dispatch) => {
    const res = await axios.post("/initialData");
    if (res.status === 200) {
      const { categories, aboutUs } = res.data;
      dispatch({
        type: categoryConstants.GET_ALL_CATEGORIES_SUCCESS,
        payload: { categories },
      });
      // dispatch({
      //   type: productConstants.GET_ALL_PRODUCTS_SUCCESS,
      //   payload: { products },
      // });
      // dispatch({
      //   type: orderConstants.GET_CUSTOMER_ORDER_SUCCESS,
      //   payload: { orders },
      // });
      dispatch({
        type: aboutUsConstants.GET_ABOUT_US_SUCCESS,
        payload: { aboutUs }
      })
      // dispatch({
      //   type: applicationConstants.GET_ALL_APPLICATION_SUCCESS,
      //   payload: { applications }
      // })

    }
  };
};
