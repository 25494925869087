import React from 'react';
import Header from '../Header';
import { Container, Row, Col } from 'react-bootstrap';
import BackButton from './../../siteSetting/components/BackButton';
import AlertComponent from './../Alert/Alert';

const Layout = ({
  sidebar,
  backButton,
  children,
  dispatchCloseAlert,
  colorAlert,
  showAlert,
  textAlert
}) => {
  return (
    <>
      <Header />
      {
        sidebar ?
          <Container fluid >

            <Row>
              {/* <Sidebar /> */}
              <Col md={12} style={{ marginLeft: 'auto', paddingTop: '60px' }}>
                {backButton ? <BackButton
                  text="На главную"
                  href="/"
                /> : null}
                {children}
              </Col>
            </Row>
          </Container>
          :
          children
      }

      <AlertComponent
        dispatchCloseAlert={dispatchCloseAlert}
        color={colorAlert}
        showObj={showAlert}
        text={textAlert}
      />
    </>
  )

}

export default Layout;