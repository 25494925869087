import axios from "../helpers/axios"
import { updatedProductMaterial } from "./api/productMaterial";
import { productMaterialConstants } from "./constants";


export const getProductMaterial = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: productMaterialConstants.GET_PRODUCT_MATERIAL_REQUEST })
            const res = await axios.get("/productMaterial/get")
            if (res.status === 200) {
                dispatch({
                    type: productMaterialConstants.GET_PRODUCT_MATERIAL_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: productMaterialConstants.GET_PRODUCT_MATERIAL_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
export const getByIdProductMaterial = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productMaterialConstants.GET_BY_ID_PRODUCT_MATERIAL_REQUEST })
            const res = await axios.get(`/productMaterial/getById/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: productMaterialConstants.GET_BY_ID_PRODUCT_MATERIAL_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: productMaterialConstants.GET_BY_ID_PRODUCT_MATERIAL_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addProductionMaterial = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productMaterialConstants.ADD_PRODUCT_MATERIAL_REQUEST })
            const res = await axios.post("/productMaterial/create", payload)
            if (res.status === 201) {
                dispatch({
                    type: productMaterialConstants.ADD_PRODUCT_MATERIAL_SUCCESS,
                    payload: res.data
                })
                dispatch(getProductMaterial())
            } else {
                dispatch({ type: productMaterialConstants.ADD_PRODUCT_MATERIAL_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateProductionMaterial = (id, post) => {
    return async (dispatch) => {
        try {
            const data = await updatedProductMaterial(id, post)
            dispatch({
                type: productMaterialConstants.UPDATE_PRODUCT_MATERIAL_SUCCESS, payload: data
            })
            dispatch(getProductMaterial())
        } catch (e) {
            console.log(e)
        }
    }
}

export const deleteProductMaterial = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: productMaterialConstants.DELETE_PRODUCT_MATERIAL_REQUEST })
            const res = await axios.delete(`/productMaterial/delete/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: productMaterialConstants.DELETE_PRODUCT_MATERIAL_SUCCESS,
                    payload: res.data
                })
                dispatch(getProductMaterial())
            } else {
                const { message } = res.data
                dispatch({
                    type: productMaterialConstants.DELETE_PRODUCT_MATERIAL_FAILURE,
                    payload: {
                        message
                    }
                })
            }
        } catch (e) {
            console.log(e.message)
        }
    }
}