import axios from "../../helpers/axios"
import { mainPageConstants } from '../constants';
import { updatedMainPage } from './../api/mainPage';
import { getAllItems } from './../mainActions/mainAction';

export const addMainPage = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: mainPageConstants.ADD_MAIN_PAGE_REQUEST })
            const res = await axios.post("mainPage/create", form)
            if (res.status === 201) {
                dispatch({ type: mainPageConstants.ADD_MAIN_PAGE_SUCCESS })
                dispatch(getAllItems("mainPage"))
            } else {
                dispatch({ type: mainPageConstants.ADD_MAIN_PAGE_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateMainPage = (id, post) => {
    return async (dispatch) => {
        try {
            const data = await updatedMainPage(id, post)
            dispatch({
                type: mainPageConstants.UPDATE_MAIN_PAGE_SUCCESS, payload: data
            })
            dispatch(getAllItems("mainPage"))
        } catch (e) {
            console.log(e)
        }
    }
}

export const deleteMainPage = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: mainPageConstants.DELETE_MAIN_PAGE_REQUEST })
            const res = await axios.delete("mainPage/delete", {
                data: { payload }
            })
            if (res.status === 202) {
                dispatch({ type: mainPageConstants.DELETE_MAIN_PAGE_SUCCESS })
                dispatch(getAllItems("mainPage"))
            } else {
                const { error } = res.data
                dispatch({
                    type: mainPageConstants.DELETE_MAIN_PAGE_FAILURE,
                    payload: {
                        error
                    }
                })

            }
        } catch (e) {
            console.log(e.message)
        }
    }
}