import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { MainContainer } from '../MainContainer';
import AlertComponent from '../../components/Alert/Alert';
import {
    updateApplicationGalleryStatus,
    deleteApplicationGalleryById,
    deleteManyApplicationGallery,
    getApplicationGalleryById,
    searchApplicationGallery,
    getApplicationGallery,
    updateUserPassword,
    updateUser,
    updateApplicationViewed,
} from '../../actions';
import {
    setCloseAlertApplicationGallery,
    setPageNumberApplicationGallery,
    setPageSizeApplicationGallery
} from '../../reducers/applicationGallery';
import { RenderItems } from '../ApplicationsCallMe/RenderItems';
import RenderDetailsModal from './RenderDetailsModal';
import ModalConfirm from '../../components/BurgerMenu/ModalConfirm';

const ApplicationsGallery = () => {
    const {
        applicationGalleryObj,
        applicationsGallery,
        variantAlert,
        showAlert,
        message,
        loading,
        sort,
        pageNumber,
        pageSize,
        totalAll,
    } = useSelector(state => state.applicationGallery);

    const { user } = useSelector(state => state.auth);

    const [rejectModal, setRejectModal] = useState(false)

    const [showDetailsModal, setApplicationDetailModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [valueTabsSort, setValueTabsSort] = useState("all")
    // const [sortItems, setSortItems] = useState("createdAtMinus")
    const [typeModal, setTypeModal] = useState("");
    const [typeConfirm, setTypeConfirm] = useState("");

    const [type, setType] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getApplicationGallery(valueTabsSort, sort, pageNumber, pageSize))
    }, [sort, pageNumber, pageSize])

    const handleClose = () => {
        setApplicationDetailModal(false);
        setTypeModal("")
    };

    const handleViewApplication = async (id) => {
        const obj = await applicationGalleryObj
        if (!obj.isViewed) {
            dispatch(updateApplicationViewed(id));
        }
    };
    const showModal = (item) => {
        handleViewApplication(item._id)
        if (selectedItemId === item._id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(item._id);
            dispatch(getApplicationGalleryById(item._id));
        }
        setApplicationDetailModal(true);
    };

    const handleCloseConfirm = () => {
        setRejectModal(false)
    }

    const handleUpdateStatus = () => {
        const payload = {
            applicationId: applicationGalleryObj._id,
            type: typeConfirm === "rejected" ? "rejected" : type
        }
        dispatch(updateApplicationGalleryStatus(payload))
        if (typeConfirm === "rejected") {
            handleCloseConfirm()
        }
    }

    const handleDeleteById = () => {
        dispatch(deleteApplicationGalleryById(applicationGalleryObj._id))
        setApplicationDetailModal(false)
    }

    const handleUpdateUser = () => {
        let payload;
        const variant = applicationGalleryObj.variant
        const userId = applicationGalleryObj.createdBy
        const applicationId = applicationGalleryObj._id
        switch (variant) {
            case "personal":
                payload = {
                    fieldsToUpdate: {
                        gender: applicationGalleryObj.gender,
                        birthData: applicationGalleryObj.birthData,
                        firstName: applicationGalleryObj.firstName,
                        lastName: applicationGalleryObj.lastName,
                        regionName: applicationGalleryObj.region.name,
                        regionId: applicationGalleryObj.region.id,
                    }
                }
                break;
            case "login":
                payload = {
                    fieldsToUpdate: {
                        email: applicationGalleryObj.email,
                    }
                }
                break;
            default:
                payload = {
                    fieldsToUpdate: {
                        contactNumber: applicationGalleryObj.contactNumber,
                        backupNumber: applicationGalleryObj.backupNumber,
                    }
                }
        }
        payload.variant = variant;
        payload.userId = userId;
        payload.applicationId = applicationId;
        dispatch(updateUser(payload))
            .then(() => dispatch(getApplicationGallery(valueTabsSort, sort)))
        setRejectModal(false)
    }

    const handleUpdateUserPassword = () => {
        let payload;
        if (applicationGalleryObj.variant === "password") {

            payload = {
                applicationId: applicationGalleryObj._id,
                userId: applicationGalleryObj.createdBy,
                variant: applicationGalleryObj.variant,
                newPassword: applicationGalleryObj.newPassword
            }
            dispatch(updateUserPassword(payload))
        } else {
            alert("Вариат не равен паролю!")
        }
    }

    return (
        <MainContainer
            dispatchAction
            backButtonProduct
            setValueTabsSort={setValueTabsSort}
            itemsType="application"
            sortItems={sort}
            type
            tabs
            backHref
            search={searchApplicationGallery}
            // get={getApplicationGallery}
            title="Запросы с галерей"
            setPerPageSearchQuery={setPageSizeApplicationGallery}
            setPageNumber={setPageNumberApplicationGallery}
            pageNumber={pageNumber}
            pageSize={pageSize}
        // sort={sort}
        >
            <div className="applicationGallery">

                <Row style={{ marginTop: "15px" }}>
                    <Col>
                        <RenderItems
                            applicationsCallMe={applicationsGallery}
                            setTypeModal={setTypeModal}
                            showModal={showModal}
                            typeItems="Gallery"
                            deleteDispatch={deleteManyApplicationGallery}
                        />
                    </Col>
                </Row>
                <RenderDetailsModal
                    loading={loading}
                    handleUpdateUserPassword={handleUpdateUserPassword}
                    handleUpdateStatus={handleUpdateStatus}
                    item={applicationGalleryObj}
                    handleDeleteById={handleDeleteById}
                    showDetailsModal={showDetailsModal}
                    setTypeConfirm={setTypeConfirm}
                    setRejectModal={setRejectModal}
                    valueTabsSort={valueTabsSort}
                    rejectModal={rejectModal}
                    handleClose={handleClose}
                    sortItems={sort}
                    typeModal={typeModal}
                    setType={setType}
                    role={user.role}
                    user={user}
                />
            </div>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertApplicationGallery}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
            <ModalConfirm
                title={"подтврдить отмену"}
                description="Если в действительно хотите удалить этот блог, подтвердите это"
                show={rejectModal}
                handleClose={handleCloseConfirm}
                onClick={typeConfirm === "rejected" ? handleUpdateStatus : handleUpdateUser}
            />
        </MainContainer>
    )
}

export default ApplicationsGallery