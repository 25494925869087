import DEFAULT_IMAGE_URL from '../src/assets/noPhoto1.png'

export const api = 'https://admin-stlkirovsk51.ru/api';

export const generatePublicUrl = (fileName) => {
    if (!fileName) {
        return DEFAULT_IMAGE_URL;
    }
    return `https://admin-stlkirovsk51.ru/public/${fileName}`;
}

// export const api = 'http://localhost:2001/api';

// export const generatePublicUrl = (filename) => {
//     if (!filename) {
//         return DEFAULT_IMAGE_URL;
//     }
//     return `http://localhost:2001/public/${filename}`;
// };

// export const api = 'https://admin-mafkirovsk51.online/api';
// export const generatePublicUrl = (fileName) => 
//     return `https://admin-mafkirovsk51.online/public/${fileName}`;
// }



// ln -s /etc/nginx/sites-available/mafkirovsk51.ru /etc/nginx/sites-enabled/mafkirovsk51.ru

// sudo mkdir -p /var/www/mafkirovsk51.ru/public_html

// sudo nano /var/www/mafkirovsk51.ru/public_html/index.html

// <!DOCTYPE html>
//     <html lang="en" dir="ltr">
//         <head>
//             <meta charset="utf-8">
//                 <title>Welcome to mafkirovsk51.ru</title>
//         </head>
//         <body>
//             <h1>Здесь у нас панель админастратора, ведутся работы</h1>
//         </body>
//     </html>

// sudo chown -R www-data: /var/www/mafkirovsk51.ru

// sudo nano /etc/nginx/sites-available/mafkirovsk51.ru.conf
// server {
//     listen 80;
//     listen [::]:80;

//      root /var/www/mafkirovsk51.ru/public_html;

//      index index.html;

//      server_name mafkirovsk51.ru www.mafkirovsk51.ru;

//      access_log /var/log/nginx/mafkirovsk51.ru.access.log;
//      error_log /var/log/nginx/mafkirovsk51.ru.error.log;

//      location / {
//     #     try_files $uri $uri/ =404;
//     try_files $uri $uri/ /index.html;
//  }
//  }

// sudo ln -s /etc/nginx/sites-available/mafkirovsk51.ru.conf /etc/nginx/sites-enabled/ +++++

// sudo ln -s /etc/nginx/sites-available/alotb.ru conf/etc/nginx/sites-enabled/ ------
//     sudo nginx -t
// sudo systemctl restart nginx



// sudo apt install certbot python3-certbot-nginx
// sudo certbot --nginx -d mafkirovsk51.ru -d www.mafkirovsk51.ru
// certbot renew --dry-run

// ----------------------------------------------------------------------------------------------------------------------

// admin-mafkirovsk51.ru


// ln -s /etc/nginx/sites-available/admin-mafkirovsk51.ru /etc/nginx/sites-enabled/admin-mafkirovsk51.ru

// sudo mkdir -p /var/www/admin-mafkirovsk51.ru/public_html

// sudo nano /var/www/admin-mafkirovsk51.ru/public_html/index.html

// <!DOCTYPE html>
//     <html lang="en" dir="ltr">
//         <head>
//             <meta charset="utf-8">
//                 <title>Welcome to admin-mafkirovsk51.ru</title>
//         </head>
//         <body>
//             <h1>Здраствуйте, мы на новом сервере 22034</h1>
//         </body>
//     </html>

// sudo chown -R www-data: /var/www/admin-mafkirovsk51.ru

// sudo nano /etc/nginx/sites-available/admin-mafkirovsk51.ru.conf
// server {
//     listen 80;
//     listen [::]:80;

//      root /var/www/admin-mafkirovsk51.ru/public_html;

//      index index.html;

//      server_name admin-mafkirovsk51.ru www.admin-mafkirovsk51.ru;

//      access_log /var/log/nginx/admin-mafkirovsk51.ru.access.log;
//      error_log /var/log/nginx/admin-mafkirovsk51.ru.error.log;

//      location / {
//     #     try_files $uri $uri/ =404;
//     try_files $uri $uri/ /index.html;
//  }
//  }
// sudo ln -s /etc/nginx/sites-available/admin-mafkirovsk51.ru.conf /etc/nginx/sites-enabled/ +++++

// sudo ln -s /etc/nginx/sites-available/alotb.ru conf/etc/nginx/sites-enabled/ ------


//     sudo nginx -t
// sudo systemctl restart nginx



// sudo apt install certbot python3-certbot-nginx
// sudo certbot --nginx -d alotb.ru -d www.alotb.ru
// certbot renew --dry-run





















// sudo mkdir - p /var/www/alotb.ru/public_html

// sudo nano /var/www/alotb.ru/public_html/index.html

// < !DOCTYPE html >
//     <html lang="en" dir="ltr">
//         <head>
//             <meta charset="utf-8">
//                 <title>Welcome to alotb.ru</title>
//         </head>
//         <body>
//             <h1>Success! alotb.ru home page!</h1>
//         </body>
//     </html>

// sudo chown -R www-data: /var/www/alotb.ru

// sudo nano /etc/nginx/sites-available/alotb.ru.conf

// server {
//     listen 80;
//     listen[::]: 80;

//     root /var/www/alotb.ru / public_html;

//     index index.html;

//     server_name alotb.ru www.alotb.ru;

//     access_log /var/log/nginx/alotb.ru.access.log;
//     error_log /var/log/nginx/alotb.ru.error.log;

//     location / {
//         try_files $uri $uri/ =404;
// }
// }

// sudo ln - s /etc/nginx/sites-available/alotb.ru.conf/etc/nginx/sites-enabled/

// sudo nginx -t


// const arrayProduct = [
//     "62ba1426c0ef2d7369409d43",
//     "62f36ee8256045dba6fc3219",
//     "6480d43ff2da9c4115eef893",
//     "6480d43ff2da9c4115eef893",
//     "6480e52cf2da9c4115eef8b6",
//     "6480e7def2da9c4115eef8ce",
//     "6480e9c7f2da9c4115eef8d9",
//     "6480f0a3f2da9c4115eef910",
//     "648114c7f2da9c4115eef985",
//     "649edc8df972cd38136d7304",
//     "649ede8ff972cd38136d7333",
//     "64a1e4702f941968b993b2d3",
//     "64a1e6542f941968b993b303",
//     "64a1ea8b2f941968b993b319",
//     "64a1f8872f941968b993b3c4",
// ]