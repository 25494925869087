
import { mainActionsConstants } from './../constants';
import axios from "../../helpers/axios";


const renderUrl = (model, path, itemId, fields, userId) => {
    let url;
    if (userId !== "")
        if (path === "") {
            url = `/view/${model}/${itemId}/${fields}/${userId}`
        } else {
            url = `/view/${model}/${itemId}/${fields}/${path}/${userId}`
        }
    else {
        if (path === "") {
            url = `/view/${model}/${itemId}/${fields}`
        } else {
            url = `/view/${model}/${itemId}/${fields}/${path}`
        }
    }
    return url
}

export const getAllItems = (model, fields = "", path = "",) => {
    return async (dispatch) => {
        try {
            dispatch({ type: mainActionsConstants.GET_ALL_ITEMS_REQUEST });
            let url;
            if (path === "") {
                url = `/admin/getAllItems/${model}/${fields}`
            } else {
                url = `/admin/getAllItems/${model}/${fields}/${path}`
            }
            const res = await axios.get(url);
            if (res.status === 200) {
                const { items } = res.data
                dispatch({
                    type: mainActionsConstants.GET_ALL_ITEMS_SUCCESS,
                    payload: { items }
                });
            } else {
                dispatch({ type: mainActionsConstants.GET_ALL_ITEMS_FAILURE });
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateViewById = (model, itemId, path = "", fields = "", userId = "") => {
    return async (dispatch) => {
        try {
            dispatch({ type: mainActionsConstants.UPDATE_VIEW_BY_ID_REQUEST });
            const res = await axios.get(renderUrl(model, path, itemId, fields, userId));
            if (res.status === 200) {
                const { item } = res.data
                dispatch({
                    type: mainActionsConstants.UPDATE_VIEW_BY_ID_SUCCESS,
                    payload: { item }
                });
            } else {
                dispatch({ type: mainActionsConstants.UPDATE_VIEW_BY_ID_FAILURE });
            }
        } catch (e) {
            console.log(e);
        }
    };
};
export const getItemById = (model, itemId, path = "", fields = "") => {
    return async (dispatch) => {
        try {
            dispatch({ type: mainActionsConstants.GET_BY_ID_REQUEST });
            let url;
            if (path === "") {
                url = `/admin/${model}/${itemId}/${fields}`
            } else {
                url = `/admin/${model}/${itemId}/${fields}/${path}`
            }
            const res = await axios.get(url);
            if (res.status === 200) {
                const { item } = res.data
                dispatch({
                    type: mainActionsConstants.GET_BY_ID_SUCCESS,
                    payload: { item }
                });
            } else {
                dispatch({ type: mainActionsConstants.GET_BY_ID_FAILURE });
            }
        } catch (e) {
            console.log(e);
        }
    };
};


export const cancellationStatus = (model, itemId, fieldsStatus, payload) => {
    return async (dispatch) => {
        try {
            dispatch({ type: mainActionsConstants.CANCELLATION_STATUS_REQUEST });
            const res = await axios.put(
                `/admin/${model}/${itemId}/${fieldsStatus}/cancellationStatus`,
                payload
            );
            if (res.status === 201) {
                dispatch({
                    type: mainActionsConstants.CANCELLATION_STATUS_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({ type: mainActionsConstants.CANCELLATION_STATUS_FAILURE });
            }
        } catch (e) {
            console.log(e);
        }
    };
}; 