import React from 'react'
import { Row, Col } from "react-bootstrap";
import PicturesMiniRow from '../../../components/Pictures/PicturesMiniRow';
import SettingIconBlock from '../../../components/Button/SettingIconBlock';
import RenderTextEmptyProduct from '../../../components/RenderTextEmptyProduct';

const DetailsContent = ({
    item,
    setTypeModal,
    handleShowModalImg,
    updateVisible
}) => {
    if (!item) return null

    const contentItem = (
        title,
        text,
        full
    ) => {
        return <Col md={full ? "12" : "6"}>
            <label className="key">{title}</label>
            <p className="value"
                style={{ color: text ? "" : "red" }}
            >{text ? text : "пусто"}</p>
        </Col>
    }
    return <>
        <div style={{
            margin: "10px",
            display: "flex",
            justifyContent: "space-between"
        }}>
            <button
                className="btn btn-secondary"
            // onClick={handleDeleteById}
            >
                Удалить заявку
            </button>
            <button
                className="btn btn-secondary"
                onClick={updateVisible}
            >
                {item.visible ? "Скрыть список" : "Показать список"}
            </button>
        </div>
        <Row>
            {contentItem("Название", item.name)}
            {contentItem("Тип", item.type)}
        </Row>
        <Row>
            {contentItem("Подробное описание", item.description, true)}
        </Row>
        <Row>
            {contentItem("Время создания", item.createdAt?.slice(0, 10) + " " + item.createdAt?.slice(11, 19))}
            {contentItem("Время обновления", item.updatedAt?.slice(0, 10) + " " + item.updatedAt?.slice(11, 19))}
        </Row>
        <Row>
            <Col md="12">
                <label className="key"
                    style={{
                        position: "relative"
                    }}
                >
                    Вложенные товары
                    <SettingIconBlock
                        tooltip="Настройки товаров"
                        onClick={() => setTypeModal("detailsProducts")}
                        right="-25px"
                    />
                </label>
                {item.products?.length > 0
                    ? <PicturesMiniRow
                        itemsPicture={item.products}
                        handleClick={handleShowModalImg}
                        // visibleImg
                        type="product"
                    />
                    : <RenderTextEmptyProduct
                        mini
                    />
                }

            </Col>

        </Row>
    </>
}

export default DetailsContent