import React, { useState } from 'react'
import { Button } from '@mui/material'
import VariantBlock from './VariantBlock'
import NewObject from './NewObject'
import ViewAdded from './ViewAdded'
import ProductBluePrints from '../ProductBlueprints/ProductBluePrints'

const ProductionsMaterial = ({
    productDetails,
    loading,

}) => {
    const [typeContent, setTypeContent] = useState("main")

    const renderContent = () => {
        let title, content;
        switch (typeContent) {
            case "newObj":
                title = "Добавить новый материал к товару"
                content = <NewObject
                    productDetails={productDetails}
                    loading={loading}
                />
                break;
            case "viewAdded":
                title = "Добавленные к товару"
                content = <ViewAdded
                    productDetails={productDetails}
                    loading={loading}
                />
                break;
            case "blueprints":
                title = "Чертежи"
                content = <ProductBluePrints
                    productDetails={productDetails}
                    loading={loading}
                />
                break;
            default:
                title = "Основное меню"
                content = null
        }
        return { title, content }
    }

    return (
        <div className="productionsMaterial">
            <div className="productionsMaterial__topButtonBlock">
                <VariantBlock
                    setTypeContent={setTypeContent}
                    typeContent={typeContent}
                />
            </div>
            <div className="productionsMaterial__mainTitle">
                {renderContent().title}
            </div>

            <div className="productionsMaterial__content">
                {renderContent().content}
            </div>
        </div>
    )
}

export default ProductionsMaterial 