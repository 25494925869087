import axios from "../helpers/axios";
import { setApplicationCallMe } from "../reducers/applicationCallMe";
import { deletedApplicationCallMe } from "./api/applicationCallMe";
import { applicationCallMeConstants } from "./constants";

export const getApplicationCallMe = (sort) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationCallMeConstants.GET_ALL_APPLICATION_CALL_ME_REQUEST });
      let url = "applicationCallMe/get"
      if (sort) {
        url = `applicationCallMe/get?sort=${sort}`
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        const { applicationsCallMe } = res.data;
        dispatch({
          type: applicationCallMeConstants.GET_ALL_APPLICATION_CALL_ME_SUCCESS,
          payload: { applicationsCallMe },
        });
      } else {
        dispatch({ type: applicationCallMeConstants.GET_ALL_APPLICATION_CALL_ME_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const deleteManyApplicationCallMe = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationCallMeConstants.DELETE_MANY_REQUEST });
      const res = await axios.post("/applicationCallMe/deleteMany", payload);
      if (res.status === 200) {
        dispatch({
          type: applicationCallMeConstants.DELETE_MANY_SUCCESS,
          payload: res.data
        });
        dispatch(getApplicationCallMe())
      } else {
        dispatch({
          type: applicationCallMeConstants.DELETE_MANY_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateApplicationCallMeStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationCallMeConstants.UPDATE_STATUS_REQUEST });
      const res = await axios.post("/applicationCallMe/updateStatus", payload);
      if (res.status === 201) {
        dispatch({
          type: applicationCallMeConstants.UPDATE_STATUS_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: applicationCallMeConstants.UPDATE_STATUS_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchApplicationCallMe = (search) => {
  return async dispatch => {
    try {
      const response = await axios.get(`/applicationCallMe/getApplicationsCallMe/search?search=${search}`)
      dispatch(setApplicationCallMe(response.data))
    } catch (e) {
      alert(e?.response?.data?.message)
    }
  }
}

export const deleteApplicationCallMeById = (id) => async (dispatch) => {
  try {
    await deletedApplicationCallMe(id);
    dispatch({ type: applicationCallMeConstants.DELETE_APPLICATION_CALL_ME_BY_ID_SUCCESS, payload: id });
    dispatch(getApplicationCallMe())
  } catch (error) {
    console.log(error);
  }
} 