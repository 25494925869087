import { mainPageConstants, mainActionsConstants } from '../../actions/constants';



const initialState = {
    mainPages: [],
    mainPageObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case mainPageConstants.DELETE_MAIN_PAGE_SUCCESS:
            return state.mainPages.filter((mainPage) => mainPage._id !== action.payload)
        case mainPageConstants.UPDATE_MAIN_PAGE_SUCCESS:
            state = {
                mainPages: state.mainPages.map((mainPage) => mainPage._id === action.payload._id ? action.payload : mainPage),
                message: "Успешно обновлено!",
                showAlert: true,
                variantAlert: "success"
            }
            break;
        case mainPageConstants.UPDATE_MAIN_PAGE_FAILURE:
            state = {
                message: "Ошибка обновления!",
                showAlert: true,
                variantAlert: "warning"
            }
            break;
        case mainPageConstants.ADD_MAIN_PAGE_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case mainPageConstants.ADD_MAIN_PAGE_SUCCESS:
            state = {
                ...state,
                message: action.payload?.data?.message,
                showAlert: true,
                loading: false
            };
            break;
        case mainPageConstants.ADD_MAIN_PAGE_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload?.data.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                mainPageObj: action.payload.item,
                loading: false
            };
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false,
            }
            break;
        case mainActionsConstants.GET_ALL_ITEMS_REQUEST:
            state = {
                ...state,
                loading: true
            };
        case mainActionsConstants.GET_ALL_ITEMS_SUCCESS:
            state = {
                ...state,
                mainPages: action.payload?.items,
                loading: false,

            };
            break;
        case mainActionsConstants.GET_ALL_ITEMS_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case mainActionsConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false
            }
            break;
        default:
            return state

    }
    return state
}

export const setCloseAlert = (payload) => ({ type: mainActionsConstants.SET_CLOSE_ALERT, payload })
