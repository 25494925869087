import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeProductMaterialById } from '../../../actions';

import AddedButtons from './AddedButtons';
import ItemViewAdded from './ItemViewAdded';
import TotalAmount from './TotalAmount';

const ViewAdded = ({
    productDetails,
    loading
}) => {
    const [showDeletingModal, setShowDeletingModal] = useState(false);
    const [editingMaterialId, setEditingMaterialId] = useState(null);
    const [editedMaterialsTemp, setEditedMaterialsTemp] = useState({});
    const [editedMaterialsFinal, setEditedMaterialsFinal] = useState({});

    const dispatch = useDispatch();

    const headerGroupArray = [
        {
            text: "Название"
        },
        {
            text: "Единица измерения"
        },
        {
            text: "Цена за 1шт"
        },
        {
            text: "Цена за все"
        },
    ];

    const removeProductMaterial = () => {
        if (editingMaterialId) {
            dispatch(removeProductMaterialById(productDetails._id, editingMaterialId))
                .then(() => {
                    setShowDeletingModal(false)
                    setEditedMaterialsFinal({})
                });
        }
    };

    const handleShowEditInputs = (materialId) => {

        setEditingMaterialId(isMaterialEditing(materialId) ? null : materialId);
    };

    const saveEditMaterial = (materialId) => {
        setEditingMaterialId(null);

        if (editedMaterialsTemp[materialId]) {
            setEditedMaterialsFinal((prevEditedMaterialsFinal) => ({
                ...prevEditedMaterialsFinal,
                [materialId]: { ...editedMaterialsTemp[materialId] }
            }));
        }

        setEditedMaterialsTemp((prevEditedMaterialsTemp) => ({
            ...prevEditedMaterialsTemp,
            [materialId]: {}
        }));
    };

    const isMaterialEditing = (materialId) => editingMaterialId === materialId && !showDeletingModal

    const handleShowDeleteModal = (itemId) => {
        setEditingMaterialId(itemId);
        setShowDeletingModal(true);
    }

    return (
        <div className="productionsMaterial__viewAdded">
            <div className="productionsMaterial__viewAdded__row">
                {headerGroupArray.map((item, index) =>
                    <div className="productionsMaterial__viewAdded__headerGroup"
                        key={index}
                    >
                        {item.text}
                    </div>
                )}
            </div>
            <div className="productionsMaterial__viewAdded__items">
                {productDetails.productMaterial.map((item) => <ItemViewAdded
                    item={item}
                    isMaterialEditing={isMaterialEditing}
                    editedMaterialsFinal={editedMaterialsFinal}
                    saveEditMaterial={saveEditMaterial}
                    handleShowEditInputs={handleShowEditInputs}
                    editedMaterialsTemp={editedMaterialsTemp}
                    removeProductMaterial={removeProductMaterial}
                    setEditedMaterialsTemp={setEditedMaterialsTemp}
                    handleShowDeleteModal={handleShowDeleteModal}
                    setShowDeletingModal={setShowDeletingModal}
                    setEditingMaterialId={setEditingMaterialId}
                    showDeletingModal={showDeletingModal}
                    productDetails={productDetails}
                />
                )}
            </div>
            <TotalAmount
                productDetails={productDetails}
            />
            <AddedButtons
                editedMaterialsFinal={editedMaterialsFinal}
                setEditedMaterialsFinal={setEditedMaterialsFinal}
                productId={productDetails._id}
            />
        </div>
    );
}

export default ViewAdded;
