import React from 'react'
import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import ContainerDetailsContent from './ContainerDetailsContent'
import { updateUserRole } from '../../actions/user.actions'

const EditRoleUserBlock = ({
    userDetails,
    setTypeModal
}) => {


    const dispatch = useDispatch()

    const handleClick = () => {
        const payload = {
            id: userDetails._id,
            role: ""
        }
        dispatch(updateUserRole(payload))
    }
    return (
        <ContainerDetailsContent
            setTypeModal={setTypeModal}
            noneButtons
        >
            <Button
                style={{
                    margin: "5px"
                }}
                variant='contained'
                // key={index}
                onClick={() => handleClick()}
            >
                {userDetails.role}
            </Button>
        </ContainerDetailsContent>
    )
}

export default EditRoleUserBlock