import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import { createCategoryList } from './createCategoryList';
import { editProductCategory } from "../../actions";
import { generatePublicUrl } from "../../urlConfig";

const EditProductCategories = ({
    products,
    slug
}) => {
    const category = useSelector((state) => state.category.categories);
    const [open, setOpen] = useState(false);
    const [productIds, setProductIds] = useState([]);
    const [newCategoryId, setNewCategoryId] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setProductIds([]);
        setNewCategoryId("");
    };

    const handleProductIdsChange = (event) => {
        setProductIds(event.target.value);
        setSelectedProduct(null);
    };

    const handleNewCategoryIdChange = (event) => {
        setNewCategoryId(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            productIds,
            newCategoryId
        };

        dispatch(editProductCategory(payload, slug)).then(handleClose);
    };

    return (
        <div style={{
            margin: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "right"
        }}>
            <Button variant="contained" onClick={handleClickOpen}>
                Изменить категорию в этих продуктах
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Изменить категорию в этих продуктах</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Выберите нужные товары из списка и одну из категорий в списке ниже
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Товары</InputLabel>
                            <Select
                                multiple
                                value={productIds}
                                onChange={handleProductIdsChange}
                                renderValue={() => selectedProduct ? selectedProduct.fullName : ''}
                            >
                                {products.map((prod) => (
                                    <MenuItem

                                        key={prod._id}
                                        value={prod._id}
                                        onMouseEnter={() => setSelectedProduct(prod.productPictures[0].img)}
                                    >

                                        {prod.fullName}
                                    </MenuItem>
                                ))}
                                {selectedProduct && (
                                    <div
                                        style={{
                                            position: "fixed",
                                            right: "37%",
                                            top: "10%",
                                            width: "200px",
                                            height: "150px",
                                            display: "flex",
                                            justifyContent: "right",
                                            overflow: "hidden",

                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "auto",
                                                height: "100%",
                                            }}
                                            src={generatePublicUrl(selectedProduct)} alt={selectedProduct} />
                                    </div>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Новая категория</InputLabel>
                            <Select
                                value={newCategoryId}
                                onChange={handleNewCategoryIdChange}
                            >
                                {createCategoryList(category).map((cat) => (
                                    <MenuItem key={cat.value} value={cat.value}>{cat.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <DialogActions>
                            <Button onClick={handleClose}>Назад</Button>
                            <Button type="submit" color="primary">
                                Обновить категорию
                            </Button>
                        </DialogActions>
                    </form>

                </DialogContent>
                <DialogContent>

                </DialogContent>
            </Dialog>
        </div >
    );
};
export default EditProductCategories;


// import React, { useState } from 'react';
// import { FormControl, InputLabel, MenuItem, Select, Button } from '@material-ui/core';

// const EditProductCategoryForm = ({ productIds, categories, onSave }) => {
//     const [categoryId, setCategoryId] = useState('');

//     const handleCategoryChange = (event) => {
//         setCategoryId(event.target.value);
//     };

//     const handleSaveClick = () => {
//         onSave(productIds, categoryId);
//     };

//     return (
//         <div>
//             <FormControl>
//                 <InputLabel id="category-select-label">New category</InputLabel>
//                 <Select
//                     labelId="category-select-label"
//                     id="category-select"
//                     value={categoryId}
//                     onChange={handleCategoryChange}
//                 >
//                     {categories.map((category) => (
//                         <MenuItem key={category._id} value={category._id}>
//                             {category.name}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>
//             <Button variant="contained" color="primary" onClick={handleSaveClick}>
//                 Save
//             </Button>
//         </div>
//     );
// };

// export default EditProductCategoryForm;
