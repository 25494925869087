import { productListLinkConstants } from "../actions/constants";



const initialState = {
    productListLinks: [],
    productListLinkObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case productListLinkConstants.DELETE_PRODUCT_LIST_LINK_SUCCESS:
            return state.productListLinks.filter((mainPage) => mainPage._id !== action.payload)
        case productListLinkConstants.UPDATE_PRODUCT_LIST_LINK_SUCCESS:
            state = {
                productListLinks: state.productListLinks.map((mainPage) => mainPage._id === action.payload._id ? action.payload : mainPage),
                message: "Успешно обновлено!",
                showAlert: true,
                variantAlert: "success"
            }
            break;
        case productListLinkConstants.UPDATE_PRODUCT_LIST_LINK_FAILURE:
            state = {
                message: "Ошибка обновления!",
                showAlert: true,
                variantAlert: "warning"
            }
            break;
        case productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_SUCCESS:
            state = {
                ...state,
                loading: false,
                productListLinkObj: action.payload.productListLink
            };
            break;
        case productListLinkConstants.GET_BY_ID_PRODUCT_LIST_LINK_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case productListLinkConstants.GET_PRODUCT_LIST_LINK_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productListLinkConstants.GET_PRODUCT_LIST_LINK_SUCCESS:
            state = {
                ...state,
                loading: false,
                productListLinks: action.payload.productListLinks
            };
            break;
        case productListLinkConstants.GET_PRODUCT_LIST_LINK_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case productListLinkConstants.DELETE_PRODUCT_LIST_LINK_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productListLinkConstants.DELETE_PRODUCT_LIST_LINK_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            };
            break;
        case productListLinkConstants.DELETE_PRODUCT_LIST_LINK_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;

        case productListLinkConstants.ADD_PRODUCT_LIST_LINK_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productListLinkConstants.ADD_PRODUCT_LIST_LINK_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            };
            break;
        case productListLinkConstants.ADD_PRODUCT_LIST_LINK_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;
        case productListLinkConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false,
            };
            break;
        default:
            return state
    }
    return state
}

export const setCloseAlertProductListLink = (payload) => ({ type: productListLinkConstants.SET_CLOSE_ALERT, payload })
