import React from 'react';
import Layout from '../../components/Layout';
import { mainItemsHref } from '../../store/localItems';

const Home = (props) => {

  return (
    <Layout sidebar>
      <h1 style={{
        margin: "20px",
        textAlign: "center"
      }}>
        Главная страница
      </h1>
      <div className="homePageContainer">
        {mainItemsHref.map((item, index) => (
          <div key={index} className="homePageContainer__mainBox">
            <a href={item.href}>
              <div className="homePageContainer__iconContainer">
                {item.icon}
              </div>
              <div className="homePageContainer__text">
                {item.text}
              </div>
            </a>
          </div>
        ))}
      </div>
    </Layout>
  )

}

export default Home