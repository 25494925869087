import React, { useState } from 'react'
import ContainerModalConfirmPlusIcon from './ContainerModalConfirmPlusIcon';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { useDispatch } from 'react-redux';
import { deletePaymentAccount } from '../../actions';
import DetailsPaymentAccount from './DetailsPaymentAccount';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import DescriptionIcon from '@mui/icons-material/Description';
const ContentItem = ({
    item,
    index,
    handleViewType,
    orderObj
}) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const dispatch = useDispatch();

    const confirmDelete = () => {
        const payload = {
            orderId: orderObj._id
        }
        dispatch(deletePaymentAccount(item._id, payload))
    }

    return (
        <div className="contentItem">
            <div className="contentItem__indexNumber">
                {index + 1}
            </div>
            <ContainerModalConfirmPlusIcon
                tooltip="Удалить этот счет"
                top="3px"
                right="5px"
                icon={<DeleteForeverTwoToneIcon />}
                onClickShow={() => setShowDeleteConfirm(true)}
                title={`Подтвержить удаление счета на: ${item?.price}`}
                description="Если вы продолжите то это действие будет невозможно вернуть, счет удалится навсегда!"
                show={showDeleteConfirm}
                handleClose={() => setShowDeleteConfirm(false)}
                onClick={confirmDelete}
            />
            <SettingIconBlock
                tooltip="Подробная информация"
                top="3px"
                right="25px"
                icon={<DescriptionIcon />}
                onClick={() => setShowDetails(true)}
            />
            <div className="contentItem__title">

                <span>
                    {item.afTitle || item.price}
                </span>
            </div>
            <div className="contentItem__description">
                <span>
                    {item.afDescription || item.description}
                </span>
            </div>
            {item.afViewType && <div className="contentItem__viewType">
                <span>
                    {handleViewType(item.afViewType)}
                </span>
            </div>}
            <DetailsPaymentAccount
                item={item}
                show={showDetails}
                setShowDetails={setShowDetails}
            />
        </div>
    )
}

export default ContentItem