export const saveSettings = (sort, pageNumber, pageSize) => {
    let settings = {
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize
    };
    localStorage.setItem('settings', JSON.stringify(settings));
}
export const saveSlugSettings = (slug, sort, pageNumber, pageSize) => {
    let settings = {
        slug: slug,
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize
    };
    localStorage.setItem('slugSettings', JSON.stringify(settings));
} 