import React, { useState } from 'react'
import ProductImgSliderModal from './ProductImgSliderModal';
import Slider from './../Slider/slider/Slider/Slider';
import RemoveProductPictures from './../../containers/ComponentContainer/RemoveProductPictures';
import BRenderAddPictures from '../Button/BRenderAddPictures';
import { useDispatch } from 'react-redux';
import { addBluePrintsProductById, addSamplePhotoProduct, removeGalleryPicturesByIds } from '../../actions';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Row, Col } from 'react-bootstrap';
import Input from '../../components/UI/Input/index';
import { Button } from '@mui/material';

const RenderImgSliderModal = ({
    disableRemoveFunctional,
    setProductPictures,
    currentIdPicture,
    productPictures,
    setShowModalImg,
    productDetails,
    deleteAction,
    showModalImg,
    setTypeModal,
    typeModal,
    typeClose,
    noneProd,
    loading,
    item
}) => {

    const [visibleWatermark, setVisibleWatermark] = useState("")

    const dispatch = useDispatch();

    const handleClosePhoto = () => {
        setShowModalImg(false)
        if (typeClose !== "product") {
            if (!noneProd && setTypeModal) {
                setTypeModal("")
            }
        }
    }
    const handleCloseAll = () => {
        setShowModalImg(false)
    }
    const handleSubmitAddPhoto = () => {
        if (productPictures.length > 0) {
            if (typeModal === "settingPhotoBluePrints") {
                dispatch(addBluePrintsProductById(productDetails._id, productPictures, visibleWatermark))
                    .then(handleCloseAll)

            } else if (typeModal === "settingPhoto") {
                dispatch(addSamplePhotoProduct(productDetails._id, productPictures, visibleWatermark))
            }

        }
    };
    console.log("item", item)
    console.log("typeModal", typeModal)
    const renderContent = () => {
        let content, title;
        switch (typeModal) {
            // case "updateMainImageById":
            //     title = "Изменить фоновое изображение";
            //     content = <h1>Hello</h1>
            //     break;
            case "settingPhoto":
            case "settingPhotoBluePrints":
                title = "Добавить новые фото"
                content =
                    <div>
                        <BRenderAddPictures
                            setPictures={setProductPictures}
                        // pictures={pictures}
                        />
                        <Row>
                            <Col md="8">
                                <div className="custom-radio-wrapper">
                                    <div>
                                        <input
                                            type="radio"
                                            className="custom-radio"
                                            name="visibleWatermark"
                                            value="visible"
                                            checked={visibleWatermark === "visible"}
                                            onChange={() => setVisibleWatermark("visible")}
                                        />
                                        Наложить
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            className="custom-radio"
                                            name="visibleWatermark"
                                            value="hide"
                                            checked={visibleWatermark === "hide"}
                                            onChange={() => setVisibleWatermark("hide")}
                                        />
                                        Без знака
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Button
                            disabled={productPictures.length > 0 ? false : true}
                            variant="contained"
                            onClick={handleSubmitAddPhoto}>
                            Добавить новые фото
                        </Button>
                    </div>
                break;
            default:
                title = `Все фотографии товара - ${productDetails && productDetails.fullName !== "" ? productDetails.fullName : null}`
                content = <div style={{
                    maxHeight: "90%"
                }}>
                    <div>
                        <Slider
                            currentIdPicture={currentIdPicture}
                            item={item}
                            onClick
                        />
                    </div>
                    {disableRemoveFunctional ? null : <RemoveProductPictures
                        deleteAction={deleteAction}
                        handleCloseAll={handleCloseAll}
                        productDetailsObj={productDetails}
                        pictures={item}
                        loading={loading}
                    />}

                </div>
        }
        return { title, content }
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: typeModal === "settingPhoto" || "settingPhotoBluePrints"
            ? "800px"
            : "400px",
        maxWidth: typeModal === "settingPhoto" || "settingPhotoBluePrints"
            ? "1000px"
            : "500px",
        maxHeight: "90%",
        minHeight: "100px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        overflowY: "auto"
    };
    return (
        <Modal
            open={showModalImg}
            onClose={handleClosePhoto}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ margin: "10px auto", textAlign: "center" }}>
                    {renderContent().title}
                </h3>
                {renderContent().content}
            </Box>
        </Modal>

    )
}

export default RenderImgSliderModal