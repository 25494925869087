import { contactMConstants } from "../../actions/constants"


const initialState = {
    contactM: [],
    loading: false
}


export default (state = initialState, action) => {
    switch (action.type) {
        case contactMConstants.DELETE_CONTACT_M_SUCCESS:
            return state.contactM.filter((contactM) => contactM._id !== action.payload)
        case contactMConstants.UPDATE_CONTACT_M_SUCCESS:
            return state.contactM.map((contactM) => contactM._id === action.payload._id ? action.payload : contactM)

        case contactMConstants.ADD_CONTACT_M_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case contactMConstants.ADD_CONTACT_M_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break
        case contactMConstants.ADD_CONTACT_M_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case contactMConstants.UPDATE_CONTACT_M_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case contactMConstants.UPDATE_CONTACT_M_SUCCESS:
            state = {
                ...state,
                contactM: action.payload.contactM,
                loading: false
            }
            break
        case contactMConstants.UPDATE_CONTACT_M_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case contactMConstants.GET_CONTACT_M_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case contactMConstants.GET_CONTACT_M_SUCCESS:
            state = {
                ...state,
                contactM: action.payload.contactM,
                loading: false
            }
            break
        case contactMConstants.GET_CONTACT_M_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        default:
            return state

    }
    return state
}