import { paymentAccountConstants } from "../../actions/constants"


const initialState = {
    paymentAccounts: [],
    paymentAccountDetails: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "success",
}


export default (state = initialState, action) => {
    switch (action.type) {
        case paymentAccountConstants.DELETE_PAYMENT_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.DELETE_PAYMENT_ACCOUNT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                paymentAccounts: state.paymentAccounts?.filter((paymentAccounts) => paymentAccounts._id !== action.payload),
                loading: false
            }
            break;
        case paymentAccountConstants.DELETE_PAYMENT_ACCOUNT_FAILURE:
            state = {
                ...state,
                // message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
                loading: false,
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_SUCCESS:
            state = {
                ...state,
                paymentAccounts: action.payload.paymentAccounts,
                message: action.payload.message,
                showAlert: true,
                loading: false
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            }
            break;
        case paymentAccountConstants.ADD_PAYMENT_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.ADD_PAYMENT_ACCOUNT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                loading: false
            }
            break;
        case paymentAccountConstants.ADD_PAYMENT_ACCOUNT_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            }
            break;
        case paymentAccountConstants.UPDATE_PAYMENT_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.UPDATE_PAYMENT_ACCOUNT_SUCCESS:
            state = {
                ...state,
                paymentAccounts: state.paymentAccounts?.map((paymentAccounts) => paymentAccounts._id === action.payload._id ? action.payload : paymentAccounts),
                loading: false
            }
            break;
        case paymentAccountConstants.UPDATE_PAYMENT_ACCOUNT_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_SUCCESS:
            state = {
                ...state,
                paymentAccounts: action.payload.paymentAccounts,
                loading: false
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case paymentAccountConstants.SET_CLOSE_ALERT_PAYMENT_ACCOUNT:
            state = {
                ...state,
                showAlert: false
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                paymentAccountDetails: action.payload.paymentAccountDetails
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                error: action.payload.error
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                paymentAccounts: action.payload.paymentAccounts,
            }
            break;
        case paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                error: action.payload.error
            }
            break;
        default:
            return state
    }
    return state;
}

export const setCloseAlertPaymentAccount = (payload) => ({
    type: paymentAccountConstants.SET_CLOSE_ALERT_PAYMENT_ACCOUNT,
    payload
})
