import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Row, Col } from "react-bootstrap";
import { setShowUserModal, setHideUserModal } from '../../reducers/user.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from '../../actions';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '1px solid gray',
    boxShadow: 24,
    p: 2,
    maxHeight: '100ch',
    overflowY: 'auto',
};

const UserInfoModal = () => {
    const { userDetails, userId, showModal } = useSelector((state) => state.user)

    const dispatch = useDispatch()

    useEffect(() => {

        if (userId) {
            dispatch(getUserById(userId))
        }
    }, [userId])

    const handleClose = () => {
        dispatch(setHideUserModal())

    }

    const contentItem = (
        title,
        text
    ) => {
        return <Col md="6">
            <label className="key">{title}</label>
            <p className="value"
                style={{ color: text ? "" : "red" }}
            >{text ? text : "пусто"}</p>
        </Col>
    }
    return (
        <div>
            <Modal
                open={showModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <h3
                        className="text-center"
                        style={{ marginBottom: "10px" }}>
                        Вся информация о пользователе
                    </h3>
                    <Row>
                        {contentItem("Область", userDetails.regionName)}
                        {contentItem("Роль пользователя ", userDetails.role)}
                    </Row>
                    <Row>
                        {contentItem("username(никнейм)", userDetails.username)}
                        {contentItem("Email", userDetails.email)}
                    </Row>
                    <Row>
                        {contentItem("Имя", userDetails.firstName)}
                        {contentItem("Фамилия", userDetails.lastName)}
                    </Row>
                    <Row>
                        {contentItem("Номер", userDetails.contactNumber)}
                        {contentItem("Email", userDetails.email)}
                    </Row>
                    <Row>
                        {contentItem("День рождение", userDetails.birthData)}
                        {contentItem("Пол", userDetails.gender)}
                    </Row>
                    <Row>
                        {contentItem("Время создания", userDetails.createdAt?.slice(0, 10) + " " + userDetails.createdAt?.slice(11, 19))}
                        {contentItem("Время обновления", userDetails.updatedAt?.slice(0, 10) + " " + userDetails.updatedAt?.slice(11, 19))}
                    </Row>
                </Box>
            </Modal>
        </div>
    )
}

export default UserInfoModal