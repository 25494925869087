import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVisibleHiddenCategories } from '../reducers/category.reducer'
import { useNavigate } from 'react-router-dom'

const ContainerButtonVisible = ({
    setHideMarked,
    hideMarked,
    slug,

}) => {
    const categories = useSelector((state) => state.category.categories)
    const [currentIndex, setCurrentIndex] = useState(1)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (slug === "Kresty-K_tR6qOVAb") {
            navigate("/products/all")
        }
    }, [hideMarked])

    const handleHideProduct = (index, text) => {
        setCurrentIndex(index + 1)
        setHideMarked(text === "Скрыть отмеченные" ? true : false)
        dispatch(setVisibleHiddenCategories(text === "Скрыть отмеченные" ? true : false))
    }

    const itemButton = [
        {
            text: "Показать все",

        },
        {

            text: "Скрыть отмеченные",
        },
    ]
    return (
        <div className="containerButtonVisible">
            <div className="containerButtonVisible__row">
                {itemButton.map((item, index) =>
                    <div
                        key={index}
                        className={currentIndex === index + 1
                            ? "containerButtonVisible__box active"
                            : "containerButtonVisible__box"

                        }
                        onClick={() => handleHideProduct(index, item.text)}
                    >
                        <span>
                            {item.text}
                        </span>
                    </div>
                )}
            </div>
        </div >
    )
}

export default ContainerButtonVisible