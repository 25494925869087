import React from 'react'

const RenderSelectModel = ({
    value,
    setValue,
    itemsModel,
    title,
    titleOption,
    handleChange,
    name,
    valueKey
}) => {

    const onChange = (e) => {

        if (valueKey !== "") {
            handleChange(valueKey, e.target.value)
        } else {
            setValue(e.target.value)
        }
    }
    return (
        <div>
            <label htmlFor={name ? name : "categoryId"}>{title ? title : "Выбрать Цвет"}</label>
            <select
                className="form-control"
                name={name ? name : "categoryId"}
                value={value}
                onChange={(e) => onChange(e)}
            >
                <option
                    value={titleOption ? titleOption : "Выбрать цвет"} >
                    {titleOption ? titleOption : "Выбрать цвет"}
                </option>
                {itemsModel.map((option) => (
                    <option key={option._id} value={option._id}>
                        {option.name || option.title}
                    </option>
                ))}
            </select>
        </div >
    )
}

export default RenderSelectModel