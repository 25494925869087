import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import SettingIconBlock from '../../../components/Button/SettingIconBlock';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import PicturesMiniRow from '../../../components/Pictures/PicturesMiniRow';
import { removeBlueprintsByIds } from '../../../actions';


const ProductBluePrints = ({
    productDetails,
    loading
}) => {

    const [productBluePrints, setProductBluePrints] = useState([])
    const [currentIdPicture, setCurrentIdPicture] = useState(null)

    const [showModalImg, setShowModalImg] = useState(false)
    const [typeModal, setTypeModal] = useState("")


    const handleShowModalImg = (pictureId) => {
        setCurrentIdPicture(pictureId)
        setShowModalImg(true)
        setTypeModal("")
    }
    const handleClose = () => {
        setShowModalImg(false)
        setTypeModal("")
    }
    const handleShowTypeModal = (type) => {
        setTypeModal(type)
        setShowModalImg(true)
    }
    const handleDownloadAllPhotos = () => { }
    // const promises = productDetails?.productPictures?.map((picture, index) => {
    //     return fetch(generatePublicUrl(picture.img))
    //         .then(response => response.blob())
    //         .then(blob => {
    //             const filename = `photo_${index}.jpg`;
    //             saveAs(blob, filename);
    //         });
    // });
    // Promise.all(promises)
    //     .then(() => {
    //         console.log("All photos downloaded");
    //     })
    //     .catch((error) => {
    //         console.log("Error downloading photos", error);
    //     });

    return (
        <div>
            <Row>
                <Col>
                    <label
                        style={{ position: "relative" }}
                        className="key">
                        Фотографии
                        <SettingIconBlock
                            tooltip="Добавить"
                            icon={<AddCircleOutlineIcon />}
                            right="-25px"
                            top="-2px"
                            onClick={() => handleShowTypeModal("settingPhotoBluePrints")}
                        />
                        <SettingIconBlock
                            tooltip="Скачать все фото сразу"
                            icon={<DownloadForOfflineIcon />}
                            right="-50px"
                            top="-2px"
                            onClick={handleDownloadAllPhotos}
                        />
                    </label>
                    <PicturesMiniRow
                        itemsPicture={productDetails?.blueprints}
                        handleClick={handleShowModalImg}
                        visibleImg
                    />
                </Col>
            </Row>
            <RenderImgSliderModal
                deleteAction={removeBlueprintsByIds}
                setProductPictures={setProductBluePrints}
                item={productDetails.blueprints}
                currentIdPicture={currentIdPicture}
                setShowModalImg={setShowModalImg}
                productPictures={productBluePrints}
                productDetails={productDetails}
                setTypeModal={setTypeModal}
                showModalImg={showModalImg}
                handleClose={handleClose}
                typeModal={typeModal}
                loading={loading}
            // slug={slug}
            />

        </div>


    )
}

export default ProductBluePrints