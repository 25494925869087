import React from 'react'
import { deleteContact } from '../../actions';
import Loader from '../../components/Loader';
import { useDispatch } from 'react-redux';
import BackButton from './../components/BackButton';
import { Row, Col } from 'react-bootstrap';

export const RenderContacts = ({ contact, setCurrentId, setShow }) => {
    const dispatch = useDispatch()

    const Edit = (item) => {
        setCurrentId(item._id)
        setShow(true)
    }

    const handleDeleteContact = (id) => {
        dispatch(deleteContact(id))
    }

    // {
    //     orderItem.disableFunctionality ? null : <ContainerModalConfirmPlusIcon
    //         tooltip="Удалить заказ"
    //         top="20px"
    //         right="60px"
    //         icon={<DeleteIcon />}
    //         onClickShow={() => setShowDeletingConfirmation(true)}
    //         title={`Подтвердить удаление заказа ${orderItem._id}`}
    //         description="Если вы продолжите то вернуть его будет невозможно!!!"
    //         show={showDeletingConfirmation}
    //         handleClose={() => setShowDeletingConfirmation(false)}
    //         onClick={() => confirmDelete(orderItem._id)}
    //     />
    // }

    return (
        <div className="renderContact">
            {!contact ? <Loader /> :
                contact.map((item) =>
                    <div className="renderContact__contentBlock" key={item._id} >

                        <Row>
                            <Col md="6">
                                <label className="key">Название фирмы</label>
                                <p className="value">{item.nameCompany || null}</p>
                            </Col>
                            <Col md="6">
                                <label className="key">Город</label>
                                <p className="value">{item.city || null}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <label className="key">Адрес</label>
                                <p className="value">{item.address || null}</p>
                            </Col>
                            <Col md="6">
                                <label className="key">Индекс города</label>
                                <p className="value">{item.cityIndex || null}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <label className="key">Страна</label>
                                <p className="value">{item.country || null}</p>
                            </Col>
                            <Col md="6">
                                <label className="key">Email</label>
                                <p className="value">{item.email || null}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <label className="key">Номер телефона</label>
                                <p className="value">{item.number || null}</p>
                            </Col>
                            <Col md="6">
                                <label className="key">Второй номер телефона</label>
                                <p className="value">{item.numberTwo || null}</p>
                            </Col>
                        </Row>
                        <div className="renderContact__buttonBlock">
                            <BackButton
                                onClickButton
                                onClick={() => Edit(item)}
                                text="Изменить"
                            />
                            <BackButton
                                onClickButton
                                onClick={() => { }}
                                text="Удалить"
                            />
                        </div>
                    </div>
                )
            }
        </div >
    )
}
