import React, { useState, useEffect } from 'react'
import { deleteMaterial, getMaterial } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import AddMaterial from './../create/AddMaterial';
import { MainContainer } from './../MainContainer';
import ObjRender from './../render/ObjRender';

const Material = () => {
    const material = useSelector((state) => state.material.material)
    const [show, setShow] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMaterial())
    }, [])


    return (
        <MainContainer
            type="add"
            backButtonProduct
            title="Материалы"
            get={getMaterial}
            handleShow={handleShow}
            buttonText="Создать материал"
        >
            <ObjRender
                obj={material}
                setCurrentId={setCurrentId}
                setShow={setShow}
                delete={deleteMaterial}
            />
            <AddMaterial
                handleClose={handleClose}
                show={show}
                currentId={currentId}
            />
        </MainContainer>
    )
}

export default Material