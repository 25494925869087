import React from "react";
import { NavLink } from 'react-router-dom';
import Layout from '../components/Layout/index';
import BackButton from '../siteSetting/components/BackButton';
import { partnerArray } from '../store/localItems';

const LinkPartnerPage = () => {


    return (
        <Layout sidebar>
            <div style={{
                marginTop: "20px"
            }}>
                <BackButton
                    text="На главную"
                    href="/"
                />
            </div>
            <div className="row ">
                {partnerArray.map(item => (
                    <>
                        <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 text-center">
                            <div className="containerProductPage">
                                <NavLink to={item.href} style={{ textDecoration: "none" }}>
                                    <div className="containerProductPage__block">
                                        <div className="containerProductPage__blockImg">
                                            <img src={item.photo} className="containerProductPage__img" alt="foto" />
                                        </div>
                                        <div className="containerProductPage__body text-center">
                                            <h4>
                                                {item.text}
                                            </h4>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </Layout>
    )
}

export default LinkPartnerPage