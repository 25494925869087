import auth from './auth.reducers'
import user from './user.reducer'
import product from './product.reducer'
import category from './category.reducer'
import order from './order.reducer'
import page from './page.reducer'
import loader from './loader.reducer'
import gallery from './galleryImage'
import application from './application.reducer'
import header from './header'
import promotion from './components/promotion'
import mainImage from './components/mainImage'
import manufacture from './components/manufacture'
import weWorkOnline from './components/weWorkOnline'
import comeToUs from './components/comeToUs'
import engraving from './components/engraving'
import contact from './components/contact'
import warranty from './components/warranty'
import graniteMaterial from './components/graniteMaterial'
import paymentLI from './components/paymentLI'
import catalogTitle from './components/catalogTitle'
import costDelivery from './components/costDelivery'
import blog from './components/blog'
import portfolio from './components/portfolio'
import tombstoneCurb from './components/tombstoneCurb'
import graniteTiles from './components/graniteTiles'
import footer from './components/footer'
import engravingLayout from './components/engravingLayout'
import engravingCategory from './components/engravingCategory'
import deliveryInfo from './components/deliveryInfo'
import paymentList from './components/paymentList'
import aboutUs from './components/aboutUs'
import benefits from './components/benefits'
import architect from './components/architect'
import architectLi from './components/architectLi'
import modalContent from './components/modalContent'
import architectSkills from './components/architectSkills'
import material from './components/material'
import allQuestion from './components/allQuestion'
import pickupPoints from './components/pickupPoints'
import contactM from './components/contactM'
import colorProduct from './components/colorProduct'
import paymentAccount from './components/paymentAccount'
import region from './components/region'
import searchQuery from './components/searchQuery'
import address from './address'
import roleChangeRequest from './roleChangeRequest'
import elementPaymentPage from './components/elementPaymentPage'
import applicationCallMe from './applicationCallMe'
import applicationChangeProfile from './applicationChangeProfile'
import mainPage from './components/mainPage'
import applicationGallery from './applicationGallery'
import featuredProductItem from './featuredProductItem'
import productListLink from './productListLink'
import productMaterial from './productMaterial'
import siteCategory from './siteCategory'
import mailing from './mailing'

import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    user,
    auth,
    category,
    product,
    order,
    page,
    loader,
    application,
    gallery,
    header,
    promotion,
    mainImage,
    manufacture,
    comeToUs,
    weWorkOnline,
    engraving,
    contact,
    warranty,
    graniteMaterial,
    paymentLI,
    catalogTitle,
    costDelivery,
    portfolio,
    blog,
    tombstoneCurb,
    footer,
    graniteTiles,
    deliveryInfo,
    engravingCategory,
    engravingLayout,
    paymentList,
    aboutUs,
    benefits,
    architect,
    architectLi,
    architectSkills,
    modalContent,
    material,
    allQuestion,
    pickupPoints,
    contactM,
    colorProduct,
    paymentAccount,
    address,
    region,
    searchQuery,
    roleChangeRequest,
    elementPaymentPage,
    applicationCallMe,
    applicationChangeProfile,
    mainPage,
    applicationGallery,
    featuredProductItem,
    productListLink,
    productMaterial,
    siteCategory,
    mailing
})


export default rootReducer