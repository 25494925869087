import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import { generatePublicUrl } from '../../../urlConfig';
import { getFeaturedProductItemById, getGalleryById } from '../../../actions';
import { useDispatch } from 'react-redux';
import { Button, Link } from '@mui/material';
import RenderMiniImg from '../../../components/Button/RenderMiniImg';
import PicturesMiniRow from '../../../components/Pictures/PicturesMiniRow';

const RenderDetails = ({
    productListLinkObj,
    setShowModalImg,
    showModalImg,
    typeModal,
    featuredProductItemObj,
    handleShowModalImg,
    currentIdPicture
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (productListLinkObj?.featuredProductItemId && typeModal === "details") {
            const test = dispatch(getFeaturedProductItemById(productListLinkObj?.featuredProductItemId))
        }
    }, [productListLinkObj])

    if (!productListLinkObj) return null
    if (!featuredProductItemObj) return null
    return (
        <>
            <Row>
                <Col md="6">
                    <label className="key">Заголовок</label>
                    <p className="value">{productListLinkObj.name}</p>
                </Col>
            </Row>
            <Row>

                <Col md="12">
                    <label className="key">Тип</label>
                    <p className="value">{productListLinkObj.type}</p>
                </Col>
            </Row>

            <Row>
                <Col md="12">
                    <label className="key">Какой список с товарами к этому привязан</label>
                    <p
                        className={`value ${productListLinkObj.featuredProductItemId ? "" : "empty"}`} >
                        {productListLinkObj.featuredProductItemId
                            ? featuredProductItemObj.name
                            : "Нет привязанной галереи"}
                    </p>
                    {
                        productListLinkObj.featuredProductItemId
                            ? <PicturesMiniRow
                                itemsPicture={featuredProductItemObj.products}
                                handleClick={handleShowModalImg}
                            />
                            : null}
                    <Link href="/featuresProductItem"
                        underline="hover"
                        style={{
                            margin: "10px auto",
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        Страница с списками
                    </Link>
                </Col>

            </Row>
            <RenderImgSliderModal
                disableRemoveFunctional
                currentIdPicture={currentIdPicture}
                item={featuredProductItemObj.products}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            />
        </>
    )
}

export default RenderDetails





