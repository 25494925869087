import React, { useState } from 'react'
import ProductImgSliderModal from '../../components/ProductImgSliderModal/ProductImgSliderModal'
import ContentButtonBox from './../../components/Button/ContentButtonBox';
import { updateProductVisibleById, updateProductPriceVisibleById } from './../../actions/product.action';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const RenderSettingProductModal = ({
    show,
    handleClose,
    modalTitle,
    productDetails

}) => {
    const [visiblePrice, setVisiblePrice] = useState(productDetails?.visiblePrice);
    const [visibleObj, setVisibleObj] = useState(productDetails?.visibleProduct);

    const dispatch = useDispatch()
    const { slug } = useParams()

    if (!productDetails) return null

    const handleVisibleObj = (id) => {
        dispatch(updateProductPriceVisibleById(id))
        setVisibleObj(!visibleObj)
    }
    const handleVisiblePrice = (id) => {
        dispatch(updateProductVisibleById(id, slug))
        setVisiblePrice(!visiblePrice)
    }
    return (
        <ProductImgSliderModal
            show={show}
            handleClose={handleClose}
            modalTitle={modalTitle}
            size="md"
        >
            <ContentButtonBox
                checked={visibleObj}
                checkedTitleText={"товар для покупателя"}
                productDetails={productDetails}
                showTextDesc={"Товар видно покупателю"}
                hideTextDesc={"Товар скрыт для покупателя"}
                editFunc={handleVisibleObj}
            />
            <ContentButtonBox
                checked={visiblePrice}
                checkedTitleText={"цену товара"}
                productDetails={productDetails}
                showTextDesc={"Цену видно покупателю"}
                hideTextDesc={"Цена скрыта для покупателя"}
                updateAction={updateProductPriceVisibleById}
                editFunc={handleVisiblePrice}
            />
        </ProductImgSliderModal>
    )
}

export default RenderSettingProductModal