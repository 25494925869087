import axios from "../helpers/axios";
import { featuredProductItemConstants } from "./constants";

export const getFeaturedProductItemById = (id, random = "false") => {
  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.GET_BY_ID_REQUEST });
      let url = `/featuredProductItem/getById/${id}/${random}`

      const res = await axios.get(url);
      if (res.status === 200) {
        dispatch({
          type: featuredProductItemConstants.GET_BY_ID_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({ type: featuredProductItemConstants.GET_BY_ID_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getFeaturedProductItems = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.GET_ALL_ITEMS_REQUEST });
      let url = "/featuredProductItem/get"

      const res = await axios.get(url);
      if (res.status === 200) {
        const { featuredProductItems } = res.data;
        dispatch({
          type: featuredProductItemConstants.GET_ALL_ITEMS_SUCCESS,
          payload: { featuredProductItems },
        });
      } else {
        dispatch({ type: featuredProductItemConstants.GET_ALL_ITEMS_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const updateFeaturedProductItemById = (id, payload) => {

  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_REQUEST });
      const res = await axios.post(`/featuredProductItem/updateById/${id}`, payload);
      if (res.status === 200) {
        dispatch({
          type: featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_SUCCESS,
          payload: res.data
        });
        dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateFeaturedProductOrder = (payload) => {

  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_REQUEST });
      const res = await axios.post("/featuredProductItem/updateProductOrder", payload);
      if (res.status === 200) {
        dispatch({
          type: featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_SUCCESS,
          payload: res.data
        });
        dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeProductByIds = (payload) => {

  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.REMOVE_PRODUCT_BY_IDS_REQUEST });
      const res = await axios.post("/featuredProductItem/removeProductByIds", payload);
      if (res.status === 200) {
        dispatch({
          type: featuredProductItemConstants.REMOVE_PRODUCT_BY_IDS_SUCCESS,
          payload: res.data
        });
        // dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.REMOVE_PRODUCT_BY_IDS_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const removeProductByIdProductFeaturedProductItem = (payload) => {

  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.REMOVE_PRODUCT_BY_ID_REQUEST });
      const res = await axios.post("/featuredProductItem/removeProduct", payload);
      if (res.status === 200) {
        dispatch({
          type: featuredProductItemConstants.REMOVE_PRODUCT_BY_ID_SUCCESS,
          payload: res.data
        });
        // dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.REMOVE_PRODUCT_BY_ID_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addNewProductFeaturedProductItem = (payload) => {

  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.ADD_NEW_PRODUCT_REQUEST });
      const res = await axios.post("/featuredProductItem/addNewProduct", payload);
      if (res.status === 201) {
        dispatch({
          type: featuredProductItemConstants.ADD_NEW_PRODUCT_SUCCESS,
          payload: res.data
        });
        dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.ADD_NEW_PRODUCT_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const createFeaturedProductItem = (form) => {

  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.CREATE_REQUEST });
      const res = await axios.post("/featuredProductItem/create", form);
      if (res.status === 201) {
        dispatch({
          type: featuredProductItemConstants.CREATE_SUCCESS,
          payload: res.data
        });
        dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.CREATE_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteFeaturedProductItemById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: featuredProductItemConstants.DELETE_BY_ID_REQUEST });
      const res = await axios.delete(`/featuredProductItem/delete/${id}`);
      if (res.status === 202) {
        dispatch({
          type: featuredProductItemConstants.DELETE_BY_ID_SUCCESS,
          payload: res.data
        });
        dispatch(getFeaturedProductItems())
      } else {
        dispatch({
          type: featuredProductItemConstants.DELETE_BY_ID_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateFeaturedProductItemVisibleById = (id) => async (dispatch) => {
  try {
    dispatch({ type: featuredProductItemConstants.UPDATE_VISIBLE_BY_ID_REQUEST })
    const res = await axios.get(`/featuredProductItem/visible/${id}`)
    if (res.status === 200) {
      dispatch({
        type: featuredProductItemConstants.UPDATE_VISIBLE_BY_ID_SUCCESS,
        payload: res.data
      })
    } else {
      dispatch({
        type: featuredProductItemConstants.UPDATE_VISIBLE_BY_ID_FAILURE,
        payload: res.data
      })
    }
    dispatch(getFeaturedProductItems())
  } catch (error) {
    console.log(error);
  }
}