import React, { useEffect, useState } from 'react'
import { addProductionMaterial, updateProductionMaterial } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Input from '../../components/UI/Input/index';
import BRenderAddPictures from '../../components/Button/BRenderAddPictures';
import { Button } from '@mui/material';

const AddProductionMaterial = ({
    productMaterialForm,
    pictures,
    setPictures,
    handleClose,
    currentId,
    setProductMaterialForm,
}) => {
    const productMaterial = useSelector((state) => currentId
        ? state.productMaterial.productMaterials.find((item) => item._id === currentId)
        : null)

    const dispatch = useDispatch()

    console.log('currentId', currentId)
    console.log('productMaterial', productMaterial)

    const submitProductForm = () => {
        if (currentId) {
            dispatch(updateProductionMaterial(currentId, productMaterialForm))
            handleClose()
        } else {
            const form = new FormData();
            form.append("name", productMaterialForm.name);
            form.append("description", productMaterialForm.description);
            form.append("measurementType", productMaterialForm.measurementType);
            form.append("height", productMaterialForm.height);
            form.append("width", productMaterialForm.width);
            // form.append("length", productMaterialForm.length);
            form.append("price", productMaterialForm.price);
            // form.append("size", productMaterialForm.size);
            form.append("weight", productMaterialForm.weight);
            for (let i = 0; i < pictures.length; i++) {
                form.append("pictures", pictures[i]);
            }
            dispatch(addProductionMaterial(form)).then(() => handleClose());
        }
    };

    useEffect(() => {
        if (productMaterial) setProductMaterialForm(productMaterial)
    }, [productMaterial])

    return (
        <div>

            <Row>
                <Col md="8">
                    <Input
                        showError
                        type="select"
                        label="Укажите тип:"
                        name="measurementType"
                        value={productMaterialForm.measurementType}
                        placeholder="Ничего"
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, measurementType: e.target.value })}
                        options={[
                            { value: "length", name: "Длина" },
                            { value: "area", name: "Площадь" },
                            { value: "volume", name: "Объем" },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="8">
                    <Input
                        label="Название"
                        name="name"
                        value={productMaterialForm.name}
                        placeholder={`Введите Название`}
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, name: e.target.value })}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="8">
                    <Input
                        label="Подробное описание"
                        name="description"
                        value={productMaterialForm.description}
                        placeholder={`подробное описание`}
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, description: e.target.value })}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Input
                        label="Высота"
                        name="height"
                        value={productMaterialForm.height}
                        placeholder={`Введите Высоту`}
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, height: e.target.value })}
                    />
                </Col>
                <Col md="6">
                    <Input
                        label="Ширина"
                        name="width"
                        value={productMaterialForm.width}
                        placeholder={`Введите Ширину`}
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, width: e.target.value })}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Input
                        label="Цена за 1 единицу"
                        name="price"
                        value={productMaterialForm.price}
                        placeholder={`Введите сумму`}
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, price: e.target.value })}
                    />
                </Col>
                <Col md="6">
                    <Input
                        label="Вес за 1 единицу"
                        name="weight"
                        value={productMaterialForm.weight}
                        placeholder={`Введите вес`}
                        onChange={(e) => setProductMaterialForm({ ...productMaterialForm, weight: e.target.value })}
                    />
                </Col>
            </Row>
            {!currentId
                ? <BRenderAddPictures
                    setPictures={setPictures}
                    pictures={pictures}
                />
                : null}


            <Button
                variant='contained'
                onClick={submitProductForm}
            >
                {currentId ? "Сохранить правки" : "Сохранить"}
            </Button>
        </div>
    )
}

export default AddProductionMaterial