import React from 'react'

const TextHidePicture = ({
    visibleImg,
    pictureVisible,
    type
}) => {

    const renderContent = () => {
        let content;
        switch (type) {
            case "":
                content = ""
                break;
            default:
                content = visibleImg && !pictureVisible
                    ?
                    <div className="pictureMiniRow__textHidePicture">
                        Скрыто
                    </div>
                    : null

        }
        return { content }
    }
    return <>
        {renderContent().content}
    </>

}

export default TextHidePicture