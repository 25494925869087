import React, { useState, useEffect } from 'react'
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addMaterial, updateMaterial } from '../../actions';


const AddMaterial = ({ show, handleClose, currentId }) => {
    const dispatch = useDispatch()
    const materialObj = useSelector((state) => currentId ? state.material.material?.find((item) => item._id === currentId) : null)
    const [formMaterial, setFormMaterial] = useState({
        name: "",
        description: ""
    });

    useEffect(() => {
        if (materialObj) setFormMaterial(materialObj)
    }, [materialObj])

    const submitMaterial = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updateMaterial(currentId, formMaterial))
            handleClose()
        } else {
            const form = new FormData();
            form.append('name', formMaterial.name);
            form.append('description', formMaterial.description);
            dispatch(addMaterial(form)).then(() => handleClose());
        }
    }
    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle={"Добавить Материал"}
            onSubmit={submitMaterial}
        >
            <Input
                label="Название"
                value={formMaterial.name}
                placeholder="Введите название"
                name="name"
                onChange={(e) => setFormMaterial({ ...formMaterial, name: e.target.value })}
            />
            <Input
                label="Описание"
                value={formMaterial.description}
                placeholder="Описание для материала"
                name="description"
                onChange={(e) => setFormMaterial({ ...formMaterial, description: e.target.value })}
            />
        </Modal>
    )
}

export default AddMaterial