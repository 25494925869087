import React, { useState, useEffect } from 'react'
import { Row, Col } from "react-bootstrap";

import { } from 'react';
import RenderListItems from './RenderListItems';
import { Button } from '@material-ui/core';
import RenderMiniImg from '../../components/Button/RenderMiniImg';
import Loader from './../../components/Loader';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setShowUserModal, setHideUserModal } from '../../reducers/user.reducer';


const DetailsContent = ({
    showModalUser,
    handleDeleteById,
    setRejectModal,
    rejectedStatus,
    setTypeConfirm,
    handleUpdateUserPassword,
    loading,
    item,
}) => {
    const [disabledButton, setDisabledButton] = useState(true)

    // useEffect(() => {

    // }, []);

    const showConfirm = (type) => {
        setTypeConfirm(type)
        setRejectModal(true)
    }



    if (!item) return null

    const contentItem = (
        title,
        text
    ) => {
        return <Col md="6">
            <label className="key">{title}</label>
            <p className="value"
                style={{ color: text ? "" : "red" }}
            >{text ? text : "пусто"}</p>
        </Col>
    }

    if (loading) return <Loader />
    return <>
        <div style={{
            margin: "10px",
        }}>
            <button className="btn btn-secondary" onClick={handleDeleteById}>Удалить заявку</button>
        </div>
        <Row>
            <Col md="6">
                <label className="key">Номер заявки</label>
                <p className="value">{item.applicationNumber}</p>
            </Col>
            <Col md="6">
                <label className="key">Тип пользователя</label>
                <div
                    style={{
                        position: "relative",

                    }}
                >
                    <p className="value"
                        style={{
                            color: item.createdBy ? "green" : "red"
                        }}
                    >
                        {item.createdBy
                            ? "Зарегистрированый"
                            : "Не зарегистрированый"
                        }

                    </p>
                    {item.createdBy
                        ? <SettingIconBlock
                            right="10px"
                            tooltip="Посмотреть профиль"
                            icon={<AccountCircleIcon />}
                            onClick={() => showModalUser(item.createdBy)}
                        />
                        : null
                    }
                </div>
            </Col>
        </Row>
        <Row>
            {contentItem("Полное имя", item.fullName)}
            {contentItem("Название фирмы", item.nameCompany)}
        </Row>
        <Row>
            {contentItem("Номер", item.contactNumber)}
            {contentItem("Email", item.email)}
        </Row>
        <Row>
            {contentItem("Время создания", item.createdAt?.slice(0, 10) + " " + item.createdAt?.slice(11, 19))}
            {contentItem("Время обновления", item.updatedAt?.slice(0, 10) + " " + item.updatedAt?.slice(11, 19))}
        </Row>
        <Row>
            <Col md="12">
                <label className="key">Подробнее</label>
                <p className="value">
                    {item.descriptionProblem}
                </p>
            </Col>
        </Row>
        <Row>
            <Col md="12">
                <RenderMiniImg
                    itemObj={item.galleryPictures}
                // slideIndex={slideIndex}
                />
            </Col>
        </Row>
        {item.previousData || rejectedStatus
            ? <div className="applicationChangeProfile__successText">
                {rejectedStatus
                    ? "Заявка была отклонена"
                    : "Эти данные уже обновлены"
                }
            </div>
            : <div className="applicationChangeProfile__buttonContainer">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => showConfirm("rejected")}
                >
                    Отказать
                </Button>
                {item.variant === "password"
                    ? <Button
                        variant="contained"
                        color="success"
                        // disabled={disabledButton}
                        onClick={handleUpdateUserPassword}
                    >
                        Подтвердить смену пароля
                    </Button>
                    : <Button
                        variant="contained"
                        color="success"
                        disabled={disabledButton}
                        onClick={showConfirm}
                    >
                        Принять
                    </Button>
                }

            </div>
        }

    </>
}

export default DetailsContent