import { mailingConstants } from "../actions/constants";



const initialState = {
    productListLinks: [],
    mailingObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "",
}

export default (state = initialState, action) => {
    switch (action.type) {

        case mailingConstants.GET_BY_ID_MAILING_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case mailingConstants.GET_BY_ID_MAILING_SUCCESS:
            state = {
                ...state,
                loading: false,
                mailingObj: action.payload.productListLink
            };
            break;
        case mailingConstants.GET_BY_ID_MAILING_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case mailingConstants.GET_MAILING_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case mailingConstants.GET_MAILING_SUCCESS:
            state = {
                ...state,
                loading: false,
                mailing: action.payload.mailing
            };
            break;
        case mailingConstants.GET_MAILING_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;

        case mailingConstants.ADD_MAILING_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case mailingConstants.ADD_MAILING_SUCCESS:
            state = {
                ...state,
                // message: action.payload.message,
                // showAlert: true,
                // variantAlert: "success",
                loading: false
            };
            break;
        case mailingConstants.ADD_MAILING_FAILURE:
            state = {
                ...state,
                loading: false,
                // message: action.payload.message,
                // showAlert: true,
                // variantAlert: "warning"
            };
            break;
        case mailingConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false,
            };
            break;
        default:
            return state
    }
    return state
}

export const setCloseAlertProductListLink = (payload) => ({ type: mailingConstants.SET_CLOSE_ALERT, payload })
