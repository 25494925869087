import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ContainerSiteSetting from '../../ContainerSiteSetting';
import { addRegion, deleteRegion, getRegions, updateRegion, createAllRegion } from './../../../actions';
import RenderItemRegions from './RenderItemRegions';
import AddRegionModal from '../../create/AddRegionModal';
import Loader from '../../../components/Loader';
import { setCloseAlert } from '../../../reducers/product.reducer';
import { setCloseAlertRegion } from '../../../reducers/components/region';


const Region = () => {
    const {
        regions,
        loading,
        variantAlert,
        showAlert,
        message,
        regionObj
    } = useSelector((state) => state.region)


    const dispatch = useDispatch()
    const [currentId, setCurrentId] = useState(null)
    const [show, setShow] = useState(false)
    const [typeModal, setTypeModal] = useState("")

    const [formRegion, setFormRegion] = useState({
        name: "",
    })

    useEffect(() => {
        dispatch(getRegions())
    }, [])

    const handleShow = () => {
        setShow(true)
    }

    const submitRegionForm = (e) => {
        e.stopPropagation()
        if (currentId) {
            dispatch(updateRegion(currentId, formRegion))
            handleClose()
        } else {
            const form = new FormData()
            form.append("name", formRegion.name)
            dispatch(addRegion(formRegion)).then(() => handleClose())
        }

    }
    const handleClose = () => {
        setShow(false)
        clear()
    }
    const clear = () => {
        setCurrentId(null)
        setFormRegion({
            name: "",
        })
        setTypeModal("")
    }
    return (
        <>
            <ContainerSiteSetting
                dispatchCloseAlert={() => dispatch(setCloseAlertRegion())}
                itemsLength={regions.length}
                colorAlert={variantAlert}
                showAlert={showAlert}
                setShow={handleShow}
                textAlert={message}
                loading={loading}
                title="Все регионы"
                href="/siteSetting"
            >
                {!regions
                    ? <Loader />
                    : <RenderItemRegions
                        setShow={setShow}
                        handleClose={handleClose}
                        setTypeModal={setTypeModal}
                        items={regions}
                        setCurrentId={setCurrentId}
                        deleteButton={deleteRegion}
                    />}

                <AddRegionModal
                    formRegion={formRegion}
                    handleClose={handleClose}
                    onSubmit={submitRegionForm}
                    setFormRegion={setFormRegion}
                    show={show}
                    regionObj={regionObj}
                    typeModal={typeModal}
                    currentId={currentId}
                />
            </ContainerSiteSetting>
        </>
    )
}

export default Region
