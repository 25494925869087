import React, { useState, useEffect } from 'react'
import Input from '../../../components/UI/Input/index';
import Modal from '../../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addMainPage, getGalleryById, updateMainPage, } from '../../../actions';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import BRenderAddPictures from '../../../components/Button/BRenderAddPictures';
import { generatePublicUrl } from '../../../urlConfig';
import Loader from '../../../components/Loader';
import RenderSelectModel from '../../../components/RenderSelectModel';
import RenderDetails from './RenderDetails';
import RenderAdd from './RenderAdd';

const AddMainPage = ({
    show,
    handleClose,
    setCurrentId,
    currentId,
    galleries,
    mainPageObj,
    typeModal,
    showModalImg,
    setShowModalImg,
    loading
}) => {
    const { galleryObj } = useSelector((state) => state.gallery)
    const dispatch = useDispatch()
    // const mainPage = useSelector((state) => currentId
    //     ? state.mainPage.mainPages.find((c) => c._id === currentId)
    //     : null)

    const [itemPictures, setItemPictures] = useState("");

    const [formContact, setFormMainPage] = useState({
        buttonText: mainPageObj ? mainPageObj.buttonText : "",
        galleryId: mainPageObj ? mainPageObj.galleryId : "",
        description: mainPageObj ? mainPageObj.description : "",
        href: mainPageObj ? mainPageObj.href : "",
        title: mainPageObj ? mainPageObj.title : "",
    })
    const handleCloseAll = () => {
        handleClose()
        clear()
    }

    useEffect(() => {
        if (mainPageObj) setFormMainPage(mainPageObj)
    }, [mainPageObj])

    const submitContactForm = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updateMainPage(currentId, formContact))
        } else {
            const form = new FormData();
            form.append("galleryId", formContact.galleryId);
            form.append("title", formContact.title);
            form.append("description", formContact.description);
            form.append("buttonText", formContact.buttonText);
            form.append("href", formContact.href);
            for (let i = 0; i < itemPictures.length; i++) {
                form.append("itemPictures", itemPictures[i]);
            }
            dispatch(addMainPage(form)).then(() => handleCloseAll())
        }
    }
    console.log("formContact.galleryId", formContact.galleryId)

    const clear = () => {
        setCurrentId(null)
        setFormMainPage({
            description: "",
            buttonText: "",
            galleryId: "",
            title: "",
            href: "",
        })
        setItemPictures("")
    }


    const renderItem = () => {
        let content;
        switch (typeModal) {
            case "renderDetails":
                content = <RenderDetails
                    setShowModalImg={setShowModalImg}
                    showModalImg={showModalImg}
                    mainPageObj={mainPageObj}
                    galleryObj={galleryObj}
                    typeModal={typeModal}
                />
                break;
            default:
                content = <RenderAdd
                    setFormMainPage={setFormMainPage}
                    setPictures={setItemPictures}
                    formContact={formContact}
                    galleries={galleries}
                    currentId={currentId}
                />
        }
        return content
    }

    return (
        <Modal
            show={show}
            handleClose={handleCloseAll}
            modalTitle="Добавить соцсеть"
            onSubmit={submitContactForm}
        >
            {renderItem()}
        </Modal>

    )
}


export default AddMainPage