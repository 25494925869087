import axios from "../../helpers/axios"
import { regionConstants } from '../constants';
import { deletedRegion, updatedRegion } from '../api/region';


export const getRegions = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: regionConstants.GET_REGION_REQUEST })
            const res = await axios.get("region/get")
            if (res.status === 200) {
                const { regions } = res.data
                dispatch({
                    type: regionConstants.GET_REGION_SUCCESS,
                    payload: {
                        regions
                    }
                })
            } else {
                dispatch({ type: regionConstants.GET_REGION_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addRegion = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: regionConstants.ADD_REGION_REQUEST })
            const res = await axios.post("region/create", form)
            if (res.status === 201) {
                dispatch({ type: regionConstants.ADD_REGION_SUCCESS, payload: res.data })
                dispatch(getRegions())
            } else {
                dispatch({ type: regionConstants.ADD_REGION_FAILURE, payload: res.data })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateRegion = (id, post) => async (dispatch) => {
    try {
        dispatch({ type: regionConstants.UPDATE_REGION_REQUEST })
        const { data, status } = await updatedRegion(id, post)
        if (status === 200) {
            dispatch({
                type: regionConstants.UPDATE_REGION_SUCCESS, payload: data
            })
        } else {
            dispatch({
                type: regionConstants.UPDATE_REGION_FAILURE, payload: data
            })
        }
        dispatch(getRegions())
    } catch (error) {
        console.log(error);
    }
}

export const deleteRegion = (id) => async (dispatch) => {
    try {
        dispatch({ type: regionConstants.DELETE_REGION_REQUEST })
        const res = await deletedRegion(id);
        const { data, status } = res;
        if (status === 200) {
            dispatch({ type: regionConstants.DELETE_REGION_SUCCESS, payload: { id, data } });
        } else {
            dispatch({ type: regionConstants.DELETE_REGION_FAILURE, payload: { data } });
        }
        dispatch(getRegions())
    } catch (error) {
        console.log(error);
    }
}