import React from 'react'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const SelectComponent = ({
    title,
    selectItem,
    value,
    handleChange,
    minWidth
}) => {
    return (
        <div className="selectContainer1">
            <FormControl sx={{ m: 1, minWidth: minWidth ? minWidth : 120 }}>
                <InputLabel id="demo-simple-select-helper-label">
                    {title}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={value}
                    label="Сортировка"
                    onChange={handleChange}
                >
                    {selectItem.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item.value}
                            style={{
                                display: 'block',
                                padding: "10px"
                            }}>
                            {item.text ? item.text : item.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default SelectComponent