import { authConstants } from "./constants";
import axios from "../helpers/axios";
import swal from 'sweetalert';

export const login = (user) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.LOGIN_REQUEST });
        try {
            const res = await axios.post("/admin/signin", { ...user });
            if (res.status === 200) {
                const { token, user } = res.data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                dispatch({
                    type: authConstants.LOGIN_SUCCESS,
                    payload: {
                        token, user
                    }
                });
                swal({
                    title: `Приветствуем вас ${user.firstName}`,
                    text: "Добавить новый товар или может категорию?",
                    icon: "success",
                });
            }
        } catch (error) {
            console.log("error", error)
            dispatch({
                type: authConstants.LOGIN_FAILURE,
                payload: { message: error.response.data.message }
            });
        }
    }
}

export const isUserLoggedIn = () => {
    return async dispatch => {
        const token = localStorage.getItem('token');
        if (token) {
            const user = JSON.parse(localStorage.getItem('user'));
            dispatch({
                type: authConstants.LOGIN_SUCCESS,
                payload: {
                    token, user
                }
            });
        } else {
            dispatch({
                type: authConstants.NEED_TO_LOGIN,
                payload: { message: 'Нужно войти в аккаунт' }
            });
        }
    }
}

export const signout = () => {
    return async dispatch => {

        dispatch({ type: authConstants.LOGOUT_REQUEST });
        const res = await axios.post(`/admin/signout`);

        if (res.status === 200) {
            localStorage.clear();
            dispatch({ type: authConstants.LOGOUT_SUCCESS, payload: res.data });
        } else {
            dispatch({
                type: authConstants.LOGOUT_FAILURE,
                payload: { error: res.data.error }
            });
        }


    }
}
