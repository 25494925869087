import React from 'react'
import Loader from '../../components/Loader';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export const RenderModalContent = ({ item, setCurrentId, setShowModal }) => {

    if (!item) {
        return (
            <Loader />
        )
    }
    const EditFunc = (obj) => {
        setCurrentId(obj._id)
        setShowModal(true)
    }
    return ( 

        <div>
            <div className="siteSettingMainContainer__blockRowBox">
                {item.map((obj, index) => (
                    <div className="siteSettingMainContainer__blockBox"
                        key={index}
                        onClick={() => EditFunc(obj)}>
                        <div className="siteSettingMainContainer__iconBox">
                            <DriveFileRenameOutlineIcon />
                        </div>
                        <p>{obj.title}</p>
                    </div>
                ))}
            </div>
        </div >
    )
}
