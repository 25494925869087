import axios from "../../helpers/axios"
import { searchQueryConstants } from '../constants';
import { deletedSearchQuery, updatedSearchQuery } from '../api/searchQuery';


export const getSearchQuery = (sort, pageNumber, pageSize) => {
    return async (dispatch) => {
        try {
            dispatch({ type: searchQueryConstants.GET_SEARCH_QUERY_REQUEST })
            const res = await axios.get(`/searchQuery/get?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}`)

            if (res.status === 200) {
                dispatch({
                    type: searchQueryConstants.GET_SEARCH_QUERY_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: searchQueryConstants.GET_SEARCH_QUERY_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const findSearchQueryById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: searchQueryConstants.GET_SEARCH_QUERY_BY_ID_REQUEST })
            const res = await axios.get(`/searchQuery/findSearchQueryById/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: searchQueryConstants.GET_SEARCH_QUERY_BY_ID_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: searchQueryConstants.GET_SEARCH_QUERY_BY_ID_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}


export const addSearchQuery = (form) => {

    return async (dispatch) => {
        try {
            dispatch({ type: searchQueryConstants.ADD_SEARCH_QUERY_REQUEST })
            const res = await axios.post("searchQuery/create", form)
            if (res.status === 201) {
                dispatch({ type: searchQueryConstants.ADD_SEARCH_QUERY_SUCCESS })
                dispatch(getSearchQuery())
            } else {
                dispatch({ type: searchQueryConstants.ADD_SEARCH_QUERY_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}


export const updateSearchQuery = (id, post) => async (dispatch) => {
    try {
        dispatch({ type: searchQueryConstants.UPDATE_SEARCH_QUERY_REQUEST })
        const res = await updatedSearchQuery(id, post)
        const { data } = res
        if (data) {
            dispatch({
                type: searchQueryConstants.UPDATE_SEARCH_QUERY_SUCCESS, payload: data
            })
        }
        dispatch(getSearchQuery())
    } catch (error) {
        console.log(error);
    }
}
export const updateAllSearchQuery = () => async (dispatch) => {
    try {
        dispatch({ type: searchQueryConstants.UPDATE_SEARCH_QUERY_REQUEST })
        const res = await axios.post("searchQuery/updateAll")
        const { data } = res
        if (data) {
            dispatch({
                type: searchQueryConstants.UPDATE_SEARCH_QUERY_SUCCESS, payload: data
            })
        } else {
            dispatch({
                type: searchQueryConstants.UPDATE_SEARCH_QUERY_FAILURE, payload: data
            })
        }
        dispatch(getSearchQuery())
    } catch (error) {
        console.log(error);
    }
}

export const deleteSearchQuery = (id) => async (dispatch) => {
    try {
        await deletedSearchQuery(id);
        dispatch({ type: searchQueryConstants.DELETE_SEARCH_QUERY_SUCCESS, payload: id });
        dispatch(getSearchQuery())
    } catch (error) {
        console.log(error);
    }
}