import { regionConstants, mainActionsConstants } from '../../actions/constants';

const initialState = {
    regions: [],
    regionObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "success",

}

export default (state = initialState, action) => {
    switch (action.type) {
        case regionConstants.ADD_REGION_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case regionConstants.ADD_REGION_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
            }
            break;
        case regionConstants.ADD_REGION_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
            }
            break;
        case regionConstants.UPDATE_REGION_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case regionConstants.UPDATE_REGION_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                regions: state.regions.filter((obj) => obj._id === action.payload._id ? action.payload : obj)
            }
            break;
        case regionConstants.UPDATE_REGION_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
            }
            break;
        case regionConstants.DELETE_REGION_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case regionConstants.DELETE_REGION_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.data.message,
                showAlert: true,
                variantAlert: "success",
                regions: state.regions.filter((obj) => obj._id !== action.payload)
            }
            break;
        case regionConstants.DELETE_REGION_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.data.message,
                showAlert: true,
                variantAlert: "warning",
            }
            break;
        case regionConstants.GET_REGION_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case regionConstants.GET_REGION_SUCCESS:
            if (action.payload && Array.isArray(action.payload.regions)) {
                state = {
                    ...state,
                    regions: action.payload.regions.reverse(),
                    loading: false
                };
            }
            break;
        case regionConstants.GET_REGION_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                regionObj: action.payload.item,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            };
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false,
            }
            break;
        case regionConstants.CLOSE_ALERT_TO_REGION:
            state = {
                ...state,
                showAlert: false
            }
            break;
        default:
            return state
    }
    return state
}

export const setCloseAlertRegion = (payload) => ({
    type: regionConstants.CLOSE_ALERT_TO_REGION,
    payload
})