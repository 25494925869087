import React from 'react'
import BRenderAddPictures from '../../../components/Button/BRenderAddPictures'
import Input from '../../../components/UI/Input/index';
import RenderSelectModel from '../../../components/RenderSelectModel';
import Loader from '../../../components/Loader';

const RenderAdd = ({
    formProductListLink,
    setFormProductListLink,
    featuredProductItems,
}) => {

    const handleChange = (key, value) => {
        setFormProductListLink({ ...formProductListLink, [key]: value })
    }
    return (
        <>
            <Input
                label="Название"
                value={formProductListLink.name}
                placeholder={"Название"}
                onChange={(e) => handleChange("name", e.target.value)}
            />
            <Input
                label="Тип"
                value={formProductListLink.type}
                placeholder={"Добавить Тип"}
                onChange={(e) => handleChange("type", e.target.value)}
            />
            <RenderSelectModel
                value={formProductListLink.featuredProductItemId}
                titleOption="Ничего не привязано"
                title="Выбрать какую список товаров привязать"
                itemsModel={featuredProductItems}
                valueKey="featuredProductItemId"
                handleChange={handleChange}
            />
        </>
    )
}

export default RenderAdd