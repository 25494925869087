import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContainerDetailsContent from './ContainerDetailsContent'
import { getUserCart, getUserDesires } from '../../actions'
import { generatePublicUrl } from '../../urlConfig'
import PicturesViews from '../../components/Pictures/PicturesViews'
import { Button } from '@mui/material'
import Loader from './../../components/Loader';
import EmptyMessage from './EmptyMessage'

const UserProductContainer = ({
    typeModal,
    setTypeModal,
    userId,
    cart,
    desires,
    loading,
    message
}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        if (typeModal === "cart") {
            dispatch(getUserCart(userId))
        }
        if (typeModal === "desires") {
            dispatch(getUserDesires(userId))
        }
    }, [typeModal])

    const itemsProduct = () => {
        let items, obj;
        items = typeModal === "cart" ? cart?.cartItems : desires?.desiresItems
        obj = typeModal === "cart" ? cart : desires

        return { items, obj }
    }

    return (
        <ContainerDetailsContent
            setTypeModal={setTypeModal}
            noneButtons
        >
            <div className="userProductContainer">
                {
                    loading
                        ? <Loader />
                        : itemsProduct().obj?.length === 0
                            ? <EmptyMessage
                                message={message}
                            />
                            :
                            <div className="userProductContainer__row">
                                <div className="userProductContainer__productBox">
                                    {!itemsProduct().items?.length > 0
                                        ? null
                                        : itemsProduct().items.map((item) =>
                                            <div className="userProductContainer__itemProd"
                                                key={item._id}
                                            >

                                                <div className="userProductContainer__imgBox">
                                                    <div className="userProductContainer__imgBox__img">
                                                        <img
                                                            src={generatePublicUrl(item.product?.productPictures[0].img)}
                                                            alt=""
                                                        />
                                                        <div className="userProductContainer__viewsLength">
                                                            <PicturesViews
                                                                // type={type}
                                                                top="0px"
                                                                picture={item.product}
                                                            />
                                                            {/* {item.product.views.length} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="userProductContainer__textBox">
                                                    <div className="userProductContainer__fullName">
                                                        {item.product.fullName}
                                                    </div>
                                                    <div className="userProductContainer__createdAt">
                                                        {item.createdAt
                                                            ? `${item.createdAt.slice(0, 10) + " " + item.createdAt.slice(11, 19)}`
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="userProductContainer__quantity">
                                                        <span>Кол-во:</span>  {item.quantity}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div className="userProductContainer__infoBox">
                                    <div className="userProductContainer__infoBox__productLength">
                                        {
                                            itemsProduct?.length > 0
                                                ? <>
                                                    <span>
                                                        Всего позиций:
                                                    </span>
                                                    {
                                                        itemsProduct?.length
                                                    }
                                                </>
                                                : "Пользовательно пока не создал"
                                        }
                                    </div>
                                    <div className="userProductContainer__buttonBox">
                                        <Button
                                            variant='contained'
                                            onClick={() => { }}
                                        >
                                            Помочь с оформлением
                                        </Button>
                                    </div>
                                </div>
                            </div>
                }
            </div>
        </ContainerDetailsContent>
    )
}

export default UserProductContainer