import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function BackButton(props) {
    const navigate = useNavigate();
    
    return (
        <>
            {props.onClickButton ? <div>
                <button
                    onClick={props.onClick}
                    className="backButton">
                    {props.text ? props.text : "Назад"}
                </button>
            </div>
                :
                <button
                    onClick={() => navigate(-1)}
                    className="backButton">
                    {props.text ? props.text : "Назад"}
                </button>
            }
        </>

    )
}
