import React from 'react'
import ButtonSecondary from './../../components/Button/ButtonSecondary';
import { IoIosAdd, IoIosTrash, IoIosCloudUpload } from 'react-icons/io'

const MainButtonContainer = ({
    handleShow,
    deleteCategory,
    updateCategory,
}) => {

    const buttonArray = [
        {
            text: "Добавить",
            icon: <IoIosAdd />,
            onClick: handleShow
        },
        {
            text: "Удалить",
            icon: <IoIosTrash />,
            onClick: deleteCategory
        },
        {
            text: "Редактировать",
            icon: <IoIosCloudUpload />,
            onClick: updateCategory
        },

    ]

    return (
        <div className="categoryPage">
            <div className="actionBtnContainer">
                <span>Действие: </span>
                <div className="actionBtnContainer__btnBox">
                    {buttonArray.map((obj, index) => <ButtonSecondary
                        onClick={obj.onClick}
                        icon={obj.icon}
                        text={obj.text}
                        key={index}
                    />)}
                </div>
            </div>
            <div className="categoryPage__titleBox">
                <h3>Категории</h3>
            </div>
        </div>
    )
}

export default MainButtonContainer