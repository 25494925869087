import React, { useState, useEffect } from 'react'
import { MainContainer } from '../../MainContainer'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProductMaterial, getByIdProductMaterial, getProductMaterial } from '../../../actions'
import Details from './Details'
import ObjRender from '../../render/ObjRender'
import AlertComponent from '../../../components/Alert/Alert'
import { setCloseAlertProductMaterial } from '../../../reducers/productMaterial'

const ProductionMaterialPage = () => {
    const {
        productMaterials,
        productMaterialObj,
        loading,
        message,
        showAlert,
        variantAlert,
    } = useSelector((state) => state.productMaterial)

    const [show, setShow] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [detailsTypeModal, setDetailsTypeModal] = useState(null)
    const [pictures, setPictures] = useState([])
    const [productMaterialForm, setProductMaterialForm] = useState({
        name: "",
        description: "",
        measurementType: "",
        height: "",
        width: "",
        price: "",
        weight: "",

    })
    const clear = () => {
        setCurrentId(null)
        setProductMaterialForm({
            name: "",
            description: "",
            measurementType: "",
            height: "",
            width: "",
            price: "",
            weight: "",
        })
        setPictures([])
    }

    const handleShow = () => setShow(true)

    const handleClose = () => {
        clear()
        setShow(false)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProductMaterial())
    }, [])

    return (
        <MainContainer
            type="add"
            backButtonProduct
            title="Расходные материалы"
            get={getProductMaterial}
            handleShow={handleShow}
            buttonText="Создать расходный материал"
        >
            <ObjRender
                getById={getByIdProductMaterial}
                setTypeModal={setDetailsTypeModal}
                obj={productMaterials}
                setCurrentId={setCurrentId}
                setShow={setShow}
                typeView="list"
                deleteFunc={deleteProductMaterial}
            />
            <Details
                currentId={currentId}
                setProductMaterialForm={setProductMaterialForm}
                productMaterialForm={productMaterialForm}
                setPictures={setPictures}
                pictures={pictures}
                show={show}
                itemDetails={productMaterialObj}
                loading={loading}
                handleClose={handleClose}
                handleShow={handleShow}
                detailsTypeModal={detailsTypeModal}
            />

            <AlertComponent
                dispatchCloseAlert={setCloseAlertProductMaterial}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />

        </MainContainer>
    )
}

export default ProductionMaterialPage