import { siteCategoryConstants } from "../actions/constants";



const initialState = {
    siteCategories: [],
    siteCategoryObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case siteCategoryConstants.UPDATE_SITE_CATEGORY_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case siteCategoryConstants.UPDATE_SITE_CATEGORY_SUCCESS:
            state = {
                siteCategories: state.siteCategories.map((item) => item._id === action.payload._id ? action.payload : item),
                message: "Успешно обновлено!",
                showAlert: true,
                variantAlert: "success"
            }
            break;
        case siteCategoryConstants.UPDATE_SITE_CATEGORY_FAILURE:
            state = {
                message: "Ошибка обновления!",
                showAlert: true,
                variantAlert: "warning"
            }
            break;
        case siteCategoryConstants.GET_BY_ID_SITE_CATEGORY_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case siteCategoryConstants.GET_BY_ID_SITE_CATEGORY_SUCCESS:
            state = {
                ...state,
                loading: false,
                siteCategoryObj: action.payload.item
            };
            break;
        case siteCategoryConstants.GET_BY_ID_SITE_CATEGORY_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case siteCategoryConstants.GET_SITE_CATEGORIES_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case siteCategoryConstants.GET_SITE_CATEGORIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                siteCategories: action.payload.siteCategories
            };
            break;
        case siteCategoryConstants.GET_SITE_CATEGORIES_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case siteCategoryConstants.DELETE_SITE_CATEGORY_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case siteCategoryConstants.DELETE_SITE_CATEGORY_SUCCESS:
            state = {
                siteCategory: state.siteCategories.filter((item) => item._id !== action.payload),
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case siteCategoryConstants.DELETE_SITE_CATEGORY_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;

        case siteCategoryConstants.ADD_SITE_CATEGORY_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case siteCategoryConstants.ADD_SITE_CATEGORY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "success",
                loading: false
            };
            break;
        case siteCategoryConstants.ADD_SITE_CATEGORY_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;
        case siteCategoryConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false,
            };
            break;
        default:
            return state
    }
    return state
}

export const setCloseAlertSiteCategory = (payload) => ({ type: siteCategoryConstants.SET_CLOSE_ALERT, payload })
