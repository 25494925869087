import React from 'react'
import ContainerModalConfirmPlusIcon from '../../Orders/ContainerModalConfirmPlusIcon';
import SettingIconBlock from '../../../components/Button/SettingIconBlock';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const ItemViewAdded = ({
    isMaterialEditing,
    editedMaterialsFinal,
    saveEditMaterial,
    handleShowEditInputs,
    editedMaterialsTemp,
    removeProductMaterial,
    setEditedMaterialsTemp,
    handleShowDeleteModal,
    productDetails,
    setShowDeletingModal,
    showDeletingModal,
    setEditingMaterialId,
    item,

}) => {

    return (
        <div
            className={`productionsMaterial__viewAdded__item 
                        ${editedMaterialsFinal[item._id]
                    ? 'edited-item'
                    : ''
                }`}
            key={item._id}
        >
            {isMaterialEditing(item._id)
                ? (
                    <SettingIconBlock
                        icon={<SaveIcon />}
                        tooltip="Сохранить правки"
                        onClick={() => saveEditMaterial(item._id)}
                        left="-40px"
                        top="5px"
                    />
                )
                : null
            }
            <SettingIconBlock
                icon={isMaterialEditing(item._id) ? <CloseIcon /> : <EditIcon />}
                tooltip={isMaterialEditing(item._id) ? "Закрыть" : "Изменить параметры"}
                onClick={() => handleShowEditInputs(item._id)}
                left="-20px"
                top="5px"
            />
            <div className="productionsMaterial__viewAdded__name">
                {item.materialId?.name}
            </div>
            {isMaterialEditing(item._id)
                ? <input
                    value={editedMaterialsTemp[item._id]?.dimensionValue || item.dimensionValue}
                    onChange={(e) => setEditedMaterialsTemp(prevEditedMaterialsTemp => ({
                        ...prevEditedMaterialsTemp,
                        [item._id]: {
                            ...prevEditedMaterialsTemp[item._id],
                            dimensionValue: e.target.value
                        }
                    }))}
                />
                : <div className="productionsMaterial__viewAdded__dimensionValue">
                    <span className={editedMaterialsFinal[item._id]?.dimensionValue ? "new" : ""}>
                        {editedMaterialsFinal[item._id]?.dimensionValue
                            ? <div>
                                <span className="oldValue">
                                    {item.dimensionValue}
                                </span>
                                {editedMaterialsFinal[item._id]?.dimensionValue}
                            </div>
                            : item.dimensionValue
                        }
                        м
                    </span>
                </div>
            }
            {isMaterialEditing(item._id)
                ? <input
                    value={editedMaterialsTemp[item._id]?.unitPrice || item.unitPrice}
                    onChange={(e) => setEditedMaterialsTemp(prevEditedMaterialsTemp => ({
                        ...prevEditedMaterialsTemp,
                        [item._id]: {
                            ...prevEditedMaterialsTemp[item._id],
                            unitPrice: e.target.value
                        }
                    }))}
                />
                : <div className="productionsMaterial__viewAdded__unitPrice">
                    <span className={editedMaterialsFinal[item._id]?.unitPrice ? "new" : ""}>
                        {editedMaterialsFinal[item._id]?.unitPrice
                            ? <div>
                                <span className="oldValue">
                                    {item.unitPrice}
                                </span>
                                {editedMaterialsFinal[item._id]?.unitPrice}
                            </div>
                            : item.unitPrice
                        }
                    </span>
                </div>
            }
            <div className="productionsMaterial__viewAdded__totalCost">
                {item.totalCost}
            </div>
            <ContainerModalConfirmPlusIcon
                tooltip="Удалить материал"
                right="-20px"
                top="3px"
                icon={<DeleteForeverIcon />}
                onClickShow={() => handleShowDeleteModal(item._id)}

                title={`Подтвердить удаление материала: ${item.materialId?.name} с продукции ${productDetails.fullName}`}
                description="Если вы продолжите, то вернуть его будет невозможно!!!"
                show={showDeletingModal}
                handleClose={() => {
                    setEditingMaterialId(null)
                    setShowDeletingModal(false)
                }}
                onClick={removeProductMaterial}
            />
        </div>
    )
}

export default ItemViewAdded