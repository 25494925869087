import React from 'react'
import SettingIconBlock from './../../components/Button/SettingIconBlock';
import ModalConfirm from './../../components/BurgerMenu/ModalConfirm';

const ContainerModalConfirmPlusIcon = ({
    tooltip,
    top,
    right,
    icon,
    onClickShow,
    title,
    description,
    show,
    handleClose,
    onClick,
    color
}) => {
    return (
        <>
            <SettingIconBlock
                color={color}
                tooltip={tooltip}
                top={top}
                right={right}
                icon={icon}
                onClick={onClickShow}
            />
            <ModalConfirm
                title={title}
                description={description}
                show={show}
                handleClose={handleClose}
                onClick={onClick}
            />
        </>
    )
}

export default ContainerModalConfirmPlusIcon