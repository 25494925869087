import React from "react";
import { NavLink } from 'react-router-dom';
import Layout from '../components/Layout/index';
import BackButton from '../siteSetting/components/BackButton';

const LinkContainerPage = ({
    itemsArray
}) => {

    return (
        <Layout sidebar>
            <div style={{
                marginTop: "20px"
            }}>
                <BackButton
                    text="На главную"
                    href="/"
                />
            </div>
            <div className="containerProductPage">
                {itemsArray.map((item, index) => (
                    <NavLink
                        className="containerProductPage__item"
                        to={item.href}
                        style={{ textDecoration: "none" }}
                        key={index}
                    >
                        <div className="containerProductPage__blockImg">
                            <img src={item.photo} alt="foto" />
                        </div>
                        <h4>
                            {item.text}
                        </h4>
                    </NavLink>
                ))}
            </div>
        </Layout>
    )
}

export default LinkContainerPage