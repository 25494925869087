import React from 'react'

const ButtonSecondary = ({
    text,
    onClick,
    icon,
    disabled
}) => {
    return (
        <button
            disabled={disabled ? true : false}
            className="btn btn-secondary"
            onClick={onClick}>
            {icon}
            <span>{text}</span>
        </button>
    )
}

export default ButtonSecondary