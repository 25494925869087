import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';

const SettingIconBlock = ({
    tooltip,
    onClick,
    children,
    icon,
    top,
    color,
    right,
    left
}) => {
    return (
        <div className="settingIconBlock"
            style={{
                color: color ? color : null,
                position: children ? null : "absolute",
                top: top ? top : null,
                right: right ? right : null,
                left: left ? left : null,

            }}

            onClick={onClick}
        >
            <Tooltip title={tooltip}>
                {children
                    ? children
                    : icon ? icon : <SettingsIcon />
                }
            </Tooltip>
        </div>
    )
}

export default SettingIconBlock