import { categoryConstants } from "../actions/constants";

const initState = {
    categories: [],
    loading: false,
    categoryDetails: {},
    error: null,
    variantAlert: "success",
    message: "",
    showAlert: false,
    visibleHiddenCategories: false
};

const buildNewCategories = (parentId, categories, category) => {
    let myCategories = [];

    if (parentId === undefined) {
        return [
            ...categories,
            {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                type: category.type,
                visibleAllProductPrice: category.visibleAllProductPrice,
                children: []
            }
        ];
    }

    for (let cat of categories) {

        if (cat._id == parentId) {
            const newCategory = {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parentId: category.parentId,
                type: category.type,
                visibleAllProductPrice: category.visibleAllProductPrice,
                children: []
            };
            myCategories.push({
                ...cat,
                children: cat.children.length > 0 ? [...cat.children, newCategory] : [newCategory]
            })
        } else {
            myCategories.push({
                ...cat,
                children: cat.children ? buildNewCategories(parentId, cat.children, category) : []
            });
        }


    }


    return myCategories;
}
const filterVisibleCategoriesRecursive = (categories) => {
    return categories.reduce((acc, category) => {
        // Фильтрация текущего уровня категорий
        if (category.visibleCategory) {
            const filteredCategory = { ...category };

            // Рекурсивная фильтрация для дочерних категорий
            if (category.children && category.children.length > 0) {
                filteredCategory.children = filterVisibleCategoriesRecursive(
                    category.children
                );
            }

            acc.push(filteredCategory);
        }

        return acc;
    }, []);
};



export default (state = initState, action) => {
    switch (action.type) {
        //update visible category price
        case categoryConstants.UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case categoryConstants.UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                categoryDetails: action.payload.result,
                showAlert: true,
                loading: false
            }
            break;
        case categoryConstants.UPDATE_CATEGORY_VISIBLE_PRICE_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
                loading: false
            }
            break;
        //update visible category
        case categoryConstants.UPDATE_CATEGORY_VISIBLE_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case categoryConstants.UPDATE_CATEGORY_VISIBLE_BY_ID_SUCCESS:
            state = {
                ...state,
                categoryDetails: action.payload.result,
                message: action.payload.message,
                showAlert: true,
                loading: false
            }
            break;
        case categoryConstants.UPDATE_CATEGORY_VISIBLE_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
                loading: false
            }
            break;
        //get details cat
        case categoryConstants.GET_CATEGORY_DETAILS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case categoryConstants.GET_CATEGORY_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                categoryDetails: action.payload.categoryDetails
            }
            break;
        case categoryConstants.GET_CATEGORY_DETAILS_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                error: action.payload.error
            }
            break;
        case categoryConstants.GET_ALL_CATEGORIES_SUCCESS:
            state = {
                ...state,
                categories: !state.visibleHiddenCategories
                    ? action.payload.categories
                    : filterVisibleCategoriesRecursive(action.payload.categories),
            };
            break;
        case categoryConstants.ADD_NEW_CATEGORY_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case categoryConstants.ADD_NEW_CATEGORY_SUCCESS:
            const category = action.payload.category;
            const updatedCategories = buildNewCategories(category.parentId, state.categories, category);
            state = {
                ...state,
                categories: updatedCategories,
                loading: false,
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case categoryConstants.ADD_NEW_CATEGORY_FAILURE:
            state = {
                ...initState,
                loading: false,
                error: action.payload.error,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        //update category
        case categoryConstants.UPDATE_CATEGORIES_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case categoryConstants.UPDATE_CATEGORIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                variantAlert: "success",
                showAlert: true,
                loading: false
            }
            break;
        case categoryConstants.UPDATE_CATEGORIES_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                variantAlert: "warning",
                showAlert: true,
                loading: false,
            }
            break;
        //delete category
        case categoryConstants.DELETE_CATEGORIES_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case categoryConstants.DELETE_CATEGORIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                variantAlert: "success",
                showAlert: true,
                loading: false
            }
            break;
        case categoryConstants.DELETE_CATEGORIES_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
                error: action.payload.error
            }
            break;
        case categoryConstants.SET_CURRENT_CATEGORY:
            state = {
                ...state,
                loading: false,
                categoryDetails: action.payload,

            }
            break;
        case categoryConstants.SET_CLOSE_CATEGORY_ALERT:
            state = {
                ...state,
                showAlert: false,
            }
            break;
        case categoryConstants.SET_VISIBLE_HIDDEN_CATEGORIES:
            state = {
                ...state,
                visibleHiddenCategories: action.payload,
            }
            break;
        default:
            return state
    }

    return state;
}

export const setCategoryDetails = (payload) => ({ type: categoryConstants.SET_CURRENT_CATEGORY, payload })
export const setCloseCategoryAlert = (payload) => ({ type: categoryConstants.SET_CLOSE_CATEGORY_ALERT, payload })
export const setVisibleHiddenCategories = (payload) => ({ type: categoryConstants.SET_VISIBLE_HIDDEN_CATEGORIES, payload })
