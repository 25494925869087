import React, { useEffect, useState } from 'react';
import Modal from '../../components/UI/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentList, getPickupPoints, getDeliveryInfo, updateOrderDeliveryPickupPointsPayment, updateOrderPackageTracking } from '../../actions';
import { Button } from '@mui/material';
import Input from './../../components/UI/Input/index';
import IconContentCopy from '../../components/Icon/IconContentCopy';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const RenderSettingEditModal = ({
    show,
    handleClose,
    typeEditOrderModal,
    orderObj
}) => {
    const { paymentList } = useSelector((state) => state.paymentList);
    const { deliveryInfo } = useSelector((state) => state.deliveryInfo);
    const { pickupPoints } = useSelector((state) => state.pickupPoints);
    const [typeValue, setTypeValue] = useState("");
    const [showPackageTrackingInput, setShowPackageTrackingInput] = useState(false);
    const [packageTracking, setPackageTracking] = useState("")

    const dispatch = useDispatch();
    useEffect(() => {
        if (show) {
            switch (typeEditOrderModal) {
                case "deliveryId": {
                    setTypeValue(orderObj?.deliveryId || "");
                    setPackageTracking(orderObj?.packageTracking || "");

                    dispatch(getDeliveryInfo());
                    break;
                }
                case "paymentId": {
                    setTypeValue(orderObj?.paymentId || "");

                    dispatch(getPaymentList());
                    break;
                }
                case "pickupPointsId": {
                    setTypeValue(orderObj?.pickupPointsId || "");
                    dispatch(getPickupPoints());
                    break;
                }
                default:
                    setTypeValue("");
                    break;
            }
        }
    }, [typeEditOrderModal, orderObj, show]);




    const renderSelect = () => {
        switch (typeEditOrderModal) {
            case 'paymentId':
                return paymentList.map((option) => (
                    <option key={option._id} value={option._id}>
                        {option.title}
                    </option>
                ));
            case 'pickupPointsId':
                return pickupPoints.map((option) => (
                    <option key={option._id} value={option._id}>
                        {option.address}
                    </option>
                ));
            case 'deliveryId':
                return deliveryInfo.map((option) => (
                    <option key={option._id} value={option._id}>
                        {option.title}
                    </option>
                ))
            default:
                return null;
        }
    };

    const onSubmit = () => {
        let payload;
        switch (typeEditOrderModal) {
            case 'paymentId':
                payload = {
                    orderId: orderObj._id,
                    paymentId: typeValue,
                }
                break;
            case 'pickupPointsId':
                payload = {
                    orderId: orderObj._id,
                    pickupPointsId: typeValue
                }
                break;
            case 'deliveryId':
                payload = {
                    orderId: orderObj._id,
                    deliveryId: typeValue,
                }
                break;
            default:
                return null;
        }
        dispatch(updateOrderDeliveryPickupPointsPayment(payload))
            .then(() => handleClose())
    }
    const handlePackageTracking = () => {

        const payload = {
            packageTracking,
            orderId: orderObj._id
        }
        dispatch(updateOrderPackageTracking(payload))
            .then(() => {
                setShowPackageTrackingInput(false)
                handleClose()
            })
    }
    const handleCloseAll = () => {
        handleClose()
        setShowPackageTrackingInput(false)
    }


    const renderPackageTrackingBox = () => {
        return (
            <>
                {typeEditOrderModal === 'deliveryId' && orderObj.orderStatus.some(item => item.type === "shipped" && item.isCompleted === true)
                    ?
                    <>

                        <div style={{ marginTop: "20px" }}>
                            <Button
                                variant="outlined"
                                onClick={() => setShowPackageTrackingInput(!showPackageTrackingInput)}>
                                {showPackageTrackingInput
                                    ? "Назад"
                                    : packageTracking !== "" ? "Изменить ТТН" : "Добавить ТТН"}
                            </Button>

                            <div>
                                {showPackageTrackingInput
                                    ? <>
                                        <Input
                                            label="Введите ТТН"
                                            title="title"
                                            value={packageTracking}
                                            placeholder={"К примеру: 874545879857"}
                                            onChange={(e) => setPackageTracking(e.target.value)}
                                        />
                                        <Button variant="outlined" onClick={handlePackageTracking}>
                                            Сохранить новый ТТН
                                        </Button>
                                    </>
                                    : <div style={{ marginTop: "20px" }}>
                                        <span>{orderObj?.packageTracking ? "Номер ТТН: " : ""}</span>
                                        <span className="copyText" style={{
                                            color: "orange",
                                            position: "relative"
                                        }}>
                                            {orderObj?.packageTracking ? orderObj?.packageTracking : "Не указано"}
                                            <IconContentCopy
                                                classBlock="copyText"
                                            />
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    : typeEditOrderModal === "deliveryId" && orderObj?.pickupPointsId === undefined || orderObj?.pickupPointsId === "Empty"
                        ? <div style={{
                            position: "relative",
                            marginTop: "20px"
                        }}>
                            <span>ТТН</span>
                            <SettingIconBlock
                                icon={<HelpOutlineIcon />}
                                tooltip="Для того чтобы добавить ТТН сначала нужно изменить статут заявки на `Передан в службу доставки` "
                                onClick={() => { }}
                                left="30px"
                                top="0"
                            />
                        </div>
                        : null
                }
            </>
        )
    }

    return (
        <Modal
            show={show}
            handleClose={handleCloseAll}
            modalTitle={`Изменить параметр к: ${typeEditOrderModal}`}
            onSubmit={onSubmit}
            disabledFunctional
        >

            <div style={{
                minHeight: "150px"
            }}>
                <label htmlFor="categoryId">Выбрать тип</label>
                <select
                    className="form-control"
                    name="categoryId"
                    value={typeValue}
                    onChange={(e) => setTypeValue(e.target.value)}
                >
                    <option value="empty">Выбрать тип</option>
                    {renderSelect()}
                </select>
                <Button style={{ marginTop: "20px" }} variant="outlined" onClick={onSubmit} >
                    Сохранить новый тип
                </Button>
                {renderPackageTrackingBox()}
            </div>
        </Modal>
    )
}

export default RenderSettingEditModal