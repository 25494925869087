import { weWorkOnlineConstants } from "../../actions/constants"


const initialState = {
    pickupPoints: [],
    loading: false
}


export default (state = initialState, action) => {
    switch (action.type) {
        case weWorkOnlineConstants.DELETE_WE_WORK_ONLINE_SUCCESS:
            return state.pickupPoints.filter((pickupPoints) => pickupPoints._id !== action.payload)
        case weWorkOnlineConstants.UPDATE_WE_WORK_ONLINE_SUCCESS:
            return state.pickupPoints.map((pickupPoints) => pickupPoints._id === action.payload._id ? action.payload : pickupPoints)

        case weWorkOnlineConstants.ADD_WE_WORK_ONLINE_REQUEST:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
                loading: true
            }
            break
        case weWorkOnlineConstants.ADD_WE_WORK_ONLINE_SUCCESS:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
                loading: false
            }
            break
        case weWorkOnlineConstants.ADD_WE_WORK_ONLINE_FAILURE:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
                loading: false
            }
            break
        case weWorkOnlineConstants.GET_WE_WORK_ONLINE_SUCCESS:
            state = {
                ...state,
                pickupPoints: action.payload.pickupPoints,
            }
            break
        default:
            return state

    }
    return state
}