import React, { useState, useEffect } from 'react'
import Input from '../../../components/UI/Input/index';
import Modal from '../../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addProductListLink, updateProductListLink, } from '../../../actions';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import BRenderAddPictures from '../../../components/Button/BRenderAddPictures';
import { generatePublicUrl } from '../../../urlConfig';
import Loader from '../../../components/Loader';
import RenderSelectModel from '../../../components/RenderSelectModel';
import RenderDetails from './RenderDetails';
import RenderAdd from './RenderAdd';

const AddProductListLink = ({
    show,
    handleClose,
    setCurrentId,
    currentId,
    featuredProductItems,
    featuredProductItemObj,
    productListLinkObj,
    typeModal,
    showModalImg,
    setShowModalImg,
    handleShowModalImg,
    currentIdPicture
}) => {
    const dispatch = useDispatch()

    // const [itemPictures, setItemPictures] = useState("");

    const [formProductListLink, setFormProductListLink] = useState({
        name: "",
        type: "",
        featuredProductItemId: ""
    })

    const handleCloseAll = () => {
        handleClose()
        clear()
    }

    useEffect(() => {
        if (productListLinkObj) setFormProductListLink(productListLinkObj)
    }, [productListLinkObj])

    const submitContactForm = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updateProductListLink(currentId, formProductListLink))
        } else {
            dispatch(addProductListLink(formProductListLink)).then(() => handleCloseAll())
        }
    }

    const clear = () => {
        setCurrentId(null)
        setFormProductListLink({
            name: "",
            type: "",
            featuredProductItemId: ""
        })
    }

    const renderItem = () => {
        let content, title;
        switch (typeModal) {
            case "details":
                content = <RenderDetails
                    currentIdPicture={currentIdPicture}
                    handleShowModalImg={handleShowModalImg}
                    setShowModalImg={setShowModalImg}
                    showModalImg={showModalImg}
                    productListLinkObj={productListLinkObj}
                    featuredProductItemObj={featuredProductItemObj}
                    typeModal={typeModal}
                />
                title = "Подробная информация"
                break;
            default:
                content = <RenderAdd
                    setFormProductListLink={setFormProductListLink}
                    formProductListLink={formProductListLink}
                    featuredProductItems={featuredProductItems}
                />
                title = currentId ? "Редактировать" : "Создать"
        }
        return { content, title }
    }

    return (
        <Modal
            show={show}
            handleClose={handleCloseAll}
            modalTitle={renderItem().title}
            onSubmit={submitContactForm}
        >
            {renderItem().content}
        </Modal>

    )
}


export default AddProductListLink