import { applicationCallMeConstants, mainActionsConstants } from "../actions/constants";
import { } from '../actions/constants';

const initialState = {
    applicationsCallMe: [],
    applicationCallMeObj: {},
    message: "",
    showAlert: false,
    variantAlert: "success",

};

export default (state = initialState, action) => {
    switch (action.type) {
        case applicationCallMeConstants.GET_ALL_APPLICATION_CALL_ME_SUCCESS:
            state = {
                ...state,
                applicationsCallMe: action.payload.applicationsCallMe
            }
            break;
        case applicationCallMeConstants.DELETE_APPLICATION_CALL_ME_BY_ID_SUCCESS:
            state = {
                ...state,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case applicationCallMeConstants.DELETE_MANY_REQUEST:
            state = {
                ...state,
                loading: true
            };
        case applicationCallMeConstants.DELETE_MANY_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;
        case applicationCallMeConstants.DELETE_MANY_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                applicationCallMeObj: action.payload?.item,
                loading: false,
            };
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case mainActionsConstants.CANCELLATION_STATUS_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case mainActionsConstants.CANCELLATION_STATUS_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                applicationCallMeObj: action.payload.result,
                showAlert: true,
            };
            break;
        case mainActionsConstants.CANCELLATION_STATUS_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                variantAlert: "warning",
                showAlert: true,
            };
            break;


        case applicationCallMeConstants.UPDATE_STATUS_REQUEST:
            state = {
                ...state,
            }
            break;
        case applicationCallMeConstants.UPDATE_STATUS_SUCCESS:
            state = {
                ...state,
                applicationCallMeObj: action.payload.result,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case applicationCallMeConstants.UPDATE_STATUS_FAILURE:
            state = {
                ...state,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            }
            break;
        case applicationCallMeConstants.UPDATE_STATUS_REQUEST:
            state = {
                ...state,
            }
            break;
        case applicationCallMeConstants.UPDATE_STATUS_SUCCESS:
            state = {
                ...state,
                applicationsCallMe: action.payload.applicationsCallMe,
                showAlert: true,
                variantAlert: "success",
                loading: false
            }
            break;
        case applicationCallMeConstants.UPDATE_STATUS_FAILURE:
            state = {
                ...state,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            }
            break;
        case applicationCallMeConstants.CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false
            }
            break;
        default:
            return state
    }

    return state;
}
export const setCloseAlertApplicationCallMe = (payload) => ({ type: applicationCallMeConstants.CLOSE_ALERT, payload })

export const setApplicationCallMe = (applicationsCallMe) => ({ type: applicationCallMeConstants.GET_ALL_APPLICATION_CALL_ME_SUCCESS, payload: applicationsCallMe })
