import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import { Button } from '@mui/material';
import { formatDate } from '../../components/FormDate';
import { addUpdatedPicturesStatusProductById, cancellationProductPicturesStatus, updateProductPicturesStatus } from '../../actions';
import { useParams } from 'react-router-dom';
import GenerateStatusDefault from './GenerateStatusDefault';

const DetailsStatusPictures = ({
    loading,
    productDetails,
    onHide,
}) => {
    const { role } = useSelector((state) => state.auth.user)
    const [type, setType] = useState("")

    const dispatch = useDispatch()
    const { slug } = useParams()


    const { updatedPicturesStatus } = productDetails

    const onPicturesUpdate = () => {
        const payload = {
            productId: productDetails._id,
            type
        }
        dispatch(updateProductPicturesStatus(payload, slug))
    }
    console.log('productDetails', productDetails)
    console.log('role', role)


    const handleChangeOrderStatus = (e) => {
        setType(e.target.value)
    }

    const handleCancellationOrderStatus = (text, type) => {
        if (role === "superAdmin") {

            const payload = {
                productId: productDetails._id,
                text,
                type
            }
            dispatch(cancellationProductPicturesStatus(payload, slug))
            // .then(() => onHide())
        }
    }
    return (
        <>
            <GenerateStatusDefault
                itemId={productDetails._id}
                statusArrayLength={updatedPicturesStatus?.length}
                statusGenerateAction={addUpdatedPicturesStatusProductById}
            />
            <div className="orderModalCenter__orderStatusMainContainer">
                <div className="orderModalCenter__orderStatusBox">
                    {updatedPicturesStatus?.map((status) => (
                        <>
                            <div className={`orderModalCenter__orderStatus ${status.isCompleted ? "active" : ""}`}>
                                {role === "superAdmin" && status.isCompleted && <SettingIconBlock
                                    icon={<CloseIcon />}
                                    tooltip="Отменить"
                                    onClick={() => handleCancellationOrderStatus(status.text, status.type)}
                                    top="10px"
                                />}
                                <div className={`time ${status.isCompleted ? "active" : ""
                                    }`}>{formatDate(status.date)}</div >
                                <span>{status.text}</span>
                                <div className={`orderModalCenter__line ${status.isCompleted ? "active" : ""}`}>
                                </div>
                            </div>

                        </>
                    ))}

                </div>
                <div className="orderModalCenter__selectBlock">
                    <div>
                        <select onChange={(e) => handleChangeOrderStatus(e)}>
                            <option value={""}>Выбрать статус</option>
                            {updatedPicturesStatus?.map((status) => {
                                return (
                                    <>
                                        {!status.isCompleted ? (
                                            <option key={status.type} value={status.type}>
                                                {status.type} {status.text}
                                            </option>
                                        ) : null}
                                    </>
                                );
                            })}
                        </select>
                    </div>
                    <div>
                        <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => onPicturesUpdate()}
                        >
                            подтвердить
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsStatusPictures