import React from 'react'
import DetailsProductionMaterial from './DetailsProductionMaterial';
import Modal from '../../../components/UI/Modal/index'
import AddProductionMaterial from '../../create/AddProductionMaterial';

const Details = ({
    handleClose,
    objDetails,
    currentId,
    show,
    detailsTypeModal,
    setProductMaterialForm,
    productMaterialForm,
    pictures,
    setPictures,

}) => {

    const renderContent = () => {
        let content, title;

        switch (detailsTypeModal) {
            case "details":
                title = "Подробнее";
                content = <DetailsProductionMaterial />
            case "edit":
                title = "редактировать";
                content = "hekki"

            case "createModal":
                title = "Создать";
                content = ""
            default:
                title = "редактировать";
                content = <AddProductionMaterial
                    currentId={currentId}
                    setProductMaterialForm={setProductMaterialForm}
                    pictures={pictures}
                    setPictures={setPictures}
                    productMaterialForm={productMaterialForm}
                    handleClose={handleClose}
                    objDetails={objDetails}
                />
        }
        return { content, title }
    }

    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle={renderContent()?.title}
            size="lg"
        >
            {renderContent()?.content}
        </Modal>
    )
}

export default Details