import { Button } from '@mui/material';
import React from 'react'
import { Row, Col } from "react-bootstrap";
import ButtonContainer from './ButtonContainer';
import ContainerDetailsContent from './ContainerDetailsContent';

const DetailsContent = ({
    userDetails,
    setTypeModal
}) => {

    return (
        <ContainerDetailsContent
            noneBackButton
            setTypeModal={setTypeModal}
        >
            <Row>
                <Col md="6">
                    <label className="key">Имя</label>
                    <p className="value">{userDetails.firstName}</p>
                </Col>
                <Col md="6">
                    <label className="key">Фамилия</label>
                    <p className="value">От {userDetails.lastName}</p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Роль</label>
                    <p className="value">{userDetails.role}</p>
                </Col>
                <Col md="6">
                    <label className="key">Email</label>
                    <p className="value">{userDetails.email}</p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Время регистрации</label>
                    <p className="value">
                        {`${userDetails.createdAt?.slice(0, 10)
                            + " " +
                            userDetails.createdAt?.slice(11, 19)}`}
                    </p>
                </Col>
                <Col md="6">
                    <label className="key">Последне обнволение</label>
                    <p className="value">
                        {`${userDetails.updatedAt?.slice(0, 10)
                            + " " +
                            userDetails.updatedAt?.slice(11, 19)}`}
                    </p>
                </Col>
            </Row>
        </ContainerDetailsContent>
    )
}

export default DetailsContent