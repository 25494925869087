import React, { useState } from 'react'
import RenderProductOrder from '../render/RenderProductOrder';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingIconBlock from './../../components/Button/SettingIconBlock';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddOrderProducts from './AddOrderProducts';
import TotalAmountContainer from './TotalAmountContainer';
import ContainerModalConfirmPlusIcon from './ContainerModalConfirmPlusIcon';
import RenderSettingEditModal from './RenderSettingEditModal';
import OrderDetailsButton from './OrderDetailsButton';

const RenderOrderDetails = ({
    setShowDeletingConfirmation,
    setShowPaymentAccountModal,
    showDeletingConfirmation,
    setShowSettingEditModal,
    confirmVisibleOrderById,
    handleShowSettingModal,
    handleTotalAmount,
    orderedProduct,
    typeEditOrderModal,
    showOrderModal,
    handleShowEditOrderModal,
    confirmDelete,
    setClicked,
    orderItem,
    orderObj,
    clicked,
    orderId,
    index,

}) => {

    const user = useSelector((state) => state.user.userDetails)

    const [showVisibleConfirm, setShowVisibleConfirm] = useState(false)

    const totalAmount = () => orderedProduct.reduce((obj, key) => {
        const { bayerPrice, purchasedQty } = key;
        return obj + bayerPrice * purchasedQty;
    },
        0
    );

    if (!user) return <Loader />
    const arrayOrderDetailsButtons = [
        {

        }
    ]
    return (
        <div className="renderOrderDetails"
            onClick={e => e.stopPropagation()}
        >
            {clicked === index ? (
                <div className='container'>


                    <div className="row">
                        <div className="col-sm-4">
                            <p>Информация о Заказчике</p>
                            <div className="m-2 p-1">
                                <div>{user.firstName} {user.lastName}</div>
                                <div> {user.contactNumber ? user.contactNumber : "Номер не указан"} </div>
                                <div> {user.email} </div>
                            </div>
                            <OrderDetailsButton
                                setShowSettingEditModal={setShowSettingEditModal}
                                label="История заказа"
                                type=""
                                onClick={() => showOrderModal(orderItem, "")}
                                handleShowEditOrderModal={handleShowEditOrderModal}
                                typeEditOrderModal={typeEditOrderModal}
                            />
                            <OrderDetailsButton
                                setShowSettingEditModal={setShowSettingEditModal}
                                label="Адрес доставки"
                                type="address"
                                onClick={() => showOrderModal(orderItem, "address")}
                                handleShowEditOrderModal={handleShowEditOrderModal}
                                typeEditOrderModal={typeEditOrderModal}
                            />
                            <OrderDetailsButton
                                setShowSettingEditModal={setShowSettingEditModal}
                                label="Способ доставки"
                                isDisabled={orderObj.deliveryId === undefined || orderObj.deliveryId === null}
                                onClick={() => orderObj.deliveryId !== undefined && orderObj.deliveryId !== null && showOrderModal(orderItem, "deliveryId")}
                                settingIcon
                                type="deliveryId"
                                handleShowEditOrderModal={handleShowEditOrderModal}
                                typeEditOrderModal={typeEditOrderModal}
                            />
                            <OrderDetailsButton
                                setShowSettingEditModal={setShowSettingEditModal}
                                label="Способы оплаты"
                                isDisabled={orderObj.paymentId === undefined || orderObj.paymentId == null}
                                onClick={() => orderObj.paymentId !== undefined && orderObj.paymentId !== null && showOrderModal(orderItem, "paymentId")}
                                settingIcon
                                type="paymentId"
                                handleShowEditOrderModal={handleShowEditOrderModal}
                                typeEditOrderModal={typeEditOrderModal}
                            />
                            <OrderDetailsButton
                                setShowSettingEditModal={setShowSettingEditModal}
                                label="Точка самовывоза"
                                isDisabled={orderObj.pickupPointsId === undefined || orderObj.pickupPointsId == null}
                                onClick={() => orderObj.pickupPointsId !== undefined && orderObj.pickupPointsId !== null && showOrderModal(orderItem, "pickupPointsId")}
                                settingIcon
                                type="pickupPointsId"
                                handleShowEditOrderModal={handleShowEditOrderModal}
                                typeEditOrderModal={typeEditOrderModal}
                            />
                            {orderObj.orderOnOperator && <div div className="renderOrderDetails__orderOnOperator">
                                <span>Заказчик предпочел, чтобы оператор более детально изучил вопрос с доставкой.</span>
                            </div>}

                            {orderObj.paymentOrderOnOperator && <div className="renderOrderDetails__orderOnOperator">
                                <span>Заказчик предпочел, чтобы оператор более детально изучил вопрос с оплатой.</span>
                            </div>}
                        </div>
                        <div className="col-sm-8 renderOrderDetails__contentItemsBox">
                            <div className="renderOrderDetails__titleContainer">
                                <span className="title">Товары Нашего производства</span>
                                <div className="renderOrderDetails__settingIconBlock">
                                    <SettingIconBlock
                                        tooltip="Список оплаты"
                                        icon={<AccountBalanceWalletIcon />}
                                        top="20px"
                                        right="20px"
                                        onClick={() => setShowPaymentAccountModal(orderItem._id)}
                                    />
                                    {orderItem.disableFunctionality ? null : <ContainerModalConfirmPlusIcon
                                        tooltip={`${orderItem.visibleOrder.isCompleted ? "Добавить заказ в скрытые" : "Востановить заказ"}`}
                                        top="20px"
                                        right="40px"
                                        icon={<VisibilityIcon />}
                                        onClickShow={() => setShowVisibleConfirm(true)}
                                        title={`${orderItem.visibleOrder.isCompleted ? "Подтвердить добавление заказа в скрытые" : "Подтвердить востановление заказа"}`}
                                        description="Если действиетельно хотите подтвердить действие то нажмите 'Продолжить' "
                                        show={showVisibleConfirm}
                                        handleClose={() => setShowVisibleConfirm(false)}
                                        onClick={confirmVisibleOrderById}
                                    />
                                    }
                                    {orderItem.disableFunctionality ? null : <ContainerModalConfirmPlusIcon
                                        tooltip="Удалить заказ"
                                        top="20px"
                                        right="60px"
                                        icon={<DeleteIcon />}
                                        onClickShow={() => setShowDeletingConfirmation(true)}
                                        title={`Подтвердить удаление заказа ${orderItem._id}`}
                                        description="Если вы продолжите то вернуть его будет невозможно!!!"
                                        show={showDeletingConfirmation}
                                        handleClose={() => setShowDeletingConfirmation(false)}
                                        onClick={() => confirmDelete(orderItem._id)}
                                    />}
                                </div>
                            </div>
                            <div className="renderOrderDetails__productOrderContainer">
                                {orderedProduct ? orderedProduct.map((item) => (
                                    <RenderProductOrder
                                        orderItem={orderItem}
                                        handleShowSettingModal={handleShowSettingModal}
                                        key={item.productId._id}
                                        setClicked={setClicked}
                                        item={item.productId}
                                        orderId={orderId}
                                        itemObj={item}
                                    />
                                )) : null}
                            </div>
                            <AddOrderProducts
                                setClicked={setClicked}
                                orderItem={orderItem}
                            />
                            <TotalAmountContainer
                                totalAmount={totalAmount}
                                handleTotalAmount={handleTotalAmount}
                                orderItem={orderItem}
                            />
                        </div>
                    </div>
                </div>
            ) : null
            }

        </div >
    )
}

export default RenderOrderDetails