
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../components/Loader";
import { cancellationOrderStatus } from './../../actions/order.action';
import SettingIconBlock from './../../components/Button/SettingIconBlock';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { findDeliveryInfoById, findPaymentListById, findPickupPointsById, getAddressById } from "../../actions";

import AddressForm from "./AddressForm";
import MethodShipping from "./MethodShipping";
import PickupPointsOrder from "./PickupPointsOrder";
import PaymentListOrder from './PaymentListOrder';

const OrderModalCenter = (props) => {
    const {
        typeOrderModalCenter,
        onOrderUpdate,
        formatDate,
        details,
        setType,
        loading,
        orderObj,
        onHide
    } = props
    const { role } = useSelector((state) => state.auth.user)
    const { deliveryInfoObj } = useSelector((state) => state.deliveryInfo)
    const { pickupPointsObj } = useSelector((state) => state.pickupPoints)
    const { paymentListObj } = useSelector((state) => state.paymentList)
    const {
        address,
        loadingA,
        variantAlert,
        showAlert,
        message
    } = useSelector((state) => state.address)

    const [editAddress, setEditAddress] = useState()
    console.log("address", address)
    const dispatch = useDispatch();
    useEffect(() => {
        if (typeOrderModalCenter === "address") {
            const payload = {
                userId: orderObj.user,
                addressId: orderObj.addressId
            }
            dispatch(getAddressById(payload))
        }
        if (typeOrderModalCenter === "deliveryId") {
            dispatch(findDeliveryInfoById(orderObj.deliveryId))
        }
        if (typeOrderModalCenter === "pickupPointsId") {
            dispatch(findPickupPointsById(orderObj.pickupPointsId))
        }
        if (typeOrderModalCenter === "paymentId") {
            dispatch(findPaymentListById(orderObj.paymentId))

        }
    }, [typeOrderModalCenter])

    if (!details) {
        return null
    }
    const handleChangeOrderStatus = (e) => {
        setType(e.target.value)
    }

    const handleCancellationOrderStatus = (text, type) => {
        if (role === "superAdmin") {

            const payload = {
                orderId: orderObj._id,
                text,
                type
            }
            dispatch(cancellationOrderStatus(payload))
                .then(() => onHide())
        }
    }
    let headerText, content;
    switch (typeOrderModalCenter) {
        case "deliveryId":
            headerText = "Способ доставки";
            content = <MethodShipping
                item={deliveryInfoObj}
            />;
            break;
        case "pickupPointsId":
            headerText = "Точка самовывоза";
            content = <PickupPointsOrder
                item={pickupPointsObj}
            />;
            break;
        case "paymentId":
            headerText = "Способ Оплаты";
            content = <PaymentListOrder
                item={paymentListObj}
            />;
            break;
        case "address":
            headerText = "Адрес доставки";
            content = (
                <AddressForm
                    editAddress={editAddress}
                    setEditAddress={setEditAddress}
                    initialData={address}
                    loadingA={loadingA}
                />
            );
            break;
        default:
            headerText = "История заказа";
            content = (
                <>
                    <div className="orderModalCenter__orderStatusMainContainer">
                        <div className="orderModalCenter__orderStatusBox">
                            {details.orderStatus?.map((status) => (
                                <>
                                    <div className={`orderModalCenter__orderStatus ${status.isCompleted ? "active" : ""}`}>
                                        {role === "superAdmin" && status.isCompleted && <SettingIconBlock
                                            icon={<CloseIcon />}
                                            tooltip="Отменить"
                                            onClick={() => handleCancellationOrderStatus(status.text, status.type)}
                                            top="10px"
                                        />}
                                        <div className={`time ${status.isCompleted ? "active" : ""
                                            }`}>{formatDate(status.date)}</div >
                                        <span>{status.text}</span>
                                        <div className={`orderModalCenter__line ${status.isCompleted ? "active" : ""}`}>
                                        </div>
                                    </div>

                                </>
                            ))}

                        </div>
                        <div className="orderModalCenter__selectBlock">
                            <div>
                                <select onChange={(e) => handleChangeOrderStatus(e)}>
                                    <option value={""}>Выбрать статус</option>
                                    {details.orderStatus?.map((status) => {
                                        return (
                                            <>
                                                {!status.isCompleted ? (
                                                    <option key={status.type} value={status.type}>
                                                        {status.type} {status.text}
                                                    </option>
                                                ) : null}
                                            </>
                                        );
                                    })}
                                </select>
                            </div>
                            <div>
                                <Button style={{ marginLeft: "10px" }} onClick={() => onOrderUpdate(details._id)}>
                                    подтвердить
                                </Button>

                            </div>
                        </div>
                    </div>
                </>
            )
    }
    if (loading) return <Loader />
    return (
        <Modal

            {...props}
            size="lg"
            aria-divledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body
                style={{
                    minHeight: "300px"
                }}
            >
                <div className="orderModalCenter"
                >
                    <h4 className="text-center mb-3">{headerText}</h4>
                    {content}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal >
    );
}
export default OrderModalCenter