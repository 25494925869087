import axios from "../../helpers/axios"
import { contactMConstants } from '../constants';
import { deletedContactM, updatedContactM } from '../api/contactM';


export const getContactM = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: contactMConstants.GET_CONTACT_M_REQUEST })
            const res = await axios.get("contactM/get")
            if (res.status === 200) {
                const { contactM } = res.data
                dispatch({
                    type: contactMConstants.GET_CONTACT_M_SUCCESS,
                    payload: {
                        contactM
                    }
                })
            } else {
                dispatch({ type: contactMConstants.GET_CONTACT_M_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addContactM = (form) => {

    return async (dispatch) => {
        try {
            dispatch({ type: contactMConstants.ADD_CONTACT_M_REQUEST })
            const res = await axios.post("contactM/create", form)
            if (res.status === 201) {
                dispatch({ type: contactMConstants.ADD_CONTACT_M_SUCCESS })
            } else {
                dispatch({ type: contactMConstants.ADD_CONTACT_M_FAILURE })
            }
            dispatch(getContactM())
        } catch (e) {
            console.log(e)
        }
    }
}


export const updateContactM = (id, post) => async (dispatch) => {
    try {
        dispatch({ type: contactMConstants.UPDATE_CONTACT_M_REQUEST })
        const res = await updatedContactM(id, post)
        const { data } = res
        if (data) {
            dispatch({
                type: contactMConstants.UPDATE_CONTACT_M_SUCCESS, payload: data
            })
        }
        dispatch(getContactM())
    } catch (error) {
        console.log(error);
    }
}
export const updateAllContactM = () => async (dispatch) => {
    try {
        dispatch({ type: contactMConstants.UPDATE_CONTACT_M_REQUEST })
        const res = await axios.post("contactM/updateAll")
        const { data } = res
        if (data) {
            dispatch({
                type: contactMConstants.UPDATE_CONTACT_M_SUCCESS, payload: data
            })
        } else {
            dispatch({
                type: contactMConstants.UPDATE_CONTACT_M_FAILURE, payload: data
            })
        }
        dispatch(getContactM())
    } catch (error) {
        console.log(error);
    }
}

export const deleteContactM = (id) => async (dispatch) => {
    try {
        await deletedContactM(id);
        dispatch({ type: contactMConstants.DELETE_CONTACT_M_SUCCESS, payload: id });
        dispatch(getContactM())
    } catch (error) {
        console.log(error);
    }
}