import { elementPaymentPageConstants, mainActionsConstants } from '../../actions/constants';



const initialState = {
    elementPaymentPages: [],
    elementPaymentPageObj: {},
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break
        case elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_SUCCESS:
            state = {
                ...state,
                elementPaymentPages: action.payload.elementPaymentPages,
                loading: false

            }
            break
        case elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true

            }
            break
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                elementPaymentPageObj: action.payload?.item,
                loading: false

            }
            break
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        default:
            return state
    }
    return state
}