import React, { useState, useEffect, useMemo } from 'react';
import { generatePublicUrl } from '../../../urlConfig';
import Loader from '../../../components/Loader';
import { Button } from '@material-ui/core';
import PicturesViews from '../../../components/Pictures/PicturesViews';
import { addNewProductFeaturedProductItem, removeProductByIdProductFeaturedProductItem, updateFeaturedProductOrder } from '../../../actions';
import { useDispatch } from 'react-redux';
import ButtonBox from './ButtonBox';
import IconItemProductBox from './IconItemProductBox';
import RenderTextEmptyProduct from '../../../components/RenderTextEmptyProduct';

const RenderProductList = ({
    filterProduct,
    itemObj,
    setTypeModal,
    products,
    productIds,
    setSelectedProduct,
    setProductIds,
    selectedProduct,
    setFilterProductId,
    featuredProductItemObj,
    filterProductId,
    setUpdatedProducts,
    updatedProducts
}) => {
    const [showSelectProduct, setShowSelectProduct] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditOrderInput, setShowEditOrderInput] = useState(false);
    const [numberEditOrderInput, setNumberEditOrderInput] = useState(null);
    const [editOrderId, setEditOrderId] = useState(null);

    const dispatch = useDispatch()

    const uniqueProductIds = useMemo(() => {
        return [...new Set(featuredProductItemObj.products.map(product => product.productId))];
    }, [featuredProductItemObj.products]);

    useEffect(() => {
        if (products.length > 0 && filterProductId.length === 0) {
            const productArray = products.filter(product => !uniqueProductIds.includes(product._id));
            setFilterProductId(productArray);
        }
    }, [products, filterProductId, uniqueProductIds]);

    if (!itemObj) return <Loader />;


    const submitAddNewProduct = () => {

        const payload = {
            id: featuredProductItemObj._id,
            newProducts: productIds
        }
        if (payload.newProducts.length > 0) {
            dispatch(addNewProductFeaturedProductItem(payload))
            setProductIds([])
            setShowSelectProduct(false)
        }
    }
    const confirmDelete = () => {
        const payload = {
            productId: editOrderId,
            id: featuredProductItemObj._id
        }
        dispatch(removeProductByIdProductFeaturedProductItem(payload))
        setShowDeleteConfirm(false)
        setEditOrderId(null)
    }
    const handleShowEditOrder = (item) => {
        setEditOrderId(item._id);
        setNumberEditOrderInput(item.order);
        setShowEditOrderInput(!showEditOrderInput);

        if (numberEditOrderInput !== item.order) {
            const existingUpdatedProduct = updatedProducts.find((updatedItem) => updatedItem._id === item._id);

            if (existingUpdatedProduct) {
                const updatedItem = { ...existingUpdatedProduct, order: numberEditOrderInput };
                setUpdatedProducts((prevState) => prevState.map((item) => (item._id === updatedItem._id ? updatedItem : item)));
            } else {
                const updatedItem = { ...item, order: numberEditOrderInput };
                setUpdatedProducts((prevState) => [...prevState, updatedItem]);
            }
        } else {
            setUpdatedProducts((prevState) => prevState.filter((updatedItem) => updatedItem._id !== item._id));
        }
    };

    const confirmUpdateProductConfirm = () => {
        const payload = {
            id: featuredProductItemObj._id,
            products: updatedProducts
        };

        if (payload.products.length > 0) {
            dispatch(updateFeaturedProductOrder(payload))
                .then(() => setUpdatedProducts([]))
        }
    };

    return (
        <div className="renderProductMiniList">
            <Button onClick={() => setTypeModal("details")}>
                Назад
            </Button>
            <div className="renderProductMiniList__row">
                {itemObj.products.length > 0
                    ? itemObj.products.map((item) => (
                        <div
                            className={`renderProductMiniList__item`}
                            key={item._id}
                        >
                            <div className="renderProductMiniList__imgBox">
                                <img src={generatePublicUrl(item.img)} alt="Фото" />
                            </div>
                            {showEditOrderInput && editOrderId === item._id ? (
                                <input
                                    className="renderProductMiniList__inputOrder"
                                    type="text"
                                    value={numberEditOrderInput}
                                    onChange={(e) => setNumberEditOrderInput(e.target.value)}
                                />
                            ) : (
                                <>
                                    <PicturesViews
                                        type="product"
                                        right="10px"
                                        picture={item}
                                    />
                                    {
                                        updatedProducts.length > 0 && updatedProducts.some(s => s.productId === item.productId)
                                            ? <PicturesViews
                                                type="product"
                                                color="blue"
                                                tooltip="Новое"
                                                right="40px"
                                                picture={updatedProducts.find(s => s.productId === item.productId)}
                                            />
                                            : null
                                    }

                                </>
                            )}
                            <IconItemProductBox
                                setEditOrderId={setEditOrderId}
                                setShowEditOrderInput={setShowEditOrderInput}
                                setShowDeleteConfirm={setShowDeleteConfirm}
                                handleShowEditOrder={handleShowEditOrder}
                                setUpdatedProducts={setUpdatedProducts}
                                showEditOrderInput={showEditOrderInput}
                                showDeleteConfirm={showDeleteConfirm}
                                updatedProducts={updatedProducts}
                                confirmDelete={confirmDelete}
                                editOrderId={editOrderId}
                                item={item}
                            />
                        </div>

                    ))
                    : <RenderTextEmptyProduct />
                }
            </div>
            <ButtonBox
                filterProduct={filterProduct}
                productIdsLength={productIds.length}
                confirmUpdateProductConfirm={confirmUpdateProductConfirm}
                setShowSelectProduct={setShowSelectProduct}
                submitAddNewProduct={submitAddNewProduct}
                showEditOrderInput={showEditOrderInput}
                setSelectedProduct={setSelectedProduct}
                showSelectProduct={showSelectProduct}
                updatedProducts={updatedProducts}
                selectedProduct={selectedProduct}
                setProductIds={setProductIds}
                productIds={productIds}
                products={products}
            />

        </div>
    );
};

export default RenderProductList;
