import React, { useEffect } from 'react'
import Input from '../../components/UI/Input';
import Modal from '../../components/UI/Modal';
import { useSelector } from 'react-redux';


const AddRegionModal = (props) => {
    const {
        onSubmit,
        setFormRegion,
        formRegion,
        handleClose,
        typeModal,
        regionObj,
        currentId,
        show,
    } = props

    const regionId = useSelector((state) => currentId
        ? state.region.regions.find((c) => c._id === currentId)
        : null)


    useEffect(() => {
        if (regionId) setFormRegion(regionId)
    }, [regionId])

    const renderItem = () => {
        let content;
        switch (typeModal) {
            case "renderDetails":
                return content = <>
                    {/* <h1>{regionObj._id}</h1> */}
                    <h2>{regionObj.name}</h2>
                    {/* {regionObj.updatedAt.slice(0, 10)} */}
                    {/* &nbsp; */}
                    {/* {regionObj.updatedAt.slice(11, 19)} */}
                </>
            default:
                return <>
                    <Input
                        label="Область(регион)"
                        name={"name"}
                        value={formRegion.name}
                        placeholder={"Введите область"}
                        onChange={(e) => setFormRegion({ ...formRegion, name: e.target.value })}

                    />
                </>
        }
    }
    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle={currentId ? "Редактировать" : "Добавить"}
            onSubmit={onSubmit}
        >
            {renderItem()}
        </Modal>

    )
}


export default AddRegionModal
