import { mainActionsConstants, applicationGalleryConstants } from '../actions/constants';

const initState = {
  applicationsGallery: [],
  applicationGalleryObj: {},
  loading: false,
  loadingStatus: false,
  message: "",
  showAlert: false,
  variantAlert: "",
  sort: "updatedAtMinus",
  pageNumber: 0,
  pageSize: 12,
  totalAll: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case applicationGalleryConstants.UPDATE_STATUS_REQUEST:
      state = {
        ...state,
        loadingStatus: true
      };
      break;
    case applicationGalleryConstants.UPDATE_STATUS_SUCCESS:
      state = {
        ...state,
        applicationGalleryObj: action.payload?.result,
        variantAlert: "success",
        message: action.payload.message,
        showAlert: true,
        loadingStatus: false,
      };
      break;
    case applicationGalleryConstants.UPDATE_STATUS_FAILURE:
      state = {
        ...state,
        loadingStatus: false,
        message: action.payload.message,
        variantAlert: "warning",
        showAlert: true,
      };
      break;
    case mainActionsConstants.CANCELLATION_STATUS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case mainActionsConstants.CANCELLATION_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        variantAlert: "success",
        message: action.payload.message,
        applicationGalleryObj: action.payload.result,
        showAlert: true,
      };
      break;
    case mainActionsConstants.CANCELLATION_STATUS_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
        variantAlert: "warning",
        showAlert: true,
      };
      break;
    case applicationGalleryConstants.GET_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case applicationGalleryConstants.GET_BY_ID_SUCCESS:
      state = {
        ...state,
        applicationGalleryObj: action.payload?.applicationsGallery,
        loading: false,
      };
      break;
    case applicationGalleryConstants.GET_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationGalleryConstants.GET_ALL_REQUEST:
      state = {
        ...state,
        loading: true
      };
    case applicationGalleryConstants.GET_ALL_SUCCESS:
      state = {
        ...state,
        applicationsGallery: action.payload?.applicationsGallery,
        loading: false,
      };
      break;
    case applicationGalleryConstants.GET_ALL_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case applicationGalleryConstants.CLOSE_ALERT:
      state = {
        ...state,
        showAlert: false,
      };
      break;
    case applicationGalleryConstants.SET_SORT:
      state = {
        ...state,
        sort: action.payload
      }
      break;
    case applicationGalleryConstants.SET_PAGE_SIZE:
      state = {
        ...state,
        pageSize: action.payload
      }
      break;
    case applicationGalleryConstants.SET_PAGE_NUMBER:
      state = {
        ...state,
        pageNumber: action.payload
      }
      break;
    default:
      return state
  }
  return state;
};
export const setSortApplicationGallery = (payload) => ({ type: applicationGalleryConstants.SET_SORT, payload })
export const setPageSizeApplicationGallery = (payload) => ({ type: applicationGalleryConstants.SET_PAGE_SIZE, payload })
export const setPageNumberApplicationGallery = (payload) => ({ type: applicationGalleryConstants.SET_PAGE_NUMBER, payload })

export const setApplicationGallery = (applicationsGallery) => ({
  type: applicationGalleryConstants.GET_ALL_SUCCESS,
  payload: applicationsGallery
})

export const setCloseAlertApplicationGallery = (payload) => ({
  type: applicationGalleryConstants.CLOSE_ALERT,
  payload
})
