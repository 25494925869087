import React from 'react'

const RenderTextEmptyProduct = ({
    text,
    mini
}) => {
    return (
        <div className={`renderTextEmptyProduct ${mini ? "mini" : ""}`}>
            <div className="renderTextEmptyProduct__text">
                {text ? text : "Пока ничего не добавлено!"}
            </div>
        </div>
    )
}

export default RenderTextEmptyProduct