import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingIconBlock from '../Button/SettingIconBlock';

const IconContentCopy = ({
    classBlock
}) => {

    const copyToClipboard = () => {
        const textToCopy = document.querySelector(`.${classBlock}`).textContent;
        navigator.clipboard.writeText(textToCopy)
            .then(() => console.log('Text copied to clipboard'))
            .catch((error) => console.error('Error copying text: ', error));
    }

    return (
        <SettingIconBlock
            icon={<ContentCopyIcon />}
            tooltip="Скопировать"
            onClick={copyToClipboard}
            right="-30px"
            top="-2px"
        />


    )
}

export default IconContentCopy