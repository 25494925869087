import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getColorProductById } from '../../actions';
import { generatePublicUrl } from '../../urlConfig';

const RenderSettingOrderProductDetails = ({
    productOrderDetails
}) => {

    const colorProduct = useSelector((state) => state.colorProduct)

    const { colorProductDetails, loading } = colorProduct
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getColorProductById(productOrderDetails.typeProductColor))
    }, [])
    return (
        <div>
            <Row>
                <Col md="6">
                    <label className="key">Количество</label>
                    <p className="value">{productOrderDetails.purchasedQty} шт. </p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Длина</label>
                    <p className="value">{productOrderDetails.length} мм. </p>
                </Col>
                <Col md="6">
                    <label className="key">Высота</label>
                    <p className="value">{productOrderDetails.height} мм.</p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Ширина</label>
                    <p className="value">{productOrderDetails.width} мм.</p>
                </Col>
                <Col md="6">
                    <label className="key">Цена</label>
                    <p className="value"> {productOrderDetails.bayerPrice} валюта</p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Цвет</label>
                    <p className="value">{colorProductDetails.name ? colorProductDetails.name : "Цвет пока не указан!"}</p>
                </Col>

            </Row>

        </div>


    )
}

export default RenderSettingOrderProductDetails