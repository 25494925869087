import React from 'react'
import { IoIosAdd } from 'react-icons/io';

export const ButtonShow = ({
    disabled,
    setShow
}) => {


    return (
        <>
            <button
                disabled={disabled}
                className="btn btn-secondary"
                onClick={setShow}>
                <IoIosAdd /> <span>Добавить</span>
            </button>
        </>
    )
}
