import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

const GenerateStatusDefault = ({
    statusGenerateAction,
    statusArrayLength,
    itemId
}) => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(statusGenerateAction(itemId))
    }

    return statusArrayLength & statusArrayLength > 0
        ? null
        : <Button variant="contained" onClick={handleClick}>
            Сгенерировать Статус
        </Button>
}

export default GenerateStatusDefault
