import React from 'react'
import Layout from './../components/Layout/index';
import { Container, Row, Col } from 'react-bootstrap';
import BackButton from './components/BackButton';
import { ButtonShow } from './ButtonShow';
import Loader from '../components/Loader';
import AlertComponent from '../components/Alert/Alert';


const ContainerSiteSetting = ({
    dispatchCloseAlert,
    itemsLength,
    colorAlert,
    showAlert,
    textAlert,
    limitItem,
    children,
    setShow,
    loading,
    title,
    href,
    text,

}) => {
    const renderButtonAdd = () => {
        if (itemsLength && itemsLength === 0) {
            return (
                <div className="actionBtnContainer">
                    <span>Действие: </span>
                    <ButtonShow
                        setShow={setShow}
                    />
                </div>
            )
        } else {
            return <div className="actionBtnContainer">
                <span>Действие: </span>
                <ButtonShow
                    disabled={true}
                // setShow={setShow}
                />
            </div>
        }
    }
    return (
        <>
            <Layout sidebar>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3 style={{ textAlign: "center", margin: "20px" }}>{`${title}`}</h3>
                        </Col>
                        <Col md={12}>
                            <h4 style={{ textAlign: "center" }}>{` Всего: ${itemsLength} шт.`}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div style={{ margin: '20px auto', display: 'flex', justifyContent: 'space-between', maxWidth: "800px", alignItems: "center" }}>
                                {limitItem
                                    ? renderButtonAdd()
                                    : <div className="actionBtnContainer">
                                        <span>Действие: </span>
                                        <ButtonShow
                                            setShow={setShow}
                                        />
                                    </div>}


                                <BackButton
                                    href={href}
                                    text={text}
                                />

                            </div>

                        </Col>
                    </Row>
                    <Row>
                        {loading && <Loader />}
                        <Col md={12}>
                            {itemsLength === 0
                                ? <p>Сюда пока ничего не было добавлено!</p>
                                : children
                            }
                        </Col>
                    </Row>

                </Container>

            </Layout>
            <AlertComponent
                dispatchCloseAlert={dispatchCloseAlert}
                color={colorAlert}
                showObj={showAlert}
                text={textAlert}
            />
        </>
    )
}
export default ContainerSiteSetting;
