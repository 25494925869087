import React, { useState, useEffect } from 'react'
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addPickupPoints, updatePickupPoints } from '../../actions';

const AddPickupPoints = ({
    show,
    handleClose,
    setCurrentId,
    currentId
}) => {
    const dispatch = useDispatch()
    const pickupPointsId = useSelector((state) => currentId
        ? state.pickupPoints.pickupPoints.find((c) => c._id === currentId)
        : null)
    const [itemPictures, setItemPictures] = useState("");
    const [formPickupPoints, setFormPickupPoints] = useState({
        city: "",
        address: "",
        number: "",
    })


    useEffect(() => {
        if (pickupPointsId) setFormPickupPoints(pickupPointsId)
    }, [pickupPointsId])

    const handleItemPictures = (e) => {
        setItemPictures(e.target.files);
    };
    const submitContactForm = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updatePickupPoints(currentId, formPickupPoints))
            handleClose()
            clear()
        } else {
            const form = new FormData();
            form.append("city", formPickupPoints.city);
            form.append("address", formPickupPoints.address);
            form.append("number", formPickupPoints.number);
            for (let i = 0; i < itemPictures.length; i++) {
                form.append("itemPictures", itemPictures[i]);
            }
            dispatch(addPickupPoints(form)).then(() => handleClose())
        }
    }
    const clear = () => {
        setCurrentId(null)
        setFormPickupPoints({
            city: "",
            address: "",
            number: "",
        })
        setItemPictures("")
    }
    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle="Пункт выдачи"
            onSubmit={submitContactForm}
        >
            <Input
                label="Город"
                value={formPickupPoints.city}
                placeholder={"Город"}
                onChange={(e) => setFormPickupPoints({ ...formPickupPoints, city: e.target.value })}
            />
            <Input
                label="Адрес производства"
                value={formPickupPoints.address}
                placeholder={"Введите адресс производства"}
                onChange={(e) => setFormPickupPoints({ ...formPickupPoints, address: e.target.value })}
            />
            <Input
                label="Номер телефона"
                value={formPickupPoints.number}
                placeholder={"Введите основной номер телефона"}
                onChange={(e) => setFormPickupPoints({ ...formPickupPoints, number: e.target.value })}
            />
            {!currentId ? <>
                <input
                    type="file"
                    name="itemPictures"
                    onChange={(e) => handleItemPictures(e)}
                    required
                    multiple
                />
            </> : null
            }
        </Modal>

    )
}


export default AddPickupPoints