import { colorProductConstants } from "../../actions/constants"


const initialState = {
    colorProducts: [],
    colorProductDetails: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "success",
}


export default (state = initialState, action) => {
    switch (action.type) {
        case colorProductConstants.DELETE_COLOR_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case colorProductConstants.DELETE_COLOR_PRODUCT_SUCCESS:
            state = {
                ...state,
                showAlert: true,
                colorProducts: state.colorProducts?.filter((colorProducts) => colorProducts._id !== action.payload),
                loading: false
            }
            break;
        case colorProductConstants.DELETE_COLOR_PRODUCT_FAILURE:
            state = {
                ...state,
                variantAlert: "warning",
                showAlert: true,
                loading: false,
            }
            break;
        case colorProductConstants.ADD_COLOR_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case colorProductConstants.ADD_COLOR_PRODUCT_SUCCESS:
            state = {
                ...state,
                showAlert: true,
                colorProducts: action.payload.colorProducts,
                loading: false
            }
            break;
        case colorProductConstants.ADD_COLOR_PRODUCT_FAILURE:
            state = {
                ...state,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            }
            break;
        case colorProductConstants.UPDATE_COLOR_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case colorProductConstants.UPDATE_COLOR_PRODUCT_SUCCESS:
            state = {
                ...state,
                colorProducts: state.colorProducts?.map((colorProducts) => colorProducts._id === action.payload._id ? action.payload : colorProducts),
                loading: false
            }
            break;
        case colorProductConstants.UPDATE_COLOR_PRODUCT_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case colorProductConstants.GET_COLOR_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case colorProductConstants.GET_COLOR_PRODUCT_SUCCESS:
            state = {
                ...state,
                colorProducts: action.payload.colorProducts,
                loading: false
            }
            break;
        case colorProductConstants.GET_COLOR_PRODUCT_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break;
        case colorProductConstants.SET_CLOSE_ALERT_COLOR_PRODUCT:
            state = {
                ...state,
                showAlert: false
            }
            break;
        case colorProductConstants.GET_COLOR_PRODUCT_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case colorProductConstants.GET_COLOR_PRODUCT_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                colorProductDetails: action.payload.colorProductDetails
            }
            break;
        case colorProductConstants.GET_COLOR_PRODUCT_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                error: action.payload.error
            }
            break;

        default:
            return state
    }
    return state;
}

export const setCloseAlertColorProduct = (payload) => ({
    type: colorProductConstants.SET_CLOSE_ALERT_COLOR_PRODUCT,
    payload
})
