import React, { useEffect, useState } from 'react'
import SettingIconBlock from '../../components/Button/SettingIconBlock'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const IsViewedIconsList = ({
    item,
    items,
    children,
    showModal,
    setTypeModal,
    selectAll,
    selectedItems,
    setSelectedItems

}) => {


    useEffect(() => {
        if (selectAll) {
            const allIds = items.map(a => a._id);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    }, [selectAll, items]);

    const handleCheckboxChange = (e, id) => {
        e.stopPropagation();
        if (e.target.checked) {
            setSelectedItems(prevItems => [...prevItems, id]);
        } else {
            setSelectedItems(prevItems => prevItems.filter(itemId => itemId !== id));
        }
    };

    const handleShowSettingModal = (e, type, item) => {
        e.stopPropagation();
        setTypeModal(type);
        showModal(item);
    };

    const RenderListsItem = () => {
        return (
            <>
                <SettingIconBlock
                    tooltip="Настройки"
                    right="10px"
                    top="8px"
                    onClick={(e) => handleShowSettingModal(e, "settingModal", item, item.isViewed)}
                />
                {item.isViewed
                    ? false
                    : <SettingIconBlock
                        tooltip="Новая заявка"
                        right="-20px"
                        icon={<NotificationsActiveIcon />}
                        top="8px"
                        onClick={() => { }}
                    />}
                <div
                    style={{
                        position: "absolute",
                        top: "10px",
                        left: "-20px"
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <input
                        type="checkbox"
                        checked={selectedItems.includes(item._id)}
                        onChange={(e) => handleCheckboxChange(e, item._id)}
                    />
                </div>
            </>
        )
    }
    const TopBlock = () => {
        return (
            <>

            </>
        )
    }

    return (
        <>
            <tr key={item._id}
                onClick={() => showModal(item, item.isViewed)}
                style={{
                    position: "relative",
                    background: !item.isViewed ? "rgba(19, 98, 202, 0.164)" : ""
                }}
            >
                <TopBlock />
                {children}
                <RenderListsItem />
            </tr>
        </>
    )
}

export default IsViewedIconsList