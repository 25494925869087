import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";

import { useEffect } from 'react';
import RenderListItems from './RenderListItems';
import { Button } from '@material-ui/core';

const DetailsContent = ({
    handleDeleteById,
    setRejectModal,
    rejectedStatus,
    setTypeConfirm,
    handleUpdateUserPassword,
    item,
}) => {
    const [disabledButton, setDisabledButton] = useState(true)
    const [contactDataArray, setContactDataArray] = useState([
        {
            name: "Основной номер",
            nameSubmit: "contactNumber",
            beforeText: item.previousData?.contactNumber || item.userObj?.contactNumber,
            afterText: item.contactNumber,
            equalityField: item.contactNumber !== item.userObj?.contactNumber,
            confirmed: false
        },
        {
            name: "Доп-номер",
            nameSubmit: "backupNumber",
            beforeText: item.previousData?.backupNumber || item.userObj?.backupNumber,
            afterText: item.backupNumber,
            equalityField: item.backupNumber !== item.userObj?.backupNumber,
            confirmed: false
        }
    ])
    const [emailDataArray, setEmailDataArray] = useState([
        {
            name: "Пароль",
            nameSubmit: "newPassword",
            beforeText: item.previousData?.email || item.userObj?.email,
            afterText: item.email,
            equalityField: item.email !== item.userObj?.email,
            confirmed: false
        },
    ])
    const [passwordDataArray, setPasswordDataArray] = useState([
        {
            name: "Пароль",
            nameSubmit: "newPassword",
            beforeText: item.previousData?.password || item.userObj?.password,
            afterText: item.newPassword,
            equalityField: item.contactNumber !== item.userObj?.contactNumber,
            confirmed: false
        },
    ])
    const [personalDataArray, setPersonalDataArray] = useState([
        {
            name: "Имя",
            nameSubmit: "firstName",
            beforeText: item.previousData?.firstName || item.userObj?.firstName,
            afterText: item.firstName,
            equalityField: item.firstName !== item.userObj?.firstName,
            confirmed: false
        },
        {
            name: "Фамилия",
            nameSubmit: "lastName",
            beforeText: item.previousData?.lastName || item.userObj?.lastName,
            afterText: item.lastName,
            equalityField: item.lastName !== item.userObj?.lastName,
            confirmed: false
        },
        {
            name: "Пол",
            nameSubmit: "gender",
            beforeText: item.previousData?.gender || item.userObj?.gender,
            afterText: item.gender,
            equalityField: item.gender !== item.userObj?.gender,
            confirmed: false
        },
        {
            name: "День рождения",
            nameSubmit: "birthData",
            beforeText: item.previousData?.birthData || item.userObj?.birthData,
            afterText: item.birthData,
            equalityField: item.birthData !== item.userObj?.birthData,
            confirmed: false
        },
        {
            name: "Регион",
            nameSubmit: "regionName",
            beforeText: item.previousData?.regionName || item.userObj?.regionName,
            afterText: item.region?.name,
            equalityField: item.region?.name !== item.userObj?.regionName,
            confirmed: false
        },
    ]);

    const filteredDataObject = personalDataArray.reduce((result, item) => {
        if (item.confirmed === true) {
            result[item.nameSubmit] = item.afterText;
        }
        return result;
    }, {});

    //Посмотреть в чем тут проблема не понятно для чего функция filteredDataObject



    useEffect(() => {
        const confirmedExists = personalDataArray.some(item => item.confirmed);
        setDisabledButton(!confirmedExists);
    }, [personalDataArray]);

    const showConfirm = (type) => {
        setTypeConfirm(type)
        setRejectModal(true)
    }

    const renderVariant = () => {
        const variant = item.variant
        let content, text;
        switch (variant) {
            case "personal":
                content = <RenderListItems
                    rejectedStatus={rejectedStatus}
                    previousData={item.previousData}
                    items={personalDataArray}
                    setItems={setPersonalDataArray}
                />
                text = "личные данные"
                break;
            case "contact":
                content = <RenderListItems
                    rejectedStatus={rejectedStatus}
                    items={contactDataArray}
                    setItems={setContactDataArray}
                />
                text = "личные контакты"
                break;
            case "password":
                content = <RenderListItems
                    rejectedStatus={rejectedStatus}
                    items={passwordDataArray}
                    setItems={setPasswordDataArray}
                />
                text = "пароль"
                break;
            default:
                content = <RenderListItems
                    rejectedStatus={rejectedStatus}
                    items={emailDataArray}
                    setItems={setEmailDataArray}
                />
                text = "свой Email"
        }
        return {
            content,
            text: `Пользователь хочет изменить ${text}`
        }
    }
    if (!item) return null
    return <>
        <div style={{
            margin: "10px",
        }}>
            <button className="btn btn-secondary" onClick={handleDeleteById}>Удалить заявку</button>
        </div>
        <Row>
            <Col md="6">
                <label className="key">Номер заявки</label>
                <p className="value">{item.applicationNumber}</p>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <label className="key">Полное имя</label>
                <p className="value">{`${item.userObj?.firstName + " " + item.userObj?.lastName}`}</p>
            </Col>
            <Col md="6">
                <label className="key">Название фирмы</label>
                <p className="value">{item.userObj?.nameCompany}</p>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <label className="key">Номер</label>
                <div>
                    <a href='tel:' className="value">{item.userObj?.contactNumber}</a>
                </div>
            </Col>
            <Col md="6">
                <label className="key">Email
                </label>
                <p className="value">{item.userObj?.email}</p>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <label className="key">Время создания</label>
                <p className="value">
                    {item.createdAt?.slice(0, 10)}
                    &nbsp;
                    {item.createdAt?.slice(11, 19)}
                </p>
            </Col>
            <Col md="6">
                <label className="key">Время обновления</label>
                <p className="value">
                    {item.updatedAt?.slice(0, 10)}
                    &nbsp;
                    {item.updatedAt?.slice(11, 19)}
                </p>
            </Col>
        </Row>
        <Row>
            <Col md="12">
                <label className="key">{item.previousData || rejectedStatus
                    ? rejectedStatus ? "Заявка была отклонена, данные не изменены" : "Данные с заявки до и после"
                    : "Что нужно изменить"}
                </label>
                <p className="value">{item.previousData || rejectedStatus ? null : renderVariant().text}</p>
            </Col>
        </Row>

        {renderVariant().content}
        {item.previousData || rejectedStatus
            ? <div className="applicationChangeProfile__successText">
                {rejectedStatus ? "Заявка была отклонена"
                    : "Эти данные уже обновлены"
                }
            </div>
            : <div className="applicationChangeProfile__buttonContainer">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => showConfirm("rejected")}
                >
                    Отказать
                </Button>
                {item.variant === "password"
                    ? <Button
                        variant="contained"
                        color="success"
                        // disabled={disabledButton}
                        onClick={handleUpdateUserPassword}
                    >
                        Подтвердить смену пароля
                    </Button>
                    : <Button
                        variant="contained"
                        color="success"
                        disabled={disabledButton}
                        onClick={showConfirm}
                    >
                        Сохранить эти свойства
                    </Button>
                }

            </div>
        }

    </>
}

export default DetailsContent