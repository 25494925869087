import React from 'react'

const StatusOrder = ({
    filterOrderStatusColor,
    status,
    mini
}) => {



    const statusText = () => {
        let det;
        det = status.filter((status) => status.isCompleted).slice(-1)
        return det
    }


    return (
        <>
            {statusText().map((item) => (
                <div style={{ width: mini && "70px" }}>
                    <div
                        className={`order__heading ${mini ? "mini" : ""} ${filterOrderStatusColor(
                            item.text
                        )}`}
                    ></div>
                    <div
                        style={{
                            whiteSpace: mini && "nowrap",
                            overflow: mini && "hidden",
                            textOverflow: mini && "ellipsis"
                        }}
                        className={`order__statusOrder ${filterOrderStatusColor(item.text)}`}
                        key={item._id}
                    >
                        {item.text}
                    </div>
                </div>
            ))}
        </>

    )
}

export default StatusOrder