import axios from "../../helpers/axios"
import { colorProductConstants } from '../constants';
import { deletedColorProduct, updatedColorProduct } from '../api/colorProduct';


export const getColorProduct = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: colorProductConstants.GET_COLOR_PRODUCT_REQUEST })
            const res = await axios.get("/colorProduct/get")
            if (res.status === 200) {
                const { colorProducts } = res.data
                dispatch({
                    type: colorProductConstants.GET_COLOR_PRODUCT_SUCCESS,
                    payload: {
                        colorProducts
                    }
                })
            } else {
                dispatch({ type: colorProductConstants.GET_COLOR_PRODUCT_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addColorProduct = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: colorProductConstants.ADD_COLOR_PRODUCT_REQUEST })
            const res = await axios.post("/colorProduct/create", form)
            if (res.status === 201) {
                dispatch({ type: colorProductConstants.ADD_COLOR_PRODUCT_SUCCESS, payload: res })
            } else {
                dispatch({ type: colorProductConstants.ADD_COLOR_PRODUCT_FAILURE, payload: res })
            }
            dispatch(getColorProduct())
        } catch (e) {
            console.log(e)
        }
    }
}


export const updateColorProduct = (id, post) => async (dispatch) => {
    try {
        dispatch({ type: colorProductConstants.UPDATE_COLOR_PRODUCT_REQUEST })
        const res = await updatedColorProduct(id, post)
        const { data } = res
        if (data) {
            dispatch({
                type: colorProductConstants.UPDATE_COLOR_PRODUCT_SUCCESS,
                payload: data
            })
        }
        dispatch(getColorProduct())
    } catch (error) {
        console.log(error);
    }
}
// export const updateAllColorProduct = () => async (dispatch) => {
//     try {
//         dispatch({ type: colorProductConstants.UPDATE_COLOR_PRODUCT_REQUEST })
//         const res = await axios.post("/colorProduct/updateAll")
//         const { data } = res
//         if (data) {
//             dispatch({
//                 type: colorProductConstants.UPDATE_COLOR_PRODUCT_SUCCESS,
//                 payload: data
//             })
//         } else {
//             dispatch({
//                 type: colorProductConstants.UPDATE_COLOR_PRODUCT_FAILURE,
//                 payload: data
//             })
//         }
//         dispatch(getColorProduct())
//     } catch (error) {
//         console.log(error);
//     }
// }

export const deleteColorProduct = (id) => async (dispatch) => {
    try {
        dispatch({ type: colorProductConstants.DELETE_COLOR_PRODUCT_REQUEST });
        const res = await deletedColorProduct(id);
        if (res.status === 200) {
            dispatch({ type: colorProductConstants.DELETE_COLOR_PRODUCT_SUCCESS, payload: id });
        } else {
            dispatch({ type: colorProductConstants.DELETE_COLOR_PRODUCT_FAILURE });
        }
        dispatch(getColorProduct())
    } catch (error) {
        console.log(error);
    }
}
export const getColorProductById = (colorProductId) => {
    return async dispatch => {
        dispatch({ type: colorProductConstants.GET_COLOR_PRODUCT_BY_ID_REQUEST });
        const res = await axios.get(`/colorProduct/getColorProductById/${colorProductId}`);
        try {
            if (res) {
                dispatch({
                    type: colorProductConstants.GET_COLOR_PRODUCT_BY_ID_SUCCESS,
                    payload: { colorProductDetails: res.data.colorProductDetails }
                });
            }
        } catch (error) {
            console.log(error);

        }
    }
}