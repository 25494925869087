import { allQuestionConstants } from "../../actions/constants"


const initialState = {
    allQuestion: [],
    allQuestionDetails: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case allQuestionConstants.DELETE_All_QUESTION_SUCCESS:
            return state.allQuestion.filter((allQuestion) => allQuestion._id !== action.payload)
        case allQuestionConstants.UPDATE_All_QUESTION_SUCCESS:
            return state.allQuestion.map((allQuestion) => allQuestion._id === action.payload._id ? action.payload : allQuestion)


        case allQuestionConstants.GET_ALL_QUESTION_SUCCESS:
            state = {
                ...state,
                allQuestion: action.payload.allQuestion,

            }
            break;
        default:
            return state
    }
    return state
}