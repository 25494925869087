import axios from "../helpers/axios";
import { setProduct } from "../reducers/product.reducer";
import { deletedProduct, updatedProduct } from "./api/product";
import { productConstants } from "./constants";
import { saveSettings, saveSlugSettings } from "./localSave/saveSetting";

export const updateProductsViewsLength = async () => {
  try {
    await axios.post("/product/updateProductsViewsLength");
    alert("Все товары успешно обновлены"); // Уведомление об успешном обновлении
  } catch (error) {
    console.log(error);
    alert("Ошибка при обновлении товаров: " + error.message); // Уведомление об ошибке
  }
};



export const updateMainImageById = (productId, imageId) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_MAIN_IMAGE_BY_ID_REQUEST });
    try {
      const res = await axios.post(`/admin/product/updateMainImageById/${productId}/${imageId}`);
      if (res.status === 200) {
        dispatch({ type: productConstants.UPDATE_MAIN_IMAGE_BY_ID_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: productConstants.UPDATE_MAIN_IMAGE_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addBluePrintsProductById = (productId, files, visibleWatermark) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_BLUEPRINTS_BY_ID_REQUEST });
    try {
      const formData = new FormData();
      for (let pic of files) {
        formData.append("samplePhoto", pic);
      }
      const res = await axios.post(`/admin/product/${productId}/${visibleWatermark}/addBluePrintsProduct`, formData);
      if (res.status === 200) {
        dispatch({
          type: productConstants.ADD_BLUEPRINTS_BY_ID_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: productConstants.ADD_BLUEPRINTS_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetTotalAmountById = (productId, payload) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.RESET_TOTAL_AMOUNT_BY_ID_REQUEST });
    try {
      const res = await axios.post(`/admin/product/resetTotalAmount/${productId}`, payload);
      if (res.status === 200) {
        dispatch({ type: productConstants.RESET_TOTAL_AMOUNT_BY_ID_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: productConstants.RESET_TOTAL_AMOUNT_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const editProductMaterialsById = (productId, payload) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.EDIT_PRODUCT_MATERIALS_BY_ID_REQUEST });
    try {
      const res = await axios.post(`/admin/product/editProductMaterialsById/${productId}`, payload);
      if (res.status === 200) {
        dispatch({ type: productConstants.EDIT_PRODUCT_MATERIALS_BY_ID_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: productConstants.EDIT_PRODUCT_MATERIALS_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addProductMaterialsById = (productId, payload) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_PRODUCT_MATERIALS_BY_ID_REQUEST });
    try {
      const res = await axios.post(`/admin/product/addProductMaterials/${productId}`, payload);
      if (res.status === 200) {
        dispatch({ type: productConstants.ADD_PRODUCT_MATERIALS_BY_ID_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: productConstants.ADD_PRODUCT_MATERIALS_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeProductMaterialById = (productId, materialId) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.REMOVE_PRODUCT_MATERIALS_BY_ID_REQUEST });
    try {
      const res = await axios.post(`/admin/product/removeProductMaterialById/${productId}/${materialId}`);
      if (res.status === 200) {
        dispatch({ type: productConstants.REMOVE_PRODUCT_MATERIALS_BY_ID_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: productConstants.REMOVE_PRODUCT_MATERIALS_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addUpdatedPicturesStatusProductById = (productId) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_UPDATED_PICTURES_STATUS_BY_ID_REQUEST });
    try {
      const res = await axios.post(`/admin/product/addUpdatedPicturesStatusProductById/${productId}`);
      if (res.status === 201) {
        dispatch({ type: productConstants.ADD_UPDATED_PICTURES_STATUS_BY_ID_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: productConstants.ADD_UPDATED_PICTURES_STATUS_BY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

// export const addUpdatedPicturesStatusAllProduct = () => {
//   return async (dispatch) => {
//     dispatch({ type: productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_REQUEST });
//     try {
//       const res = await axios.post("/admin/product/addUpdatedPicturesStatusAllProduct");
//       if (res.status === 201) {
//         dispatch({ type: productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_SUCCESS, payload: res.data });
//       } else {
//         dispatch({
//           type: productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_FAILURE,
//           payload: res.data,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

export const toggleTypeImgProduct = (payload) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.TOGGLE_TYPE_IMG_REQUEST });
    try {
      const res = await axios.post("/admin/product/toggleTypeImg", payload);
      if (res.status === 200) {
        dispatch({
          type: productConstants.TOGGLE_TYPE_IMG_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: productConstants.TOGGLE_TYPE_IMG_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const toggleVisibleImg = (payload) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.TOGGLE_VISIBLE_IMG_REQUEST });
    try {
      const res = await axios.post("/admin/product/toggleVisibleImg", payload);
      if (res.status === 200) {
        dispatch({
          type: productConstants.TOGGLE_VISIBLE_IMG_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: productConstants.TOGGLE_VISIBLE_IMG_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


const getProdSetting = (slug, dispatch) => {
  let settings = JSON.parse(localStorage.getItem('settings'));

  if (slug !== "all") {
    dispatch(getProductsBySlug(
      slug,
      settings.sort,
      settings.pageNumber,
      settings.pageSize
    ))
  } else {
    dispatch(getProducts(
      settings.sort,
      settings.pageNumber,
      settings.pageSize
    ))
  }
}
export const cancellationProductPicturesStatus = (payload, slug) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_REQUEST });
    try {
      const res = await axios.post("/admin/product/cancellationProductPicturesStatus", payload);
      if (res.status === 201) {
        dispatch({ type: productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_SUCCESS, payload: res.data });
        getProdSetting(slug, dispatch)
      } else {
        dispatch({
          type: productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateProductPicturesStatus = (payload, slug) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.UPDATE_STATUS_PICTURES_REQUEST });
    try {
      const res = await axios.post("/admin/product/updateStatusPictures", payload);
      if (res.status === 201) {
        dispatch({ type: productConstants.UPDATE_STATUS_PICTURES_SUCCESS, payload: res.data });
        getProdSetting(slug, dispatch)
      } else {
        const { error } = res.data;
        dispatch({
          type: productConstants.UPDATE_STATUS_PICTURES_FAILURE,
          payload: { error },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateProductsLength = () => {
  return async dispatch => {
    const res = await axios.post("/product/updateProductsLength");
    if (res.status === 200) {
      dispatch(getProducts())
    }
  }
}

export const createQRCodeById = (productId) => {
  return async dispatch => {
    dispatch({ type: productConstants.CREATE_QR_CODE_BY_ID_REQUEST });
    let res
    try {
      res = await axios.post(`/admin/product/createQRCodeImg/${productId}`);
      dispatch({
        type: productConstants.CREATE_QR_CODE_BY_ID_SUCCESS,
        payload: res.data
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: productConstants.CREATE_QR_CODE_BY_ID_FAILURE,
        // payload: { error: res.data.error }
      });
    }
  }
}

export const addSamplePhotoProduct = (_id, files, visibleWatermark) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      for (let pic of files) {
        formData.append("samplePhoto", pic);
      }
      const res = await axios.post(`/admin/product/${_id}/${visibleWatermark}/addSamplePhoto`, formData);
      console.log(res.data)
      dispatch({ type: productConstants.ADD_SAMPLE_PHOTO_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: productConstants.ADD_SAMPLE_PHOTO_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.ADD_SAMPLE_PHOTO_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const editProductCategory = (payload, slug) => {
  return async dispatch => {
    dispatch({ type: productConstants.EDIT_PRODUCT_CATEGORY_REQUEST });
    const res = await axios.post("/product/editProductCategory", payload);
    if (res.status === 200) {
      dispatch({
        type: productConstants.EDIT_PRODUCT_CATEGORY_SUCCESS,
        payload: res.data
      });

      let settings = JSON.parse(localStorage.getItem('settings'));

      if (slug !== "all") {
        dispatch(getProductsBySlug(
          slug,
          settings.sort,
          settings.pageNumber,
          settings.pageSize
        ))
      } else {
        dispatch(getProducts(
          settings.sort,
          settings.pageNumber,
          settings.pageSize
        ))
      }
    } else {
      dispatch({
        type: productConstants.EDIT_PRODUCT_CATEGORY_FAILURE,
        payload: res.data
      });
    }
  }
}
export const updateProductVisibleById = (id, slug) => async (dispatch) => {
  try {
    const data = await axios.get(`/product/visible/${id}`)
    dispatch({
      type: productConstants.UPDATE_PRODUCT_VISIBLE_BY_ID, payload: data
    })

    let settings = JSON.parse(localStorage.getItem('settings'));

    if (slug !== "all") {
      dispatch(getProductsBySlug(
        slug,
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    } else {
      dispatch(getProducts(
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    }
  } catch (error) {
    console.log(error);
  }
}

export const updateProductPriceVisibleById = (id, slug) => async (dispatch) => {
  try {
    const data = await axios.get(`/product/priceVisible/${id}`)
    dispatch({
      type: productConstants.UPDATE_PRODUCT_PRICE_VISIBLE_BY_ID, payload: data
    })

    let settings = JSON.parse(localStorage.getItem('settings'));

    if (slug !== "all") {
      dispatch(getProductsBySlug(
        slug,
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    } else {
      dispatch(getProducts(
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    }
  } catch (error) {
    console.log(error);
  }
}

export const getProductsBySlug = (slug, sort = "createdAtMinus", pageNumber, pageSize, updatedPictures) => {
  return async dispatch => {
    try {
      dispatch({ type: productConstants.GET_PRODUCTS_BY_SLUG_REQUEST })

      saveSlugSettings(slug, sort, pageNumber, pageSize);

      let url = `admin/products/${slug}`
      let urlTwo = `admin/products/${slug}?sort=${sort}`
      let urlThree = `admin/products/${slug}?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}&updatedPictures=${updatedPictures}`
      let urlPageNumber = `admin/products/${slug}?sort=${sort}&page=${pageNumber}&updatedPictures=${updatedPictures}`
      let urlUpdatedPictures = `admin/products/${slug}?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}&updatedPictures=${updatedPictures}`
      if (sort) {
        url = urlTwo
      }
      if (pageNumber) {
        url = urlPageNumber
      }
      if (pageSize) {
        url = urlThree
      }
      if (sort && pageNumber && pageSize) {
        url = urlThree
      }
      if (updatedPictures) {
        url = urlUpdatedPictures
      }
      const res = await axios.get(url)
      if (res.status === 200) {
        const {
          products,
          totalPages,
          totalAll,
          perPage,
          pageSize,
          total,
          categoryName,
          message,
          countPage
        } = res.data;
        dispatch({
          type: productConstants.GET_PRODUCTS_BY_SLUG_SUCCESS,
          payload: {
            products,
            totalPages,
            totalAll,
            perPage,
            pageSize,
            total,
            categoryName,
            message,
            countPage
          },
        })
      } else {
        dispatch({
          type: productConstants.GET_PRODUCTS_BY_SLUG_FAILURE,
        })
      }
    } catch (error) {
      console.log(error)
    }

  }
}

export const getProducts = (sort = "createdAtMinus", pageNumber, pageSize, updatedPictures) => {
  return async (dispatch) => {
    try {
      saveSettings(sort, pageNumber, pageSize);

      let url = "admin/product/getProducts"
      let urlTwo = `admin/product/getProducts?sort=${sort}`
      let urlThree = `admin/product/getProducts?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}`
      let urlUpdatedPictures = `admin/product/getProducts?sort=${sort}&page=${pageNumber}&pageSize=${pageSize}&updatedPictures=${updatedPictures}`
      let urlPageNumber = `admin/product/getProducts?sort=${sort}&page=${pageNumber}`
      if (sort) {
        url = urlTwo
      }
      if (pageNumber) {
        url = urlPageNumber
      }
      if (pageSize) {
        url = urlThree
      }
      if (sort && pageNumber && pageSize) {
        url = urlThree
      }
      if (updatedPictures) {
        url = urlUpdatedPictures
      }
      const res = await axios.get(url);
      if (res.status === 200) {
        const {
          products,
          allProducts,
          totalPages,
          totalAll,
          perPage,
          pageSize,
          categoryName,
          message
        } = res.data;
        dispatch({
          type: productConstants.GET_ALL_PRODUCTS_SUCCESS,
          payload: {
            products,
            allProducts,
            totalPages,
            totalAll,
            perPage,
            pageSize,
            categoryName,
            message
          },
        });
      } else {
        dispatch({ type: productConstants.GET_ALL_PRODUCTS_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addProduct = (form, slug) => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.ADD_PRODUCT_REQUEST });
      const res = await axios.post('product/create', form);
      if (res.status === 201) {
        dispatch({ type: productConstants.ADD_PRODUCT_SUCCESS, payload: res });
      } else {
        dispatch({ type: productConstants.ADD_PRODUCT_FAILURE, payload: res });
      }
      let settings = JSON.parse(localStorage.getItem('settings'));

      if (slug !== "all") {
        dispatch(getProductsBySlug(
          slug,
          settings.sort,
          settings.pageNumber,
          settings.pageSize
        ))
      } else {
        dispatch(getProducts(
          settings.sort,
          settings.pageNumber,
          settings.pageSize
        ))
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateAllMaterialProduct = (slug) => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.UPDATE_ALL_PRODUCT_REQUEST });
      const res = await axios.post('product/updateAllProduct');
      if (res.status === 200) {
        dispatch({ type: productConstants.UPDATE_ALL_PRODUCT_SUCCESS, payload: res });
      } else {
        dispatch({ type: productConstants.UPDATE_ALL_PRODUCT_FAILURE, payload: res });
      }
      let settings = JSON.parse(localStorage.getItem('settings'));

      if (slug !== "all") {
        dispatch(getProductsBySlug(
          slug,
          settings.sort,
          settings.pageNumber,
          settings.pageSize
        ))
      } else {
        dispatch(getProducts(
          settings.sort,
          settings.pageNumber,
          settings.pageSize
        ))
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteProductById = (id, slug) => async (dispatch) => {
  try {
    const { data } = await deletedProduct(id);
    dispatch({
      type: productConstants.DELETE_PRODUCT_BY_ID_SUCCESS,
      payload: { id, data }
    });
    let settings = JSON.parse(localStorage.getItem('settings'));

    if (slug !== "all") {
      dispatch(getProductsBySlug(
        slug,
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    } else {
      dispatch(getProducts(
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    }
  } catch (error) {
    console.log(error);
  }
}

export const getProductById = (productId) => {
  return async dispatch => {
    dispatch({ type: productConstants.GET_PRODUCT_BY_ID_REQUEST });
    let res
    try {
      res = await axios.get(`/admin/product/${productId}`);
      dispatch({
        type: productConstants.GET_PRODUCT_BY_ID_SUCCESS,
        payload: { productDetails: res.data.productDetails }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: productConstants.GET_PRODUCT_BY_ID_FAILURE,
        payload: { error: res.data.error }
      });
    }
  }
}

export const updateProductById = (id, product, slug) => async (dispatch) => {
  try {
    dispatch({ type: productConstants.UPDATE_PRODUCT_NEW_BY_ID_REQUEST })
    const res = await updatedProduct(id, product)
    const { data } = res
    if (res.status === 200) {
      dispatch({
        type: productConstants.UPDATE_PRODUCT_BY_ID_SUCCESS,
        payload: { data, message: data.message },
      })
    } else {
      dispatch({
        type: productConstants.UPDATE_PRODUCT_NEW_BY_ID_FAILURE,
        payload: { message: data.message },
      })
    }
    let settings = JSON.parse(localStorage.getItem('settings'));

    if (slug !== "all") {
      dispatch(getProductsBySlug(
        slug,
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    } else {
      dispatch(getProducts(
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    }
  } catch (error) {
    console.log(error);
  }
}


export const updateProductNewById = (payload, slug, typeSet) => async (dispatch) => {
  dispatch({ type: productConstants.UPDATE_PRODUCT_NEW_BY_ID_REQUEST });

  try {
    const res = await axios.post(`/product/updateNew?typeSet=${typeSet}`, payload);
    if (res.status === 201) {
      dispatch({
        type: productConstants.UPDATE_PRODUCT_NEW_BY_ID_SUCCESS,
        payload: { message: res.data.message }
      })
    } else {
      dispatch({
        type: productConstants.UPDATE_PRODUCT_NEW_BY_ID_FAILURE,
        payload: { message: res.data.message }
      })
    }
    let settings = JSON.parse(localStorage.getItem('settings'));

    if (slug !== "all") {
      dispatch(getProductsBySlug(
        slug,
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    } else {
      dispatch(getProducts(
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    }
  } catch (error) {
    console.log(error);
  }
}


export const searchProducts = (search, type, sortSearch) => {
  return async dispatch => {
    try {
      dispatch({ type: productConstants.SEARCH_PRODUCTS_REQUEST });
      const res = await axios.get(`admin/product/getProducts/search?search=${search}&sort=${sortSearch}`)
      if (res.status === 200) {
        dispatch({
          type: productConstants.SEARCH_PRODUCTS_SUCCESS,
          payload: res.data
        })
        dispatch(setProduct(res.data))
      } else {
        dispatch({
          type: productConstants.SEARCH_PRODUCTS_FAILURE,
          payload: res.data
        })
      }
    } catch (e) {
      console.log(e?.res)
    }
  }
}

export const updateProductMaterialById = (id, typeMaterial, slug) => async (dispatch) => {
  try {
    const data = await axios.get(`/product/updateMaterial/${id}?typeMaterial=${typeMaterial}`, typeMaterial)
    dispatch({
      type: productConstants.UPDATE_PRODUCT_MATERIAL_BY_ID, payload: { data, typeMaterial }
    })
    let settings = JSON.parse(localStorage.getItem('settings'));

    if (slug !== "all") {
      dispatch(getProductsBySlug(
        slug,
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    } else {
      dispatch(getProducts(
        settings.sort,
        settings.pageNumber,
        settings.pageSize
      ))
    }
  } catch (error) {
    console.log(error);
  }
}



