import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingIconBlock from './SettingIconBlock';

const RenderMiniImg = ({
    itemObj,
    setShowModalImg,
    moveDot,
    slideIndex,
    directlyImg,
    onClickIcon,
    tooltip
}) => {

    const handleClick = (index) => {
        if (moveDot) return moveDot(index + 1)
        else {
            console.log("hello")
        }
    }

    return (
        <div className='containerMiniImg'>
            {
                !itemObj?.length ? null : itemObj?.map((obj, index) => (
                    <div
                        key={directlyImg ? index : obj._id}
                        onClick={setShowModalImg ? () => setShowModalImg(true) : () => handleClick(index)}
                        onMouseEnter={() => handleClick(index)}

                        className={slideIndex && slideIndex === index + 1
                            ? "containerMiniImg__miniImg active-mini"
                            : "containerMiniImg__miniImg"}
                    >
                        <img
                            src={directlyImg ? obj : generatePublicUrl(obj.img)}
                        />
                        {
                            onClickIcon
                                ? <SettingIconBlock
                                    tooltip={tooltip}
                                    onClick={() => onClickIcon(obj.productId)}
                                    icon={<DeleteForeverIcon />}
                                />
                                : null
                        }
                    </div>
                )
                )}
        </div>
    )
}

export default RenderMiniImg