import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { generatePublicUrl } from '../../urlConfig';
import ProductImgSliderModal from './../ProductImgSliderModal/ProductImgSliderModal';
import { useDispatch } from 'react-redux';
import { updateCategoryVisiblePriceById, updateProductsLength } from '../../actions';
import { updateCategoryVisibleById } from './../../actions/category.action';


const RenderSettingCategoryModal = ({
    setShowSettingCategoryModal,
    setCheckedCategoryVisible,
    showSettingCategoryModal,
    checkedCategoryVisible,
    categoryObj,
    setChecked,
    checked
}) => {

    const dispatch = useDispatch()

    if (!categoryObj) {
        return null
    }

    const ButtonBlock = ({ onClick, checkedVisible }) => {
        return (
            <div>
                <button
                    onClick={onClick}
                    className="buttonSettingCategoryShow">
                    {!checkedVisible ? "Показать" : "Скрыть"}
                </button>
            </div>
        )
    }
    const handleShow = (categoryObj) => {
        let id = categoryObj._id
        let slug = categoryObj.slug
        dispatch(updateCategoryVisiblePriceById(id, slug))
        setChecked(!checked)
        // setShowSettingCategoryModal(false)
    }
    const handleShowVisibleCategory = (id) => {
        dispatch(updateCategoryVisibleById(id))
        setCheckedCategoryVisible(!checkedCategoryVisible)
        // setShowSettingCategoryModal(false)
    }

    return (
        <div className="renderSettingCategoryModal">

            <ProductImgSliderModal
                show={showSettingCategoryModal}
                handleClose={() => setShowSettingCategoryModal(false)}
                modalTitle={`Настроить отображение категории " ${categoryObj.name} "`}
                size="md"
            >
                <Row>
                    <Col md="12">
                        <label className="key">{!checked ? "Показать" : "Скрыть"} все цены в категории</label>
                        <div className="renderSettingCategoryModal__rowButtonContainer">
                            <ButtonBlock
                                checkedVisible={checked}
                                onClick={() => handleShow(categoryObj)}
                            />
                            <div>
                                {!checked
                                    ? "Сейчас цены в этой категории скрыты"
                                    : "Сейчас цены видны для покупателя"}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <label className="key">Скрыть эту категорию для покупателя</label>
                        <div className="renderSettingCategoryModal__rowButtonContainer">
                            <ButtonBlock
                                checkedVisible={checkedCategoryVisible}
                                onClick={() => handleShowVisibleCategory(categoryObj._id)}
                            />
                            <div>
                                {!checkedCategoryVisible
                                    ? "Сейчас эта категория скрыта "
                                    : "Отображено для покупателя"}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <label className="key">Фотографии</label>
                        <div style={{ display: "flex" }}>
                            <div
                                // onClick={handleShowModalImg}
                                className="productImgContainer p-1">
                                <img src={generatePublicUrl(categoryObj.categoryImage)} alt="Фото" />
                            </div>
                        </div>
                        {/* {categoryObj.name === "Скамейки" && <Button onClick={() => dispatch(updateProductsLength())}> Изменить длину всех скамеек на 1500</Button>} */}
                        {/* {categoryObj.name === "Скамейки" && <Button onClick={() => dispatch(updateProductsLength())}> Изменить все название</Button>} */}
                        {/* {categoryObj.name === "Скамейки" && <Button onClick={() => dispatch(updateProductsLength())}>Сгенерировать названия </Button>} */}
                    </Col>
                </Row>
                {/* <RenderImgSliderModal
                item={productDetails.productPictures}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            /> */}
            </ProductImgSliderModal>
        </div >

    )
}

export default RenderSettingCategoryModal