import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import Input from '../../components/UI/Input';
import { useSelector, useDispatch } from 'react-redux';
import { getColorProduct, updateOrderAdditionalField } from '../../actions';
// import Loader from '../../components/Loader';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Modal from '../../components/UI/Modal';


const RenderAddAdditionalFieldModal = ({
    setShowAddAdditionalField,
    showAddAdditionalField,
    productOrderDetails,
    setDataAdditional,
    onHide,
    orderId,
}) => {
    // const colorProduct = useSelector((state) => state.colorProduct)

    // const { colorProducts, loading } = colorProduct

    const [afDescription, setAfDescription] = useState("")
    const [afTitle, setAfTitle] = useState("")
    const [afViewType, setAfViewType] = useState("")

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getColorProduct())
    }, [])

    const submitAdditionalField = (e) => {
        if (afTitle !== "" && afDescription !== "") {
            const payload = {
                afTitle: afTitle,
                afDescription: afDescription,
                afViewType: afViewType,
                productOrderDetailsId: productOrderDetails._id,
                typeSet: "additionalField",
                orderId,
            }
            dispatch(updateOrderAdditionalField(payload))
            setDataAdditional(true)
            clear()
            setShowAddAdditionalField(false)
        }

    }

    const clear = () => {
        setAfDescription("")
        setAfTitle("")
    }

    const arrayTypeView = [
        {
            type: "Только админ",
            value: "admin"
        },
        {
            type: "Общедоступный",
            value: "publicly"
        },
    ]
    if (productOrderDetails === null) return null
    return (
        <Modal
            show={showAddAdditionalField}
            handleClose={onHide}
            modalTitle="Добавить доп-параметр"
            onSubmit={submitAdditionalField}
        >
            <Row>
                <Col md="6">
                    <Input
                        label="Название"
                        name="afTitle"
                        value={afTitle}
                        placeholder="Название"
                        onChange={(e) => setAfTitle(e.target.value)}
                    />
                </Col>
                <Col md="6">
                    <Input
                        label="Описание"
                        name="afDescription"
                        value={afDescription}
                        placeholder="Подробное описание"
                        onChange={(e) => setAfDescription(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={{
                margin: "10px"
            }}>
                <label >Выбрать вид просмотра</label>
                <select
                    className="form-control"
                    name="afViewType"
                    value={afViewType}
                    onChange={(e) => setAfViewType(e.target.value)}
                >
                    <option>Выбрать тип</option>
                    {arrayTypeView.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.type}
                        </option>
                    ))}
                </select>
            </Row>
        </Modal>
    );
}

export default RenderAddAdditionalFieldModal;