import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ContainerSiteSetting from '../ContainerSiteSetting';
import RenderItemsContainer from '../components/RenderItemsContainer';
import { deleteContactM, getContactM } from '../../actions/components/contactM';
import AddContactM from './../create/AddContactM';

const ContactM = () => {
    const contactM = useSelector((state) => state.contactM.contactM)
    const dispatch = useDispatch()
    const [currentId, setCurrentId] = useState(null)
    const [show, setShow] = useState(false)
    const [typeModal, setTypeModal] = useState(false)
    const [showModalImg, setShowModalImg] = useState(false);

    useEffect(() => {
        dispatch(getContactM())
    }, [])

    const handleShow = () => {
        setShow(true)
    }

    return (
        <ContainerSiteSetting
            item={contactM}
            // itemsLength={contactM.length}
            setShow={handleShow}
            title="Наши соцсети"
            href="/siteSetting"
        >
            <RenderItemsContainer
                setShow={setShow}
                items={contactM}
                setCurrentId={setCurrentId}
                deleteButton={deleteContactM}
            />
            <AddContactM
                show={show}
                typeModal={typeModal}
                handleClose={() => setShow(false)}
                setCurrentId={setCurrentId}
                currentId={currentId}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            />

        </ContainerSiteSetting>
    )
}

export default ContactM
