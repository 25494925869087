import React, { useState } from 'react'
import Card from '../../components/UI/Card/index';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import Loader from './../../components/Loader';
import { generatePublicUrl } from '../../urlConfig';
import RenderOrderDetails from './RenderOrderDetails';
import { useDispatch } from 'react-redux';
import { getUserById } from '../../actions';

import LeftContainerInfo from './LeftContainerInfo';
import RenderSettingEditModal from './RenderSettingEditModal';

export const RenderOrders = ({
    setShowOrderNumberAdditionalModal,
    setShowDeletingConfirmation,
    setShowPaymentAccountModal,
    showDeletingConfirmation,
    setShowOrderSettingModal,
    handleShowEditOrderModal,
    confirmVisibleOrderById,
    setShowSettingEditModal,
    handleShowSettingModal,
    showSettingEditModal,
    typeEditOrderModal,
    handleTotalAmount,
    showOrderModal,
    orderedProduct,
    confirmDelete,
    orderProduct,
    setOrderObj,
    setOrderId,
    setClicked,
    orderObj,
    loading,
    clicked,
    orderId,
    order,
}) => {

    const dispatch = useDispatch()
    const toggle = (orderItem, index) => {
        setOrderObj(orderItem)
        setOrderId(orderItem._id)
        dispatch(getUserById(orderItem.user))
        if (clicked === index) {
            return setClicked(null);
        }
        setClicked(index);
    };

    if (loading) return <Loader />
    if (order.length === 0) return null
    return (
        <div style={{
            maxWidth: "1200px",
            margin: "0 auto",
        }}>

            {
                order.length > 0 && order.map((orderItem, index) => {
                    return (
                        <Card
                            onClick={() => toggle(orderItem, index)}
                            style={{
                                margin: "10px 0",
                                background: `${!orderItem.visibleOrder.isCompleted ? "rgba(255, 0, 0, 0.316)" : ""}`
                            }}
                            key={index}
                        >
                            <div
                                onClick={() => orderProduct(orderItem)}
                                className="order row m-1 p-2">
                                <div className="col-sm-3">
                                    <div className="order__start">
                                        <LeftContainerInfo
                                            orderItem={orderItem}
                                            clicked={clicked}
                                            index={index}
                                            setShowOrderNumberAdditionalModal={setShowOrderNumberAdditionalModal}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="title">
                                        Cумма заказа
                                    </div>
                                    <span className="value">{orderItem.totalAmount > 0 ? orderItem.totalAmount : "не посчитано"}</span>
                                </div>
                                <div className="col-sm-2">
                                    <div className="title">
                                        Со скидкой
                                    </div>
                                    <span className="value">{orderItem.totalAmount > 0 ? orderItem.discountForBuyers : "не посчитано"}</span>
                                </div>
                                <div className="col-sm-5">
                                    <div className="order__renderItemsProductOrder">
                                        <div className="order__rightContainer">
                                            {clicked === index ? null : <div className="order__imgContainer">
                                                {orderItem.items.map((obj, index) => (
                                                    <div className='order__img'
                                                        key={index}
                                                    >
                                                        <img src={generatePublicUrl(obj.productId?.productPictures[0].img) || null} alt="foto" />
                                                    </div>
                                                ))}
                                            </div>}
                                            <div className='order__iconContainer'>
                                                <span>{clicked === index
                                                    ? <BsChevronDown size='20px' color='#3e77aa' />
                                                    : <BsChevronUp size='20px' color="#3e77aa" />}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <RenderOrderDetails
                                handleShowEditOrderModal={handleShowEditOrderModal}
                                setShowDeletingConfirmation={setShowDeletingConfirmation}
                                setShowPaymentAccountModal={setShowPaymentAccountModal}
                                showDeletingConfirmation={showDeletingConfirmation}
                                setShowOrderSettingModal={setShowOrderSettingModal}
                                confirmVisibleOrderById={confirmVisibleOrderById}
                                setShowSettingEditModal={setShowSettingEditModal}
                                handleShowSettingModal={handleShowSettingModal}
                                handleTotalAmount={handleTotalAmount}
                                showOrderModal={showOrderModal}
                                orderedProduct={orderedProduct}
                                confirmDelete={confirmDelete}
                                orderProduct={orderProduct}
                                setClicked={setClicked}
                                orderItem={orderItem}
                                orderObj={orderObj}
                                clicked={clicked}
                                orderId={orderId}
                                index={index}
                            />
                        </Card>
                    );
                })
            }
            <RenderSettingEditModal
                show={showSettingEditModal}
                typeEditOrderModal={typeEditOrderModal}
                handleClose={() => setShowSettingEditModal(false)}
                orderObj={orderObj}
            />
        </div >
    )
}





