import axios from "../helpers/axios";
import { galleryImageConstants } from "./constants";

const limit = true;
export const getAllGalleries = (fields = "", limit = false) => {
  return async (dispatch) => {
    try {
      dispatch({ type: galleryImageConstants.GET_ALL_GALLERY_REQUEST });
      let url = `gallery/get/${fields}/${limit}`

      const res = await axios.get(url);
      if (res.status === 200) {
        const { items } = res.data;
        dispatch({
          type: galleryImageConstants.GET_ALL_GALLERY_SUCCESS,
          payload: { items },
        });
      } else {
        dispatch({ type: galleryImageConstants.GET_ALL_GALLERY_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

//
export const removeGalleryPicturesByIds = (payload) => {
  return async dispatch => {
    dispatch({ type: galleryImageConstants.REMOVE_GALLERY_PICTURES_IDS_REQUEST });
    const res = await axios.post("/gallery/removeGalleryPicturesByIds", payload);
    if (res.status === 200) {
      dispatch({
        type: galleryImageConstants.REMOVE_GALLERY_PICTURES_IDS_SUCCESS,
        payload: res.data
      });
      dispatch(getAllGalleries("all", limit))
    } else {
      dispatch({
        type: galleryImageConstants.REMOVE_GALLERY_PICTURES_IDS_FAILURE,
        payload: res.data
      });
    }
  }
}

export const addGallery = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: galleryImageConstants.CREATE_GALLERY_REQUEST });
      const res = await axios.post("gallery/create", form);
      if (res.status === 201) {
        dispatch({ type: galleryImageConstants.CREATE_GALLERY_SUCCESS });
        dispatch(getAllGalleries("all", limit))
      } else {
        dispatch({ type: galleryImageConstants.CREATE_GALLERY_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteGalleryById = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: galleryImageConstants.DELETE_GALLERY_BY_ID_REQUEST });
      const res = await axios.delete("gallery/deleteGalleryById", {
        data: { payload },
      });
      if (res.status === 202) {
        dispatch({ type: galleryImageConstants.DELETE_GALLERY_BY_ID_SUCCESS });
        dispatch(getAllGalleries("all", limit))
      } else {
        const { error } = res.data;
        dispatch({
          type: galleryImageConstants.DELETE_GALLERY_BY_ID_FAILURE,
          payload: {
            error,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGalleryById = (galleryId, limit = false, random = false) => {
  return async dispatch => {
    dispatch({ type: galleryImageConstants.GET_GALLERY_BY_ID_REQUEST });
    let res;
    try {
      res = await axios.get(`/gallery/getById/${galleryId}/${limit}/${random}`);
      dispatch({
        type: galleryImageConstants.GET_GALLERY_BY_ID_SUCCESS,
        payload: { gallery: res.data.gallery }
      });

    } catch (error) {
      console.log(error);
      dispatch({
        type: galleryImageConstants.GET_GALLERY_BY_ID_FAILURE,
      });
    }
  }
}

export const addSamplePhotoGallery = (_id, files) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      for (let pic of files) {
        formData.append("samplePhoto", pic);
      }
      const res = await axios.post(`/gallery/${_id}/addSamplePhoto`, formData);
      console.log(res.data)
      dispatch({ type: galleryImageConstants.ADD_SAMPLE_PHOTO_REQUEST });
      if (res.status === 200) {
        dispatch({
          type: galleryImageConstants.ADD_SAMPLE_PHOTO_SUCCESS,
          payload: res.data,
        });
        dispatch(getAllGalleries("all", limit))
      } else {
        dispatch({
          type: galleryImageConstants.ADD_SAMPLE_PHOTO_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};