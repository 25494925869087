import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import Modal from "../../components/UI/Modal";
import { generatePublicUrl } from '../../urlConfig';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import { cancellationStatus } from '../../actions';
import { useDispatch } from 'react-redux';
import { formatDate } from './../../components/FormDate';

const RenderDetailsModal = ({
    roleChangeRequestObj,
    applicationDetailModal,
    handleClose,
    // regionObj,
    handleDeleteById,
    typeModal,
    handleUpdateStatus,
    setType,
    role,
    userDetails
}) => {
    const dispatch = useDispatch()
    if (!roleChangeRequestObj) {
        return null;
    }
    const handleChangeOrderStatus = (e) => {
        setType(e.target.value)
    }

    const handleCancellationStatus = (text, type) => {
        const payload = {
            text,
            type
        }
        dispatch(cancellationStatus("application", roleChangeRequestObj._id, "status", payload))
    }
    const onSubmit = (id) => {
        handleUpdateStatus(id)
        // handleClose()
    }

    return (
        <Modal
            show={applicationDetailModal}
            handleClose={handleClose}
            modalTitle={"Детали заявки на смену роли"}
            size="lg"
        >
            {typeModal === "settingModal"
                ? <div className="orderModalCenter__orderStatusMainContainer">
                    <div className="orderModalCenter__orderStatusBox">
                        {roleChangeRequestObj.status?.map((status) => (
                            <>
                                <div className={`orderModalCenter__orderStatus ${status.isCompleted ? "active" : ""}`}>
                                    {role === "superAdmin" && status.isCompleted && <SettingIconBlock
                                        icon={<CloseIcon />}
                                        tooltip="Отменить"
                                        onClick={() => handleCancellationStatus(status.text, status.type)}
                                        top="10px"
                                    />}
                                    <div className={`time ${status.isCompleted ? "active" : ""
                                        }`}>{formatDate(status.date)}</div >
                                    <span>{status.text}</span>
                                    <div className={`orderModalCenter__line ${status.isCompleted ? "active" : ""}`}>
                                    </div>
                                </div>

                            </>
                        ))}

                    </div>
                    <div className="orderModalCenter__selectBlock">
                        <div>
                            <select onChange={(e) => handleChangeOrderStatus(e)}>
                                <option value={""}>Выбрать статус</option>
                                {roleChangeRequestObj.status?.map((status) => {
                                    return (
                                        <>
                                            {!status.isCompleted ? (
                                                <option key={status.type} value={status.type}>
                                                    {status.type} {status.text}
                                                </option>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <Button
                                style={{ marginLeft: "10px" }}
                                onClick={() => onSubmit(roleChangeRequestObj._id)}
                            >
                                подтвердить
                            </Button>

                        </div>
                    </div>
                </div>
                : <>
                    <div style={{
                        margin: "10px",
                    }}>
                        <button className="btn btn-secondary" onClick={handleDeleteById}>Удалить заявку</button>
                    </div>
                    <Row>
                        <Col md="6">
                            <label className="key">Регион пользователя</label>
                            <div>
                                <p className="value">{userDetails.region}</p>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="6">
                            <label className="key">Email пользователя</label>
                            <div>
                                <p className="value">{userDetails.email}</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <label className="key">Полное имя
                            </label>
                            <p className="value">{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <label className="key">Номер указанный в заявке</label>
                            <div>
                                <a href='tel:' className="value">{roleChangeRequestObj.contactNumber}</a>
                            </div>
                        </Col>
                        <Col md="6">
                            <label className="key">Желаемая роль
                            </label>
                            <p className="value">{roleChangeRequestObj.newRole}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <label className="key">Описание проблемы</label>
                            <p className="value">{roleChangeRequestObj.description}</p>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </>
            }

        </Modal>
    );
};

export default RenderDetailsModal