import React from 'react'

const LoaderFullScreen = () => {
    return (
        <div className="loaderFullScreen">
            <div className="loaderFullScreen__animated-element"></div>
        </div>
    )
}

export default LoaderFullScreen 