import React from 'react'
import NumberOrder from './NumberOrder';
import StatusOrder from './StatusOrder';

const LeftContainerInfo = ({
    setShowOrderNumberAdditionalModal,
    orderItem,
    clicked,
    index,
}) => {
    const filterOrderStatusColor = (text) => {
        let color;
        switch (text) {
            case "Оформлен":
                color = "gray";
                break;
            case "Подтвержден оператором":
                color = "green";
                break;
            case "Производство":
                color = "greenDark";
                break;
            case "Передан в службу доставки":
                color = "blue";
                break;
            case "Доставлен":
                color = "gold";
                break;
            default: color = "green";
        }
        return color
    }

    return (
        <div className="order__leftContainer">
            <NumberOrder
                setShowOrderNumberAdditionalModal={setShowOrderNumberAdditionalModal}
                orderItem={orderItem}
                clicked={clicked}
                index={index}
            />
            <StatusOrder
                filterOrderStatusColor={filterOrderStatusColor}
                status={orderItem.orderStatus}
            />
        </div>
    )
}

export default LeftContainerInfo