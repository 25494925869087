import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import RenderItemsContainer from '../../components/RenderItemsContainer';
import ButtonSecondary from '../../../components/Button/ButtonSecondary';
import { updateMainPage, deleteMainPage, getAllItems, getItemById, getAllGalleries, } from './../../../actions';
import ContainerSiteSetting from '../../ContainerSiteSetting';
import AddMainPage from './AddMainPage';
import { setCloseAlert } from '../../../reducers/components/mainPage';
import AlertComponent from '../../../components/Alert/Alert';

const MainPageSetting = () => {
    const {
        mainPages,
        mainPageObj,
        variantAlert,
        message,
        loading,
        showAlert
    } = useSelector((state) => state.mainPage)
    const { galleries } = useSelector((state) => state.gallery)
    const dispatch = useDispatch()

    const [currentId, setCurrentId] = useState(null)
    const [show, setShow] = useState(false)
    const [typeModal, setTypeModal] = useState("")
    const [showModalImg, setShowModalImg] = useState(false);

    useEffect(() => {
        dispatch(getAllItems("mainPage"))
    }, [])
    useEffect(() => {
        dispatch(getAllGalleries("text"))
    }, [])

    useEffect(() => {
        if (currentId) {
            dispatch(getItemById("mainPage", currentId))
        }
    }, [currentId])

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        setTypeModal("")
        setCurrentId(null)
    }

    const updateAllW = () => {
        dispatch(updateMainPage())
    }
    return (
        <ContainerSiteSetting
            itemsLength={mainPages?.length}
            setShow={handleShow}
            title="Главные страницы сайта"
            href="/siteSetting"
        >
            <ButtonSecondary
                onClick={updateAllW}
                text="Обновить все"
            />
            <RenderItemsContainer
                setShow={setShow}
                items={mainPages}
                setTypeModal={setTypeModal}
                setCurrentId={setCurrentId}
                deleteButton={deleteMainPage}
            />
            <AddMainPage
                mainPageObj={mainPageObj}
                show={show}
                galleries={galleries}
                handleClose={handleClose}
                setCurrentId={setCurrentId}
                currentId={currentId}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
                typeModal={typeModal}
                loading={loading}
            />
            <AlertComponent
                dispatchCloseAlert={setCloseAlert}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </ContainerSiteSetting>
    )
}

export default MainPageSetting
