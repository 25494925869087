import React from 'react'


import Layout from '../../components/Layout';
import { NavLink } from 'react-router-dom';


const Analytics = (props) => {
    const itemsLink = [
        {
            text: "Поисковые запросы",
            href: "/analytics/searchQuery"
        }
    ]
    return (
        <Layout
            sidebar
            backButton
        >
            <div className="siteSettingMainContainer">
                <h2>Аналитика</h2>
                <div className="siteSettingMainContainer__titleModalSetting">
                    Страницы и их содержимое
                </div>
                <ul className="siteSetting">
                    {itemsLink.map((obj, index) => (
                        <li key={index}>
                            <NavLink to={obj.href}>{obj.text}</NavLink></li>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default Analytics
