import axios from "../../helpers/axios"
import { materialConstants } from '../constants';
import { deletedMaterial, updatedMaterial } from '../api/material';


export const getMaterial = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: materialConstants.GET_MATERIAL_REQUEST })
            const res = await axios.get("material/get")
            if (res.status === 200) {
                const { material } = res.data
                dispatch({
                    type: materialConstants.GET_MATERIAL_SUCCESS,
                    payload: {
                        material
                    }
                })
            } else {
                dispatch({ type: materialConstants.GET_MATERIAL_FAILURE })
            }
        } catch (e) {
            console.log(e)
        } 
    }
}

export const addMaterial = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: materialConstants.ADD_MATERIAL_REQUEST })
            const res = await axios.post("material/create", form)
            if (res.status === 201) {
                dispatch({ type: materialConstants.ADD_MATERIAL_SUCCESS })
                dispatch(getMaterial())
            } else {
                dispatch({ type: materialConstants.ADD_MATERIAL_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}


export const updateMaterial = (id, post) => async (dispatch) => {
    try {
        const { data } = await updatedMaterial(id, post)
        dispatch({
            type: materialConstants.GET_MATERIAL_SUCCESS, payload: data
        })
        dispatch(getMaterial())
    } catch (error) {
        console.log(error);
    }
}

export const deleteMaterial = (id) => async (dispatch) => {
    try {
        await deletedMaterial(id);
        dispatch({ type: materialConstants.DELETE_MATERIAL_SUCCESS, payload: id });
        dispatch(getMaterial())
    } catch (error) {
        console.log(error);
    }
}