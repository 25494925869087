import { addressConstants } from "./constants";
import axios from "../helpers/axios";



export const getAddressById = (payload) => {
    return async dispatch => {
        dispatch({ type: addressConstants.GET_ADDRESS_BY_ID_REQUEST });
        let res
        try {
            res = await axios.post("/address/getAddressById", payload)
            dispatch({
                type: addressConstants.GET_ADDRESS_BY_ID_SUCCESS,
                payload: res.data
            });
        } catch (error) {
            dispatch({
                type: addressConstants.GET_ADDRESS_BY_ID_FAILURE,
                payload: res.data
            });
        }
    }
}