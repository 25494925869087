import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

const RenderListItems = ({
    items,
    setItems,
    previousData,
    rejectedStatus

}) => {

    const handleSuccess = (index) => {
        setItems((prevState) =>
            prevState.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        equalityField: !item.equalityField,
                        confirmed: !item.confirmed,
                    }
                    : item
            )
        );
    };

    return <>
        <div className="applicationChangeProfile__listItemRowTitle">
            <div className="applicationChangeProfile__title">
                Поле
            </div>
            <div className="applicationChangeProfile__title">
                С
            </div>
            <div className="applicationChangeProfile__title">
                На
            </div>
        </div>
        {items.map((item, index) =>
            <div className={`applicationChangeProfile__listItemRow ${!previousData && item.equalityField ? "new" : ""} ${item.confirmed ? "confirmed" : ""}`}
                key={index}
            >
                <div className="applicationChangeProfile__name">
                    {item.name}
                    {!previousData && item.equalityField
                        ? rejectedStatus ? null : <div className="applicationChangeProfile__svgNewBox"
                            onClick={() => handleSuccess(index)}
                        >
                            <AddBoxIcon />
                        </div>
                        : null}
                </div>
                <div className="applicationChangeProfile__before">
                    <div className="applicationChangeProfile__before__text">
                        {item.beforeText
                            ? item.beforeText
                            : <span>-----</span>
                        }
                    </div>
                    <div className="applicationChangeProfile__svgBox">
                        <ArrowRightAltIcon />
                    </div>
                </div>
                <div className="applicationChangeProfile__after">
                    {item.afterText ? item.afterText : <span>-----</span>}
                    {item.confirmed
                        ? rejectedStatus ? null : <div className="applicationChangeProfile__svgOkBox"
                            onClick={() => handleSuccess(index)}
                        >
                            <DoneOutlineIcon />
                        </div>
                        : null}
                </div>
            </div>
        )}
    </>
}

export default RenderListItems