import React, { useEffect } from 'react'
import { getAllItems } from '../../actions';
import { MainContainer } from './../MainContainer';
import { useDispatch, useSelector } from 'react-redux';

const PartnerPage = () => {
    const { roleChangeRequests } = useSelector((state) => state.roleChangeRequest)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllItems("roleChangeRequest"))
    }, [])

    return (
        <MainContainer
            backButtonProduct
            type
            backHref
            // search={searchApplication}
            href="/containerPartnerPage"
            get={getAllItems("roleChangeRequest")}
            title={"Наши партнеры"}
        >
            <div>Hello world</div>
        </MainContainer>

    )
}

export default PartnerPage