import React, { useState, useEffect } from 'react'
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addModalContent, updateModalContent } from '../../actions';

export const AddModalContent = ({ showModal, handleClose, setCurrentId, currentId }) => {
    const modalContentId = useSelector((state) => currentId ? state.modalContent.modalContent.find((obj) => obj._id === currentId) : null)
    const [formModalContent, setFormModalContent] = useState({
        name: "",
        title: "",
        description: "",
    })
    const dispatch = useDispatch()
 

    useEffect(() => {
        if (modalContentId) setFormModalContent(modalContentId)
    }, [modalContentId])


    const submitModalContentForm = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updateModalContent(currentId, formModalContent))
            handleClose()
            clear()
        } else {
            const form = new FormData()
            form.append("name", formModalContent.name)
            form.append("title", formModalContent.title)
            form.append("description", formModalContent.description)
            dispatch(addModalContent(formModalContent)).then(() => handleClose())
            // clear()
        }
    }
    const clear = () => {
        setCurrentId(null)
        setFormModalContent({
            name: "",
            title: "",
            description: ""
        })
    }
    return (
        <Modal
            show={showModal}
            handleClose={handleClose}
            modalTitle={"Добавить модалку"}
            onSubmit={submitModalContentForm}
        >
       
            <Input
                label="Имя"
                value={formModalContent.name}
                placeholder={"Введите Имя"}
                onChange={(e) => setFormModalContent({ ...formModalContent, name: e.target.value })}
            />
            <Input
                label="Заголовок"
                value={formModalContent.title}
                placeholder={"Введите Заголовок"}
                onChange={(e) => setFormModalContent({ ...formModalContent, title: e.target.value })}
            />
            <Input
                label="Подробнее"
                value={formModalContent.description}
                placeholder={"Подробнее"}
                onChange={(e) => setFormModalContent({ ...formModalContent, description: e.target.value })}
            />
        </Modal>

    )
}

