import React, { useEffect, useState } from 'react'
import { deleteRoleChangeRequest, getItemById, getRoleChangeRequest, getUserById, updateRoleChangeRequestStatus, updateRoleChangeRequestViewed } from '../../actions';
import { MainContainer } from '../MainContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RenderPartnerApplication } from './RenderPartnerApplication';
import RenderDetailsModal from './RenderDetailsModal';
import RenderImgSliderModal from '../../components/ProductImgSliderModal/RenderImgSliderModal';
import { setPageNumberChangeRequest, setPageSizeRoleChangeRequest, setSortRoleChangeRequest } from '../../reducers/roleChangeRequest';

const PartnerShipApplication = () => {
    const {
        roleChangeRequests,
        roleChangeRequestObj,
        sort,
        pageSize,
        pageNumber,
    } = useSelector((state) => state.roleChangeRequest)
    const { user } = useSelector((state) => state.auth)
    const { userDetails } = useSelector((state) => state.user)

    const [typeModal, setTypeModal] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [applicationDetailModal, setApplicationDetailModal] = useState(false);
    const [type, setType] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getRoleChangeRequest("all", sort, pageNumber, pageSize))
    }, [sort, pageNumber, pageSize])

    const handleViewApplication = async (id) => {
        const obj = await roleChangeRequestObj
        if (!obj.isViewed) {
            dispatch(updateRoleChangeRequestViewed(id));
        }
    };
    const showModal = (item) => {
        console.log("item", item)
        handleViewApplication(item._id)
        if (selectedItemId === item._id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(item._id);
            dispatch(getUserById(item.userId));
            dispatch(getItemById("roleChangeRequest", item._id));
        }

        setApplicationDetailModal(true);
    };

    const handleClose = () => {
        setApplicationDetailModal(false);
        setTypeModal("")
    };
    const handleDeleteById = () => {
        dispatch(deleteRoleChangeRequest(roleChangeRequestObj._id))
        setApplicationDetailModal(false)
    }

    const handleUpdateStatus = (id) => {
        console.log('id', id)
        const payload = {
            applicationId: id,
            type
        }
        dispatch(updateRoleChangeRequestStatus(payload))
    }

    return (
        <MainContainer
            backButtonProduct
            dispatchAction
            setPerPageSearchQuery={setPageSizeRoleChangeRequest}
            setPageNumber={setPageNumberChangeRequest}
            pageNumber={pageNumber}
            pageSize={pageSize}
            sort={sort}
            type
            backHref
            // search={searchApplication} 
            href="/containerPartnerPage"
            get={getRoleChangeRequest()}
            title="Все заявки на партнерство"
            pagination="onlyButton"
        >
            <RenderPartnerApplication
                roleChangeRequests={roleChangeRequests}
                setTypeModal={setTypeModal}
                showModal={showModal}
            />
            <RenderDetailsModal
                // regionObj={regionObj}
                handleUpdateStatus={handleUpdateStatus}
                role={user.role}
                setType={setType}
                handleClose={handleClose}
                typeModal={typeModal}
                roleChangeRequestObj={roleChangeRequestObj}
                handleDeleteById={handleDeleteById}
                applicationDetailModal={applicationDetailModal}
                userDetails={userDetails}
            />

            {/* <AlertComponent
                dispatchCloseAlert={setCloseAlertApplication}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            /> */}

        </MainContainer>

    )
}

export default PartnerShipApplication