import { featuredProductItemConstants } from '../actions/constants';



const initialState = {
    featuredProductItems: [],
    featuredProductItemObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "success",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case featuredProductItemConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case featuredProductItemConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                featuredProductItemObj: action.payload.featuredProductItemObj,
                loading: false
            }
            break
        case featuredProductItemConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case featuredProductItemConstants.GET_ALL_ITEMS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case featuredProductItemConstants.GET_ALL_ITEMS_SUCCESS:
            state = {
                ...state,
                featuredProductItems: action.payload.featuredProductItems,
                loading: false
            }
            break
        case featuredProductItemConstants.GET_ALL_ITEMS_FAILURE:
            state = {
                ...state,
                loading: false
            }
            break
        case featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                featuredProductItemObj: action.payload.updatedItem,
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.UPDATE_FEATURED_PRODUCT_ORDER_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.REMOVE_PRODUCT_BY_IDS_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.REMOVE_PRODUCT_BY_IDS_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
                featuredProductItemObj: action.payload.updatedItem
            }
            break
        case featuredProductItemConstants.REMOVE_PRODUCT_BY_IDS_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.REMOVE_PRODUCT_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.REMOVE_PRODUCT_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
                featuredProductItemObj: action.payload.updatedItem
            }
            break
        case featuredProductItemConstants.REMOVE_PRODUCT_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.ADD_NEW_PRODUCT_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.ADD_NEW_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
                featuredProductItemObj: action.payload.updatedItem
            }
            break
        case featuredProductItemConstants.ADD_NEW_PRODUCT_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.CREATE_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.CREATE_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.CREATE_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.DELETE_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.DELETE_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.DELETE_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.UPDATE_VISIBLE_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break
        case featuredProductItemConstants.UPDATE_VISIBLE_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                featuredProductItemObj: action.payload.updatedItem,
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.UPDATE_VISIBLE_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break
        case featuredProductItemConstants.CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false,
            };
            break;
        default:
            return state
    }
    return state
}

export const setCloseAlertFeaturedProductItem = (payload) => ({
    type: featuredProductItemConstants.CLOSE_ALERT,
    payload
})