import { mailingConstants } from "./constants";
import axios from "../helpers/axios";


export const sendMailing = (formMaterial) => {
    return async (dispatch) => {
        try {
            let payload = formMaterial
            dispatch({ type: mailingConstants.SEND_MAILING_REQUEST });
            const res = await axios.post('mailing/create', payload);
            if (res.status === 201) {
                dispatch({
                    type: mailingConstants.SEND_MAILING_SUCCESS,
                    payload: res
                });
                dispatch(addMailing(payload))
            } else {
                dispatch({
                    type: mailingConstants.SEND_MAILING_FAILURE,
                    payload: res
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};
export const getMailing = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: mailingConstants.GET_MAILING_REQUEST })
            const res = await axios.get("/mailing/getEmailing")
            if (res.status === 200) {
                dispatch({
                    type: mailingConstants.GET_MAILING_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: mailingConstants.GET_MAILING_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
export const getByIdMailing = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: mailingConstants.GET_BY_ID_MAILING_REQUEST })
            const res = await axios.get(`/mailing/getById/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: mailingConstants.GET_BY_ID_MAILING_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: mailingConstants.GET_BY_ID_MAILING_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addMailing = (payload) => {
    console.log("payload", payload)
    return async (dispatch) => {
        try {
            dispatch({ type: mailingConstants.ADD_MAILING_REQUEST })
            const res = await axios.post("/mailing/createEmailingData", payload)
            if (res.status === 201) {
                dispatch({ type: mailingConstants.ADD_MAILING_SUCCESS })
            } else {
                dispatch({ type: mailingConstants.ADD_MAILING_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
