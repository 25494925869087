import { Button } from '@mui/material'
import React from 'react'
import ButtonContainer from './ButtonContainer'

const ContainerDetailsContent = ({
    setTypeModal,
    children,
    noneButtons,
    noneBackButton
}) => {
    return (
        <div>
            {noneBackButton
                ? null
                : <Button
                    onClick={() => setTypeModal("")}
                >
                    На главную
                </Button>}
            {children}
            {noneButtons
                ? null
                : <ButtonContainer
                    setTypeModal={setTypeModal}
                />}
        </div>
    )
}

export default ContainerDetailsContent