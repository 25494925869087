import axios from "../helpers/axios";
import { setApplicationChangeProfile } from "../reducers/applicationChangeProfile";
import { deletedApplicationChangeProfile } from "./api/applicationChangeProfile";
import { applicationChangeProfileConstants } from "./constants";

export const getApplicationChangeProfile = (sortStatusOrder, sort) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationChangeProfileConstants.GET_ALL_APPLICATION_CHANGE_PROFILE_REQUEST });
      let url = "applicationChangeProfile/get"
      if (sort) {
        url = `applicationChangeProfile/get?sort=${sort}`
      }
      const res = await axios.get(url, {
        params: {
          sortStatusOrder: sortStatusOrder,
        },
      });
      if (res.status === 200) {
        const { applicationsChangeProfile } = res.data;
        dispatch({
          type: applicationChangeProfileConstants.GET_ALL_APPLICATION_CHANGE_PROFILE_SUCCESS,
          payload: { applicationsChangeProfile },
        });
      } else {
        dispatch({ type: applicationChangeProfileConstants.GET_ALL_APPLICATION_CHANGE_PROFILE_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteManyApplicationChangeProfile = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationChangeProfileConstants.DELETE_MANY_REQUEST });
      const res = await axios.post("/applicationChangeProfile/deleteMany", payload);
      if (res.status === 200) {
        dispatch({
          type: applicationChangeProfileConstants.DELETE_MANY_SUCCESS,
          payload: res.data
        });
        dispatch(getApplicationChangeProfile("all"))
      } else {
        dispatch({
          type: applicationChangeProfileConstants.DELETE_MANY_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateApplicationChangeProfileStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationChangeProfileConstants.UPDATE_STATUS_REQUEST });
      const res = await axios.post("/applicationChangeProfile/updateStatus", payload);
      if (res.status === 201) {
        dispatch({
          type: applicationChangeProfileConstants.UPDATE_STATUS_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: applicationChangeProfileConstants.UPDATE_STATUS_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getApplicationChangeProfileById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationChangeProfileConstants.GET_BY_ID_REQUEST });
      const res = await axios.get(`/applicationChangeProfile/getById/${id}`);
      if (res.status === 200) {
        dispatch({
          type: applicationChangeProfileConstants.GET_BY_ID_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: applicationChangeProfileConstants.GET_BY_ID_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchApplicationChangeProfile = (search) => {
  return async dispatch => {
    try {
      const response = await axios.get(`/applicationChangeProfile/getApplicationsChangeProfile/search?search=${search}`)
      dispatch(setApplicationChangeProfile(response.data))
    } catch (e) {
      alert(e?.response?.data?.message)
    }
  }
}

export const deleteApplicationChangeProfileById = (id) => async (dispatch) => {
  try {
    await deletedApplicationChangeProfile(id);
    dispatch({ type: applicationChangeProfileConstants.DELETE_APPLICATION_CHANGE_PROFILE_BY_ID_SUCCESS, payload: id });
    dispatch(getApplicationChangeProfile())
  } catch (error) {
    console.log(error);
  }
}