import { addressConstants } from "../actions/constants";

const initState = {
    address: {},
    loadingA: false,
    variantAlert: "success",
    message: "",
    showAlert: false,
};


export default (state = initState, action) => {

    switch (action.type) {
        case addressConstants.GET_ADDRESS_BY_ID_REQUEST:
            state = {
                ...state,
                loadingA: true
            }
            break;
        case addressConstants.GET_ADDRESS_BY_ID_SUCCESS:
            state = {
                ...state,
                address: action.payload.address,
                loadingA: false,
            }
            break;
        case addressConstants.GET_ADDRESS_BY_ID_FAILURE:
            state = {
                ...state,
                loadingA: false,
            }
            break;
        case addressConstants.SET_CLOSE_ADDRESS_ALERT:
            state = {
                ...state,
                showAlert: false,
            }
            break;
        default:
            return state
    }

    return state;
}

export const setCategoryDetails = (payload) => ({ type: addressConstants.SET_CURRENT_CATEGORY, payload })
export const setCloseCategoryAlert = (payload) => ({ type: addressConstants.SET_CLOSE_ADDRESS_ALERT, payload })
