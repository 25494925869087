import React from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';


const TextViews = ({
    itemViews,
    title
}) => {
    if (!itemViews) return null
    return (

        <div className="textViews">
            <Tooltip title={title ? title : "Просмотры"}>
                <div className="textViews__content">
                    <div className="textViews__icon">
                        <VisibilityIcon />
                    </div>
                    <div className="textViews__text">
                        {itemViews}
                    </div>
                </div>
            </Tooltip>
        </div >
    )
}

export default TextViews