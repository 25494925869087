import React, { useState } from 'react';
import { Button, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { generatePublicUrl } from '../../urlConfig';
import { removeProductPicturesByIds } from './../../actions/productPictures.action';
import { useDispatch } from 'react-redux';
import Loader from './../../components/Loader';
import { toggleTypeImgProduct, toggleVisibleImg } from '../../actions/product.action';
import VisibleIconComponent from '../../components/VisibleIconComponent';
import TextHidePicture from '../../components/Pictures/TextHidePicture';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
const RemoveProductPictures = ({
    productDetailsObj,
    handleCloseAll,
    deleteAction,
    pictures,
    loading,
}) => {
    const [pictureIds, setPictureIds] = useState([]);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        const payload = {
            productId: productDetailsObj._id,
            pictureIds
        }
        dispatch(deleteAction(payload)).then(() => handleCloseAll());

    };

    const handlePictureClick = (pictureId) => {
        const newPictureIds = pictureIds.includes(pictureId)
            ? pictureIds.filter((id) => id !== pictureId)
            : [...pictureIds, pictureId];
        setPictureIds(newPictureIds);
    };

    const handleCheckboxChange = (pictureId) => {
        handlePictureClick(pictureId);
    };

    const handleVisibleImg = (imgId) => {
        const payload = {
            productId: productDetailsObj._id,
            imgId
        }
        dispatch(toggleVisibleImg(payload))
    }
    const handleEditTypeImg = (imgId) => {
        let typeImg = "real";
        const payload = {
            productId: productDetailsObj._id,
            imgId,
            typeImg
        }
        dispatch(toggleTypeImgProduct(payload))
    }
    if (loading) return <Loader />

    return (
        <div className="removeProductPictures">
            <div className="removeProductPictures__imgRow">
                {pictures?.map((picture) => (
                    <div
                        className="removeProductPictures__imgBox"
                        key={picture._id}
                    >
                        <div className="removeProductPictures__visibleImgIcon">
                            <VisibleIconComponent
                                handleClick={handleVisibleImg}
                                itemId={picture._id}
                                itemVisible={picture.visibleImg}
                                text="фото"
                            />
                            <div className="file__visibleIconBlock"
                                onClick={() => handleEditTypeImg(picture._id)}
                            >
                                <Tooltip title="Реальность фото">
                                    <ViewInArIcon />
                                </Tooltip>
                            </div>
                        </div>
                        <Checkbox
                            className="removeProductPictures__checkbox"
                            checked={pictureIds.includes(picture._id)}
                            onChange={() => handleCheckboxChange(picture._id)} // Добавлен этот обработчик
                            value={picture._id}
                        />
                        <img
                            src={generatePublicUrl(picture.img)}
                            alt={picture._id}
                        />
                        <TextHidePicture
                            visibleImg
                            pictureVisible={picture.visibleImg}
                        />
                    </div>
                ))}
            </div>
            <div className="removeProductPictures__buttonContainer">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={pictureIds.length > 0 ? false : true}
                >
                    Удалить фотографии
                </Button>
            </div>
        </div>
    );
};

export default RemoveProductPictures;
