import React from 'react'
import { useDispatch } from 'react-redux';
import { getItemById } from './../../../actions/mainActions/mainAction';

const RenderItemRegions = ({
    items,
    setCurrentId,
    setShow,
    deleteButton,
    setTypeModal,
    handleClose
}) => {
    const dispatch = useDispatch()

    const Edit = (item) => {
        setCurrentId(item._id)
        setShow(true)
    }

    const handleShowModal = (itemId) => {
        setTypeModal("renderDetails")
        dispatch(getItemById("region", itemId, "components", "-__v"))
        setShow(true)
    }

    return (
        <div className="renderItemBenefits">
            {items.length &&
                items.map((item, index) =>
                    <div
                        className="renderItemBenefits__itemContainer"
                        key={item._id}
                    >
                        <div className="renderItemBenefits__contentBlock">
                            <div className="renderItemBenefits__titleContainer">
                                <h3>{index + 1}. </h3>
                                <h3 onClick={() => handleShowModal(item._id)}>
                                    {item.name}
                                </h3>

                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                        </div>
                        <button
                            className="btn btn-secondary"
                            onClick={() => Edit(item)}>
                            Редактировать
                        </button>
                        <button
                            style={{ marginLeft: "5px" }}

                            className="btn btn-secondary"
                            onClick={() => dispatch(deleteButton(item._id))}>
                            Удалить
                        </button>
                    </div>

                )}
        </div >
    )
}

export default RenderItemRegions