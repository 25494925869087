import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getSearchQuery, searchApplication, } from '../../../actions';
import Modal from "../../../components/UI/Modal";
import { MainContainer } from '../../MainContainer';
import { RenderSearchQuery } from './SearchQuery/RenderSearchQuery';
import { setCurrentPageSearchQuery, setPerPageSearchQuery, setSortSearchQuery } from '../../../reducers/components/searchQuery';
import { setShowUserModal, } from '../../../reducers/user.reducer';

const SearchQuery = () => {
    const {
        searchQuery,
        loading,
        analyticsObj,
        pageSize,
        pageNumber,
        sortSearchQuery,
        totalAll,
        totalPages,
    } = useSelector((state) => state.searchQuery)

    const [detailsId, setDetailsId] = useState(null);
    const [applicationDetailModal, setApplicationDetailModal] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSearchQuery(sortSearchQuery, pageNumber, pageSize))
    }, [sortSearchQuery, pageNumber, pageSize])


    const showDetailsModal = (id) => {
        setDetailsId(id);
        setApplicationDetailModal(true);
    };

    const showModalUser = (id) => {
        const payload = {
            userId: id
        }
        dispatch(setShowUserModal(payload))
    }
    console.log("sortSearchQuery", sortSearchQuery)
    return (
        <MainContainer
            setPageNumber={setCurrentPageSearchQuery}
            setSortReducer={setSortSearchQuery}
            setPageSize={setPerPageSearchQuery}
            sortItems={sortSearchQuery}
            search={searchApplication}
            noneSelectUpdatedPictures
            title={"История поиска"}
            pageNumber={pageNumber}
            loadingItems={loading}
            get={getSearchQuery}
            pageSize={pageSize}
            backButtonProduct
            dispatchAction
            backHref
            type
            totalPages={totalPages}
            totalAll={totalAll}
            pagination
        >
            <Row style={{ marginTop: "15px" }}>
                <Col>
                    <RenderSearchQuery
                        handleShowDetailsUserModal={showModalUser}
                        showDetailsModal={showDetailsModal}
                        searchQuery={searchQuery}
                    />
                </Col>

            </Row>
        </MainContainer>
    )
}

export default SearchQuery