import React from 'react'
import BRenderAddPictures from '../../../components/Button/BRenderAddPictures'
import Input from '../../../components/UI/Input/index';
import RenderSelectModel from '../../../components/RenderSelectModel';
import Loader from '../../../components/Loader';

const RenderAdd = ({
    formContact,
    setPictures,
    setFormMainPage,
    galleries,
    currentId
}) => {

    const handleChange = (key, value) => {
        setFormMainPage({ ...formContact, [key]: value })
    }
    return (
        <>
            <Input
                label="Название"
                value={formContact.title}
                placeholder={"Название"}
                onChange={(e) => handleChange("title", e.target.value)}
            />
            <textarea
                style={{
                    width: "100%",
                    minHeight: "200px"
                }}
                label="Описание"
                value={formContact.description}
                placeholder={"Описание"}
                onChange={(e) => handleChange("description", e.target.value)}
            />
            <Input
                label="Ссылка"
                value={formContact.href}
                placeholder={"Добавить ссылку"}
                onChange={(e) => handleChange("href", e.target.value)}
            />
            <Input
                label="Текст кнопки перехода"
                value={formContact.buttonText}
                placeholder={"Введите текст"}
                onChange={(e) => handleChange("buttonText", e.target.value)}
            />
            <RenderSelectModel
                value={formContact.galleryId}
                titleOption="Ничего не привязано"
                title="Выбрать какую галлерею привязать"
                itemsModel={galleries}
                valueKey="galleryId"
                handleChange={handleChange}
            />
            {!currentId ?
                <BRenderAddPictures
                    setPictures={setPictures}
                /> : null
            }
        </>
    )
}

export default RenderAdd