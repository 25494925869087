import React, { useEffect, useState } from 'react'
import { MainContainer } from '../MainContainer'
import { getMailing } from '../../actions/mailing.action'
import { useDispatch, useSelector } from 'react-redux'
import { RenderMailingItems } from './RenderMailingItems'
import RenderDetailsModal from '../Applications/RenderDetailsModal'
import NewModal from '../../components/UI/Modal'
import OrderDetailsButton from '../Orders/OrderDetailsButton'
import { Button } from '@mui/material'

const MailingItemsPage = () => {
    const [showModal, setShowModal] = useState(false)
    const [typeModal, setTypeModal] = useState("")
    const [emailInfo, setEmailInfo] = useState("")

    const { mailing } = useSelector((state) => state.mailing)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMailing())
    }, [])
    console.log("typeModal", typeModal
    )
    const handleClose = () => {
        setShowModal(false)
    }
    const ButtonBlock = ({ handleClick, content }) => {
        return (
            <div style={{ margin: "10px" }}>
                <Button
                    variant="contained"
                    onClick={handleClick}
                >
                    {content}
                </Button>
            </div>
        )
    }
    const renderModal = () => {
        return <NewModal
            show={showModal}
            handleClose={handleClose}
            modalTitle="Детали рассылки"
            size="lg"
        >
            {renderButton()}
            {typeModal === "resetMailing"
                ? <>
                    <ButtonBlock
                        handleClick={console.log("reset")}
                        content="Оставить все как есть"
                    />
                    <ButtonBlock
                        handleClick={console.log("edit")}
                        content="Добавить правки"
                    />
                </>
                : ""
            }
        </NewModal>
    }
    const renderButton = () => {
        return <ButtonBlock
            handleClick={() => setTypeModal(typeModal === "resetMailing" ? "" : "resetMailing")}
            content={typeModal === "resetMailing" ? "Назад" : "Повторить рассылку"}
        />
    }

    return (
        <div>
            <MainContainer
                backButtonProduct
                type
                backHref
                title={"Список отправленных рассылок"}
            >
                <RenderMailingItems
                    data={mailing}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setTypeModal={setTypeModal}
                    setEmailInfo={setEmailInfo}
                />
                {renderModal()}
            </MainContainer>
        </div >
    )
}

export default MailingItemsPage