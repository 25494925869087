import axios from "../helpers/axios";
import { setApplicationGallery } from "../reducers/applicationGallery";
import { applicationGalleryConstants } from "./constants";

export const getApplicationGallery = (sortStatusOrder, sort = "createdAt", pageNumber, pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationGalleryConstants.GET_ALL_REQUEST });
      let url = "applicationGallery/get"
      if (sort) {
        url = `applicationGallery/get?sort=${sort}`
      }
      const res = await axios.get(url, {
        params: {
          sortStatusOrder: sortStatusOrder,
          sort: sort,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
      });
      if (res.status === 200) {
        dispatch({
          type: applicationGalleryConstants.GET_ALL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({ type: applicationGalleryConstants.GET_ALL_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteManyApplicationGallery = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationGalleryConstants.DELETE_MANY_REQUEST });
      const res = await axios.post("/applicationGallery/deleteMany", payload);
      if (res.status === 200) {
        dispatch({
          type: applicationGalleryConstants.DELETE_MANY_SUCCESS,
          payload: res.data
        });
        dispatch(getApplicationGallery("all"))
      } else {
        dispatch({
          type: applicationGalleryConstants.DELETE_MANY_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateApplicationGalleryStatus = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationGalleryConstants.UPDATE_STATUS_REQUEST });
      const res = await axios.post("/applicationGallery/updateStatus", payload);
      if (res.status === 201) {
        dispatch({
          type: applicationGalleryConstants.UPDATE_STATUS_SUCCESS,
          payload: res.data
        });
        dispatch(getApplicationGallery("all"))
      } else {
        dispatch({
          type: applicationGalleryConstants.UPDATE_STATUS_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateApplicationViewed = (applicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationGalleryConstants.APPLICATION_VIEWED_REQUEST, applicationId });
      const res = await axios.put(`/applicationGallery/${applicationId}/viewed`);
      dispatch({ type: applicationGalleryConstants.APPLICATION_VIEWED_SUCCESS, applicationId });
    } catch (error) {
      dispatch({ type: applicationGalleryConstants.APPLICATION_VIEWED_FAILURE, error });
    }
  };
};
export const getApplicationGalleryById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationGalleryConstants.GET_BY_ID_REQUEST });
      const res = await axios.get(`/applicationGallery/getById/${id}`);
      if (res.status === 200) {
        dispatch({
          type: applicationGalleryConstants.GET_BY_ID_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: applicationGalleryConstants.GET_BY_ID_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchApplicationGallery = (search) => {
  return async dispatch => {
    try {
      const response = await axios.get(`/applicationGallery/getApplicationsGallery/search?search=${search}`)
      dispatch(setApplicationGallery(response.data))
    } catch (e) {
      alert(e?.response?.data?.message)
    }
  }
}


export const deleteApplicationGalleryById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: applicationGalleryConstants.DELETE_BY_ID_REQUEST });
      const res = await axios.delete(`/applicationGallery/delete/${id}`);
      if (res.status === 200) {
        dispatch({
          type: applicationGalleryConstants.DELETE_BY_ID_SUCCESS,
          payload: res.data
        });
        dispatch(getApplicationGallery())
      } else {
        dispatch({
          type: applicationGalleryConstants.DELETE_BY_ID_FAILURE,
          payload: res.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};