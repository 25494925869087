import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getApplication } from '../../actions';
import Loader from '../../components/Loader';
import SettingIconBlock from './../../components/Button/SettingIconBlock';


export const RenderApplication = ({
    applications,
    setTypeModal,
    showModal,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getApplication())
    }, [])
    if (applications.length === 0) {
        return (
            <Loader />
        )
    }
    const handleShowSettingModal = (e, type, id) => {
        e.stopPropagation()
        setTypeModal(type)
        showModal(id)
    }


    return (
        <div>
            <div className="tableContainerMobile">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                                <div>
                                    <div>
                                        Мобильный
                                    </div>
                                    <div>
                                        Имя
                                    </div>
                                </div>

                            </th>
                            <th scope="col">
                                <div>
                                    <div>
                                        Мобильный
                                    </div>
                                    <div>
                                        Время
                                    </div>
                                </div>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.length > 0
                            ? applications.map((a, index) =>
                                <tr key={a._id}
                                    onClick={() => showModal(a._id)}
                                >
                                    <th scope="row">{index + 1}</th>
                                    <td><div>
                                        <div>
                                            {a.contactNumber}
                                        </div>
                                        <div>
                                            {a.firstName}
                                        </div>
                                    </div></td>
                                    <td><div>
                                        <div>
                                            {a.email}
                                        </div>
                                        <div className="tableContainerMobile__time">
                                            {a.createdAt.slice(0, 10)}
                                            &nbsp;
                                            {a.createdAt.slice(11, 19)}
                                        </div>
                                    </div></td>
                                </tr>
                            )
                            : null}

                    </tbody>
                </table>
            </div>
            <div className="tableContainer">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Мобильный</th>
                            <th scope="col">Имя</th>
                            <th scope="col">Username</th>
                            <th scope="col">Время</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.length > 0
                            ? applications.map((a, index) =>
                                <tr key={a._id}
                                    onClick={() => showModal(a._id)}
                                    style={{
                                        position: "relative"
                                    }}
                                >
                                    <th scope="row">{index + 1}</th>
                                    <td>{a.contactNumber}</td>
                                    <td>{a.contactNumber}</td>
                                    <td>{a.fullName}</td>
                                    <td>{a.email}</td>
                                    <td>{a.createdAt.slice(11, 19)}</td>
                                    <SettingIconBlock
                                        tooltip="Настройки"
                                        right="10px"
                                        top="8px"
                                        onClick={(e) => handleShowSettingModal(e, "settingModal", a._id)}
                                    />
                                </tr>
                            )
                            : null}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
