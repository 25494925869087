import React from 'react'
import PaginationComponent from '../components/PaginationComponent'

const MainContainerChildren = ({
    children,
    pagination,
    totalPages,
    pageSize,
    pageNumber,
    totalAll,
    setPageNumber
}) => {
    return pagination
        ? <> {pagination === "onlyButton" ? null : <PaginationComponent
            dispatchAction
            totalPages={totalPages}
            totalAll={totalAll}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
        />}
            {children}
            <PaginationComponent
                dispatchAction
                totalPages={totalPages}
                totalAll={totalAll}
                pageSize={pageSize}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
            />
        </>
        : children
}

export default MainContainerChildren