import React from 'react';
import { Form } from 'react-bootstrap';

const Input = (props) => {

  let input = null;

  switch (props.type) {
    case 'select':
      input = <Form.Group

      >
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <select
          style={{
            borderColor: props.showError && props.value === "" ? "red" : "#ced4da"
          }}
          className="form-control"
          value={props.value}
          onChange={props.onChange}
        >
          <option style={{
            paddingBottom: "5px",
            borderBottom: "1px solid gray"
          }} value="">{props.placeholder}</option>
          {
            props.options.length > 0 ?
              props.options.map((option, index) =>
                <option style={{
                  paddingBottom: "1px solid gray"
                }} key={index} value={option.value}>{option.name}</option>
              ) : null
          }
        </select>
      </Form.Group>
      break;
    case 'text':
    default:
      input = <Form.Group >
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Form.Control
          type={props.type}
          style={{
            borderColor: props.showError && props.value === "" ? "red" : "#ced4da"
          }}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          {...props}
        />
        <Form.Text className="text-muted">
          {props.errorMessage}
        </Form.Text>
      </Form.Group>
  }


  return input;

}

export default Input