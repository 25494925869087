import React from 'react'
import { generatePublicUrl } from '../../urlConfig'
import { Row, Col } from 'react-bootstrap';

const MethodShipping = ({
    item,
}) => {
    const { title, description, iconImg } = item
    const ContentBox = ({ label, value, md }) => {
        return (
            <Col md={md ? md : "6"}>
                <div className="key">{label}</div>
                <p className="value"
                    style={{
                        color: value === "" && "red"
                    }}
                >
                    {value === "" ? "Не заполнено" : value}</p>
            </Col>
        )
    }
    return (
        <div>
            <Row>
                <ContentBox
                    md="12"
                    label="Название компании"
                    value={title}
                />
            </Row>
            <Row>
                <ContentBox
                    md="12"
                    label="Описание компании"
                    value={description}
                />
            </Row>
            <Row>
                <img
                    style={{
                        width: "100px",
                        height: "auto",
                        margin: "0 auto"
                    }}
                    src={generatePublicUrl(iconImg)} alt="foto" />
            </Row>
        </div>
    )
}

export default MethodShipping