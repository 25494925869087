import React from 'react'
import { Row, Col } from 'react-bootstrap';

const ContentButtonBox = ({
    checked,
    checkedTitleText,
    productDetails,
    hideTextDesc,
    showTextDesc,
    editFunc

}) => {


    const ButtonShow = ({ onClick }) => {
        return (
            <button
                onClick={onClick}
                className="buttonSettingCategoryShow">
                Показать
            </button>
        )
    }
    const ButtonHide = ({ onClick }) => {
        return (
            <button
                onClick={onClick}
                className="buttonSettingCategoryShow">
                Скрыть
            </button>
        )
    }

    return (
        <Row>
            <Col md="12">
                <label className="key">{checked ? "Показать" : "Скрыть"} {checkedTitleText}</label>
                <div className="renderSettingCategoryModal__rowButtonContainer">
                    <div>
                        {checked
                            ? <ButtonShow onClick={() => editFunc(productDetails._id)} />
                            : <ButtonHide onClick={() => editFunc(productDetails._id)} />}
                    </div>
                    <div>
                        {checked
                            ? hideTextDesc
                            : showTextDesc}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ContentButtonBox