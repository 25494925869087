import React, { useState } from 'react';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ReusableTable = ({
    data,
    columns,
    onRowClick,
    onSettingIconClick,
    onEmailClick
}) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (id) => {
        console.log("id", id)
        setExpandedRow(expandedRow === id ? null : id);
    };
    console.log("data", data[0].itemsEmails.length)
    return (
        <div className="reusableTable">
            <div className="tableContainer">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index} scope="col">{col.header}</th>
                            ))}
                            <th scope="col">{`Всего Емайлов: `}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0
                            ? data.map((item, index) => (
                                <React.Fragment key={item._id}>
                                    <tr
                                        onClick={() => onRowClick(item._id)}
                                        style={{ position: "relative" }}
                                    >
                                        {columns.map((col, colIndex) => (
                                            <td key={colIndex}>{col.accessor(item, index)}</td>
                                        ))}
                                        <td>
                                            <SettingIconBlock
                                                tooltip="Настройки"
                                                right="10px"
                                                top="8px"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onSettingIconClick(e, "settingModal", item._id);
                                                }}
                                            />
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRowClick(item._id);
                                                }}
                                            >
                                                <div
                                                    className='reusableTable__emailsArray'
                                                >
                                                    <span> Всего: {`${data[0].itemsEmails.length}`} </span>
                                                    <div className="reusableTable__emailsArray__icon">
                                                        {expandedRow === item._id
                                                            ? <FaChevronUp />
                                                            : <FaChevronDown />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {expandedRow === item._id && (
                                        <tr>
                                            <td colSpan={columns.length + 1}>
                                                <ul>
                                                    {item.itemsEmails.map((emailItem) => (
                                                        <li
                                                            className='reusableTable__listItemEmail'
                                                            key={emailItem._id}
                                                            onClick={() => onEmailClick(emailItem)}
                                                        >
                                                            {emailItem.email}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                            : null}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default ReusableTable;
