import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux';
import ContainerSiteSetting from '../../ContainerSiteSetting';
import AlertComponent from '../../../components/Alert/Alert';
import {
    deleteProductListLink,
    getByIdProductListLink,
    getFeaturedProductItems,
    getProductListLink
} from '../../../actions';
import AddProductListLink from './AddProductListLink';
import { setCloseAlertProductListLink } from '../../../reducers/productListLink';
import ObjRender from '../../../containers/render/ObjRender';
import ModalConfirm from '../../../components/BurgerMenu/ModalConfirm';

const ProductListLinkSetting = () => {
    const {
        productListLinks,
        productListLinkObj,
        variantAlert,
        message,
        loading,
        showAlert
    } = useSelector((state) => state.productListLink)
    const {
        featuredProductItems,
        featuredProductItemObj,

    } = useSelector((state) => state.featuredProductItem)
    const dispatch = useDispatch()

    const [currentId, setCurrentId] = useState(null)
    const [show, setShow] = useState(false)
    const [typeModal, setTypeModal] = useState("")
    const [showModalImg, setShowModalImg] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentIdPicture, setCurrentIdPicture] = useState(null)

    useEffect(() => {
        dispatch(getFeaturedProductItems())
    }, [])
    useEffect(() => {
        dispatch(getProductListLink())
    }, [])

    useEffect(() => {
        if (currentId) {
            dispatch(getByIdProductListLink(currentId))
        }
    }, [currentId])

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        setTypeModal("")
        setCurrentId(null)
    }

    const confirmDeleteObj = () => {
        if (currentId) {
            dispatch(deleteProductListLink(currentId))
        }
        setShowDeleteModal(false)
        setCurrentId(null)
    }
    const handleShowModalImg = (pictureId) => {
        setCurrentIdPicture(pictureId)
        setShowModalImg(true)
    }

    return (
        <ContainerSiteSetting
            itemsLength={productListLinks?.length}
            setShow={handleShow}
            title="Списки товаром на сайте "
            href="/siteSetting"
        >
            <ObjRender
                deleteModal
                setShowDeleteModal={setShowDeleteModal}
                getById={getByIdProductListLink}
                loading={loading}
                typeView="list"
                setTypeModal={setTypeModal}
                deleteFunc={deleteProductListLink}
                setCurrentId={setCurrentId}
                mainTitle="Списки"
                obj={productListLinks}
                setShow={setShow}
            />
            <AddProductListLink
                currentIdPicture={currentIdPicture}
                handleShowModalImg={handleShowModalImg}
                productListLinkObj={productListLinkObj}
                show={show}
                featuredProductItems={featuredProductItems}
                featuredProductItemObj={featuredProductItemObj}
                handleClose={handleClose}
                setCurrentId={setCurrentId}
                currentId={currentId}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
                typeModal={typeModal}
                loading={loading}
            />
            <AlertComponent
                dispatchCloseAlert={setCloseAlertProductListLink}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
            <ModalConfirm
                title="Удалить"
                descriptionDelete
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                onClick={confirmDeleteObj}
            />
        </ContainerSiteSetting>
    )
}

export default ProductListLinkSetting