import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAboutUs, signout } from "../../actions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import ModalConfirm from './../BurgerMenu/ModalConfirm';
import { mainItemsHref } from "../../store/localItems";
import UserInfoModal from "../../containers/UserInfoModal/UserInfoModal";


const Header = () => {
  const auth = useSelector((state) => state.auth);
  // const aboutUs = useSelector((state) => state.aboutUs.aboutUs)
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  // const nameCompany = aboutUs[0].nameCompany;

  const handleClose = () => setShowConfirm(false);
  const handleShow = () => setShowConfirm(true);



  useEffect(() => {
    dispatch(getAboutUs())
  }, [])

  const logout = () => {
    dispatch(signout());
  };

  const renderLoggedInLinks = () => {

    return (
      <Nav>
        <div className="nav-itemContainer">
          <li className="nav-item">
            <span className="nav-link" onClick={handleShow}>
              Выйти
            </span>
          </li>
        </div>
      </Nav>
    );
  };

  const renderNonLoggedInLinks = () => {
    return (
      <Nav>
        <div className="nav-itemContainer">
          <li className="nav-item">
            <NavLink to="/signin" className="nav-link">
              Войти
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to="/signup" className="nav-link">
              Регистрация
            </NavLink>
          </li> */}
        </div>
      </Nav>
    );
  };
  const renderBurgerMenu = () => {
    if (auth.authenticate) {
      return <BurgerMenu title={"dscd"} items={mainItemsHref} />
    } else {
      return null
    }
  }
  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="lg"
      // bg="primary"
      variant="dark"
      style={{
        zIndex: 1,
        background: "rgb(52, 53, 65)",
        borderBottom: "1px solid rgb(52, 53, 65)"
      }}
    >
      <ModalConfirm
        title={"Выйти с профиля"}
        show={showConfirm}
        handleClose={handleClose}
        onClick={logout}
      />
      <Container fluid>


        {renderBurgerMenu()}


        <div className="navBarNameContainer">
          <Link to="/" className="navbar-brand">
            ПАНЕЛЬ АДМИНИСТРАТОРА
          </Link>
        </div>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">

          </Nav>
        </Navbar.Collapse> */}
        {auth.authenticate ? renderLoggedInLinks() : renderNonLoggedInLinks()}
      </Container>
      <UserInfoModal />
    </Navbar >
  );
};

export default Header;
