import React from 'react'
import Modal from "../../components/UI/Modal";
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import { cancellationStatus, getApplicationCallMe, getApplicationGallery } from '../../actions';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../components/FormDate';
import DetailsContent from './DetailsContent';
import { useEffect } from 'react';
import { useState } from 'react';
import { setShowUserModal } from '../../reducers/user.reducer';

const RenderDetailsModal = ({
    item,
    user,
    valueTabsSort,
    sortItems,
    setRejectModal,
    rejectModal,
    showDetailsModal,
    handleClose,
    handleDeleteById,
    typeModal,
    setTypeConfirm,
    handleUpdateStatus,
    handleUpdateUserPassword,
    setType,
    role,
    loading
}) => {
    const [rejectedStatus, setRejectedStatus] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (item?.applicationStatus?.length > 0) {
            const hasRejectedStatus = item.applicationStatus.some(
                (status) => status.type === "rejected" && status.isCompleted === true
            );
            setRejectedStatus(hasRejectedStatus);
        }
    }, [item]);

    if (!item) {
        return null;
    }
    const handleChangeOrderStatus = (e) => {
        setType(e.target.value)
    }

    const handleCancellationStatus = (text, type) => {
        const payload = {
            text,
            type
        }
        dispatch(cancellationStatus("applicationGallery", item._id, "applicationStatus", payload))
            .then(() => (
                dispatch(getApplicationGallery(valueTabsSort, sortItems))
            ))

    }
    const onSubmit = () => {
        handleUpdateStatus("")
    }
    const showModalUser = (id) => {
        const payload = {
            userId: id
        }
        dispatch(setShowUserModal(payload))
    }
    return (
        <Modal
            show={showDetailsModal}
            handleClose={handleClose}
            modalTitle={"Детали заявки с галереи"}
            size="lg"
        >
            {typeModal === "settingModal"
                ? <div className="orderModalCenter__orderStatusMainContainer">
                    <div className="orderModalCenter__orderStatusBox">
                        {item.applicationStatus?.map((status) => (
                            <>
                                <div className={`orderModalCenter__orderStatus ${status.isCompleted ? "active" : ""}`}>
                                    {role === "superAdmin" && status.isCompleted && <SettingIconBlock
                                        icon={<CloseIcon />}
                                        tooltip="Отменить"
                                        onClick={() => handleCancellationStatus(status.text, status.type)}
                                        top="10px"
                                    />}
                                    <div className={`time ${status.isCompleted ? "active" : ""
                                        }`}>{formatDate(status.date)}</div >
                                    <span>{status.text}</span>
                                    <div className={`orderModalCenter__line ${status.isCompleted ? "active" : ""}`}>
                                    </div>
                                </div>

                            </>
                        ))}

                    </div>
                    <div className="orderModalCenter__selectBlock">
                        <div>
                            <select onChange={(e) => handleChangeOrderStatus(e)}>
                                <option value={""}>Выбрать статус</option>
                                {item.applicationStatus?.map((status) => {
                                    return (
                                        <>
                                            {!status.isCompleted ? (
                                                <option key={status.type} value={status.type}>
                                                    {status.type} {status.text}
                                                </option>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <Button style={{ marginLeft: "10px" }} onClick={onSubmit}>
                                подтвердить
                            </Button>

                        </div>
                    </div>
                </div>
                : <DetailsContent
                    showModalUser={showModalUser}
                    item={item}
                    loading={loading}
                    setTypeConfirm={setTypeConfirm}
                    rejectedStatus={rejectedStatus}
                    setRejectModal={setRejectModal}
                    rejectModal={rejectModal}
                    handleDeleteById={handleDeleteById}
                    handleUpdateUserPassword={handleUpdateUserPassword}
                />
            }
        </Modal>
    );
};

export default RenderDetailsModal