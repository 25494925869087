import React from 'react'
import SettingIconBlock from './../../components/Button/SettingIconBlock';
import EditIcon from '@mui/icons-material/Edit';

const NumberOrder = ({
    setShowOrderNumberAdditionalModal,
    filterOrderStatusColor,
    orderItem,
    clicked,
    index,

}) => {



    return (
        <>
            <span className="title">
                {`${orderItem.orderNumber}`}
            </span>
            <span
                onClick={e => e.stopPropagation()}
                style={{
                    position: "relative"
                }}>
                {clicked === index && <SettingIconBlock
                    tooltip="Сделать правку"
                    icon={<EditIcon />}
                    top="-2px"
                    right="-25px"
                    onClick={() => setShowOrderNumberAdditionalModal(true)}
                />}
                <span className="order__numberOrderAdditional">
                    {orderItem.orderNumberAdditional ? `_${orderItem.orderNumberAdditional}` : ""}
                </span>
            </span>
        </>

    )
}

export default NumberOrder