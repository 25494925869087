import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import NewModal from './../../components/UI/Modal/index';
import RenderImgSliderModal from './../../components/ProductImgSliderModal/RenderImgSliderModal';
import { generatePublicUrl } from '../../urlConfig';

const DetailsPaymentAccount = ({
    item,
    show,
    setShowDetails
}) => {
    const [showModalImg, setShowModalImg] = useState(false)


    const handleClose = () => {
        setShowDetails(false)
    }
    return (
        <NewModal
            show={show}
            handleClose={handleClose}
            disabledFunctional
            modalTitle={`Подробное описание к счету: ${item._id}`}
            size="lg"
        >
            <Row>
                <Col md="6">
                    <label className="key">Цена</label>
                    <p className="value">{item.price}</p>
                </Col>

            </Row>
            <Row>
                <Col md="12">
                    <label className="key">Дата и время создания</label>
                    <p className="value">
                        {item.createdAt.slice(0, 10)}
                        &nbsp;
                        {item.createdAt.slice(11, 19)}
                    </p>
                </Col>
                <Col md="12">
                    <label className="key">Дата и время последнего обновления</label>
                    <p className="value">
                        {item.updatedAt.slice(0, 10)}
                        &nbsp;
                        {item.updatedAt.slice(11, 19)}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <label className="key">Подробное Описание</label>
                    <p className="value">{item.description}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label className="key">Фотографии</label>
                    <div style={{ display: "flex" }}>
                        {item.itemPictures.map((picture, index) => (
                            <div
                                className="productImgContainer p-1"
                                onClick={() => setShowModalImg(true)}
                                key={index}
                            >
                                <img src={generatePublicUrl(picture.img)} />
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
            <RenderImgSliderModal
                handleClose={handleClose}
                disableRemoveFunctional
                item={item.itemPictures}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            // loading={loading}
            // slug={slug}
            />
        </NewModal>
    )
}

export default DetailsPaymentAccount
