import { elementPaymentPageConstants } from "./constants";
import axios from "../helpers/axios";


export const addElementPaymentPage = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_REQUEST });
            const res = await axios.post('elementPaymentPage/create', form);
            if (res.status === 201) {
                dispatch({ type: elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_SUCCESS, payload: res.data });
            } else {
                dispatch({ type: elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_FAILURE, payload: res.data });
            }
            dispatch(getElementPaymentPage())
        } catch (error) {
            console.log(error);
        }
    };
};
export const updateElementPaymentPage = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_REQUEST });
            const res = await axios.post('elementPaymentPage/update', form);
            if (res.status === 200) {
                dispatch({ type: elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_SUCCESS, payload: res.data });
            } else {
                dispatch({ type: elementPaymentPageConstants.ADD_ELEMENT_PAYMENT_PAGE_FAILURE, payload: res.data });
            }
            dispatch(getElementPaymentPage())
        } catch (error) {
            console.log(error);
        }
    };
};

export const getElementPaymentPage = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_REQUEST });
            const res = await axios.get('elementPaymentPage/get');
            if (res.status === 200) {
                dispatch({
                    type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({ type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_FAILURE });
            }
        } catch (error) {
            console.log(error);
        }
    };
};
export const getElementPaymentPageById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_BY_ID_REQUEST });
            const res = await axios.get(`elementPaymentPage/getById/${id}`);
            if (res.status === 200) {
                dispatch({
                    type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_BY_ID_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({ type: elementPaymentPageConstants.GET_ELEMENT_PAYMENT_PAGE_BY_ID_FAILURE });
            }
        } catch (error) {
            console.log(error);
        }
    };
};