import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import '../src/scss/app.scss'
import Home from './containers/Home';
import Signin from './containers/Signin';
// import Signup from './containers/Signup';
import PrivateRoute from './components/HOC/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { isUserLoggedIn, getInitialData } from './actions';
import Products from './containers/ComponentContainer/Products';
import FeaturedProductItem from './containers/ComponentContainer/FeaturesProductItem/FeaturedProductItem';
import Orders from './containers/Orders';
import Category from './containers/Category';
import NewPage from './containers/NewPage';
import Applications from './containers/Applications/Applications';
import Users from './containers/Users/Users';
import SiteSetting from './siteSetting/SiteSetting';
import HeaderSetting from './siteSetting/containers/HeaderSetting';
import MainImage from './siteSetting/containers/MainImage';
import Contact from './siteSetting/containers/Contact';
import Warranty from './siteSetting/containers/Warranty';
import PaymentLI from './siteSetting/containers/PaymentLI';
import CatalogTitle from './siteSetting/containers/CatalogTitle';
import CostDelivery from './siteSetting/containers/CostDelivery';
import MultipleSelectChip from './siteSetting/containers/Chip';
import Blog from './containers/ComponentContainer/Blog';
import PortfolioPage from './containers/ComponentContainer/PortfolioPage/PortfolioPage';
import DeliveryInfo from './siteSetting/containers/DeliveryInfo';
import PaymentList from './siteSetting/containers/PaymentList';
import AboutUs from './siteSetting/containers/AboutUs/AboutUs';
import Benefits from './siteSetting/containers/Benefits/Benefits';
import Architect from './siteSetting/containers/Architect/Architect';
import ArchitectLi from './siteSetting/containers/ArchitectLi/ArchitectLi';
import ArchitectSkills from './siteSetting/containers/ArchitectSkills/ArchitectSkills';
import Material from './containers/ComponentContainer/Material';
import ColorProduct from './containers/ComponentContainer/ColorProduct';
import PickupPointsSetting from './siteSetting/containers/PickupPointsSetting';
import ContactM from './siteSetting/containers/ContactM';
import Region from './siteSetting/containers/Region/Region';
import Analytics from './containers/Analytics/Analytics';
import SearchQuery from './containers/Analytics/containers/SearchQuery';
import LinkPartnerPage from './containers/LinkPartnerPage';
import PartnerPage from './containers/PartnerPage/PartnerPage';
import PartnerShipApplication from './containers/PartnerPage/PartnerShipApplication';
import ElementPaymentPage from './siteSetting/containers/ElementPaymentPage/ElementPaymentPage';
import MainPageSetting from './siteSetting/containers/MainPage/MainPageSetting';
import LinkContainerPage from './containers/LinkContainerPage';


import { prodArray, applicationArray, materialArray, mailingArray } from './store/localItems';
import ApplicationsCallMe from './containers/ApplicationsCallMe/ApplicationsCallMe';
import GalleryPhoto from './containers/GalleryPhoto/GalleryPhoto';
import ApplicationsChangeProfile from './containers/ApplicationsChangeProfile/ApplicationsChangeProfile';
import ApplicationsGallery from './containers/ApplicationsGallery/ApplicationsGallery';
import ProductListLinkSetting from './siteSetting/containers/ProductListLink/ProductListLinkSetting';
import ProductionMaterialPage from './containers/ComponentContainer/ProductionMaterialPage/ProductionMaterialPage';
import SiteCategorySetting from './siteSetting/containers/SiteCategory/SiteCategorySetting';
import LoaderFullScreen from './components/LoaderLoaderFullScreen';
import MailingPage from './containers/Mailing/MailingPage';
import MailingItemsPage from './containers/Mailing/MailingItemsPage';



function App() {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth)
  const { loadingMainPage } = useSelector(state => state.mainPage)

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
    if (auth.authenticate) {
      dispatch(getInitialData());
    }


  }, [auth.authenticate]);


  const pageComponentsItems = [
    {
      path: "/mailing/lists",
      component: <MailingItemsPage />
    },
    {
      path: "/mailing/create",
      component: <MailingPage />
    },
    {
      path: "/analytics/searchQuery",
      component: <SearchQuery />
    },
    {
      path: "/",
      component: <Home />
    },
    {
      path: "/page",
      component: <NewPage />
    },
    {
      path: "/materials",
      component: <Material />
    },
    {
      path: "/productionMaterial",
      component: <ProductionMaterialPage />
    },
    {
      path: "/category",
      component: <Category />
    },
    {
      path: "/products/:slug",
      component: <Products />
    },
    {
      path: "/portfolio",
      component: <PortfolioPage />
    },
    {
      path: "/orders",
      component: <Orders />
    },
    {
      path: "/applications",
      component: <Applications />
    },
    {
      path: "/featuresProductItem",
      component: <FeaturedProductItem />
    },
    {
      path: "/applicationsGallery",
      component: <ApplicationsGallery />
    },
    {
      path: "/applicationsCallMe",
      component: <ApplicationsCallMe />
    },
    {
      path: "/applicationsChangeProfile",
      component: <ApplicationsChangeProfile />
    },
    {
      path: "/mainPageSetting",
      component: <MainPageSetting />
    },
    {
      path: "/productListLink",
      component: <ProductListLinkSetting />
    },
    // {
    //   path: "/siteCategory",
    //   component: <SiteCategorySetting />
    // },
    {
      path: "/analytics",
      component: <Analytics />
    },
    {
      path: "/users",
      component: <Users />
    },
    {
      path: "/siteSetting",
      component: <SiteSetting />
    },
    {
      path: "/headerSetting",
      component: <HeaderSetting />
    },
    {
      path: "/blog",
      component: <Blog />
    },
    {
      path: "/test",
      component: <MultipleSelectChip />
    },
    {
      path: "/partner/application",
      component: <PartnerShipApplication />
    },
    {
      path: "/galleryPhotoPage",
      component: <GalleryPhoto />
    },
    {
      path: "/mailing",
      component: <LinkContainerPage
        itemsArray={mailingArray}
      />
    },
    {
      path: "/containerProductPage",
      component: <LinkContainerPage
        itemsArray={prodArray}
      />
    },
    {
      path: "/containerMaterialPage",
      component: <LinkContainerPage
        itemsArray={materialArray}
      />
    },
    {
      path: "/applicationPage",
      component: <LinkContainerPage
        itemsArray={applicationArray}
      />
    },
    {
      path: "/partner/all",
      component: <PartnerPage />
    },
    {
      path: "/containerPartnerPage",
      component: <LinkPartnerPage />
    },
    {
      path: "/costDelivery",
      component: <CostDelivery />
    },
    {
      path: "/catalog-title",
      component: <CatalogTitle />
    },

    {
      path: "/orders",
      component: <Orders />
    },
    {
      path: "/contactsSetting",
      component: <Contact />
    },
    {
      path: "/contactsMSetting",
      component: <ContactM />
    },
    {
      path: "/regionSetting",
      component: <Region />
    },
    {
      path: "/mainImageSetting",
      component: <MainImage />
    },
    {
      path: "/paymentLI",
      component: <PaymentLI />
    },
    {
      path: "/warranty",
      component: <Warranty />
    },
    {
      path: "/pickupPointsSetting",
      component: <PickupPointsSetting />
    },
    {
      path: "/deliveryInfo",
      component: <DeliveryInfo />
    },
    {
      path: "/paymentList",
      component: <PaymentList />
    },
    {
      path: "/aboutUsSetting",
      component: <AboutUs />
    },
    {
      path: "/benefitsSetting",
      component: <Benefits />
    },
    {
      path: "/architectSetting",
      component: <Architect />
    },
    {
      path: "/architectLiSetting",
      component: <ArchitectLi />
    },
    {
      path: "/architectSkillsSetting",
      component: <ArchitectSkills />
    },
    {
      path: "/elementPaymentPageSetting",
      component: <ElementPaymentPage />
    },
    {
      path: "/colorsProduct",
      component: <ColorProduct />
    },
  ]
  return (
    <div className="App">
      <Routes>
        {loadingMainPage
          ? <LoaderFullScreen />
          : pageComponentsItems.map((obj, index) => (
            <Route
              key={index}
              path={obj.path}
              element={<PrivateRoute>{obj.component}</PrivateRoute>}
            />

          ))
        }
        {/* <PrivateRoute path="/tombstoneCurb" component={TombstoneCurb} /> */}
        {/* <PrivateRoute path="/graniteTiles" component={GraniteTiles} /> */}
        {/* <PrivateRoute path="/ceramics" component={Ceramics} /> */}
        {/* <PrivateRoute path="/cabinet" component={Cabinet} />

        <PrivateRoute path="/promotionsSetting" component={Promotions} />

        <PrivateRoute path="/manufactureSetting" component={Manufacture} />

        <PrivateRoute path="/comeToUsSetting" component={ComeToUs} />
        <PrivateRoute path="/weWorkOnline" component={WeWorkOnline} />
        <PrivateRoute path="/chooseMemorialPhotos" component={ChooseMemorialPhotos} />
        <PrivateRoute path="/engraving" component={Engraving} />
        <PrivateRoute path="/granite-material" component={GraniteMaterial} />
        <PrivateRoute path="/paymentLI" component={PaymentLI} />
        <PrivateRoute path="/paymentAndDelivery" component={PaymentAndDeliveryContainer} />
        {/* 
        <PrivateRoute path="/engravingLayout/:slug" component={EngravingLayout} />
        <PrivateRoute path="/engravingCategory" component={ContainerEngravingCategoryPage} /> */}

        <Route path="/signin" element={<Signin />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
      </Routes>
    </div>
  );
}

export default App;
