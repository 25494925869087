import React, { useState, useEffect } from 'react'
import {
    updateFeaturedProductItemVisibleById,
    deleteFeaturedProductItemById,
    getFeaturedProductItemById,
    getFeaturedProductItems,
    removeProductByIds,
} from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';
import ModalFeaturedProductItem from './ModalFeaturedProductItem';
import { MainContainer } from '../../MainContainer';
import ObjRender from '../../render/ObjRender';
import AlertComponent from '../../../components/Alert/Alert';
import { setCloseAlertFeaturedProductItem } from '../../../reducers/featuredProductItem';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import ModalConfirm from '../../../components/BurgerMenu/ModalConfirm';

const FeaturedProductItem = () => {
    const {
        featuredProductItems,
        featuredProductItemObj,
        variantAlert,
        showAlert,
        message,
        loading
    } = useSelector((state) => state.featuredProductItem)

    const [show, setShow] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [typeModal, setTypeModal] = useState("");
    const [currentIdPicture, setCurrentIdPicture] = useState(null);
    const [showModalImg, setShowModalImg] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleShow = () => setShow(true)

    const handleClose = () => {
        setTypeModal("")
        setShow(false)
    }
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFeaturedProductItems())
    }, [])

    const updateVisible = () => {
        dispatch(updateFeaturedProductItemVisibleById(currentId))
    }


    const handleShowModalImg = (pictureId) => {
        setCurrentIdPicture(pictureId)
        setShowModalImg(true)
    }
    const confirmDeleteObj = () => {
        if (currentId) {
            dispatch(deleteFeaturedProductItemById(currentId))
        }
        setShowDeleteModal(false)
        setCurrentId(null)
    }
    return (
        <>
            <MainContainer
                type="add"
                backButtonProduct
                title="Товары по спискам"
                get={getFeaturedProductItems}
                handleShow={handleShow}
                buttonText="Создать новый список"
            >
                <ObjRender
                    deleteModal
                    setShowDeleteModal={setShowDeleteModal}
                    getById={getFeaturedProductItemById}
                    loading={loading}
                    typeView="list"
                    setTypeModal={setTypeModal}
                    deleteFunc={deleteFeaturedProductItemById}
                    setCurrentId={setCurrentId}
                    mainTitle="Списки"
                    obj={featuredProductItems}
                    setShow={setShow}
                />
                <ModalFeaturedProductItem
                    updateVisible={updateVisible}
                    handleShowModalImg={handleShowModalImg}
                    setTypeModal={setTypeModal}
                    featuredProductItemObj={featuredProductItemObj}
                    typeModal={typeModal}
                    handleClose={handleClose}
                    show={show}
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                />
            </MainContainer>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertFeaturedProductItem}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
            <RenderImgSliderModal
                deleteAction={removeProductByIds}
                typeClose="product"
                item={featuredProductItemObj?.products}
                currentIdPicture={currentIdPicture}
                setShowModalImg={setShowModalImg}
                productDetails={featuredProductItemObj}
                setTypeModal={setTypeModal}
                showModalImg={showModalImg}
                handleClose={handleClose}
                typeModal={typeModal}
                loading={loading}
            />
            <ModalConfirm
                title="Удалить"
                descriptionDelete
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                onClick={confirmDeleteObj}
            />
        </>
    )
}

export default FeaturedProductItem