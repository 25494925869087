import React from 'react';
import Modal from '../../components/UI/Modal';

const RenderDeleteCategoryModal = ({
    show,
    deleteCategories,
    handleClose,
    expandedArray,
    checkedArray
}) => {
    return (
        <Modal
            modalTitle="Форма Для удаления"
            show={show}
            handleClose={handleClose}
            buttons={[
                {
                    label: 'Закрыть',
                    color: 'primary',
                    onClick: handleClose
                },
                {
                    label: 'Удалить',
                    color: 'danger',
                    onClick: deleteCategories
                }
            ]}
        >

            <div style={{ marginBottom: "10px" }}>
                <h5 style={{ color: "Silver" }}>Основные Категории</h5>
                {expandedArray.map((item, index) => <span key={index}>{item.name}</span>)}

            </div>
            <div>
                <h5 style={{ color: "Silver" }}>Подкатегории</h5>
                {checkedArray.map((item, index) => <span key={index}>{item.name}</span>)}
            </div>
        </Modal>
    )
}

export default RenderDeleteCategoryModal