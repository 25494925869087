import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import { generatePublicUrl } from '../../../urlConfig';
import { getGalleryById } from '../../../actions';
import { useDispatch } from 'react-redux';
import { Button, Link } from '@mui/material';

const RenderDetails = ({
    mainPageObj,
    setShowModalImg,
    showModalImg,
    typeModal,
    galleryObj,

}) => {
    const dispatch = useDispatch();

    let limit = true;

    useEffect(() => {
        if (mainPageObj?.galleryId && typeModal === "renderDetails") {
            const test = dispatch(getGalleryById(mainPageObj?.galleryId, limit))
        }
    }, [mainPageObj])

    if (!mainPageObj) return null
    if (!galleryObj) return null

    return (
        <>
            <Row>
                <Col md="6">
                    <label className="key">Заголовок</label>
                    <p className="value">{mainPageObj.title}</p>
                </Col>
            </Row>
            <Row>

                <Col md="12">
                    <label className="key">Описание</label>
                    <p className="value">{mainPageObj.description}</p>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label className="key">Дата добавления</label>
                    <p className="value">{mainPageObj.createdAt}</p>
                </Col>
                <Col md="6">
                    <label className="key">Последнее обновление</label>
                    <p className="value">{mainPageObj.updatedAt}</p>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <label className="key">Какая галлерея привязано к этой странице</label>
                    <p
                        className={`value ${mainPageObj.galleryId ? "" : "empty"}`} >
                        {mainPageObj.galleryId
                            ? galleryObj.name
                            : "Нет привязанной галереи"}
                    </p>
                    {
                        mainPageObj.galleryId
                            ?
                            <div className="imgRowContainer">
                                {galleryObj?.galleryPictures?.length > 0
                                    ? galleryObj.galleryPictures.slice(0, 3).map((picture) =>
                                        <img
                                            src={generatePublicUrl(picture.img)}
                                            alt={picture._id}
                                        />
                                    )
                                    : null
                                }
                            </div>
                            : null}
                    <Link href="/galleryPhotoPage"
                        underline="hover"
                        style={{
                            margin: "10px auto",
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        Страница с галереями
                    </Link>
                </Col>

            </Row>
            <Row>
                <Col>
                    <label className="key">Фотографии</label>
                    <div style={{ display: "flex" }}>
                        {mainPageObj.itemPictures?.length
                            ? mainPageObj.itemPictures.map((picture) => (
                                <div
                                    onClick={() => setShowModalImg(true)}
                                    className="productImgContainer m-1">
                                    <img src={generatePublicUrl(picture.img)} />
                                </div>
                            ))
                            : <p className="value">Фото нет</p>
                        }
                    </div>
                </Col>
            </Row>
            <RenderImgSliderModal
                item={mainPageObj.itemPictures}
                setShowModalImg={setShowModalImg}
                showModalImg={showModalImg}
            />
        </>
    )
}

export default RenderDetails





