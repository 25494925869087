import React from 'react'
import Input from '../../../components/UI/Input/index';
import SelectMultiple from '../SelectMultiple';
import RenderProductList from './RenderProductList';

const AddModal = ({
    formFeaturedProduct,
    currentId,
    setFormFeaturedProduct,
    products,
    productIds,
    setSelectedProduct,
    setProductIds,
    selectedProduct,
    setItemPictures,
    featuredProductItemObj
}) => {

    const handleItemPictures = (e) => {
        setItemPictures(e.target.files);
    };

    return <>
        <Input
            label="Название"
            value={formFeaturedProduct.name}
            placeholder={"Название"}
            onChange={(e) => setFormFeaturedProduct({ ...formFeaturedProduct, name: e.target.value })}
        />
        <Input
            label="Тип"
            value={formFeaturedProduct.type}
            placeholder="Тип"
            onChange={(e) => setFormFeaturedProduct({ ...formFeaturedProduct, type: e.target.value })}
        />
        <Input
            label="Описание"
            value={formFeaturedProduct.description}
            placeholder={"Описание"}
            onChange={(e) => setFormFeaturedProduct({ ...formFeaturedProduct, description: e.target.value })}
        />
        {!currentId
            ? <SelectMultiple
                items={products}
                productIds={productIds}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                setProductIds={setProductIds}
                topImg="35%"
                rightImg="15%"
            />
            : null
        }
        {!currentId
            ? <input
                type="file"
                name="itemPictures"
                onChange={(e) => handleItemPictures(e)}
                required
                multiple
            />
            : null
        }
    </>
}

export default AddModal