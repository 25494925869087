import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationCallMe, searchApplicationCallMe, deleteApplicationCallMeById, updateApplicationCallMeStatus, deleteManyApplicationCallMe } from '../../actions';
import { MainContainer } from '../MainContainer';
import { getItemById } from '../../actions/mainActions/mainAction';
import RenderDetailsModalCallMe from './RenderDetailsModalCallMe';
import AlertComponent from '../../components/Alert/Alert';
import { setCloseAlertApplication } from '../../reducers/application.reducer';
import { RenderItems } from './RenderItems';

const ApplicationsCallMe = () => {
    const {
        applicationsCallMe, applicationCallMeObj, showAlert, variantAlert, message, loading
    } = useSelector(state => state.applicationCallMe);
    const { user } = useSelector(state => state.auth);

    const [showDetailsModal, setApplicationDetailModal] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [type, setType] = useState("")

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getApplicationCallMe())
    }, [])

    const showModal = (id) => {
        if (selectedItemId === id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(id);
            dispatch(getItemById("applicationCallMe", id));
        }

        setApplicationDetailModal(true);
    };
    const handleClose = () => {
        setApplicationDetailModal(false);
        setTypeModal("")
    };
    const handleDeleteById = () => {
        dispatch(deleteApplicationCallMeById(applicationCallMeObj._id))
        setApplicationDetailModal(false)
    }
    const handleUpdateStatus = (id) => {
        const payload = {
            applicationCallMeId: id,
            type
        }
        dispatch(updateApplicationCallMeStatus(payload)).then(() => (
            dispatch(getApplicationCallMe())
        ))
    }

    return (
        <MainContainer
            backButtonProduct
            type
            backHref
            search={searchApplicationCallMe}
            get={getApplicationCallMe}
            title={"Запрос обратного звонка"}
        >
            <Row style={{ marginTop: "15px" }}>
                <Col>
                    <RenderItems
                        applicationsCallMe={applicationsCallMe}
                        setTypeModal={setTypeModal}
                        showModal={showModal}
                        deleteDispatch={deleteManyApplicationCallMe}
                    />
                </Col>
            </Row>
            <RenderDetailsModalCallMe
                handleUpdateStatus={handleUpdateStatus}
                role={user.role}
                setType={setType}
                handleClose={handleClose}
                typeModal={typeModal}
                item={applicationCallMeObj}
                handleDeleteById={handleDeleteById}
                showDetailsModal={showDetailsModal}
            />
            <AlertComponent
                dispatchCloseAlert={setCloseAlertApplication}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </MainContainer>
    )
}

export default ApplicationsCallMe
