import React, { useState, useEffect } from 'react'
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addContactM, updateContactM, } from '../../actions';
import RenderImgSliderModal from './../../components/ProductImgSliderModal/RenderImgSliderModal';
import BRenderAddPictures from './../../components/Button/BRenderAddPictures';
import { Row, Col } from 'react-bootstrap';
import { generatePublicUrl } from '../../urlConfig';

const AddContactM = ({
    show,
    handleClose,
    setCurrentId,
    currentId,
    itemObj,
    typeModal,
    showModalImg,
    setShowModalImg
}) => {

    const dispatch = useDispatch()
    const contactM = useSelector((state) => currentId
        ? state.contactM.contactM.find((c) => c._id === currentId)
        : null)

    const [itemPictures, setItemPictures] = useState("");
    const [formContact, setFormContact] = useState({
        name: "",
        href: "",
        description: "",
        href: "",
        number: "",
    })

    useEffect(() => {
        if (contactM) setFormContact(contactM)
    }, [contactM])

    const submitContactForm = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updateContactM(currentId, formContact))
            handleClose()
            clear()
        } else {
            const form = new FormData();
            form.append("description", formContact.description);
            form.append("number", formContact.number);
            form.append("name", formContact.name);
            form.append("href", formContact.href);
            for (let i = 0; i < itemPictures.length; i++) {
                form.append("itemPictures", itemPictures[i]);
            }
            dispatch(addContactM(form)).then(() => handleClose())
        }
    }

    const clear = () => {
        setCurrentId(null)
        setFormContact({
            name: "",
            href: "",
            description: "",
            href: "",
            number: "",
        })
        setItemPictures("")
    }
    const renderItem = () => {
        let content;
        switch (typeModal) {
            case "renderDetails":
                return content = <>
                    <Row>
                        <Col md="6">
                            <label className="key">Заголовок</label>
                            <p className="value">{itemObj.mainTitle}</p>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <label className="key">Описание заголовка</label>
                            <p className="value">{itemObj.mainDescription}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <label className="key">Дата добавления</label>
                            <p className="value">{itemObj.createdAt}</p>
                        </Col>
                        <Col md="6">
                            <label className="key">Послденее обновление</label>
                            <p className="value">{itemObj.updatedAt}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <label className="key">Подробное Описание</label>
                            <p className="value">{itemObj.itemsInfo.map((itemInfo) =>
                                <>
                                    <div>
                                        {itemInfo.title}
                                    </div>
                                    <div>
                                        {itemInfo.itemsDescription.map((itemDesc, index) =>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                border: "1px solid gray",
                                                padding: "10px",
                                                marginBottom: "10px"
                                            }}>
                                                <div style={{
                                                    width: "30px"
                                                }}>
                                                    {index + 1}
                                                </div>
                                                <div>
                                                    {itemDesc.text}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="key">Фотографии</label>
                            <div style={{ display: "flex" }}>
                                {itemObj.pictures.length
                                    ? itemObj.pictures.map((picture) => (
                                        <div
                                            onClick={() => setShowModalImg(true)}
                                            className="productImgContainer m-1">
                                            <img src={generatePublicUrl(picture.img)} />
                                        </div>
                                    ))
                                    : <p className="value">Фото нет</p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <RenderImgSliderModal
                        item={itemObj.pictures}
                        setShowModalImg={setShowModalImg}
                        showModalImg={showModalImg}
                    />
                </>
            default:
                return <>
                    <Input
                        label="Название"
                        value={formContact.name}
                        placeholder={"Название"}
                        onChange={(e) => setFormContact({ ...formContact, name: e.target.value })}
                    />
                    <textarea
                        style={{
                            width: "100%",
                            minHeight: "200px"
                        }}
                        label="Описание"
                        value={formContact.description}
                        placeholder={"Описание"}
                        onChange={(e) => setFormContact({ ...formContact, description: e.target.value })}
                    />
                    <Input
                        label="Ссылка"
                        value={formContact.href}
                        placeholder={"Добавить ссылку"}
                        onChange={(e) => setFormContact({ ...formContact, href: e.target.value })}
                    />
                    <Input
                        label="Номер телефона"
                        value={formContact.number}
                        placeholder={"Введите основной номер телефона"}
                        onChange={(e) => setFormContact({ ...formContact, number: e.target.value })}
                    />
                    {!currentId ?
                        <BRenderAddPictures
                            setPictures={setItemPictures}
                        /> : null
                    }
                </>
        }
    }


    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle="Добавить соцсеть"
            onSubmit={submitContactForm}
        >
            {renderItem()}
        </Modal>

    )
}


export default AddContactM