import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getAllCategory } from '../actions';
import { NavLink, useNavigate } from 'react-router-dom';
import { getCategoryDetailsById } from './../actions/category.action';
import { setCategoryDetails } from '../reducers/category.reducer';
import CategoryList from './../containers/Category/CategoryList';

const CategoryContainer = ({
    setShowActiveCategory,
    showAllProductsButton,
    setCategoryItem,
    variantRender,
    setPageNumber,
    categoryItem,
    slug,
    checked,

}) => {

    const category = useSelector((state) => state.category?.categories)
    const { visibleHiddenCategories } = useSelector((state) => state.category)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getAllCategory())
    }, [visibleHiddenCategories])

    useEffect(() => {
        const data = window.localStorage.getItem('TEST')
        setCategoryItem(JSON.parse(data))
    }, [])

    if (!category) {
        return null
    }

    const editCategoryIndex = (category) => {
        setShowActiveCategory(category._id)
        setPageNumber(0)
    }
    const editChildrenCategoryIndex = (category) => {
        setCategoryItem(category)
        dispatch(setCategoryDetails(category))
        dispatch(getCategoryDetailsById(category))
        navigate(`/products/${category.slug}`)

    }
    const renderFilter = (categories) => {
        return categories.map(category => (
            <div
                key={category.name}
                className={`categoryContainer__mainContainer `}
                onClick={() => editCategoryIndex(category)}
            >
                {category.parentId && (
                    <div
                        className={`categoryContainer__item ${slug === category.slug && "active"}`}
                        onClick={() => editChildrenCategoryIndex(category)}
                    >
                        {category.name}
                    </div>
                )}
                {category.children.length > 0 && (
                    <div className="categoryContainer__itemContainer">
                        {renderFilter(category.children)}
                    </div>
                )}
            </div>
        ));
    }

    const renderVariant = () => {
        if (variantRender === "productCategory") {
            return category.length > 0 ? renderFilter(category) : null
        }
        if (variantRender === "allCategory") {
            return <CategoryList
                editChildrenCategoryIndex={editChildrenCategoryIndex}
                category={category}
                categoryItem={categoryItem}
                setCategoryItem={setCategoryItem}

            />
        }
    }
    return (
        <div className="categoryContainer">

            <div className="categoryContainer__linkContainer">
                <div onClick={showAllProductsButton} >
                    <NavLink to="/products/all">#Все</NavLink>
                </div>
                {renderVariant()}
            </div>

        </div>
    )
}

export default CategoryContainer