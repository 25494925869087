import { Button } from '@mui/material'
import React from 'react'
import ContainerDetailsContent from './ContainerDetailsContent'
import { useDispatch } from 'react-redux'
import { addStandardSubscriptions, adminToggleSubscriptionCompletion } from '../../actions'
import { generatePublicUrl } from '../../urlConfig'
import EmptyMessage from './EmptyMessage'

const SubscribersContent = ({
    setTypeModal,
    userDetails
}) => {

    const dispatch = useDispatch()

    const addSubUser = () => {
        dispatch(addStandardSubscriptions(userDetails._id))
    }
    const toggleSubscription = (subscriptionId) => {
        const userId = userDetails._id
        dispatch(adminToggleSubscriptionCompletion(subscriptionId, userId))
    }

    return (
        <ContainerDetailsContent
            setTypeModal={setTypeModal}
            noneButtons
        >
            {userDetails.subscriptions && userDetails.subscriptions.length > 0
                ? <div className="subscribersContent">
                    <div className="subscribersContent__row">
                        {userDetails.subscriptions.map((item) =>
                            <div className={`subscribersContent__item ${item.isCompleted ? "success" : "error"}`}
                                key={item._id}
                            >
                                <div className="subscribersContent__imgBox">
                                    <img
                                        src={generatePublicUrl(item.mainPage.itemPictures[0].img)}
                                        alt={item.mainPage.title}
                                    />
                                </div>
                                <div className="subscribersContent__contentBox">
                                    <div className="subscribersContent__title">
                                        {item.mainPage.title}
                                    </div>
                                    <div className="subscribersContent__date">
                                        <span>Время:</span> {`${item.date.slice(0, 10) + " " + item.date.slice(11, 19)}`}
                                    </div>
                                    <Button
                                        variant='contained'
                                        color={item.isCompleted ? "error" : "success"}
                                        onClick={() => toggleSubscription(item._id)}
                                    >

                                        {item.isCompleted ? "отписать" : "подписать"}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                : <div>
                    <EmptyMessage
                        message="У пользователя отсутсвуют подписки!"
                    />
                    <Button
                        variant='contained'
                        onClick={addSubUser}
                    >

                        Создать подписки по умолчанию
                    </Button>
                </div>
            }



        </ContainerDetailsContent>
    )
}

export default SubscribersContent