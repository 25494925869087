import React, { useState } from 'react'
import SettingIconBlock from '../../../components/Button/SettingIconBlock';
import VariantBlock from './VariantBlock';

const TotalAmount = ({
    productDetails,

}) => {
    const [showTotalAmountDetails, setShowTotalAmountDetails] = useState(false);

    // const
    const copyToClipboard = () => {
        setShowTotalAmountDetails(!showTotalAmountDetails)
    }
    const buttonArray = [
        {
            text: "Обнулить",
            onClickButton: ""

        },
        {
            text: "Пересчет ",
            onClickButton: ""

        },

    ]
    return (
        <div className="productionsMaterial__viewAdded__totalAmount">
            <div className="productionsMaterial__viewAdded__totalAmount__text">

                Общая стоимость материалов:
                <div className="productionsMaterial__viewAdded__totalAmount__content">
                    {productDetails.productMaterialAmount}
                </div>
                <SettingIconBlock
                    // icon={<ContentCopyIcon />}
                    tooltip="Настройки"
                    onClick={copyToClipboard}
                    right="10px"
                    top="5px"
                />
            </div>
            {showTotalAmountDetails
                ? <div className="productionsMaterial__viewAdded__totalAmount__detailsAmount">
                    <VariantBlock
                        items={buttonArray}
                    />
                </div>
                : null
            }
        </div>
    )
}

export default TotalAmount