import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../actions';
import Loader from './../../components/Loader';
import { searchUser } from './../../actions/user.actions';
import { MainContainer } from '../MainContainer';
import RenderUsers from './RenderUsers';
import RenderModalContent from './RenderModalContent';


const Users = () => {
    const [userDetailModal, setUserDetailModal] = useState(false);
    // const [userDetails, setUserDetails] = useState(null);
    const [typeModal, setTypeModal] = useState("");
    const [currentId, setCurrentId] = useState(null);
    // const [sort, setSort] = useState("createdAt");
    const {
        users,
        userDetails,

    } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const handleCloseUserDetailsModal = () => {
        setUserDetailModal(false);
        setCurrentId(null)
        setTypeModal("");
    };

    const showUserDetailsModal = (userId, typeM) => {
        setTypeModal(typeM);
        setCurrentId(userId);
        setUserDetailModal(true);
    };

    return (
        <MainContainer
            typeSort="user"
            search={searchUser}
            type
            get={getUsers}
            title={"Зарегистрированные пользователи"}
        >
            <RenderUsers
                users={users}
                showUserDetailsModal={showUserDetailsModal}
            />
            <RenderModalContent
                typeModal={typeModal}
                currentId={currentId}
                userDetails={userDetails}
                setTypeModal={setTypeModal}
                userDetailModal={userDetailModal}
                handleCloseUserDetailsModal={handleCloseUserDetailsModal}
            />
        </MainContainer>
    )
}

export default Users
