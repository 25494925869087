import axios from "../helpers/axios";
import { deletedOrder } from "./api/order";
import { orderConstants } from "./constants";


export const getCustomerOrders = (orderNumber, sortStatusOrder, valueSort) => {
  console.log("orderNumber", orderNumber)
  console.log("valueSort", valueSort)

  return async (dispatch) => {
    dispatch({ type: orderConstants.GET_CUSTOMER_ORDER_REQUEST });
    try {
      const res = await axios.get(
        orderNumber ? `/order/${orderNumber}` : "/order/getCustomerOrders",
        {
          params: {
            sortStatusOrder,
            valueSort
          },
        }
      );
      if (res.status === 200) {
        const { orders } = res.data;
        dispatch({
          type: orderConstants.GET_CUSTOMER_ORDER_SUCCESS,
          payload: { orders },
        });
      } else {
        const { error } = res.data;
        dispatch({
          type: orderConstants.GET_CUSTOMER_ORDER_FAILURE,
          payload: { error },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const updateOrderPackageTracking = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_PACKAGE_TRACKING_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderPackageTracking", payload);
      if (res.status === 200) {
        dispatch({ type: orderConstants.UPDATE_ORDER_PACKAGE_TRACKING_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_PACKAGE_TRACKING_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateOrderDeliveryPickupPointsPayment = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_DELIVERY_ID_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderDeliveryPickupPointsPayment", payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_ORDER_DELIVERY_ID_SUCCESS, payload: res.data });
        dispatch(getCustomerOrders());
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_DELIVERY_ID_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateOrder = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_CUSTOMER_ORDER_REQUEST });
    try {
      const res = await axios.post("/order/update", payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_CUSTOMER_ORDER_SUCCESS, payload: res.data });
        dispatch(getCustomerOrders());
      } else {
        const { error } = res.data;
        dispatch({
          type: orderConstants.UPDATE_CUSTOMER_ORDER_FAILURE,
          payload: { error },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const cancellationOrderStatus = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_CANCELLATION_ORDER_STATUS_REQUEST });
    try {
      const res = await axios.post("/order/cancellationOrderStatus", payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_CANCELLATION_ORDER_STATUS_SUCCESS, payload: res.data });
        dispatch(getCustomerOrders());
      } else {
        dispatch({
          type: orderConstants.UPDATE_CANCELLATION_ORDER_STATUS_FAILURE,
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const updateOrderAdditionalById = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_ADDITIONAL_BY_ID_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderAdditionalById", payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_ORDER_ADDITIONAL_BY_ID_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_ADDITIONAL_BY_ID_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};
export const addOrderProductsById = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.ADD_ORDER_PRODUCTS_BU_ID_REQUEST });
    try {
      const res = await axios.post("/order/addOrderProductsById", payload);
      if (res.status === 202) {
        dispatch({ type: orderConstants.ADD_ORDER_PRODUCTS_BU_ID_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.ADD_ORDER_PRODUCTS_BU_ID_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateOrderAdditionalField = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_ADDITIONAL_FIELD_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderAdditionalField", payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_ORDER_ADDITIONAL_FIELD_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_ADDITIONAL_FIELD_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateOrderVisibleByIdAdmin = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_VISIBLE_ADMIN_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderVisibleAdmin", payload);
      if (res.status === 200) {
        dispatch({ type: orderConstants.UPDATE_ORDER_VISIBLE_ADMIN_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_VISIBLE_ADMIN_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateOrderDiscountedTotalAmount = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderDiscountedTotalAmount", payload);
      if (res.status === 200) {
        dispatch({ type: orderConstants.UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_DISCOUNTED_TOTAL_AMOUNT_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateOrderVisibleDiscountedTotal = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderVisibleDiscountedTotal", payload);
      if (res.status === 200) {
        dispatch({ type: orderConstants.UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateOrderTotalAmount = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.UPDATE_ORDER_TOTAL_AMOUNT_REQUEST });
    try {
      const res = await axios.post("/order/updateOrderTotalAmount", payload);
      if (res.status === 201) {
        dispatch({ type: orderConstants.UPDATE_ORDER_TOTAL_AMOUNT_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.UPDATE_ORDER_TOTAL_AMOUNT_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};


export const deleteOrderAdditionalById = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.DELETE_ORDER_ADDITIONAL_BY_ID_REQUEST });
    try {
      const res = await axios.post("/order/deleteOrderAdditionalById", payload);
      if (res.status === 202) {
        dispatch({ type: orderConstants.DELETE_ORDER_ADDITIONAL_BY_ID_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.DELETE_ORDER_ADDITIONAL_BY_ID_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};


export const getOrderById = (orderId) => {
  return async dispatch => {
    dispatch({ type: orderConstants.GET_ORDER_BY_ID_REQUEST });
    try {
      const res = await axios.get(`/order/getOrderById/${orderId}`);
      if (res.status === 200) {
        dispatch({
          type: orderConstants.GET_ORDER_BY_ID_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: orderConstants.GET_ORDER_BY_ID_FAILURE,
          payload: res.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const deleteOrderProductById = (payload) => {
  return async (dispatch) => {
    dispatch({ type: orderConstants.DELETE_ORDER_PRODUCT_BY_ID_REQUEST });
    try {
      const res = await axios.post("/order/deleteOrderProduct", payload);
      if (res.status === 202) {
        dispatch({ type: orderConstants.DELETE_ORDER_PRODUCT_BY_ID_SUCCESS, payload: res.data, });
      } else {
        dispatch({
          type: orderConstants.DELETE_ORDER_PRODUCT_BY_ID_FAILURE,
          payload: res.data,
        });
      }
      dispatch(getCustomerOrders());
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteOrderById = (id) => async (dispatch) => {
  dispatch({ type: orderConstants.DELETE_ORDER_BY_ID_REQUEST });
  try {
    const { data } = await deletedOrder(id);
    if (data) {
      dispatch({
        type: orderConstants.DELETE_ORDER_BY_ID_SUCCESS,
        payload: { id, data }
      });
      dispatch(getCustomerOrders())

    } else {
      dispatch({
        type: orderConstants.DELETE_ORDER_BY_ID_FAILURE,
        payload: data
      });
    }
  } catch (error) {
    console.log(error);
  }
}
