import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { MainContainer } from '../MainContainer';
import { getAllGalleries, addGallery, removeGalleryPicturesByIds } from '../../actions';
import RenderGallery from './RenderGallery';
import AddGalleryModal from './AddGalleryModal';
import { Button } from '@mui/material';
import RenderImgSliderModal from '../../components/ProductImgSliderModal/RenderImgSliderModal';
import AlertComponent from '../../components/Alert/Alert';
import { setCloseAlertGallery } from '../../reducers/galleryImage';

const GalleryPhoto = (props) => {
    const {
        galleries,
        galleryObj,
        loading,
        variantAlert,
        showAlert,
        message
    } = useSelector(state => state.gallery)
    const [currentId, setCurrentId] = useState("");
    const [typeModal, setTypeModal] = useState("")
    const [name, setName] = useState("");
    const [show, setShow] = useState(false);
    const [galleryPictures, setGalleryPictures] = useState([]);
    const [valueTabsSort, setValueTabsSort] = useState("")
    const [showModalImg, setShowModalImg] = useState(false);
    const [currentIdPicture, setCurrentIdPicture] = useState(null)

    const dispatch = useDispatch()
    //добавить просмотры каждой позиции, и подсчет переходов на страницу
    let limit = true;
    useEffect(() => {
        dispatch(getAllGalleries("all", limit))
    }, [])


    const submitGalleryForm = (e) => {
        const form = new FormData();
        form.append("name", name);
        for (let i = 0; i < galleryPictures.length; i++) {
            form.append('files', galleryPictures[i]);
        }
        dispatch(addGallery(form)).then(handleClose)
    }
    const clear = () => {
        setName("")
    }
    const handleClose = () => {
        setShow(false)
        clear()
        setGalleryPictures([])
        setTypeModal("")
    }
    const handleShowModalImg = (pictureId) => {
        setCurrentIdPicture(pictureId)
        setShowModalImg(true)
    }
    return (
        <MainContainer
            backButtonProduct
            setValueTabsSort={setValueTabsSort}
            itemsType="main"
            tabs
            type
            backHref
            href="/"
            get={getAllGalleries("all", limit)}
            title="Наши галлереи с фото"
        >
            <Button
                variant='contained'
                onClick={() => setShow(true)}
            >
                Создать Галерею
            </Button>
            <RenderGallery
                setValueTabsSort={setValueTabsSort}
                setCurrentIdPicture={setCurrentIdPicture}
                handleShowModalImg={handleShowModalImg}
                setTypeModal={setTypeModal}
                setCurrentId={setCurrentId}
                typeContent={valueTabsSort}
                typeModal={typeModal}
                galleries={galleries}
                currentId={currentId}
                loading={loading}
                setShow={setShow}
            />
            <AddGalleryModal
                handleShowModalImg={handleShowModalImg}
                setGalleryPictures={setGalleryPictures}
                submitGalleryForm={submitGalleryForm}
                setShowModalImg={setShowModalImg}
                pictures={galleryPictures}
                handleClose={handleClose}
                galleryObj={galleryObj}
                typeModal={typeModal}
                currentId={currentId}
                setName={setName}
                setShow={setShow}
                show={show}
            />
            <RenderImgSliderModal
                currentIdPicture={currentIdPicture}
                deleteAction={removeGalleryPicturesByIds}
                item={galleryObj?.galleryPictures}
                setShowModalImg={setShowModalImg}
                setTypeModal={setTypeModal}
                showModalImg={showModalImg}
                productDetails={galleryObj}
                // setProductPictures={setProductPictures}
                // productPictures={productPictures}
                handleClose={handleClose}
                typeModal={typeModal}
                loading={loading}
                noneProd
            />
            <AlertComponent
                dispatchCloseAlert={setCloseAlertGallery}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </MainContainer>
    )
}

export default GalleryPhoto
