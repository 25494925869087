import React, { useState, useEffect } from 'react'
import { addPaymentAccount, getPaymentAccountsByOrderId } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/UI/Input/index';
import Modal from '../../../components/UI/Modal';
import { Button } from '@mui/material';
import RenderContentPaymentAccount from './RenderContentPaymentAccount';
import { getPaymentAccount } from '../../../actions';
import AlertComponent from '../../../components/Alert/Alert';
import { setCloseAlertPaymentAccount } from '../../../reducers/components/paymentAccount';
import Loader from '../../../components/Loader';
import ErrorTitle from './../../../components/Title/ErrorTitle';
import TotalAmountPaymentAccount from './TotalAmountPaymentAccount';

const AddPaymentAccountModal = ({
    orderId,
    onHide,
    currentId,
    orderObj,
    show,
}) => {
    const paymentAccount = useSelector((state) => state.paymentAccount)
    const { paymentAccounts, message, variantAlert, showAlert, loading } = paymentAccount
    const [showContent, setShowContent] = useState(false)
    const [showTotalAmount, setShowTotalAmount] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [typeContent, setTypeContent] = useState("all")
    const [formPaymentAccount, setPaymentAccount] = useState({
        price: "",
        description: "",
    })
    const [itemPictures, setItemPictures] = useState("");

    const dispatch = useDispatch()

    useEffect(() => {
        if (typeContent === "contents" || typeContent === "totalAmount") {
            const payload = {
                orderId
            }
            dispatch(getPaymentAccountsByOrderId(payload))
        }
    }, [typeContent === "contents" || typeContent === "totalAmount"])


    const submitPaymentAccount = () => {

        if (formPaymentAccount.price !== "" && formPaymentAccount.description !== "" && itemPictures.length > 0) {
            const form = new FormData();
            form.append("order", orderId);
            form.append("price", formPaymentAccount.price);
            form.append("description", formPaymentAccount.description);
            for (let i = 0; i < itemPictures.length; i++) {
                form.append("itemPictures", itemPictures[i]);
            }
            dispatch(addPaymentAccount(form)).then(() => clear())
        } else {
            setErrorMessage(true)
        }

    }

    const clear = () => {
        setItemPictures([])
        setPaymentAccount({
            price: "",
            description: "",
        })
    }
    const onHideMain = () => {
        onHide()
        setShowContent(false)
    }
    /* A ternary operator. It is a shorthand for an if/else statement. */
    // showContent
    //     ? <RenderContentPaymentAccount
    //         message={message}
    //         orderObj={orderObj}
    //         paymentAccounts={paymentAccounts}
    //     />
    //     : <>
    //         <Input
    //             label="Внесенная сумма"
    //             value={formPaymentAccount.price}
    //             placeholder={"Сумма"}
    //             onChange={(e) => setPaymentAccount({ ...formPaymentAccount, price: e.target.value })}
    //         />
    //         <Input
    //             label="Описание"
    //             value={formPaymentAccount.description}
    //             placeholder={"Описание"}
    //             onChange={(e) => setPaymentAccount({ ...formPaymentAccount, description: e.target.value })}
    //         />
    //         {!currentId ? <>
    //             <input
    //                 type="file"
    //                 name="itemPictures"
    //                 onChange={(e) => setItemPictures(e.target.files)}
    //                 required
    //                 multiple
    //             />
    //         </> 
    const renderContentModal = () => {
        switch (typeContent) {
            case "totalAmount":
                return (
                    <TotalAmountPaymentAccount
                        orderObj={orderObj}
                        setShowTotalAmount={setShowTotalAmount}
                        showTotalAmount={showTotalAmount}
                        paymentAccounts={paymentAccounts}
                    />
                )
            case "contents":
                return (
                    <RenderContentPaymentAccount
                        message={message}
                        orderObj={orderObj}
                        paymentAccounts={paymentAccounts}
                    />
                )
            case "all":
                return (
                    <>
                        <Input
                            label="Внесенная сумма"
                            value={formPaymentAccount.price}
                            placeholder={"Сумма"}
                            onChange={(e) => setPaymentAccount({ ...formPaymentAccount, price: e.target.value })}
                        />
                        <Input
                            label="Описание"
                            value={formPaymentAccount.description}
                            placeholder={"Описание"}
                            onChange={(e) => setPaymentAccount({ ...formPaymentAccount, description: e.target.value })}
                        />
                        {!currentId ? <>
                            <input
                                type="file"
                                name="itemPictures"
                                onChange={(e) => setItemPictures(e.target.files)}
                                required
                                multiple
                            />
                        </> : null}
                    </>
                )
            default:
                return null
        }
    }
    return (
        <>
            <Modal
                show={show}
                handleClose={onHideMain}
                modalTitle={`Счета об оплате`}
                onSubmit={submitPaymentAccount}
            >
                {typeContent === "totalAmount" ? null : <div style={{
                    margin: "5px"
                }}>
                    <Button
                        variant="contained"
                        onClick={() => setTypeContent(typeContent === "contents" ? "all" : "contents")}
                    >
                        {typeContent === "contents" ? "Назад" : "Посмотреть добавленные"}
                    </Button>
                </div>
                }
                {typeContent === "contents" ? null : <div style={{
                    margin: "5px"
                }}>
                    <Button
                        variant="contained"
                        onClick={() => setTypeContent(typeContent === "totalAmount" ? "all" : "totalAmount")}
                    >
                        {typeContent === "totalAmount" ? "Назад" : "Сумма которая внесена"}
                    </Button>
                </div>
                }

                {loading ? <Loader /> : renderContentModal()}
                <ErrorTitle
                    text="Все поля должны быть заполнены"
                    setShow={setErrorMessage}
                    show={errorMessage}
                />

            </Modal>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertPaymentAccount}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
        </>
    )
}

export default AddPaymentAccountModal