import React, { useEffect } from 'react'
import SettingIconBlock from './../../components/Button/SettingIconBlock';


const OrderDetailsButton = ({
    label,
    isDisabled,
    onClick,
    settingIcon,
    setShowSettingEditModal,
    handleShowEditOrderModal,
    type
}) => {

    const handleShowSettingModal = () => {
        handleShowEditOrderModal(type)
        setShowSettingEditModal(true)
    }

    return (
        <div className="m-2 p-1"
            style={{ position: "relative" }}


        >
            <div
                className={`renderOrderDetails__orderHistoryButton ${isDisabled
                    ? "disabled"
                    : ""}`}
                onClick={onClick}
            >
                {label}
            </div>
            {
                !settingIcon ? null :
                    <SettingIconBlock
                        color="black"
                        tooltip={`Параметры в: ${label}`}
                        right="-20px"
                        top="10px"
                        onClick={handleShowSettingModal}
                    />
            }
        </div>
    );
};

export default OrderDetailsButton