import React from 'react'

const ElementPaymentPageItems = ({
    elementPaymentPages,
    showDetailsModal,
}) => {
    return (
        <div className="elementPaymentPageItems">
            <div className="elementPaymentPageItems__content">

                <div className="tableContainer">
                    <table className="table table-hover table-dark">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Описание</th>
                                <th scope="col">Подробнее</th>
                            </tr>
                        </thead>
                        <tbody>
                            {elementPaymentPages.length > 0
                                ? elementPaymentPages.map((item, index) =>
                                    <tr key={item._id}
                                        onClick={() => showDetailsModal(item._id)}

                                    >
                                        <th scope="row">{index + 1}</th>
                                        <td style={{
                                            maxWidth: "200px",
                                            overflow: "hidden"
                                        }}>{item.mainTitle}</td>
                                        <td>{item.mainDescription}</td>

                                    </tr>
                                )
                                : null}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ElementPaymentPageItems