import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import { addProductMaterialsById, getProductMaterial } from '../../../actions';
import { Col, Row } from 'react-bootstrap';
import Input from '../../../components/UI/Input';
import { Button } from '@mui/material';
import SettingIconBlock from './../../../components/Button/SettingIconBlock';
import CloseIcon from '@mui/icons-material/Close';

const NewObject = ({
    productDetails
}) => {
    const {
        productMaterials,
        loading
    } = useSelector((state) => state.productMaterial)

    const [materialIds, setMaterialIds] = useState([]);
    const [materialForms, setMaterialForms] = useState({});
    const [addedMaterials, setAddedMaterials] = useState([]);
    const [showForm, setShowForm] = useState({});


    const filteredMaterials = productMaterials.filter((material) =>
        !productDetails.productMaterial.some((item) => item.materialId._id === material._id)
    );

    const dispatch = useDispatch();

    const handleProductIdsChange = (event) => {
        const selectedIds = event.target.value;
        const initialForms = selectedIds.reduce((forms, id) => {
            forms[id] = {
                materialId: id,
                dimensionValue: "",
                unitPrice: ""
            };
            return forms;
        }, {});
        setMaterialForms(initialForms);
        setMaterialIds(selectedIds);
    };

    const handleValueChange = (id, field, value) => {
        setMaterialForms((prevForms) => ({
            ...prevForms,
            [id]: {
                ...prevForms[id],
                [field]: value
            }
        }));
    };

    const handleSaveMaterial = (id) => {
        const totalCost = calculateTotalCost(id);
        const materialData = {
            ...materialForms[id],
            _totalCost: totalCost
        };

        setAddedMaterials((prevMaterials) => [...prevMaterials, materialData]);
        setShowForm((prevShow) => ({ ...prevShow, [id]: false }));
    };


    const handleCancelEdit = (id) => {
        setShowForm((prevShow) => ({ ...prevShow, [id]: false }));
    };

    useEffect(() => {
        dispatch(getProductMaterial())
    }, []);

    const handleDeleteAddedMaterial = (itemId) => {
        setMaterialIds((prevIds) =>
            prevIds.filter((id) => id !== itemId)
        );
        setAddedMaterials((prevIds) =>
            prevIds.filter((material) => material.materialId !== itemId)
        )
    }
    const clear = () => {
        setMaterialIds([])
        setAddedMaterials([])

    }
    const addMaterialsDispatch = () => {
        if (addedMaterials.length > 0) {
            const payload = {
                addedMaterials
            }
            dispatch(addProductMaterialsById(productDetails._id, payload))
                .then(clear())
        }
    }

    const calculateTotalCost = (itemId) => {
        const unitPrice = parseFloat(materialForms[itemId]?.unitPrice) || 0;
        const dimensionValue = parseFloat(materialForms[itemId]?.dimensionValue) || 0;
        return unitPrice * dimensionValue;
    };

    return (
        <div className="productionsMaterial__newObject">
            <Row>
                <Col md="6">
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Товары</InputLabel>
                        <Select
                            multiple
                            value={materialIds}
                            onChange={handleProductIdsChange}
                        >
                            {filteredMaterials.map((prod) => (
                                <MenuItem
                                    key={prod._id}
                                    value={prod._id}
                                >
                                    {prod.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Col>
            </Row>
            {materialIds.length > 0 && (
                <div className="productionsMaterial__materialArrayTitle">
                    Заполнить формы для сохранения материалов
                </div>
            )}

            {materialIds.length > 0 &&
                materialIds.map((itemId) => (
                    <div className='productionsMaterial__itemMaterialItem' key={itemId}>
                        <Row>
                            <Col md="2">
                                {productMaterials.find((prod) => prod._id === itemId)?.name}
                            </Col>
                            <Col md="4">
                                {showForm[itemId] ? (
                                    <Input
                                        label="Размер Значения"
                                        name="dimensionValue"
                                        value={materialForms[itemId]?.dimensionValue}
                                        placeholder={`Введите длину или объем`}
                                        onChange={(e) => handleValueChange(itemId, 'dimensionValue', e.target.value)}
                                    />
                                ) : <div>
                                    {materialForms[itemId]?.dimensionValue} Метров
                                </div>}
                            </Col>
                            <Col md="5">
                                {showForm[itemId] ? (
                                    <div>
                                        <Input
                                            label="Цена за единицу"
                                            name="unitPrice"
                                            value={materialForms[itemId]?.unitPrice}
                                            placeholder={`Введите цену за единицу`}
                                            onChange={(e) => handleValueChange(itemId, 'unitPrice', e.target.value)}
                                        />
                                        <div className="price-info">
                                            {filteredMaterials.find((prod) => prod._id === itemId)?.price} Цена за 1 единицу
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {materialForms[itemId]?.unitPrice} цена за метр
                                    </div>
                                )}
                                <div className="total-cost">
                                    Общая сумма: {calculateTotalCost(itemId)}
                                </div>
                            </Col>
                            <Col md="1">
                                <SettingIconBlock
                                    tooltip="Удалить"
                                    icon={<CloseIcon />}
                                    right="10px"
                                    // top=""
                                    onClick={(e) => handleDeleteAddedMaterial(itemId)}
                                />
                            </Col>
                        </Row>
                        {showForm[itemId] ? (
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => handleSaveMaterial(itemId)}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleCancelEdit(itemId)}
                                >
                                    Отменить
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => setShowForm((prevShow) => ({ ...prevShow, [itemId]: true }))}
                            >
                                Редактировать
                            </Button>
                        )}
                    </div>
                ))}
            {addedMaterials.length > 0
                ? <Button
                    variant="contained"
                    onClick={addMaterialsDispatch}
                >
                    Сохранить Материалы в Продукты
                </Button>
                : null
            }
        </div>
    )
}

export default NewObject;
