import React from 'react'
import ContentItem from '../ContentItem';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useDispatch } from 'react-redux';

const RenderContentPaymentAccount = ({
    paymentAccounts,
    orderObj,
    message

}) => {

    return (
        <div className="renderContentPaymentAccount">
            <div className="renderContentPaymentAccount__items">

                {paymentAccounts.length > 0 ? paymentAccounts.map((item, index) => (
                    <ContentItem
                        key={index}
                        orderObj={orderObj}
                        index={index}
                        item={item}
                    // itemMoreArray={itemMoreArray}
                    />
                ))
                    : <div className="renderContentPaymentAccount__textEmpty">
                        {message}
                    </div>}
            </div>
        </div>
    )
}

export default RenderContentPaymentAccount