import React, { useEffect, useState, useRef } from 'react'
import Modal from '../../components/UI/Modal';
import Input from '../../components/UI/Input';
import { Container, Row, Col } from 'react-bootstrap';
import BRenderAddPictures from '../../components/Button/BRenderAddPictures';
import { useDispatch } from 'react-redux';
import { addSamplePhotoGallery, getGalleryById } from '../../actions';
import { Button } from '@mui/material';
import PicturesMiniRow from '../../components/Pictures/PicturesMiniRow';

const AddGalleryModal = ({
    submitGalleryForm,
    setGalleryPictures,
    setShowModalImg,
    handleShowModalImg,
    handleClose,
    galleryObj,
    typeModal,
    currentId,
    setShow,
    pictures,
    setName,
    show,
    name,

}) => {
    const dispatch = useDispatch();
    const [clearPictures, setClearPictures] = useState(false)

    useEffect(() => {
        if (typeModal === "setting" || typeModal === "details") {
            if (currentId !== galleryObj._id) {
                dispatch(getGalleryById(currentId))
            }
        }
    }, [typeModal])


    const handleSubmitAddPhoto = () => {
        if (pictures.length > 0) {
            dispatch(addSamplePhotoGallery(currentId, pictures))
        }
        setGalleryPictures([])
        setClearPictures(true)
    };

    const renderContent = () => {
        let content;
        switch (typeModal) {
            case "setting": content = <div>

                <h1>Hello</h1>
            </div>
                break;
            case "addPhoto": content = <div>
                <h4>Добавить фото</h4>
                <BRenderAddPictures
                    setPictures={setGalleryPictures}
                    pictures={pictures}
                    clearPictures={clearPictures}
                />
                <Button
                    disabled={pictures.length > 0 ? false : true}
                    variant="contained"
                    onClick={handleSubmitAddPhoto}>
                    Загрузить
                </Button>
            </div>
                break;
            case "details": content = <>
                <Row>
                    <Col md="6">
                        <label className="key">Название галереи</label>
                        <p className="value">{galleryObj.name}</p>
                    </Col>

                </Row>
                <Row>
                    <Col md="6">
                        <label className="key">Время создания</label>
                        <p className="value">
                            {galleryObj.createdAt?.slice(0, 10)}
                            &nbsp;
                            {galleryObj.createdAt?.slice(11, 19)}
                        </p>
                    </Col>
                    <Col md="6">
                        <label className="key">Последнее обновления</label>
                        <p className="value">
                            {galleryObj.updatedAt?.slice(0, 10)}
                            &nbsp;
                            {galleryObj.updatedAt?.slice(11, 19)}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <label className="key">Всего фотографий</label>
                        <p className="value">
                            {galleryObj.galleryPictures?.length} шт.
                        </p>
                    </Col>
                    <Col md="6">
                        <label className="key">Переход на страницу</label>
                        <p className="value">{galleryObj.views?.length} просмотра(-ов)</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <label className="key">Фотографии</label>
                        <PicturesMiniRow
                            itemsPicture={galleryObj.galleryPictures}
                            handleClick={handleShowModalImg}
                        />
                    </Col>
                </Row>
            </>
                break;
            default: content = <Container>
                <Row>
                    <Col>
                        <Input
                            label="Название"
                            value={name}
                            placeholder={"Добавить имя"}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Col>

                </Row>
                <BRenderAddPictures
                    setPictures={setGalleryPictures}
                />
            </Container>
        }
        return content;
    }

    return (
        <Modal
            show={show}
            modalTitle={'Добавить новые фотографии'}
            handleClose={handleClose}
            onSubmit={submitGalleryForm}
            disabledFunctional={typeModal === "setting" || typeModal === "details" || typeModal === "addPhoto" ? true : false}
        >
            {renderContent()}
        </Modal>
    )
}

export default AddGalleryModal