import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

const PaginationComponent = ({
    setPageNumber,
    pageNumber,
    pageSize,
    totalPages,
    totalAll,
    dispatchAction
}) => {
    const dispatch = useDispatch()

    const buttonPages = new Array(totalPages).fill(null).map((v, i) => i);

    const [countPa, setCountPa] = useState(0)
    const [countPaEnd, setCountPaEnd] = useState(5)

    const gotoPrevious = () => {
        if (dispatchAction) {
            dispatch(setPageNumber(Math.max(0, pageNumber - 1)));
        } else {
            setPageNumber(Math.max(0, pageNumber - 1));
        }
    };
    const test = buttonPages.slice(countPa, countPaEnd)

    const gotoNext = () => {

        // if (dispatchAction) {
        //     dispatch(setPageNumber(Math.min(totalPages - 1, pageNumber + 1)));
        // } else {
        setPageNumber(Math.min(totalPages - 1, pageNumber + 1));
        // }
        if (pageNumber + 2 === test.length) {
            setCountPa(countPa + 4)
            setCountPaEnd(countPaEnd + 4)
        }
    }

    const pageItemEnd = (one, two) => {
        let page = one * two
        return page

    }
    const pageItemStart = (one, two) => {
        let page = one * two - two + 1
        return page

    }

    const paginationCount = () => {
        setCountPa(countPa + 4)
        setCountPaEnd(countPaEnd + 4)
    }
    const paginationCountMinus = () => {
        setCountPa(countPa - 4)
        setCountPaEnd(countPaEnd - 4)
    }
    console.log("pageNumber", pageNumber)
    console.log("dispatchAction", dispatchAction)

    const handleClickNumber = (pageIndex) => {
        // console.log("pageIndex", pageIndex)
        // if (dispatchAction) {
        //     dispatch(setPageNumber(pageIndex));
        // } else {
        setPageNumber(pageIndex);
        // }
    };

    if (!totalAll > 0) return null
    return (
        <div className="paginationComponent">
            <div className="paginationComponent__mainContainer">


                <div className="paginationComponent__leftTextContainer">
                    Показано с {pageItemStart(pageNumber + 1, pageSize)} по {pageItemEnd(pageNumber + 1, pageSize < totalAll ? pageSize : totalAll)} из {totalAll} (всего {totalPages} страницы)
                </div>
                <div className="paginationComponent__container">
                    {countPa === 0 ? null : <button
                        className="paginationComponent__buttonBack"
                        onClick={paginationCountMinus}><FirstPageIcon /></button>
                    }

                    <button
                        className="paginationComponent__buttonBack"
                        onClick={gotoPrevious}>Назад</button>
                    {test.map((pageIndex) => (
                        <button
                            key={pageIndex} onClick={() => handleClickNumber(pageIndex)}
                            className={pageIndex === pageNumber
                                ? "paginationComponent__buttonNumber active"
                                : "paginationComponent__buttonNumber"}
                        >
                            {pageIndex + 1}
                        </button>
                    ))}
                    <button
                        className="paginationComponent__buttonNext"
                        onClick={gotoNext}>
                        Вперед
                    </button>
                    {!test.length < 4 ? <button
                        className="paginationComponent__buttonNext"
                        onClick={paginationCount}>
                        <LastPageIcon />
                    </button> : null}
                </div>
            </div>
        </div >
    )
}

export default PaginationComponent