import { orderConstants } from "../actions/constants";

const initState = {
  orders: [],
  orderDetails: {},
  loading: false,
  message: "",
  showAlert: false,
  variantAlert: "success",
};

export default (state = initState, action) => {
  switch (action.type) {

    case orderConstants.UPDATE_ORDER_PACKAGE_TRACKING_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_ORDER_PACKAGE_TRACKING_SUCCESS:
      state = {
        ...state,
        loading: false,
        variantAlert: "success",
        message: action.payload.message,
        orders: action.payload.result,
        showAlert: true,
      };
      break;
    case orderConstants.UPDATE_ORDER_PACKAGE_TRACKING_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
        variantAlert: "warning",
        orders: [],
        showAlert: true,
      };
      break;

    case orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_SUCCESS:
      state = {
        ...state,
        loading: false,
        variantAlert: "success",
        message: action.payload.message,
        orders: action.payload.orders,
        showAlert: true,
      };
      break;
    case orderConstants.SEARCH_ORDERS_BY_ORDER_NUMBER_FAILURE:
      state = {
        ...state,
        loading: false,
        message: "Нет заказов с таким номером(названием)",
        variantAlert: "warning",
        orders: [],
        showAlert: true,
      };
      break;
    case orderConstants.UPDATE_ORDER_DELIVERY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case orderConstants.UPDATE_ORDER_DELIVERY_ID_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        orders: action.payload.orders,
        loading: false,
        variantAlert: "success",
        showAlert: true,
      }
      break;
    case orderConstants.UPDATE_ORDER_DELIVERY_ID_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      }
      break;

    case orderConstants.UPDATE_ORDER_VISIBLE_ADMIN_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case orderConstants.UPDATE_ORDER_VISIBLE_ADMIN_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        showAlert: true,
      }
      break;
    case orderConstants.UPDATE_ORDER_VISIBLE_ADMIN_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      }
      break;

    case orderConstants.UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case orderConstants.UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        showAlert: true,
      }
      break;
    case orderConstants.UPDATE_ORDER_VISIBLE_DISCOUNTED_TOTAL_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      }
      break;

    case orderConstants.ADD_ORDER_PRODUCTS_BU_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case orderConstants.ADD_ORDER_PRODUCTS_BU_ID_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        showAlert: true,
      }
      break;
    case orderConstants.ADD_ORDER_PRODUCTS_BU_ID_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      }
      break;
    case orderConstants.UPDATE_CANCELLATION_ORDER_STATUS_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case orderConstants.UPDATE_CANCELLATION_ORDER_STATUS_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,

        loading: false,
        showAlert: true,
      }
      break;
    case orderConstants.UPDATE_CANCELLATION_ORDER_STATUS_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      }
      break;
    case orderConstants.DELETE_ORDER_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case orderConstants.DELETE_ORDER_BY_ID_SUCCESS:
      state = {
        ...state,
        message: action.payload.data.message,
        loading: false,
        showAlert: true,
        orders: state.orders?.filter((orders) => orders._id !== action.payload)
      }
      break;
    case orderConstants.DELETE_ORDER_BY_ID_FAILURE:
      state = {
        ...state,
        message: action.payload.data.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      }
      break;
    case orderConstants.GET_ORDER_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.GET_ORDER_BY_ID_SUCCESS:
      state = {
        ...state,
        orderDetails: action.payload.orderDetails,
        loading: false,
      };
      break;
    case orderConstants.GET_ORDER_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;

    case orderConstants.GET_CUSTOMER_ORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.GET_CUSTOMER_ORDER_SUCCESS:
      state = {
        ...state,
        orders: action.payload.orders,
        loading: false,
      };
      break;
    case orderConstants.GET_CUSTOMER_ORDER_FAILURE:
      state = {
        ...state,
        orders: [],
        loading: false,
      };
      break;
    case orderConstants.UPDATE_CUSTOMER_ORDER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_CUSTOMER_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
        variantAlert: "success",
        showAlert: true,
      };
      break;
    case orderConstants.UPDATE_CUSTOMER_ORDER_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        loading: false,
        variantAlert: "warning",
        showAlert: true,
      };
      break;
    //deleteAdditionalById
    case orderConstants.DELETE_ORDER_ADDITIONAL_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.DELETE_ORDER_ADDITIONAL_BY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        orderDetails: action.payload.orderDetails,
        showAlert: true,
        message: action.payload.message,
      };
      break;
    case orderConstants.DELETE_ORDER_ADDITIONAL_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        orderDetails: action.payload.orderDetails,
        message: action.payload.message,
        variantAlert: "warning",
      };
      break;
    //updateAdditionalById
    case orderConstants.UPDATE_ORDER_ADDITIONAL_BY_ID_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_ORDER_ADDITIONAL_BY_ID_SUCCESS:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        orderDetails: action.payload.orderDetails,
        message: action.payload.message,
      };
      break;
    case orderConstants.UPDATE_ORDER_ADDITIONAL_BY_ID_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        orderDetails: action.payload.orderDetails,
        message: action.payload.message,
        variantAlert: "warning",
      };
      break;

    case orderConstants.UPDATE_ORDER_TOTAL_AMOUNT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_ORDER_TOTAL_AMOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        orderDetails: action.payload.orderDetails,
        message: action.payload.message,
      };
      break;
    case orderConstants.UPDATE_ORDER_TOTAL_AMOUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        message: action.payload.message,
        variantAlert: "warning",
      };
      break;

    case orderConstants.UPDATE_ORDER_ADDITIONAL_FIELD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case orderConstants.UPDATE_ORDER_ADDITIONAL_FIELD_SUCCESS:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        orders: action.payload.orders,
        message: action.payload.message,
      };
      break;
    case orderConstants.UPDATE_ORDER_ADDITIONAL_FIELD_FAILURE:
      state = {
        ...state,
        loading: false,
        showAlert: true,
        message: action.payload.message,
        orderDetails: action.payload.orderDetails,
        variantAlert: "warning",
      };
      break;

    case orderConstants.ALERT_CLOSE_ORDER_ADDITIONAL_FIELD:
      state = {
        ...state,
        showAlert: false,
      };
      break;
    default:
      return state
  }

  return state;
};


export const setOrder = (orders) => ({ type: orderConstants.GET_CUSTOMER_ORDER_SUCCESS, payload: orders })
export const setCloseAlertAdditionalField = (payload) => ({ type: orderConstants.ALERT_CLOSE_ORDER_ADDITIONAL_FIELD, payload })
