import { productConstants } from './constants';
import axios from "../helpers/axios";
import { getProductsBySlug, getProducts } from './product.action';




export const removeProductPicturesByIds = (payload) => {
    return async dispatch => {
        dispatch({ type: productConstants.REMOVE_PRODUCT_PICTURES_IDS_REQUEST });
        const res = await axios.post("/product/removeProductPicturesByIds", payload);
        if (res.status === 200) {
            dispatch({
                type: productConstants.REMOVE_PRODUCT_PICTURES_IDS_SUCCESS,
                payload: res.data
            });
            dispatch(getProducts())
        } else {
            dispatch({
                type: productConstants.REMOVE_PRODUCT_PICTURES_IDS_FAILURE,
                payload: res.data
            });
        }
    }
}

export const removeBlueprintsByIds = (payload) => {
    return async dispatch => {
        dispatch({ type: productConstants.REMOVE_PRODUCT_BLUEPRINTS_IDS_REQUEST });
        const res = await axios.post("/product/removeProductBluePrintsByIds", payload);
        if (res.status === 200) {
            dispatch({
                type: productConstants.REMOVE_PRODUCT_BLUEPRINTS_IDS_SUCCESS,
                payload: res.data
            });
            dispatch(getProducts())
        } else {
            dispatch({
                type: productConstants.REMOVE_PRODUCT_BLUEPRINTS_IDS_FAILURE,
                payload: res.data
            });
        }
    }
}
