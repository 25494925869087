import React, { useState, useEffect } from 'react'
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addColorProduct, updateColorProduct } from '../../actions';

const AddColorProduct = ({
    show,
    handleClose,
    setCurrentId,
    currentId
}) => {
    const dispatch = useDispatch()
    const colorProduct = useSelector((state) => currentId ? state.colorProduct.colorProducts?.find((c) => c._id === currentId) : null)
    const [itemPictures, setItemPictures] = useState("");
    const [formColorProduct, setFormColorProduct] = useState({
        name: "",
        description: "",
    })

    useEffect(() => {
        if (colorProduct) setFormColorProduct(colorProduct)
    }, [colorProduct])

    const handleItemPictures = (e) => {
        setItemPictures(e.target.files);
    };
    const submitContactForm = (e) => {
        if (currentId) {
            e.preventDefault()
            dispatch(updateColorProduct(currentId, formColorProduct))
            handleClose()
            clear()
        } else {
            const form = new FormData();
            form.append("description", formColorProduct.description);
            form.append("name", formColorProduct.name);
            for (let i = 0; i < itemPictures.length; i++) {
                form.append("itemPictures", itemPictures[i]);
            }
            dispatch(addColorProduct(form)).then(() => handleClose())
        }
    }
    const clear = () => {
        setCurrentId(null)
        setFormColorProduct({
            name: "",
            description: "",
        })
        setItemPictures("")
    }
    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle="Добавить цвет"
            onSubmit={submitContactForm}
        >
            <Input
                label="Название"
                value={formColorProduct.name}
                placeholder={"Название"}
                onChange={(e) => setFormColorProduct({ ...formColorProduct, name: e.target.value })}
            />
            <Input
                label="Описание"
                value={formColorProduct.description}
                placeholder={"Описание"}
                onChange={(e) => setFormColorProduct({ ...formColorProduct, description: e.target.value })}
            />
            {!currentId ? <>
                <input
                    type="file"
                    name="itemPictures"
                    onChange={(e) => handleItemPictures(e)}
                    required
                    multiple
                />
            </> : null
            }
        </Modal>

    )
}


export default AddColorProduct