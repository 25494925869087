import { Button } from '@material-ui/core'
import React from 'react'
import SelectMultiple from '../SelectMultiple'

const ButtonBox = ({
    filterProduct,
    confirmUpdateProductConfirm,
    setShowSelectProduct,
    submitAddNewProduct,
    showEditOrderInput,
    setSelectedProduct,
    showSelectProduct,
    productIdsLength,
    updatedProducts,
    selectedProduct,
    setProductIds,
    productIds,
    products,
}) => {
    return (
        <div className="renderProductMiniList__buttonBox">
            <Button
                disabled={updatedProducts.length > 0 && !showEditOrderInput ? false : true}
                variant="outlined"
                onClick={confirmUpdateProductConfirm}
            >
                Сохранить измененный порядок товаров
            </Button>
            <Button
                variant="outlined"
                onClick={() => setShowSelectProduct(!showSelectProduct)}
            >
                {showSelectProduct
                    ? "Отмена"
                    : "Добавить новые товары"
                }
            </Button>

            {
                showSelectProduct
                    ? <>
                        <SelectMultiple
                            setSelectedProduct={setSelectedProduct}
                            selectedProduct={selectedProduct}
                            setProductIds={setProductIds}
                            productIds={productIds}
                            items={filterProduct()}
                            rightImg="15%"
                            topImg="35%"
                        />
                        <Button
                            variant="outlined"
                            color="success"
                            disabled={productIdsLength > 0 ? false : true}
                            onClick={submitAddNewProduct}
                        >
                            Сохранить
                        </Button>
                    </>
                    : null
            }
        </div>
    )
}

export default ButtonBox