import axios from "../../helpers/axios"
import { pickupPointsConstants } from '../constants';
import { deletedPickupPoints, updatedPickupPoints } from '../api/pickupPoints';


export const getPickupPoints = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: pickupPointsConstants.GET_PICKUP_POINTS_REQUEST })
            const res = await axios.get("pickupPoints/get")
            if (res.status === 200) {
                const { pickupPoints } = res.data
                dispatch({
                    type: pickupPointsConstants.GET_PICKUP_POINTS_SUCCESS,
                    payload: {
                        pickupPoints
                    }
                })
            } else {
                dispatch({ type: pickupPointsConstants.GET_PICKUP_POINTS_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const findPickupPointsById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: pickupPointsConstants.GET_PICKUP_POINTS_BY_ID_REQUEST })
            const res = await axios.get(`/pickupPoints/findPickupPointsById/${id}`)
            if (res.status === 200) {
                dispatch({
                    type: pickupPointsConstants.GET_PICKUP_POINTS_BY_ID_SUCCESS,
                    payload: res.data
                })
            } else {
                dispatch({ type: pickupPointsConstants.GET_PICKUP_POINTS_BY_ID_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}


export const addPickupPoints = (form) => {

    return async (dispatch) => {
        try {
            dispatch({ type: pickupPointsConstants.ADD_PICKUP_POINTS_REQUEST })
            const res = await axios.post("pickupPoints/create", form)
            if (res.status === 201) {
                dispatch({ type: pickupPointsConstants.ADD_PICKUP_POINTS_SUCCESS })
                dispatch(getPickupPoints())
            } else {
                dispatch({ type: pickupPointsConstants.ADD_PICKUP_POINTS_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}


export const updatePickupPoints = (id, post) => async (dispatch) => {
    try {
        dispatch({ type: pickupPointsConstants.UPDATE_PICKUP_POINTS_REQUEST })
        const res = await updatedPickupPoints(id, post)
        const { data } = res
        if (data) {
            dispatch({
                type: pickupPointsConstants.UPDATE_PICKUP_POINTS_SUCCESS, payload: data
            })
        }
        dispatch(getPickupPoints())
    } catch (error) {
        console.log(error);
    }
}
export const updateAllPickupPoints = () => async (dispatch) => {
    try {
        dispatch({ type: pickupPointsConstants.UPDATE_PICKUP_POINTS_REQUEST })
        const res = await axios.post("pickupPoints/updateAll")
        const { data } = res
        if (data) {
            dispatch({
                type: pickupPointsConstants.UPDATE_PICKUP_POINTS_SUCCESS, payload: data
            })
        } else {
            dispatch({
                type: pickupPointsConstants.UPDATE_PICKUP_POINTS_FAILURE, payload: data
            })
        }
        dispatch(getPickupPoints())
    } catch (error) {
        console.log(error);
    }
}

export const deletePickupPoints = (id) => async (dispatch) => {
    try {
        await deletedPickupPoints(id);
        dispatch({ type: pickupPointsConstants.DELETE_PICKUP_POINTS_SUCCESS, payload: id });
        dispatch(getPickupPoints())
    } catch (error) {
        console.log(error);
    }
}