import { productConstants } from "../actions/constants";

const initialState = {
    products: [],
    productDetails: {},
    loadingProductDetails: false,
    allProducts: [],
    productsTwo: [],
    view: 'list',
    activeCategory: 1,
    currentCategory: "",
    totalCount: 0,
    loading: false,
    countPage: "",
    currentCategory: "",
    totalAll: 0,
    currentPage: 1,
    totalPages: 0,
    pageSize: 25,
    message: "",
    showAlert: false,
    variantAlert: "success",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case productConstants.UPDATE_MAIN_IMAGE_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.UPDATE_MAIN_IMAGE_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.UPDATE_MAIN_IMAGE_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.REMOVE_PRODUCT_BLUEPRINTS_IDS_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.REMOVE_PRODUCT_BLUEPRINTS_IDS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.REMOVE_PRODUCT_BLUEPRINTS_IDS_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.RESET_TOTAL_AMOUNT_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.RESET_TOTAL_AMOUNT_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.RESET_TOTAL_AMOUNT_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.REMOVE_PRODUCT_MATERIALS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.REMOVE_PRODUCT_MATERIALS_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.REMOVE_PRODUCT_MATERIALS_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.EDIT_PRODUCT_MATERIALS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.EDIT_PRODUCT_MATERIALS_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.EDIT_PRODUCT_MATERIALS_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.ADD_PRODUCT_MATERIALS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.ADD_PRODUCT_MATERIALS_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.ADD_PRODUCT_MATERIALS_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.ADD_UPDATED_PICTURES_STATUS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.ADD_UPDATED_PICTURES_STATUS_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                variantAlert: "success",
                showAlert: true,
            }
            break;
        case productConstants.ADD_UPDATED_PICTURES_STATUS_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.item,
                loading: false,
                showAlert: true,
            }
            break;
        case productConstants.UPDATE_CANCELLATION_PRODUCT_PICTURES_STATUS_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            }
            break;
        case productConstants.TOGGLE_TYPE_IMG_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case productConstants.TOGGLE_TYPE_IMG_SUCCESS:
            state = {
                ...state,
                loading: false,
                productDetails: action.payload.product,
                message: action.payload.message,
                variantAlert: "success",
                showAlert: true,
            };
            break;
        case productConstants.TOGGLE_TYPE_IMG_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            };
            break;
        case productConstants.TOGGLE_VISIBLE_IMG_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case productConstants.TOGGLE_VISIBLE_IMG_SUCCESS:
            state = {
                ...state,
                loading: false,
                productDetails: action.payload.product,
                message: action.payload.message,
                variantAlert: "success",
                showAlert: true,
            };
            break;
        case productConstants.TOGGLE_VISIBLE_IMG_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            };
            break;
        case productConstants.UPDATE_STATUS_PICTURES_REQUEST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case productConstants.UPDATE_STATUS_PICTURES_SUCCESS:
            state = {
                ...state,
                loading: false,
                productDetails: action.payload.item,
                message: action.payload.message,
                variantAlert: "success",
                showAlert: true,
            };
            break;
        case productConstants.UPDATE_STATUS_PICTURES_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                variantAlert: "warning",
                showAlert: true,
            };
            break;
        case productConstants.CREATE_QR_CODE_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.CREATE_QR_CODE_BY_ID_SUCCESS:
            state = {
                ...state,
                productDetails: action.payload?.updateProduct,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            };
            break;
        case productConstants.CREATE_QR_CODE_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            };
            break;

        case productConstants.ADD_BLUEPRINTS_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.ADD_BLUEPRINTS_BY_ID_SUCCESS:
            state = {
                ...state,
                productDetails: action.payload?.item,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            };
            break;
        case productConstants.ADD_BLUEPRINTS_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            };
            break;

        case productConstants.ADD_SAMPLE_PHOTO_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.ADD_SAMPLE_PHOTO_SUCCESS:
            state = {
                ...state,
                productDetails: action.payload?.application,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            };
            break;
        case productConstants.ADD_SAMPLE_PHOTO_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            };
            break;
        case productConstants.SET_PRODUCT_DETAILS:
            state = {
                ...state,
                productDetails: action.payload.productDetails,
            };
            break;
        case productConstants.GET_PRODUCT_BY_ID_REQUEST:
            state = {
                ...state,
                loadingProductDetails: true
            };
            break;
        case productConstants.GET_PRODUCT_BY_ID_SUCCESS:
            state = {
                ...state,
                productDetails: action.payload.productDetails,
                loadingProductDetails: false
            };
            break;
        case productConstants.GET_PRODUCT_BY_ID_FAILURE:
            state = {
                ...state,
                loadingProductDetails: false
            };
            break;
        case productConstants.REMOVE_PRODUCT_PICTURES_IDS_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.REMOVE_PRODUCT_PICTURES_IDS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                productDetails: action.payload.product,
                showAlert: true,
                loading: false
            };
            break;
        case productConstants.REMOVE_PRODUCT_PICTURES_IDS_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            };
            break;
        case productConstants.DELETE_PRODUCT_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.data.message,
                showAlert: true,
                products: state.products?.filter((products) => products._id !== action.payload)
            }
            break;
        case productConstants.UPDATE_PRODUCT_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.data.message,
                showAlert: true,
                products: state.products?.filter((products) => products._id === action.payload._id ? action.payload : products)
            }
            break;
        case productConstants.UPDATE_PRODUCT_PRICE_VISIBLE_BY_ID:
            state = {
                ...state,
                message: action.payload.data.message,
                showAlert: true,
                products: state.products?.filter((products) => products._id === action.payload._id ? action.payload : products)
            }
            break;
        case productConstants.UPDATE_PRODUCT_VISIBLE_BY_ID:
            state = {
                ...state,
                message: action.payload.data.message,
                showAlert: true,
                products: state.products?.filter((products) => products._id === action.payload._id ? action.payload : products)
            }
            break;
        case productConstants.GET_PRODUCTS_BY_SLUG_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.GET_PRODUCTS_BY_SLUG_SUCCESS:
            state = {
                ...state,
                products: action.payload.products,
                productsTwo: action.payload.products?.filter(function (item) {
                    if (!item.visibleProduct) {
                        return false;
                    }
                    return true;
                }),
                pageSize: action.payload.pageSize,
                countPage: action.payload.countPage,
                totalPages: action.payload.totalPages,
                totalAll: action.payload.totalAll,
                perPage: action.payload.perPage,
                categoryName: action.payload.categoryName,
                // message: action.payload.message,
                loading: false,
            };
            break;
        case productConstants.GET_PRODUCTS_BY_SLUG_FAILURE:
            state = {
                ...state,
                loading: false
            };
            break;
        case productConstants.UPDATE_ALL_PRODUCT_REQUEST:
            state = {
                ...state,
                message: action.payload?.data.message,
                loading: true
            };
            break;
        case productConstants.UPDATE_ALL_PRODUCT_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                loading: false
            };
            break;
        case productConstants.UPDATE_ALL_PRODUCT_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false

            };
            break;
        case productConstants.ADD_PRODUCT_SUCCESS:
            state = {
                ...state,
                message: action.payload?.data?.message,
                showAlert: true,
                variantAlert: "success"
            };
            break;
        case productConstants.ADD_PRODUCT_FAILURE:
            state = {
                ...state,
                message: action.payload?.data.message,
                showAlert: true,
                variantAlert: "warning"
            };
            break;
        case productConstants.EDIT_PRODUCT_CATEGORY_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.EDIT_PRODUCT_CATEGORY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                loading: false
            };
            break;
        case productConstants.EDIT_PRODUCT_CATEGORY_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            };
            break;
        case productConstants.UPDATE_PRODUCT_NEW_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case productConstants.UPDATE_PRODUCT_NEW_BY_ID_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                loading: false
            };
            break;
        case productConstants.UPDATE_PRODUCT_NEW_BY_ID_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            };
            break;

        case productConstants.GET_ALL_PRODUCTS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case productConstants.GET_ALL_PRODUCTS_SUCCESS:
            state = {
                ...state,
                allProducts: action.payload.allProducts,
                productsTwo: action.payload.products?.filter(function (item) {
                    if (!item.visibleProduct) {
                        return false;
                    }
                    return true;
                }),
                pageSize: action.payload.pageSize,
                products: action.payload.products,
                countPage: action.payload.countPage,
                totalPages: action.payload.totalPages,
                totalAll: action.payload.totalAll,
                perPage: action.payload.perPage,
                categoryName: action.payload.categoryName,
                // message: state.message === "" ? action.payload.message : state.message,
                loading: false,
            }
            break;
        case productConstants.GET_ALL_PRODUCTS_FAILURE:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                variantAlert: "warning"
            }
            break;
        case productConstants.SET_VIEW:
            state = {
                ...state,
                view: action.payload
            }
            break;
        case productConstants.SET_CURRENT_CATEGORY:
            state = {
                ...state,
                currentCategory: action.payload
            }
            break;
        case productConstants.SET_ACTIVE_CATEGORY:
            state = {
                ...state,
                activeCategory: action.payload
            }
            break;
        case productConstants.SET_CURRENT_PAGE:
            state = {
                ...state,
                currentPage: action.payload
            }
            break;
        case productConstants.SEARCH_PRODUCTS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case productConstants.SEARCH_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                showAlert: true,
                variantAlert: state.variantAlert
            }
            break;
        case productConstants.SEARCH_PRODUCTS_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                loading: false,
                showAlert: true,
                variantAlert: "warning"
            }
            break;
        case productConstants.CLOSE_ALERT_TO_PRODUCT_SUCCESS:
            state = {
                ...state,
                showAlert: false
            }
            break;
        default:
            return state
    }

    return state;
}
export const setPerPage = (pageSize) => ({ type: productConstants.SET_PAGE_SIZE, payload: pageSize })
export const setCurrentCategory = (currentCategory) => ({
    type: productConstants.SET_CURRENT_CATEGORY,
    payload: currentCategory
})
export const setFileView = (payload) => ({ type: productConstants.SET_VIEW, payload })
export const setSort = (payload) => ({ type: productConstants.SET_SORT, payload })
export const setActiveCategory = (payload) => ({ type: productConstants.SET_ACTIVE_CATEGORY, payload })
export const setProduct = (products) => ({ type: productConstants.GET_ALL_PRODUCTS_SUCCESS, payload: products })
export const setCategoryName = (categoryName) => ({ type: productConstants.UPDATE_CATEGORY_NAME, payload: categoryName })
export const setShowAlert = (payload) => ({ type: productConstants.SET_SHOW_ALERT, payload })
export const setCurrentPage = (page) => ({ type: productConstants.SET_CURRENT_PAGE, payload: page })
export const setCloseAlert = (payload) => ({ type: productConstants.CLOSE_ALERT_TO_PRODUCT_SUCCESS, payload })
export const setProductDetails = (payload) => ({ type: productConstants.SET_PRODUCT_DETAILS, payload })