import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProductViews } from '../../actions'
import ContainerDetailsContent from './ContainerDetailsContent'
import { generatePublicUrl } from '../../urlConfig'
import Loader from '../../components/Loader'
import EmptyMessage from './EmptyMessage'

const UserHistoryItems = ({
    userId,
    setTypeModal,
    loading,
    message
}) => {
    const { productsHistory, totalNumberProduct } = useSelector((state) => state.user)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserProductViews(userId))
    }, [])

    const infoArray = [
        {
            name: "Всего просмотров:",
            content: productsHistory?.length
        },
        {
            name: "Всего позиций товаров:",
            content: totalNumberProduct
        },
    ]
    return (
        <ContainerDetailsContent
            setTypeModal={setTypeModal}
            noneButtons
        >
            <div className="userProductHistory">
                <div className="userProductHistory__items">
                    {
                        loading
                            ? <Loader />
                            : !productsHistory?.length > 0
                                ? <EmptyMessage
                                    message={message}
                                />
                                : productsHistory?.map((item) =>
                                    <div className="userProductHistory__item"
                                        key={item._id}
                                    >
                                        <div className="userProductHistory__imgBox">
                                            <img
                                                src={generatePublicUrl(item.img)}
                                                alt={item.fullName} />
                                        </div>
                                        <div className="userProductHistory__fullName">
                                            {item.fullName}
                                        </div>
                                        <div className="userProductHistory__createdAt">
                                            {`${item.createdAt.slice(0, 10) + " " + item.createdAt.slice(11, 19)}`}
                                        </div>
                                    </div>
                                )
                    }
                </div>
                <div className="userProductHistory__infoContainer">
                    <div className="userProductHistory__infoContainer__row">
                        {infoArray.map((item, index) =>
                            <div className="userProductHistory__infoContainer__item"
                                key={index}
                            >
                                <div className="userProductHistory__infoContainer__name">
                                    {item.name}
                                </div>
                                <div className="userProductHistory__infoContainer__content">
                                    {item.content}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ContainerDetailsContent>
    )
}

export default UserHistoryItems