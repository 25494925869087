import React, { useState } from 'react'
import NewModal from '../../../components/UI/Modal'
import Input from '../../../components/UI/Input';
import { TextField, Button } from '@material-ui/core';
import BRenderAddPictures from './../../../components/Button/BRenderAddPictures';
import Loader from '../../../components/Loader';
import { Row, Col } from 'react-bootstrap';
import { generatePublicUrl } from '../../../urlConfig';
import RenderImgSliderModal from '../../../components/ProductImgSliderModal/RenderImgSliderModal';
import { useSelector } from 'react-redux';

const AddElementPaymentPage = ({
    show,
    handleClose,
    onSubmit,
    currentId,
    itemObj,
    formItem,
    setPictures,
    loading,
    setFormItem,
    typeModal,
    showModalImg,
    setShowModalImg
}) => {

    const handleAddItem = () => {
        const newItemsInfo = [...formItem.itemsInfo, { title: '', itemsDescription: [{ text: '' }] }];
        setFormItem({ ...formItem, itemsInfo: newItemsInfo });
    }

    const handleRemoveItem = (index) => {
        const newItemsInfo = formItem.itemsInfo.filter((item, i) => i !== index);
        setFormItem({ ...formItem, itemsInfo: newItemsInfo });
    }

    const handleTitleChange = (event, index) => {
        const newItemsInfo = [...formItem.itemsInfo];
        newItemsInfo[index].title = event.target.value;
        setFormItem({ ...formItem, itemsInfo: newItemsInfo });
    }

    const handleDescriptionChange = (event, itemIndex, descIndex) => {
        const newItemsInfo = [...formItem.itemsInfo];
        newItemsInfo[itemIndex].itemsDescription[descIndex].text = event.target.value;
        setFormItem({ ...formItem, itemsInfo: newItemsInfo });
    }

    const renderItemInfo = () => {
        return (
            <div>
                {formItem.itemsInfo.map((item, itemIndex) => (
                    <div key={itemIndex}
                        style={{
                            border: "1px solid gray",
                            padding: "10px",
                            marginTop: "25px"
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "space-between",
                            maxWidth: "300px",

                        }}>

                            <TextField
                                label="Заголовок"
                                value={item.title}
                                onChange={(e) => handleTitleChange(e, itemIndex)}
                            />
                            <Button variant="outlined" onClick={() => handleRemoveItem(itemIndex)}>
                                Удалить
                            </Button>

                        </div>
                        {item.itemsDescription.map((desc, descIndex) => (
                            <div
                                style={{
                                }}
                                key={descIndex}>
                                <TextField
                                    label="Описание"
                                    value={desc.text}
                                    onChange={(e) => handleDescriptionChange(e, itemIndex, descIndex)}
                                />
                            </div>
                        ))}
                        <Button
                            style={{
                                marginTop: "20px"
                            }}
                            variant="outlined"
                            onClick={() => {
                                const newItemsInfo = [...formItem.itemsInfo];
                                newItemsInfo[itemIndex].itemsDescription.push({ text: '' });
                                setFormItem({ ...formItem, itemsInfo: newItemsInfo });
                            }}>
                            Добавить описание
                        </Button>
                    </div>
                ))}
                <Button
                    style={{
                        marginTop: "20px"
                    }}
                    variant="outlined"
                    onClick={handleAddItem}>
                    создать новый элемент
                </Button>
            </div>

        );
    }

    if (!itemObj) return null
    if (loading) return <Loader />
    const renderItem = () => {
        let content;
        switch (typeModal) {
            case "renderDetails":
                return content = <>
                    <Row>
                        <Col md="6">
                            <label className="key">Заголовок</label>
                            <p className="value">{itemObj.mainTitle}</p>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <label className="key">Описание заголовка</label>
                            <p className="value">{itemObj.mainDescription}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <label className="key">Дата добавления</label>
                            <p className="value">{itemObj.createdAt}</p>
                        </Col>
                        <Col md="6">
                            <label className="key">Послденее обновление</label>
                            <p className="value">{itemObj.updatedAt}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <label className="key">Подробное Описание</label>
                            <p className="value">{itemObj.itemsInfo.map((itemInfo) =>
                                <>
                                    <div>
                                        {itemInfo.title}
                                    </div>
                                    <div>
                                        {itemInfo.itemsDescription.map((itemDesc, index) =>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                border: "1px solid gray",
                                                padding: "10px",
                                                marginBottom: "10px"
                                            }}>
                                                <div style={{
                                                    width: "30px"
                                                }}>
                                                    {index + 1}
                                                </div>
                                                <div>
                                                    {itemDesc.text}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="key">Фотографии</label>
                            <div style={{ display: "flex" }}>
                                {itemObj.pictures.length
                                    ? itemObj.pictures.map((picture) => (
                                        <div
                                            onClick={() => setShowModalImg(true)}
                                            className="productImgContainer m-1">
                                            <img src={generatePublicUrl(picture.img)} />
                                        </div>
                                    ))
                                    : <p className="value">Фото нет</p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <RenderImgSliderModal
                        item={itemObj.pictures}
                        setShowModalImg={setShowModalImg}
                        showModalImg={showModalImg}
                    />
                </>
            default:
                return <>
                    <Input
                        label="Основной заголовок"
                        name={"mainTitle"}
                        value={formItem.mainTitle}
                        placeholder={"Введите область"}
                        onChange={(e) => setFormItem({ ...formItem, mainTitle: e.target.value })}
                    />
                    <Input
                        label="Основное описание"
                        name={"mainDescription"}
                        value={formItem.mainDescription}
                        placeholder={"Введите область"}
                        onChange={(e) => setFormItem({ ...formItem, mainDescription: e.target.value })}
                    />
                    {renderItemInfo()}
                    <BRenderAddPictures
                        setPictures={setPictures}
                    // pictures={pictures}
                    />
                </>
        }
    }

    return (
        <NewModal
            show={show}
            handleClose={handleClose}
            modalTitle={currentId ? "Редактировать" : "Добавить"}
            onSubmit={onSubmit}
        >
            {renderItem()}
        </NewModal>
    )
}

export default AddElementPaymentPage