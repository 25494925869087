import React from 'react'
import VariantBlock from './VariantBlock'
import { editProductMaterialsById } from '../../../actions';
import { useDispatch } from 'react-redux';

const AddedButtons = ({
    setEditedMaterialsFinal,
    editedMaterialsFinal,
    productId
}) => {
    const numEditedMaterials = Object.keys(editedMaterialsFinal).length;
    const dispatch = useDispatch()

    const editMaterialProductByIds = () => {
        if (numEditedMaterials > 0) {
            const payload = {
                editedMaterials: editedMaterialsFinal
            }
            dispatch(editProductMaterialsById(productId, payload))
                .then(() => {
                    setEditedMaterialsFinal({})
                })
        }
    }

    const buttonArray = [
        {
            text: "Обновить материалы",
            onClickButton: editMaterialProductByIds
        },
        {
            text: "Просчет суммы",
        },
        // {
        //     text: "Посмотреть добавленное",
        //     value: "viewAdded"
        // },
    ]

    const handleAddedButton = () => {
        console.log("Edit")
    }

    if (!numEditedMaterials > 0) return null
    return (
        <VariantBlock
            items={buttonArray}
            onClick={handleAddedButton}
        />
    )
}

export default AddedButtons