import React, { useState } from 'react'
import { generatePublicUrl } from '../urlConfig'
import Loader from './Loader';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductNewById, updateProductPriceVisibleById, updateProductVisibleById, createQRCodeById } from './../actions/product.action';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import Tooltip from '@mui/material/Tooltip';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import Checkbox from '@mui/material/Checkbox';
import TextViews from './TextViews';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Button } from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import StatusProductPictures from '../containers/ComponentContainer/StatusProductPictures';
import VisibleIconComponent from './VisibleIconComponent';
import ExtensionIcon from '@mui/icons-material/Extension';
import GetVisibleImageUrl from './GetVisibleImageUrl';

const RenderFileContainer = (props) => {
    const {
        setShowSettingCategoryModal,
        handleShowMaterialModal,
        setShowDeleteModal,
        showSettingModal,
        showDetailsModal,
        setType,
        type,
        setTypeModal,
        setCurrentId,
        categoryObj,
        setDeleteId,
        hideMarked,
        fileView,
        loading,
        setShow,
        items,
        slug,
    } = props

    const productTwo = useSelector((state) => state.product.productsTwo)

    const dispatch = useDispatch()

    const handleShowSettingModal = () => {
        setShowSettingCategoryModal(true)
    }
    const renderMainTitle = () => {
        return (
            <div className="product__renderTitleNameCategory">
                {slug === "all" ? <h1>Все товары</h1> : <h1>{categoryObj?.name}</h1>}
                {slug === "all" ? null : <SettingsIcon onClick={() => handleShowSettingModal()} />}
            </div>
        )
    }

    const testProduct = () => {
        if (hideMarked) {
            return productTwo
        } else {
            return items
        }

    }
    const handleVisibleProduct = (id) => {
        dispatch(updateProductVisibleById(id, slug))
    }

    const handleVisibleProductPrice = (id) => {
        dispatch(updateProductPriceVisibleById(id, slug))
    }
    const Edit = (item) => {
        setCurrentId(item._id)
        setShow(true)
    }
    const confirmDeletions = (item) => {
        setDeleteId(item._id)
        setShowDeleteModal(true)

    }

    const renderEmptyItem = () => {
        return (
            <div className="renderEmptyItem">
                <h3>Обьектов пока что нет, Нажмите на кнопку ниже чтобы добавить первый из них</h3>
                <div className="renderEmptyItem__buttonContainer">
                    <button onClick={() => setShow(true)} >Добавить первый товар</button>
                </div>
            </div>
        )
    }
    const handleProductValue = (e) => {

    }

    const createQRCode = (item, typeModal) => {
        if (item.QRCodePicture) {
            showDetailsModal(item, typeModal)
        } else {
            dispatch(createQRCodeById(item._id))
        }

    }
    const handleProduction = (item, typeModal) => {
        showDetailsModal(item, typeModal)
    }
    if (loading) return <Loader />
    if (!items) return null

    switch (fileView) {
        case "plate":
            return (
                <div className="fileplate">
                    {renderMainTitle()}
                    {!testProduct() ? renderEmptyItem() :
                        testProduct().map((item) =>
                            <div className='file-plate' >
                                <div className="file-plate__imgContainer">
                                    <img
                                        onClick={() => showDetailsModal(item, "details")}
                                        className="file__img"
                                        src={generatePublicUrl(item.productPictures ? item.reducedImage : item.images[0]?.img)}
                                        alt={item.name}
                                    />
                                    {item.productPictures.length > 0 ? item.productPictures.map((img) => img)
                                        :
                                        <div className="file__img__hiddenPhotos">
                                        </div>
                                    }
                                </div>
                                <div className="file-plate__name">
                                    {item.title ? item.title : item.fullName !== "" ? item.fullName : "Не указано"}
                                </div>
                                <div className="file-plate__btns">
                                    <button className="file-plate__buttons setting" onClick={() => Edit(item)}><SettingsIcon /></button>
                                    <button className="file-plate__buttons delete" onClick={() => confirmDeletions(item)}><DeleteIcon /></button>
                                </div>
                            </div>
                        )}
                </div>
            )
        default:
            return (
                <div>
                    {renderMainTitle()}
                    {testProduct().length === 0 ? renderEmptyItem() :
                        testProduct().map((item) =>
                            <div
                                className={!item.visibleProduct ? "file hideProduct" : "file"}
                                key={item._id}>
                                {/* <div className="file__statusProductPictures">
                                    <StatusProductPictures
                                        productPicturesStatus={item.updatedPicturesStatus}
                                    />
                                </div> */}
                                <div className="file__mainContainer">

                                    <div className="file__mainSettingContainer">
                                        <Checkbox
                                            color='primary'
                                            value={item}
                                            onChange={(e) => handleProductValue(e)}
                                        />
                                        <div className="file__visibleIconBlock"
                                            onClick={() => showDetailsModal(item, "updatedPictures")}
                                        >
                                            <Tooltip title="Работы по 3д и фото">
                                                <PrecisionManufacturingIcon />
                                            </Tooltip>
                                        </div>
                                        <div className="file__visibleIconBlock"
                                            onClick={() => showSettingModal(item)}
                                        >
                                            <Tooltip title="Настройки видимости">
                                                <SettingsIcon />
                                            </Tooltip>
                                        </div>
                                        <div className="file__visibleIconBlock"
                                            onClick={() => handleShowMaterialModal(item)}
                                        >
                                            <Tooltip title="Материалы">
                                                <ViewInArIcon />
                                            </Tooltip>
                                        </div>
                                        <div
                                            onClick={() => createQRCode(item, "QRCode")}
                                        >
                                            {item.QRCodePicture
                                                ? <Tooltip title="Посмотреть QRCode">
                                                    <QrCodeIcon />
                                                </Tooltip>
                                                : <Tooltip title="Сгенерировать QRCode">
                                                    <QrCodeIcon style={{ color: "black" }} />
                                                </Tooltip>
                                            }
                                        </div>
                                        {hideMarked
                                            ? null
                                            : <VisibleIconComponent
                                                handleClick={handleVisibleProduct}
                                                itemId={item._id}
                                                itemVisible={item.visibleProduct}
                                                text="товар"
                                            />
                                        }
                                    </div>

                                    <div className={`file__containerImg ${item.updatedPictures ? "updatedPictures" : ""}`}>
                                        <img
                                            className="file__img"
                                            onClick={() => showDetailsModal(item, "details")}
                                            src={item.productPictures
                                                ? generatePublicUrl(item.reducedImage)
                                                : generatePublicUrl(item.images[0]?.img)
                                            }
                                            alt={item.title ? item.title : item.name} />

                                        <div className="file__timeBlock">
                                            Товар был добавлен: {`${item.createdAt.slice(0, 10)} ${item.createdAt.slice(11, 19)}`}
                                        </div>
                                    </div>
                                    <div className="file__mainContentContainer">
                                        <div className="file__contentBox">
                                            <div className="file__name">
                                                {item.fullName !== "" ? item.fullName : "Не указано"}

                                            </div>
                                            <TextViews
                                                itemViews={item.viewsLength}
                                            />
                                            <Tooltip
                                                onClick={() => showDetailsModal(item, "production")}
                                                title="Производство">
                                                <ExtensionIcon />
                                            </Tooltip>
                                        </div>
                                        {/* <div className="file__newSettingBox">
                                            <button onClick={() => updateSetProdNew(item)}>1.Материалы по умолчанию</button>
                                        </div>
                                        <div className="file__newSettingBox">
                                            <button onClick={() => updateSetProdMaterialName(item)}>3.Имя по материалам</button>
                                        </div> */}
                                        <div className="file__mainPriceContainer">
                                            <div className="file__visibleIconBlock"
                                                onClick={() => handleVisibleProductPrice(item._id)}
                                            >

                                                {item.visiblePrice
                                                    ? <Tooltip title="Скрыть цену"><AttachMoneyIcon /></Tooltip>
                                                    : <Tooltip title="Отобразить цену"><MoneyOffIcon style={{ color: "black" }} /></Tooltip>
                                                }


                                            </div>
                                            {item.visiblePrice
                                                ? <div className="file__price">
                                                    {item.price}
                                                </div>
                                                :
                                                <div className="file__price">
                                                    Скрыто
                                                </div>
                                            }
                                        </div>
                                        <div className="file__size">

                                        </div>
                                    </div>
                                    <div className="file__buttonContainer">
                                        <button className="file__btn file__download" onClick={() => Edit(item)}>Редактировать</button>
                                        <button className="file__btn file__delete " onClick={() => confirmDeletions(item)}>Удалить</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div >
            )
    }
}
export default RenderFileContainer

