import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader';
import SettingIconBlock from '../../components/Button/SettingIconBlock';
import StatusOrder from '../Orders/StatusOrder';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import IsViewedIconsList from './IsViewedIconsList';

export const RenderItems = ({
    applicationsCallMe,
    setTypeModal,
    showModal,
    typeItems,
    deleteDispatch
}) => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);


    const dispatch = useDispatch()


    if (applicationsCallMe?.length === 0) {
        return <Loader />;
    }


    const handleSelectAll = () => {
        setSelectAll(!selectAll);
    };
    const handleDeleteMany = () => {
        let payload;
        if (selectedItems.length > 0) {
            payload = {
                ids: selectedItems
            }
            dispatch(deleteDispatch(payload))
        }
    }
    const filterOrderStatusColor = (text) => {
        let color;
        switch (text) {
            case "Оформлена":
                color = "gray";
                break;
            case "На рассмотрении":
                color = "orange";
                break;
            case "Подтверждена оператором":
                color = "green";
                break;
            case "Отклонено":
                color = "red";
                break;
            default:
                color = "gray";
        }
        return color;
    };
    //переделать так чтобы работало свойство с разными заявками, переждать диспатч через пропсы 


    return (
        <div>
            <Button
                onClick={handleSelectAll}
            >
                {!selectAll ? "Выделить все" : "Отменить выбранные"}
            </Button>
            <Button
                disabled={true}
                onClick={handleDeleteMany}
            >
                Удалить выбранные
            </Button>
            <div className="tableContainer">
                <table className="table table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Мобильный</th>
                            <th scope="col">Имя</th>
                            <th scope="col">Email</th>
                            <th scope="col">Время</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applicationsCallMe?.length > 0
                            ? applicationsCallMe?.map((a) =>

                                <IsViewedIconsList
                                    item={a}
                                    items={applicationsCallMe}
                                    showModal={showModal}
                                    setTypeModal={setTypeModal}
                                    selectAll={selectAll}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                >
                                    <th scope="row">{a.applicationNumber}</th>
                                    <td>  <StatusOrder
                                        filterOrderStatusColor={filterOrderStatusColor}
                                        status={a.applicationCallMeStatus || a.applicationStatus}
                                        mini
                                    /></td>
                                    <td>{
                                        typeItems === "changeProfile"
                                            ? `${a.userObj.contactNumber}`
                                            : a.contactNumber}</td>
                                    <td>{
                                        typeItems === "changeProfile"
                                            ? `${a.userObj.firstName + " " + a.userObj.lastName}`
                                            : a.fullName}
                                    </td>
                                    <td>{
                                        typeItems === "changeProfile"
                                            ? a.userObj.email
                                            : a.email}
                                    </td>
                                    <td>{a.createdAt.slice(11, 19)}</td>


                                </IsViewedIconsList>
                            )
                            : <div style={{
                                margin: "20px"
                            }}>Пока нет созданных заявок</div>}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
