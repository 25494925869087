import { galleryImageConstants, mainActionsConstants } from "../actions/constants"

const initialState = {
    galleries: [],
    galleryObj: {},
    loading: false,
    message: "",
    showAlert: false,
    variantAlert: "success",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case galleryImageConstants.ADD_SAMPLE_PHOTO_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case galleryImageConstants.ADD_SAMPLE_PHOTO_SUCCESS:
            state = {
                ...state,
                productDetails: action.payload?.application,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            };
            break;
        case galleryImageConstants.ADD_SAMPLE_PHOTO_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            };
            break;
        case galleryImageConstants.REMOVE_GALLERY_PICTURES_IDS_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case galleryImageConstants.REMOVE_GALLERY_PICTURES_IDS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                galleryObj: action.payload.gallery,
                showAlert: true,
                loading: false
            };
            break;
        case galleryImageConstants.REMOVE_GALLERY_PICTURES_IDS_FAILURE:
            state = {
                ...state,
                message: action.payload.message,
                showAlert: true,
                variantAlert: "warning",
                loading: false
            };
            break;
        case galleryImageConstants.ADD_SAMPLE_PHOTO_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case galleryImageConstants.ADD_SAMPLE_PHOTO_SUCCESS:
            state = {
                ...state,
                galleryObj: action.payload?.gallery,
                loading: false,
            };
            break;
        case galleryImageConstants.ADD_SAMPLE_PHOTO_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case galleryImageConstants.GET_GALLERY_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case galleryImageConstants.GET_GALLERY_BY_ID_SUCCESS:
            state = {
                ...state,
                galleryObj: action.payload?.gallery,
                loading: false,
            };
            break;
        case galleryImageConstants.GET_GALLERY_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case galleryImageConstants.CREATE_GALLERY_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case galleryImageConstants.CREATE_GALLERY_SUCCESS:
            state = {
                ...state,
                productDetails: action.payload?.application,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            };
            break;
        case galleryImageConstants.CREATE_GALLERY_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            };
            break;
        case galleryImageConstants.GET_ALL_GALLERY_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case galleryImageConstants.GET_ALL_GALLERY_SUCCESS:
            state = {
                ...state,
                galleries: action.payload?.items,
                loading: false,
            }
            break;
        case galleryImageConstants.GET_ALL_GALLERY_FAILURE:
            state = {
                ...state,
                loading: false,
            }
            break;
        case galleryImageConstants.DELETE_GALLERY_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case galleryImageConstants.DELETE_GALLERY_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "success",
                message: action.payload?.message,
            }
            break;
        case galleryImageConstants.DELETE_GALLERY_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                showAlert: true,
                variantAlert: "warning",
                message: action.payload?.message,
            }
            break;
        case mainActionsConstants.GET_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            };
            break;
        case mainActionsConstants.GET_BY_ID_SUCCESS:
            state = {
                ...state,
                galleryObj: action.payload?.item,
                loading: false,
            };
            break;
        case mainActionsConstants.GET_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            };
            break;
        case mainActionsConstants.SET_CLOSE_ALERT:
            state = {
                ...state,
                showAlert: false
            }
            break;
        default:
            return state
    }
    return state;
}

export const setCloseAlertGallery = (payload) => ({ type: mainActionsConstants.SET_CLOSE_ALERT, payload })
