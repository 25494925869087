import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import Input from '../../components/UI/Input';
import { useSelector, useDispatch } from 'react-redux';
import { getColorProduct, updateOrderAdditionalField } from '../../actions';
import Modal from '../../components/UI/Modal';
import AdditionalField from './AdditionalField';
import RenderSettingOrderProductDetails from './RenderSettingOrderProductDetails';
import RenderSelectModel from '../../components/RenderSelectModel';


const RenderSettingOrderProductModal = ({
    setShowAddAdditionalField,
    productOrderDetails,
    dataAdditional,
    orderObj,
    loading,
    onHide,
    show
}) => {
    const { colorProducts } = useSelector((state) => state.colorProduct)

    const [additionalArray, setAdditionalArray] = useState([])
    const [updateFunc, setUpdateFunc] = useState(false)
    const [purchasedQty, setPurchasedQty] = useState(productOrderDetails === null ? "" : productOrderDetails.purchasedQty)
    const [bayerPrice, setBayerPrice] = useState(productOrderDetails === null ? "" : productOrderDetails.bayerPrice)
    const [typeProductColor, setTypeProductColor] = useState(productOrderDetails === null ? "" : productOrderDetails.typeProductColor)
    const [length, setLength] = useState(productOrderDetails === null ? "" : productOrderDetails.length)
    const [height, setHeight] = useState(productOrderDetails === null ? "" : productOrderDetails.height)
    const [width, setWidth] = useState(productOrderDetails === null ? "" : productOrderDetails.width)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getColorProduct())
    }, [])
    useEffect(() => {
        if (updateFunc) {
            setPurchasedQty(productOrderDetails?.purchasedQty)
            setBayerPrice(productOrderDetails?.bayerPrice)
            setTypeProductColor(productOrderDetails?.typeProductColor)
            setLength(productOrderDetails?.length)
            setHeight(productOrderDetails?.height)
            setWidth(productOrderDetails?.width)
        }
    }, [updateFunc])

    useEffect(() => {
        if (productOrderDetails !== null) {
            setAdditionalArray(productOrderDetails.additionalFieldItems)
        }
    }, [dataAdditional, !dataAdditional])

    if (!productOrderDetails) {
        return null
    }
    const submitSettingOrderProduct = (e) => {
        const payload = {
            length: length,
            purchasedQty: purchasedQty,
            height: height,
            width: width,
            typeProductColor: typeProductColor,
            bayerPrice: bayerPrice,
            productOrderDetailsId: productOrderDetails._id,
            typeSet: "color",
            orderId: orderObj._id,
        }
        dispatch(updateOrderAdditionalField(payload))
    }


    const handleClose = () => {
        clear()
        onHide()
        setUpdateFunc(false)
    }

    const clear = () => {
        setBayerPrice("")
        setTypeProductColor("")
        setLength("")
        setHeight("")
        setWidth("")
    }

    if (productOrderDetails === null) return null
    return (
        <Modal
            show={show}
            handleClose={handleClose}
            modalTitle={`Заполнить поля к: ${productOrderDetails.productId.name}`}
            onSubmit={submitSettingOrderProduct}
            disabledFunctional={orderObj.disableFunctionality}
        >
            {orderObj.disableFunctionality ? null : <Button
                onClick={() => setUpdateFunc(!updateFunc)}
            >
                {updateFunc ? "Подробнее" : "Изменить"}
            </Button>
            }
            {!updateFunc
                ? <RenderSettingOrderProductDetails
                    productOrderDetails={productOrderDetails}
                    setUpdateFunc={setUpdateFunc}
                />
                : <>   <Input
                    label="Указать цену для покупателей!"
                    name={"bayerPrice"}
                    value={bayerPrice}
                    placeholder="Введите в цифрах"
                    onChange={(e) => setBayerPrice(e.target.value)}

                />
                    <Input
                        label="Исправить количество"
                        name={"purchasedQty"}
                        value={purchasedQty}
                        placeholder="Введите в цифрах"
                        onChange={(e) => setPurchasedQty(e.target.value)}

                    />
                    <RenderSelectModel
                        value={typeProductColor}
                        setValue={setTypeProductColor}
                        itemsModel={colorProducts}
                        handleChange={() => console.log("handleChange")}
                    />
                    <h3 style={{
                        marginTop: "15px"
                    }}>
                        Точные размеры
                    </h3>
                    <Row>
                        <Col md="4">
                            <Input
                                label="Высота( в мм.)"
                                name="height"
                                value={height}
                                placeholder="Высота"
                                onChange={(e) => setHeight(e.target.value)}
                            />
                        </Col>
                        <Col md="4">
                            <Input
                                label="Ширина( в мм.)"
                                name="width"
                                value={width}
                                placeholder="Ширина"
                                onChange={(e) => setWidth(e.target.value)}
                            />
                        </Col>
                        <Col md="4">
                            <Input
                                label="Длина ( в мм.)"
                                name="length"
                                value={length}
                                placeholder="Длина"
                                onChange={(e) => setLength(e.target.value)}
                            />
                        </Col>
                    </Row></>}
            <AdditionalField
                loading={loading}
                orderObj={orderObj}
                setShowAddAdditionalField={setShowAddAdditionalField}
                productOrderDetails={productOrderDetails}
            />
        </Modal>
    );
}

export default RenderSettingOrderProductModal;