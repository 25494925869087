import { userConstants } from "../actions/constants"

const initState = {
    users: [],
    cart: [],
    desires: [],
    productsHistory: [],
    totalNumberProduct: null,
    userDetails: {},
    userId: null,
    error: null,
    message: "",
    showModal: false,
    showAlert: false,
    typeModalUser: "info",
    variantAlert: "",
    loading: false
}

export default (state = initState, action) => {
    switch (action.type) {
        case userConstants.SHOW_MODAL_CART:
            state = {
                ...state,
                userId: action.payload.userId,
                showModal: true
            }
            break;
        case userConstants.EDIT_TYPE_MODAL_USER:
            state = {
                ...state,
                typeModalUser: action.payload
            }
            break;
        case userConstants.SHOW_MODAL_CART_FALSE:
            state = {
                ...state,
                showModal: false
            }
            break;
        case userConstants.UPDATE_USER_ROLE_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case userConstants.UPDATE_USER_ROLE_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                userDetails: action.payload.user,
                showAlert: true,
            }
            break;
        case userConstants.UPDATE_USER_ROLE_FAILURE:
            state = {
                ...state,
                loading: false,
                variantAlert: "warning",
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                userDetails: action.payload.user,
                showAlert: true,
            }
            break;
        case userConstants.UPDATE_TOGGLE_SUBSCRIPTION_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
                variantAlert: "warning",
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case userConstants.GET_USER_PRODUCT_VIEWS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.GET_USER_PRODUCT_VIEWS_SUCCESS:
            state = {
                ...state,
                loading: false,
                productsHistory: action.payload.productsHistory,
                totalNumberProduct: action.payload.totalNumberProduct
            }
            break;
        case userConstants.GET_USER_PRODUCT_VIEWS_FAILURE:
            state = {
                ...state,
                productsHistory: action.payload.productsHistory,
                message: action.payload.message,
                loading: false,
            }
            break;
        case userConstants.ADD_STANDARD_SUBSCRIPTIONS_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.ADD_STANDARD_SUBSCRIPTIONS_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                showAlert: true,
                userDetails: action.payload.updateUser
            }
            break;
        case userConstants.ADD_STANDARD_SUBSCRIPTIONS_FAILURE:
            state = {
                ...state,
                variantAlert: "warning",
                message: action.payload.message,
                showAlert: true,
                loading: false,
            }
            break;
        case userConstants.GET_USER_CART_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.GET_USER_CART_SUCCESS:
            state = {
                ...state,
                loading: false,
                cart: action.payload?.cart,
            }
            break;
        case userConstants.GET_USER_CART_FAILURE:
            state = {
                ...state,
                cart: action.payload?.cart,
                message: action.payload?.message,
                loading: false,
            }
            break;
        case userConstants.GET_USER_DESIRES_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.GET_USER_DESIRES_SUCCESS:
            state = {
                ...state,
                loading: false,
                desires: action.payload?.desires
            }
            break;
        case userConstants.GET_USER_DESIRES_FAILURE:
            state = {
                ...state,
                loading: false,
                desires: action.payload?.desires,
                message: action.payload?.message,
            }
            break;
        case userConstants.UPDATE_USER_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.UPDATE_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                userDetails: action.payload.user
            }
            break;
        case userConstants.UPDATE_USER_FAILURE:
            state = {
                ...state,
                loading: false,
            }
            break;
        case userConstants.UPDATE_USER_PASSWORD_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.UPDATE_USER_PASSWORD_SUCCESS:
            state = {
                ...state,
                loading: false,
                userDetails: action.payload.user
            }
            break;
        case userConstants.UPDATE_USER_PASSWORD_FAILURE:
            state = {
                ...state,
                loading: false,
            }
            break;
        case userConstants.GET_USER_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.GET_USER_BY_ID_SUCCESS:
            state = {
                ...state,
                loading: false,
                userDetails: action.payload.userDetails
            }
            break;
        case userConstants.GET_USER_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            }
            break;
        case userConstants.GET_ALL_USERS_SUCCESS:
            state = {
                ...state,
                users: action.payload.users
            }
            break;
        case userConstants.USER_REGISTER_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case userConstants.USER_REGISTER_SUCCESS:
            state = {
                ...state,
                loading: false,
                variantAlert: "success",
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case userConstants.USER_REGISTER_FAILURE:
            state = {
                ...state,
                loading: false,
                variantAlert: "warning",
                message: action.payload.message,
                showAlert: true,
            }
            break;
        case userConstants.CLOSE_ALERT_TO_REGISTER_ADMIN:
            state = {
                ...state,
                showAlert: false
            }
            break;
        case userConstants.CLEAR_USER_INFO_CART:
            state = {
                ...state,
                cart: [],
                productsHistory: [],
                desires: []
            }
            break;
        // case userConstants.CLEAR_USER_INFO_DESIRES:
        //     state = {
        //         ...state,
        //     }
        //     break;
        default:
            return state
    }

    return state;
}
export const clearUserInfoCart = (payload) => ({
    type: userConstants.CLEAR_USER_INFO_CART,
    payload
})
// export const clearUserInfoDesires = (payload) => ({
//     type: userConstants.CLEAR_USER_INFO_DESIRES,
//     payload
// })
export const setCloseAlertUser = (payload) => ({
    type: userConstants.CLOSE_ALERT_TO_REGISTER_ADMIN,
    payload
})
export const setUser = (users) => ({
    type: userConstants.GET_ALL_USERS_SUCCESS,
    payload: users
})
export const setShowUserModal = (payload) => ({
    type: userConstants.SHOW_MODAL_CART,
    payload
})
export const setHideUserModal = (payload) => ({
    type: userConstants.SHOW_MODAL_CART_FALSE,
    payload
})
export const setEditTypeModalUser = (payload) => ({
    type: userConstants.EDIT_TYPE_MODAL_USER,
    payload
})