import { materialConstants } from '../../actions/constants';

const initialState = {
    material: [],
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case materialConstants.UPDATE_MATERIAL_SUCCESS:
            return state.material.filter((obj) => obj._id === action.payload._id ? action.payload : obj);
        case materialConstants.DELETE_MATERIAL_SUCCESS:
            return state.material.filter((obj) => obj._id !== action.payload);
        case materialConstants.GET_MATERIAL_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break
        case materialConstants.GET_MATERIAL_SUCCESS:
            state = {
                ...state,
                material: action.payload.material,
                loading: false

            }
            break
        case materialConstants.GET_MATERIAL_FAILURE:
            state = {
                ...state,
                loading: false

            }
            break
        default:
            return state
    }
    return state
}