import React from 'react'
import Loader from '../../components/Loader'

const RenderUsers = ({
    users,
    showUserDetailsModal
}) => {
    if (users.length === 0) {
        return (
            <Loader />
        )
    }

    return (
        <div style={{ marginTop: "20px" }}>
            <table className="table table-hover table-dark">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Роль</th>
                        <th scope="col">Имя</th>
                        <th scope="col">Фамилия</th>
                        <th scope="col">email</th>
                        <th scope="col">Дата регистрации</th>
                    </tr>
                </thead>
                <tbody>
                    {users.length > 0
                        ? users.map((user, index) =>
                            <tr key={user._id}
                                onClick={() => showUserDetailsModal(user._id, "details")}

                            >
                                <th scope="row">{index + 1}</th>
                                <td>{user.role}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>
                                    {user.createdAt.slice(0, 10)}
                                    &nbsp;
                                    {user.createdAt.slice(11, 19)}
                                </td>
                            </tr>
                        )
                        : null}

                </tbody>
            </table>
        </div>

    )
}

export default RenderUsers