import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Input from '../../components/UI/Input/index';
import Modal from '../../components/UI/Modal';
import { updateOrderTotalAmount } from './../../actions/order.action';

const RenderSettingOrderNumberModal = ({
    orderObj,
    onHide,
    show,
    orderId
}) => {
    const [orderNumberAdditional, setOrderNumberAdditional] = useState(
        orderObj === null ? "" : orderObj.orderNumberAdditional
    )
    const [orderNumber, setOrderNumber] = useState(
        orderObj === null ? "" : orderObj.orderNumber
    )
    useEffect(() => {
        if (show) {
            setOrderNumberAdditional(orderObj.orderNumberAdditional)
            setOrderNumber(orderObj.orderNumber)
        }
    }, [show])

    const dispatch = useDispatch()
    const handleOrderNumberAdditional = () => {
        const payload = {
            typeSet: "orderNumberAdditional",
            orderNumber: orderNumber,
            orderNumberAdditional: orderNumberAdditional,
            orderId: orderId
        }
        dispatch(updateOrderTotalAmount(payload))

    }
    return (
        <Modal
            show={show}
            handleClose={onHide}
            modalTitle={`Настройка номера заказа`}
            onSubmit={handleOrderNumberAdditional}
        >

            <Input
                label="Основной номер"
                value={orderNumber}
                placeholder={"Основной"}
                onChange={(e) => setOrderNumber(e.target.value)}
            />
            <Input
                label="Доп-номер(текст)"
                value={orderNumberAdditional}
                placeholder={"Дополнительный номер"}
                onChange={(e) => setOrderNumberAdditional(e.target.value)}
            />
        </Modal>
    )
}

export default RenderSettingOrderNumberModal