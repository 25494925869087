import axios from "../../helpers/axios"
import { updatedModalContent, deletedModalContent } from '../api/modalContent';
import { modalContentConstants } from './../constants';

export const getModalContent = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: modalContentConstants.GET_MODAL_CONTENT_REQUEST })
            const res = await axios.get("modalContent/get")
            if (res.status === 200) {
                const { modalContent } = res.data
                dispatch({
                    type: modalContentConstants.GET_MODAL_CONTENT_SUCCESS,
                    payload: {
                        modalContent
                    }
                })
            } else {
                dispatch({ type: modalContentConstants.GET_MODAL_CONTENT_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addModalContent = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: modalContentConstants.ADD_MODAL_CONTENT_REQUEST })
            const res = await axios.post("modalContent/create", form)
            if (res.status === 201) {
                dispatch({ type: modalContentConstants.ADD_MODAL_CONTENT_SUCCESS })
                dispatch(getModalContent())
            } else {
                dispatch({ type: modalContentConstants.ADD_MODAL_CONTENT_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}
export const updateModalContent = (id, modalContent) => async (dispatch) => {
    try {
        const { data } = await updatedModalContent(id, modalContent)
        dispatch({
            type: modalContentConstants.UPDATE_MODAL_CONTENT_SUCCESS, payload: data
        })
        dispatch(getModalContent())
    } catch (error) {
        console.log(error);
    }
}


export const deleteModalContent = (id) => async (dispatch) => {
    try {
        await deletedModalContent(id);

        dispatch({ type: modalContentConstants.DELETE_MODAL_CONTENT_SUCCESS, payload: id });
        dispatch(getModalContent())
    } catch (error) {
        console.log(error);
    }
}