import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { MainContainer } from '../MainContainer';
import AlertComponent from '../../components/Alert/Alert';
import { deleteApplicationChangeProfileById, deleteManyApplicationChangeProfile, getApplicationChangeProfile, getApplicationChangeProfileById, searchApplicationChangeProfile, updateApplicationChangeProfileStatus, updateUser, updateUserPassword } from '../../actions';
import { setCloseAlertApplicationChangeProfile } from '../../reducers/applicationChangeProfile';
import { RenderItems } from '../ApplicationsCallMe/RenderItems';
import RenderDetailsModal from './RenderDetailsModal';
import ModalConfirm from '../../components/BurgerMenu/ModalConfirm';

const ApplicationsChangeProfile = () => {
    const {
        applicationChangeProfileObj,
        applicationsChangeProfile,
        variantAlert,
        showAlert,
        message,
        loading,
    } = useSelector(state => state.applicationChangeProfile);

    const { user } = useSelector(state => state.auth);

    const [rejectModal, setRejectModal] = useState(false)

    const [showDetailsModal, setApplicationDetailModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [valueTabsSort, setValueTabsSort] = useState("all")
    const [sortItems, setSortItems] = useState("updatedAtMinus")
    const [typeModal, setTypeModal] = useState("");
    const [typeConfirm, setTypeConfirm] = useState("");

    const [type, setType] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getApplicationChangeProfile(valueTabsSort, sortItems))
    }, [valueTabsSort])

    const handleClose = () => {
        setApplicationDetailModal(false);
        setTypeModal("")
    };
    const showModal = (id) => {
        if (selectedItemId === id) {
            setSelectedItemId(null);
        } else {
            setSelectedItemId(id);
            dispatch(getApplicationChangeProfileById(id));
        }
        setApplicationDetailModal(true);

    };
    const handleCloseConfirm = () => {
        setRejectModal(false)
    }
    const handleUpdateStatus = () => {
        const payload = {
            applicationId: applicationChangeProfileObj._id,
            type: typeConfirm === "rejected" ? "rejected" : type
        }
        dispatch(updateApplicationChangeProfileStatus(payload)).then(() => (
            dispatch(getApplicationChangeProfile(valueTabsSort, sortItems))
        ))
        if (typeConfirm === "rejected") {
            handleCloseConfirm()
        }
    }
    const handleDeleteById = () => {
        dispatch(deleteApplicationChangeProfileById(applicationChangeProfileObj._id))
        setApplicationDetailModal(false)
    }

    const handleUpdateUser = () => {
        let payload;
        const variant = applicationChangeProfileObj.variant
        const userId = applicationChangeProfileObj.createdBy
        const applicationId = applicationChangeProfileObj._id
        switch (variant) {
            case "personal":
                payload = {
                    fieldsToUpdate: {
                        gender: applicationChangeProfileObj.gender,
                        birthData: applicationChangeProfileObj.birthData,
                        firstName: applicationChangeProfileObj.firstName,
                        lastName: applicationChangeProfileObj.lastName,
                        regionName: applicationChangeProfileObj.region.name,
                        regionId: applicationChangeProfileObj.region.id,
                    }
                }
                break;
            case "login":
                payload = {
                    fieldsToUpdate: {
                        email: applicationChangeProfileObj.email,
                    }
                }
                break;
            default:
                payload = {
                    fieldsToUpdate: {
                        contactNumber: applicationChangeProfileObj.contactNumber,
                        backupNumber: applicationChangeProfileObj.backupNumber,
                    }
                }
        }
        payload.variant = variant;
        payload.userId = userId;
        payload.applicationId = applicationId;
        dispatch(updateUser(payload))
            .then(() => dispatch(getApplicationChangeProfile(valueTabsSort, sortItems)))
        setRejectModal(false)
    }
    const handleUpdateUserPassword = () => {
        let payload;
        if (applicationChangeProfileObj.variant === "password") {

            payload = {
                applicationId: applicationChangeProfileObj._id,
                userId: applicationChangeProfileObj.createdBy,
                variant: applicationChangeProfileObj.variant,
                newPassword: applicationChangeProfileObj.newPassword
            }
            dispatch(updateUserPassword(payload))
        } else {
            alert("Вариат не равен паролю!")
        }
    }
    return (
        <MainContainer
            backButtonProduct
            type
            setValueTabsSort={setValueTabsSort}
            itemsType="application"
            sortItems={sortItems}
            tabs
            backHref
            search={searchApplicationChangeProfile}
            get={getApplicationChangeProfile}
            title="Запросы на смену данных"
        >
            <div className="applicationChangeProfile">

                <Row style={{ marginTop: "15px" }}>
                    <Col>
                        <RenderItems
                            applicationsCallMe={applicationsChangeProfile}
                            setTypeModal={setTypeModal}
                            showModal={showModal}
                            typeItems="changeProfile"
                            deleteDispatch={deleteManyApplicationChangeProfile}
                        />
                    </Col>
                </Row>
                <RenderDetailsModal
                    handleUpdateUserPassword={handleUpdateUserPassword}
                    handleUpdateStatus={handleUpdateStatus}
                    item={applicationChangeProfileObj}
                    handleDeleteById={handleDeleteById}
                    showDetailsModal={showDetailsModal}
                    setTypeConfirm={setTypeConfirm}
                    setRejectModal={setRejectModal}
                    valueTabsSort={valueTabsSort}
                    rejectModal={rejectModal}
                    handleClose={handleClose}
                    sortItems={sortItems}
                    typeModal={typeModal}
                    setType={setType}
                    role={user.role}
                />
            </div>
            <AlertComponent
                dispatchCloseAlert={setCloseAlertApplicationChangeProfile}
                color={variantAlert}
                showObj={showAlert}
                text={message}
            />
            <ModalConfirm
                title={"подтврдить отмену"}
                description="Если в действительно хотите удалить этот блог, подтвердите это"
                show={rejectModal}
                handleClose={handleCloseConfirm}
                onClick={typeConfirm === "rejected" ? handleUpdateStatus : handleUpdateUser}
            />
        </MainContainer>
    )
}

export default ApplicationsChangeProfile