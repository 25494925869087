import React from 'react'
import Layout from "../components/Layout";
import { Tabs } from './Component';
import { Container } from "react-bootstrap";
import BackButton from '../siteSetting/components/BackButton';
import RenderFunctional from './RenderFunctional';
import { useNavigate } from 'react-router-dom';
import ScrollBLock from '../components/ScrollBLock';
import Loader from '../components/Loader';
import MainContainerChildren from './MainContainerChildren';

export const MainContainer = (props) => {
    const { setValueTabsSort } = props
    const navigate = useNavigate()
    const goBack = () => navigate(-1)


    if (!props) {
        return null
    }

    return (
        <Layout sidebar>
            <Container fluid={props.fluid} >
                <div className="mainContainer__headerContainer">
                    <div>
                        {props.backButtonProduct ? <BackButton
                            // onClick={goBack}
                            href={props.backHref ? props.href ? props.href : "/" : "/containerProductPage"}
                        /> : props.noneHrefButton ? <BackButton
                            onClick={goBack}
                        /> : null}
                    </div>
                    <div>
                        <h1 className="text-center">{props.title}</h1>
                    </div>
                </div>
                {props.offAll ? null : <>
                    {props.tabs && <Tabs
                        itemsType={props.itemsType}
                        setValueTabsSort={setValueTabsSort}
                    />}
                    <RenderFunctional {...props} />
                </>
                }
                {props.loadingItems && props.loadingItems ? <Loader /> : null}

                <MainContainerChildren {...props} />

            </Container>
            <ScrollBLock />
        </Layout>
    )
}
