import React from 'react'
import { useDispatch } from "react-redux";
import Loader from '../../components/Loader';
import { generatePublicUrl } from '../../urlConfig';

const ObjRender = ({
    setShowDeleteModal,
    deleteModal,
    setCurrentId,
    deleteFunc,
    mainTitle,
    typeView,
    setShow,
    loading,
    setTypeModal,
    getById,
    obj,
}) => {
    const dispatch = useDispatch()

    const Edit = (item, type) => {
        setTypeModal(type)
        setCurrentId(item._id)
        setShow(true)
        if (type === "details") {
            dispatch(getById(item._id))
        }
    }
    if (loading) return <Loader />
    if (!obj) return null


    const handleShowDeleteModal = (id) => {
        setCurrentId(id)
        setShowDeleteModal(true)
    }
    return (
        <div className="objRender">
            <div className="objRender__title">{mainTitle ? mainTitle : "Список материалов"}</div>
            {typeView === "list"
                ? <div className="objRender__listType">
                    <div className="objRender__listRow">
                        {obj.length === 0 ? <Loader /> :
                            obj.map((item) =>
                                <div className="objRender__item">
                                    <div className="objRender__imgContainer">
                                        {item.itemPictures?.length > 0
                                            ? <img
                                                src={generatePublicUrl(item.itemPictures[0].img)}
                                                alt={item.name}
                                            />
                                            : <p>Без Фото</p>}

                                    </div>
                                    <div className="objRender__itemContentBox"
                                        onClick={() => Edit(item, "details")}
                                    >
                                        <div className="objRender__itemName">
                                            {item.name}

                                        </div>
                                        <div className="objRender__itemDescription">
                                            {item.description}
                                        </div>
                                    </div>
                                    <div className="objRender__btnBox">
                                        <button
                                            className="objRender__btn objRender__download"
                                            onClick={() => Edit(item)}>
                                            Редактировать
                                        </button>
                                        <button
                                            className="objRender__btn objRender__delete"
                                            onClick={deleteModal ? () => handleShowDeleteModal(item._id) : () => dispatch(deleteFunc(item._id))}>
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                : <div className="objRender__row">
                    {obj.length === 0 ? <Loader /> :
                        obj.map((item) =>
                            <div className="objRender__mainBox"
                                key={item._id}>
                                <div className="objRender__block-name">
                                    <div className="objRender__name">
                                        {item.name}
                                    </div>
                                    <div className="objRender__description">
                                        {item.description}
                                    </div>
                                </div>
                                <div className="objRender__btnBox">
                                    <button
                                        className="objRender__btn objRender__download"
                                        onClick={() => Edit(item)}>
                                        Редактировать
                                    </button>
                                    <button
                                        className="objRender__btn objRender__delete "
                                        onClick={() => dispatch(deleteFunc(item._id))}>
                                        Удалить
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div >
    )
}

export default ObjRender