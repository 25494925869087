import axios from "../../helpers/axios"
import { paymentAccountConstants } from '../constants';
import { deletedPaymentAccount, updatedPaymentAccount } from '../api/paymentAccount';


export const getPaymentAccount = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_REQUEST })
            const res = await axios.get("/paymentAccount/get")
            if (res.status === 200) {
                const { paymentAccounts } = res.data
                dispatch({
                    type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_SUCCESS,
                    payload: {
                        paymentAccounts
                    }
                })
            } else {
                dispatch({ type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_FAILURE })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const addPaymentAccount = (form) => {
    return async (dispatch) => {
        try {
            dispatch({ type: paymentAccountConstants.ADD_PAYMENT_ACCOUNT_REQUEST })
            const res = await axios.post("/paymentAccount/create", form)
            if (res.status === 201) {
                dispatch({ type: paymentAccountConstants.ADD_PAYMENT_ACCOUNT_SUCCESS, payload: res.data })
            } else {
                dispatch({ type: paymentAccountConstants.ADD_PAYMENT_ACCOUNT_FAILURE, payload: res.data })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const updatePaymentAccount = (id, post, orderId) => async (dispatch) => {
    try {
        dispatch({ type: paymentAccountConstants.UPDATE_PAYMENT_ACCOUNT_REQUEST })
        const res = await updatedPaymentAccount(id, post)
        const { data } = res
        if (data) {
            dispatch({
                type: paymentAccountConstants.UPDATE_PAYMENT_ACCOUNT_SUCCESS,
                payload: data
            })
        }
        dispatch(getPaymentAccountsByOrderId(orderId))
    } catch (error) {
        console.log(error);
    }
}

export const deletePaymentAccount = (id, payload) => async (dispatch) => {
    try {
        dispatch({ type: paymentAccountConstants.DELETE_PAYMENT_ACCOUNT_REQUEST });
        const res = await deletedPaymentAccount(id);
        if (res.status === 200) {
            dispatch({ type: paymentAccountConstants.DELETE_PAYMENT_ACCOUNT_SUCCESS, payload: id });
        } else {
            dispatch({ type: paymentAccountConstants.DELETE_PAYMENT_ACCOUNT_FAILURE });
        }
        dispatch(getPaymentAccountsByOrderId(payload))
    } catch (error) {
        console.log(error);
    }
}

export const getPaymentAccountById = (id) => {
    return async dispatch => {
        dispatch({ type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ID_REQUEST });
        const res = await axios.get(`/paymentAccount/getPaymentAccountById/${id}`);
        try {
            if (res) {
                dispatch({
                    type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ID_SUCCESS,
                    payload: { paymentAccountDetails: res.data.paymentAccountDetails }
                });
            } else {
                dispatch({
                    type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ID_FAILURE,
                    payload: res
                });
            }
        } catch (error) {
            console.log(error);

        }
    }
}
export const getPaymentAccountsByOrderId = (payload) => {
    return async dispatch => {
        dispatch({ type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_REQUEST });
        const res = await axios.post(`/paymentAccount/getPaymentAccountsByOrderId`, payload);
        try {
            if (res.status === 200) {
                dispatch({
                    type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_SUCCESS,
                    payload: res.data
                });
            }
            if (res.status === 400) {
                dispatch({
                    type: paymentAccountConstants.GET_PAYMENT_ACCOUNT_BY_ORDER_ID_SUCCESS,
                    payload: res.data
                });
            }
        } catch (error) {
            console.log(error);

        }
    }
}