import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { addOrderProductsById, getProducts } from "../../actions";
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

const AddOrderProducts = ({
    orderItem,
    setClicked
}) => {
    const allProducts = useSelector((state) => state.product.allProducts)
    const [productIds, setProductIds] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            dispatch(getProducts())
        }
    }, [open])
    const dispatch = useDispatch()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setClicked(null)
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        const payload = {
            orderId: orderItem._id,
            productIds
        }
        dispatch(addOrderProductsById(payload)).then(() => handleClose())
    }
    const handleProductIdsChange = (event) => {
        setProductIds(event.target.value);

    };

    return (
        <div className="renderOrderDetails__addOrderProduct">
            {orderItem.disableFunctionality ? null : <Button
                variant="contained"
                endIcon={<AddIcon />}
                color="primary"
                onClick={handleClickOpen}>
                Добавить товары
            </Button>
            }
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Добавить новые товар к этому заказу</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Выберите нужные товары из списка, и к ним одну из категорию в списке ниже
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Товары</InputLabel>
                            <Select
                                multiple
                                value={productIds}
                                onChange={handleProductIdsChange}
                                renderValue={(selected) => `${selected.length} всего выбрано товаров`}
                            >
                                {allProducts.map((prod) => (
                                    <MenuItem value={prod._id}>{prod.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <DialogActions>
                            <Button onClick={handleClose}>Назад</Button>
                            <Button type="submit" color="primary">
                                Добавить новые товары
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddOrderProducts;