import { deliveryInfoConstants } from '../../actions/constants';



const initialState = {
    deliveryInfo: [],
    loading: false,
    deliveryInfoObj: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case deliveryInfoConstants.DELETE_DELIVERY_INFO_SUCCESS:
            return state.deliveryInfo.filter((deliveryInfo) => deliveryInfo._id !== action.payload)
        case deliveryInfoConstants.UPDATE_DELIVERY_INFO_SUCCESS:
            return state.deliveryInfo.map((deliveryInfo) => deliveryInfo._id === action.payload ? action.payload : deliveryInfo)
        case deliveryInfoConstants.GET_DELIVERY_INFO_SUCCESS:
            state = {
                ...state,
                deliveryInfo: action.payload.deliveryInfo
            }
            break;
        case deliveryInfoConstants.GET_DELIVERY_INFO_BY_ID_REQUEST:
            state = {
                ...state,
                loading: true,
            }
            break;
        case deliveryInfoConstants.GET_DELIVERY_INFO_BY_ID_SUCCESS:
            state = {
                ...state,
                deliveryInfoObj: action.payload.deliveryInfo,
                loading: false,
            }
            break;
        case deliveryInfoConstants.GET_DELIVERY_INFO_BY_ID_FAILURE:
            state = {
                ...state,
                loading: false,
            }
            break;
        default:
            return state
    }
    return state
}