import React, { useState } from 'react'
import { Search, Sort } from './Component';
import { setFileView } from "../reducers/product.reducer";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import SelectComponent from './SelectComponent';

const RenderFunctional = ({
    additionalData,
    noneSelectUpdatedPictures,
    sortSearchBlock,
    setUpdatedPictures,
    setSortReducer,
    setSortSearch,
    sortSearch,
    dispatchAction,
    setSearchMainName,
    updatedPictures,
    valueTabsSort,
    setPageNumber,
    setPageSize,
    productSort,
    hideMarked,
    buttonText,
    pageNumber,
    handleShow,
    sortItems,
    typeSort,
    sortType,
    pageSize,
    getSlug,
    showAll,
    search,
    type,
    slug,
    view,
    get,

}) => {
    const dispatch = useDispatch();

    const selectItem = [
        {
            value: "productsFullName",
            text: "Полное имя(СДМ 0001)"
        },
        {
            value: "productsD",
            text: "По подробному описанию"
        },
        {
            value: "productsCatN",
            text: "Имя категории"
        },
        {
            value: "categoryNamePlusMaterialText",
            text: "По категории + материал(бетон)"
        },
        {
            value: "allParameters",
            text: "По всем параметрам сразу"
        },
    ]

    let propsType
    switch (type) {
        case "fullFunctional":
            propsType = <Row>
                <Col md={12}>
                    <div className="disk">
                        <button className="disk__upload-label" onClick={handleShow}>
                            {buttonText || "Добавить"}
                        </button>
                        <div className="disk__searchContainer">

                            <Search
                                sortSearch={sortSearch}
                                search={search}
                                setSearchMainName={setSearchMainName}
                                sortType={sortType}
                                productSort={productSort && productSort}
                                get={get ? get : null}
                            />
                            <SelectComponent
                                title="Cортировка"
                                selectItem={selectItem}
                                value={sortSearch}
                                handleChange={(e) => setSortSearch(e.target.value)}
                                minWidth={250}
                            />
                        </div>
                        <div
                            className={`${view === "plate" ? "disk__plate active" : "disk__plate"}`}
                            onClick={() => dispatch(setFileView('plate'))}
                        >
                            <GridViewIcon />
                        </div>
                        <div
                            className={`${view === "list" ? "disk__list active" : "disk__list"}`}
                            onClick={() => dispatch(setFileView('list'))}
                        >
                            <FormatListBulletedIcon />
                        </div>
                    </div>

                </Col>
                <Col md={12}>
                    <Sort
                        noneSelectUpdatedPictures={noneSelectUpdatedPictures}
                        additionalData={additionalData}
                        sortType={sortType}
                        setSortReducer={setSortReducer}
                        dispatchAction={dispatchAction}
                        valueTabsSort={valueTabsSort}
                        productSort={productSort ? productSort : null}
                        setUpdatedPictures={setUpdatedPictures}
                        updatedPictures={updatedPictures}
                        setPageNumber={setPageNumber}
                        setPageSize={setPageSize}
                        hideMarked={hideMarked}
                        pageNumber={pageNumber}
                        get={get ? get : null}
                        sortItems={sortItems}
                        typeSort={typeSort}
                        pageSize={pageSize}
                        getSlug={getSlug}
                        showAll={showAll}
                        slug={slug}
                    />
                </Col>
            </Row>
            break;
        case "mediumFunctional":
            propsType = <Row>
                <Col md={12}>
                    <div
                        className={`${view === "plate" ? "disk__plate active" : "disk__plate"}`}
                        onClick={() => dispatch(setFileView('plate'))}
                    >
                        <GridViewIcon />
                    </div>
                    <div
                        className={`${view === "list" ? "disk__list active" : "disk__list"}`}
                        onClick={() => dispatch(setFileView('list'))}
                    >
                        <FormatListBulletedIcon />
                    </div>
                </Col>
            </Row>
            break;
        case "add":
            propsType = <Row>
                <Col md={12}>
                    <div className="disk">
                        <button className="disk__upload-label" onClick={handleShow}>
                            {buttonText || "Добавить"}
                        </button>
                    </div>
                </Col>
            </Row>
            break;
        default:
            propsType = <Row>
                <Col md={12}>
                    <div className="disk">
                        <Search
                            search={search}
                            get={get}
                        />
                        <Sort
                            additionalData={additionalData}
                            noneSelectUpdatedPictures={noneSelectUpdatedPictures}
                            dispatchAction={dispatchAction}
                            setPageNumber={setPageNumber}
                            setPageSize={setPageSize}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            productSort={productSort}
                            get={get}
                        />
                    </div>

                </Col>
            </Row>
    }
    return propsType
}

export default RenderFunctional